export default function TelegramIcon() {
  return (
    <svg
      width="15"
      height="12"
      viewBox="0 0 15 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4735 0C14.4272 0 14.381 8.79525e-08 14.3117 0.0231467L1.18371 5.02282C0.813905 5.1617 0.813907 5.69408 1.20682 5.80981L4.25769 6.82826L6.08358 10.5086C6.10669 10.578 6.17603 10.6243 6.26848 10.6243C6.31471 10.6243 6.36093 10.6012 6.38404 10.578L8.09438 9.11978L6.17603 7.66154L6.33782 9.58271L4.95106 6.71253L12.2778 2.29152L6.17603 7.63839L10.9372 11.2493C11.099 11.365 11.2839 11.4344 11.4688 11.4344C11.8386 11.4344 12.2084 11.1798 12.3009 10.7863L14.8664 0.48608C14.9588 0.254613 14.7508 0 14.4735 0Z"
        fill="currentColor"
      />
    </svg>
  );
}
