import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { ExecuteResult } from "@cosmjs/cosmwasm-stargate";
import { Coin, coin } from "@cosmjs/amino";

import { isNativeAsset, useContracts, getTokenDenom } from "modules/common";
import { useChain } from "@cosmos-kit/react";

export interface JoinPoolMutation {
  msg: {
    poolId: string;
    assets: any;
    recipient?: string;
    lpToMint?: any;
    slippageTolerance?: any;
    autoStake?: boolean;
  };
}

export function useJoinPoolMutation(
  options?: Omit<
    UseMutationOptions<ExecuteResult, Error, JoinPoolMutation>,
    "mutationFn"
  >,
) {
  const { vault } = useContracts();
  const persistenceChain =
    process.env.NEXT_PUBLIC_ENV === "testnet"
      ? "persistencetestnet2"
      : process.env.NEXT_PUBLIC_ENV === "devnet"
      ? "Dexter Devnet"
      : "persistence";
  const chainContext = useChain(persistenceChain);
  const { address, getSigningCosmWasmClient } = chainContext;
  const queryClient = useQueryClient();

  return useMutation<ExecuteResult, Error, JoinPoolMutation>(
    async ({ msg: { poolId, assets, autoStake } }) => {
      const signingClient = await getSigningCosmWasmClient();
      if (signingClient == null || address == null) {
        throw new Error("Missing signingClient in useJoinPoolMutation");
      }
      const funds = assets
        .filter(({ info }: any) => isNativeAsset(info))
        .map(({ amount, info }: any) => coin(amount, getTokenDenom(info)))
        .sort(function (a: Coin, b: Coin) {
          if (a.denom < b.denom) {
            return -1;
          }
          if (a.denom > b.denom) {
            return 1;
          }
          return 0;
        });
      const cw20Assets = assets.filter(({ info }: any) => !isNativeAsset(info));
      // TODO Remove Hardcoding of contractAddr and amount
      const allowanceMsgs = cw20Assets.map((asset) => {
        return {
          contractAddress: asset.info.token.contract_addr,
          msg: {
            increase_allowance: {
              amount: asset.amount,
              spender: vault,
            },
          },
          funds: [],
        };
      });

      return signingClient.executeMultiple(
        address,
        [
          ...allowanceMsgs,
          {
            contractAddress: vault,
            msg: {
              join_pool: {
                pool_id: poolId,
                assets,
                auto_stake: autoStake,
                // slippage_tolerance: "0.5",
              },
            },
            funds,
          },
        ],
        "auto",
        "",
      );
    },
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries(["balances"]);
        queryClient.invalidateQueries(["bondedLpTokens"]);
      },
    },
  );
}
