import { defineStyle, defineStyleConfig } from "@chakra-ui/styled-system";

const variants = {
  primary: {
    color: "primary.white",
    background: "primary.cta",
    borderRadius: "full",
    transition: "none",
    _focus: {
      boxShadow: "none",
    },
    _disabled: {
      background: "primary.ctaDisabled",
      opacity: 1,
      color: "rgba(255,255,255,0.4)",
    },
    _hover: {
      background: "primary.ctaHover",
      _disabled: {
        background: "primary.ctaDisabled",
      },
    },
    _active: {
      background: "primary.ctaPressed",
    },
  },
  secondary: {
    position: "relative",
    color: "primary.white",
    borderRadius: "full",
    background: "transparent",
    _focus: {
      boxShadow: "none",
    },
    _disabled: {
      background: "primary.ctaDisabled",
      opacity: 1,
      color: "rgba(255,255,255,0.4)",
    },
    _hover: {
      background: "primary.dexBlue50",
      borderColor: "stroke.dexBlue",
      _disabled: {
        background: "primary.ctaDisabled",
      },
    },
    _active: {
      background: "primary.dexBlue",
    },
    _before: {
      content: "''",
      position: "absolute",
      zIndex: -1,
      inset: 0,
      padding: "2px",
      borderRadius: "full",
      background:
        "linear-gradient(284.42deg, #00AFFA -49.92%, #32A2BA 54.24%, #24FFCA 169.83%)",
      WebkitMask:
        "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
      mask: "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
      WebkitMaskComposite: "xor",
      maskComposite: "exclude",
    },
  },
  tertiary: {
    color: "primary.white",
    backgroundColor: "transparent",
    borderWidth: "1px",
    borderColor: "primary.popStar",
    borderRadius: "full",
    _focus: {
      boxShadow: "none",
    },
    _disabled: {
      background: "primary.disabled",
      opacity: 1,
      color: "rgba(255,255,255,0.4)",
    },
    _hover: {
      background: "primary.popStar50",
      _disabled: {
        background: "primary.disabled",
      },
    },
    _active: {
      background: "primary.popStar",
    },
  },
  nav: {
    gap: "2",
    fontFamily: "heading",
    fontSize: "xl",
    _hover: {
      color: "primary.white",
    },
  },
  deposit: {
    gap: "1",
    color: "primary.white",
  },
  action: {
    color: "primary.white",
    background: "primary.dexBlue20",
    borderWidth: "1px",
    borderColor: "stroke.dexBlue",
    borderRadius: "full",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    p: "0",
    _focus: {
      boxShadow: "none",
    },
    _hover: {
      background: "primary.cta",
      color: "white",
      _disabled: {
        background: "primary.dexBlue20",
        color: "stroke.dexBlue",
      },
    },
    _disabled: {
      opacity: "0.3",
    },
    _active: {
      background: "primary.cta",
    },
    w: "30px",
    h: "30px",
    minWidth: "30px",
  },
};

const sizes = {
  sm: defineStyle({
    h: "36px",
    px: "6",
  }),
  md: defineStyle({
    h: "48px",
    px: "6",
  }),
  lg: defineStyle({
    h: "40px",
    fontSize: "md",
    px: "6",
  }),
  xl: defineStyle({
    h: "14",
    fontSize: "xl",
    px: "8",
  }),
};

export default {
  variants,
  sizes,
  defaultProps: {
    variant: "primary",
  },
};
