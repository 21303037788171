import { useMemo, useState } from "react";
import Fuse from "fuse.js";
import {
  ModalProps,
  Modal,
  Flex,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Box,
  Text,
  Divider,
  Link,
  Image,
  ModalFooter,
} from "@chakra-ui/react";

import {
  Wrapper,
  TokenPairType,
  WarningIcon,
  TokenSearch,
  useDexter,
} from "modules/common";
import { SelectTokenModalItem, SelectTokenModalItemPair } from "modules/swap";
import { useReactiveVar } from "@deevoid/reactive-var";
import { rewardsTokensVar, tokensVar } from "state/globalState";
import useStore from "state/store";

const TRENDING_PAIRS = [
  {
    from: "uxprt",
    to: "persistence1gqpfkchrz0hsmxsrfdxw0t9lhk0eg8m7zl3t9x9a95yenrgw5k0qtnk3ge",
  },
];

interface Props extends Pick<ModalProps, "isOpen" | "onClose"> {
  onSelect: (value: TokenPairType) => void;
  name: string;
}

export function SelectTokenModal({
  name,
  isOpen,
  onClose,
  onSelect,
  type = "swap",
}: Props) {
  const { tokens } = useDexter();
  const [search, setSearch] = useState("");
  const [searchedTokenPairs, setSearchedTokenPairs] = useState<TokenPairType[]>(
    [],
  );

  // const tokensGlobal = useReactiveVar(tokensVar);

  const tokensGlobal = useStore((state: any) => state.tokensVar);

  // const rewardsTokensGlobal = useReactiveVar(rewardsTokensVar);

  const rewardsTokensGlobal = useStore((state: any) => state.rewardsTokensVar);

  const tokensNew =
    type === "rewardTokens" ? rewardsTokensGlobal : tokensGlobal;
  const fuse = useMemo(() => {
    // @ts-expect-error - TODO
    const pairs =
      tokens &&
      Object.values(tokens)
        .filter((data) =>
          type === "swap" ? data.whitelist_for_swap === true : true,
        )
        // .filter(
        //   (data) =>
        //     data.identifier !==
        //     "ibc/A6E3AF63B3C906416A9AF7A556C59EA4BD50E617EFFE6299B99700CCB780E444",
        // )
        // .filter(
        //   (data) =>
        //     data.token !== tokensNew.token2 && data.token !== tokensNew.token1,
        //   // name === "token1"
        //   //   ? data.token !== tokensNew.token2
        //   //   : data.token !== tokensNew.token1,
        // )
        .map((data) => ({
          ...data,
          from: data.identifier,
          disabled: Object.values(tokensNew).some(
            (token) => data.identifier === token,
          ),
        }))
        .sort((x, y) => {
          return Number(x.disabled) - Number(y.disabled);
        });

    return new Fuse(pairs, { keys: ["ticker", "identifier"] });
  }, [tokens, tokensNew.token1, tokensNew.token2]);

  const tokenList = useMemo(() => {
    // @ts-expect-error - TODO
    return (
      tokens &&
      Object.values(tokens)
        .filter((data) =>
          type === "swap" ? data.whitelist_for_swap === true : true,
        )
        // .filter(
        //   (data) =>
        //     data.identifier !==
        //     "ibc/A6E3AF63B3C906416A9AF7A556C59EA4BD50E617EFFE6299B99700CCB780E444",
        // )
        // .filter(
        //   (data) =>
        //     data.token !== tokensNew.token2 && data.token !== tokensNew.token1,
        //   // name === "token1"
        //   //   ? data.token !== tokensNew.token2
        //   //   : data.token !== tokensNew.token1,
        // )
        .map((data) => ({
          ...data,
          from: data.identifier,
          disabled: Object.values(tokensNew).some(
            (token) => data.identifier === token,
          ),
        }))
        .sort((x, y) => {
          return Number(x.disabled) - Number(y.disabled);
        })
    );
  }, [tokens, tokensNew.token1, tokensNew.token2]);

  const handleSearchFrom = (search: string) => {
    setSearch(search);

    const newPairs = fuse.search(search).map((v) => v.item);
    setSearchedTokenPairs(newPairs);
  };
  const searchedElem =
    searchedTokenPairs.length > 0 ? (
      <>
        {searchedTokenPairs.map((tokenPair, index) => (
          <SelectTokenModalItem
            key={index}
            token={tokenPair}
            onClick={() => onSelect(tokenPair)}
          />
        ))}
      </>
    ) : (
      <Wrapper variant="swapError" mx="4">
        <WarningIcon boxSize="20px" />
        <Text variant="dexterP4" color="primary.white">
          Asset Not Available
        </Text>
      </Wrapper>
    );

  const suggestedElem = (
    <Box>
      {/* <Box mb="4" px="4">
        <Text mb="1" fontSize="sm">
          Trending Pairs
        </Text>

        <Flex wrap="wrap" gap="2">
          {TRENDING_PAIRS.map((pair) => {
            return (
              <SelectTokenModalItemPair
                key={`${pair.to}+${pair.from}`}
                pair={pair}
                onClick={() => onSelect(pair)}
              />
            );
          })}
        </Flex>
      </Box>
      <Divider borderColor={"stroke.dexBlue"} mb="4" /> */}
      {tokenList &&
        tokenList.map((tokenPair, index) => (
          <SelectTokenModalItem
            key={index}
            token={tokenPair}
            onClick={() => onSelect(tokenPair)}
          />
        ))}
    </Box>
  );

  const addonElem = search.length > 0 ? searchedElem : suggestedElem;

  return (
    <Modal
      variant={type == "swap" ? "secondary" : "tokenSelect"}
      size="md"
      isCentered
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text fontSize="26px">Select a Token</Text>
          <ModalCloseButton boxSize={8} fontSize={16} />
        </ModalHeader>
        <ModalBody px="0">
          <Flex flexDir="column" h="full" flex="1">
            <Box px="4">
              <TokenSearch
                w="full"
                value={search}
                onChange={(e) => handleSearchFrom(e.target.value)}
              />
              <Divider borderColor={"stroke.dexBlue"} mb="4" />
            </Box>
            <Box maxH="60vh" overflowY="auto">
              <Box flex="1">{addonElem}</Box>
            </Box>
          </Flex>
        </ModalBody>
        {type !== "swap" ? (
          <ModalFooter>
            {/* <IconAlertCircle size={"48px"} /> */}
            <Box>
              <Text
                variant="dexterP4"
                color="primary.white"
                fontWeight={600}
                fontSize={"18px"}
                mb="2"
              >
                Don&apos;t see your token?
              </Text>
              <Text
                variant="dexterP4"
                color="#D2D2D2"
                fontWeight={300}
                fontSize={"14px"}
                lineHeight={"21px"}
              >
                Contact the Dexter team on{" "}
                <Link
                  href="https://discord.persistence.one/"
                  isExternal
                  textDecoration={"underline"}
                  textDecorationColor={"stroke.dexBlue"}
                  textUnderlineOffset={"2px"}
                  bg={"primary.dexBlue"}
                  bgClip={"text"}
                >
                  Discord
                </Link>{" "}
                to request a new token.
              </Text>
            </Box>
          </ModalFooter>
        ) : null}
      </ModalContent>
    </Modal>
  );
}
