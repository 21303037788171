import { stkDydxCValueEndpoint } from "config";
import { useEffect, useState } from "react";

export function useDydxCValue() {
  const [cValue, setCValue] = useState(0.99);

  useEffect(() => {
    const getCValue = async () => {
      const cValueData = await fetch(stkDydxCValueEndpoint);
      const cValue = await cValueData.json();
      setCValue(cValue);
    };

    getCValue();
  });
  return cValue;
}
