import { useMemo } from "react";
import { Box, Text, HStack, Flex, VStack } from "@chakra-ui/react";
import { formatAmount, num, NumberFormatSpecifier } from "@wizard-ui/core";

import { TokenIcon, useTokenInfo, useDexter } from "modules/common";

interface Props {
  data: any;
  showColor?: boolean;
}

export function PoolClaimRewardsItem({ data, showColor = false }: Props) {
  const { prices, tokens } = useDexter();
  const { getSymbol } = useTokenInfo();

  const value = useMemo(() => {
    if (prices == null) {
      return 0;
    }

    const price = prices[data.token].price;

    if (price == null) {
      return 0;
    }

    return num(data.amount)
      .div(10 ** tokens[data.token].decimals)
      .times(price)
      .toNumber();
  }, [data, prices]);

  return (
    <>
      <HStack spacing={"2"} justify="flex-start">
        {showColor && <Box bg={data?.color} width="16px" height={"16px"} />}
        <TokenIcon token={data.token} />
        <Text variant={"dexterP6"}>{getSymbol(data.token)}</Text>
      </HStack>
      <HStack spacing="1" justify={"flex-end"}>
        <Text fontSize={["md", "md", "md", "xl", "xl", "xl"]} fontWeight="600">
          {formatAmount(
            num(data.amount).div(10 ** tokens[data.token].decimals),
            {
              // shouldDivide: true,
              formatSpecifier: NumberFormatSpecifier.FLOAT,
            },
          )}
        </Text>
        <Text fontSize="sm" color="#909090">
          (~
          {formatAmount(value, {
            formatSpecifier: NumberFormatSpecifier.CURRENCY,
          })}
          )
        </Text>
      </HStack>
    </>
  );
}
