import React from "react";
import { Control, ControllerProps } from "react-hook-form";

import { Wrapper } from "modules/common";
import {
  AmountInput,
  BalanceInput,
  TokenSelect,
  TokenDisplay,
} from "modules/swap";

interface Props {
  /**
   *  provide `name`, `control` & optional `rules`
   */
  amountInputName?: ControllerProps["name"];
  tokenInputName?: ControllerProps["name"];
  control: Control<any>;
  rules?: ControllerProps["rules"];
  /**
   * for readonly field, provide `value`
   */
  value?: string;
  token: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  hideSelect?: boolean;
  isLpToken?: boolean;
  max?: number | string;
  poolType?: string | null;
  onTokenChange?: (value: any) => void;
  onAmountChange?: (value: any) => void;
  isDeposit?: boolean;
  createPool?: boolean;
  rewardsSchedule?: boolean;
}

export function TokenInput({
  amountInputName,
  tokenInputName,
  token,
  poolType,
  control,
  isDisabled,
  isLoading,
  hideSelect = false,
  isLpToken = false,
  max,
  onTokenChange,
  onAmountChange,
  type = "swap",
  isDeposit = false,
  createPool = false,
  rewardsSchedule = false,
}: Props) {
  return (
    <Wrapper
      variant={createPool ? "createPoolLiq" : "primary"}
      p={"3"}
      bg={
        rewardsSchedule
          ? "rgba(0, 53.20, 71.19, 0.50)"
          : createPool
          ? "rgba(0, 53.20, 71.19, 0.50)"
          : "linear-gradient(284.42deg, rgba(0, 175, 250, 0.2) -49.92%, rgba(50, 162, 186, 0.2) 54.24%, rgba(36, 255, 202, 0.2) 169.83%);"
      }
    >
      <BalanceInput
        token={token}
        isLpToken={isLpToken}
        name={amountInputName}
        control={control}
        isDisabled={isDisabled}
        max={max}
        onChange={onAmountChange}
        poolType={poolType}
        isDeposit={isDeposit}
      />
      <Wrapper variant="swapInner" mt="3">
        {hideSelect ? (
          <TokenDisplay token={token} isLpToken={isLpToken} />
        ) : (
          <TokenSelect
            name={tokenInputName}
            control={control}
            isDisabled={isDisabled}
            onChange={onTokenChange}
            type={type}
          />
        )}
        <AmountInput
          isLpToken={isLpToken}
          token={token}
          name={amountInputName}
          control={control}
          isDisabled={isDisabled}
          isLoading={isLoading}
          onChange={onAmountChange}
          poolType={poolType}
        />
      </Wrapper>
    </Wrapper>
  );
}
