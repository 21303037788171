import { Text, Center, Flex, VStack, useMediaQuery } from "@chakra-ui/react";
import { Player } from "@lottiefiles/react-lottie-player";

import loading from "./loading.json";

export function TxPending() {
  const [isMobile] = useMediaQuery("(max-width: 800px)");
  return isMobile ? (
    <Flex justifyItems={"center"} align="center">
      <Player
        src={loading}
        autoplay={true}
        loop={true}
        style={{ width: "100px", margin: 0 }}
      />
      <VStack align={"flex-start"}>
        <Text
          fontSize={"16px"}
          fontWeight="600"
          mt="-4"
          color={"#FCFCFC"}
          fontFamily={"Nunito Sans"}
        >
          Transaction Broadcasting
        </Text>
        <Text
          mt="2"
          fontSize={"14px"}
          fontFamily={"Nunito Sans"}
          fontWeight={400}
          color={"#D2D2D2"}
        >
          Please wait until your transaction is confirmed on the blockchain
        </Text>
      </VStack>
    </Flex>
  ) : (
    <Flex justifyItems={"center"} align="center">
      <Player
        src={loading}
        autoplay={true}
        loop={true}
        style={{ width: "150px", margin: 0 }}
      />
      <VStack align={"flex-start"}>
        <Text
          fontSize={["10px", "10px", "10px", "24px", "24px", "24px"]}
          fontWeight="600"
          mt="-4"
          color={"#FCFCFC"}
          fontFamily={"Nunito Sans"}
        >
          Transaction Broadcasting
        </Text>
        <Text
          mt="2"
          color={"#D2D2D2"}
          fontFamily={"Nunito Sans"}
          fontWeight={400}
        >
          Please wait until your transaction is confirmed on the blockchain
        </Text>
      </VStack>
    </Flex>
  );
}
