import { useMemo, useState } from "react";
import {
  Box,
  ModalProps,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  chakra,
  Text,
  Flex,
  CloseButton,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { num } from "@wizard-ui/core";

import {
  PoolAsset,
  TxPending,
  TxSuccess,
  TxError,
  useDexter,
} from "modules/common";
import { TokenInput } from "modules/swap";
import { RemoveLiquidityAsset, PoolBase } from "modules/pools";
import { useExitPoolMutation } from "modules/contracts";

import { toast } from "react-toastify";
import { useChain, useManager } from "@cosmos-kit/react";

type FormValues = {
  token: string;
  amount: string;
};

interface RemoveLiquidityModalProps extends Omit<ModalProps, "children"> {
  pool: PoolBase;
}

export function RemoveLiquidityModal({
  pool,
  onClose,
  ...rest
}: RemoveLiquidityModalProps) {
  const { mutate, isLoading, data, reset, error } = useExitPoolMutation();
  const [isConfirmStep, setIsConfirmStep] = useState(false);
  const { balances } = useDexter();
  const persistenceChain =
    process.env.NEXT_PUBLIC_ENV === "testnet"
      ? "persistencetestnet2"
      : process.env.NEXT_PUBLIC_ENV === "devnet"
      ? "Dexter Devnet"
      : "persistence";
  const chainContext = useChain(persistenceChain);

  const { getWalletRepo, mainWallets } = useManager();
  const walletRepo = getWalletRepo(persistenceChain);

  const { connect, address, isWalletConnected } = chainContext;
  const tokens = pool.assets.map(({ identifier }) => identifier);
  const {
    handleSubmit,
    control,
    watch,
    reset: resetForm,
  } = useForm<FormValues>({
    mode: "onChange",
    defaultValues: {
      token: pool.lpToken,
      amount: "",
    },
  });

  const ratio = useMemo(() => {
    if (pool == null) {
      return {};
    }

    return pool.assets.reduce((prev: any, a: PoolAsset) => {
      return {
        ...prev,
        [a.identifier]: Number(a.amount) / Number(pool.lpTokenSupply),
      };
    }, {});
  }, [pool]);

  const { amount } = watch();

  const onSubmit = (values: FormValues) => {
    mutate({
      msg: {
        lpTokenAddr: pool.lpToken,
        poolId: String(pool.id),
        amount: num(values.amount)
          .times(10 ** 18)
          .toFixed(0),
      },
    });
  };

  const handleClose = () => {
    onClose();
    setIsConfirmStep(false);
    reset();
    resetForm();
  };

  const mobileWebModeName = "mobile-web";

  const connectHandler = () => {
    sessionStorage.setItem("terms", "show");
    if (
      (window?.leap && window?.leap?.mode === mobileWebModeName) ||
      window?.navigator?.userAgent?.includes("LeapCosmos")
    ) {
      const wallet = walletRepo.wallets.find(
        (wallet) => wallet._walletInfo.name === "leap-extension",
      );
      wallet._walletInfo.mobileDisabled = false;
      walletRepo.connect("leap-extension");
      return;
    } else if (window?.keplr && window?.keplr?.mode === mobileWebModeName) {
      const wallet = walletRepo.wallets.find(
        (wallet) => wallet._walletInfo.name === "keplr-extension",
      );
      wallet._walletInfo.mobileDisabled = false;
      walletRepo.connect("keplr-extension");
      return;
    } else {
      connect();
      return;
    }
  };

  const renderForm = () => {
    if (isConfirmStep || isLoading || data != null || error != null) {
      return null;
    }
    return (
      <Box>
        <TokenInput
          amountInputName="amount"
          tokenInputName="token"
          token={pool.lpToken}
          control={control}
          isLpToken
          hideSelect
          poolType={pool.poolType}
        />
        <Text textStyle="dexterP2" mt="8" mb="2">
          You Will Receive
        </Text>
        <Flex flexDir="column" gap="2" mb="8">
          {tokens.map((token) => {
            return (
              <RemoveLiquidityAsset
                key={token}
                amount={
                  !num(amount).isNaN()
                    ? num(amount)
                        .times(
                          token ===
                            "ibc/A6E3AF63B3C906416A9AF7A556C59EA4BD50E617EFFE6299B99700CCB780E444" ||
                            token ===
                              "ibc/23DC3FF0E4CBB53A1915E4C62507CB7796956E84C68CA49707787CB8BDE90A1E" ||
                            token === "stk/adydx"
                            ? 1
                            : token ===
                                "ibc/5D3B6445EA1D7064C4B1CCB588638589529556E1BCBADF13475021B42EA8C73B" ||
                              token ==
                                "ibc/CCA9F9B22D39884C09975D45E1869B73A12B87080EE53CB44905CE2C422CA228"
                            ? 10 ** 10
                            : 10 ** 12,
                        )
                        .times(ratio[token])
                        .toFixed(6)
                    : null
                }
                token={token}
              />
            );
          })}
        </Flex>
        <Button
          variant="primary"
          type="submit"
          w="full"
          size="xl"
          isDisabled={
            isWalletConnected &&
            (num(balances?.[pool.lpToken])
              .div(10 ** 18)
              .lt(num(amount)) ||
              amount === "" ||
              Number(amount) <= 0)
          }
          onClick={() => !isWalletConnected && connectHandler()}
        >
          {isWalletConnected ? "Remove Liquidity" : "Connect Wallet"}
        </Button>
      </Box>
    );
  };

  const CustomCloseButton = ({ closeToast }) => (
    <CloseButton
      pos={"absolute"}
      left="0"
      top={"0"}
      color="#FCFCFC"
      w={34}
      h={34}
      borderRadius={"50%"}
      border={"2px solid #386794"}
      bg={"#0a1d32"}
      onClick={() => {
        handleClose();
        closeToast();
      }}
    />
  );

  const renderFinal = () => {
    if (isLoading) {
      return toast(<TxPending />, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: false,
        toastId: " ",
        closeButton: CustomCloseButton,
      });
    } else {
      if (data == null && error != null) {
        return toast.update(" ", {
          render: <TxError />,
          closeButton: CustomCloseButton,
          autoClose: 7000,
        });
      }

      if (data == null) {
        return null;
      }

      return toast.update(" ", {
        render: () => (
          <TxSuccess
            subtitle="Liquidity Removed Successfully"
            data={data}
            title="Transaction Successful"
          />
        ),
        closeButton: CustomCloseButton,
        autoClose: 7000,
      });
    }
  };

  const hideTitle = isLoading || data != null || error != null;

  return !hideTitle ? (
    <Modal size="lg" onClose={handleClose} isCentered {...rest}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {!hideTitle && "Remove Liquidity"}
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <chakra.form onSubmit={handleSubmit(onSubmit)}>
            {renderForm()}
            {/* {renderConfirm()} */}
            {/* {renderPending()}
            {renderFinal()} */}
          </chakra.form>
        </ModalBody>
      </ModalContent>
    </Modal>
  ) : (
    renderFinal()
  );
}
