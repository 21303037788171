export default {
  variants: {
    primary: {
      thead: {
        tr: {
          borderBottom: "1px solid",
          borderBottomColor: "whiteAlpha.600",
          th: {
            fontSize: "sm",
            fontWeight: "semibold",
            _last: {
              pr: 0,
            },
          },
        },
      },
      tbody: {
        td: {
          _last: {
            pr: 0,
          },
        },
      },
    },
    leaderboard: {
      thead: {
        tr: {
          borderBottom: "1px solid",
          borderBottomColor: "whiteAlpha.600",
          th: {
            fontSize: "sm",
            fontWeight: "semibold",
            pl: "2rem",
            _last: {
              pr: 0,
              // pl: 1.5,
            },
            _first: {
              // pr: 0,
              pl: 0,
            },
          },
        },
      },
      tbody: {
        td: {
          _last: {
            pr: 0,
          },
        },
      },
    },
    secondary: {
      tbody: {
        td: {
          _first: {
            pl: "6rem",
            pr: "0rem",
          },
          _last: {
            pr: "3rem",
          },
        },
      },
    },
    tertiary: {
      tbody: {
        td: {
          _first: {
            pl: "6rem",
            pr: "0rem",
          },
          _last: {
            pr: "3.9rem",
          },
        },
      },
    },
    bondLp: {
      thead: {
        tr: {
          borderBottom: "1px solid",
          borderBottomColor: "whiteAlpha.600",
          th: {
            fontSize: "sm",
            fontWeight: "semibold",
            _last: {
              pr: 7,
            },
          },
        },
      },
    },
    fee_tiers: {
      table: {
        background: "primary.dexBlue20",
        borderWidth: "8px",
        overflow: "hidden",
        borderRadius: "14px",
        borderColor: "stroke.dexBlue",
      },
      thead: {
        tr: {
          borderBottom: "1px solid",
          borderBottomColor: "whiteAlpha.600",
        },
      },
      tbody: {
        tr: {
          borderBottom: "1px solid",
          borderBottomColor: "whiteAlpha.600",
          _last: {
            borderBottom: "none",
          },
        },
      },
    },
  },
};
