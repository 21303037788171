import { useState, useMemo } from "react";
import {
  TableContainer,
  Box,
  Flex,
  Table as ChakraTable,
  Thead,
  SimpleGrid,
  Tr,
  Heading,
  Th,
  Tbody,
  Td,
  Button,
  HStack,
  IconButton,
  Text,
  Center,
  Skeleton,
  useMediaQuery,
} from "@chakra-ui/react";
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  SortingState,
  FilterFn,
  useReactTable,
  Row,
} from "@tanstack/react-table";

import {
  Wrapper,
  GridViewIcon,
  ListViewIcon,
  FilterBar,
  ChevronDownIcon,
  ChevronUpIcon,
} from "modules/common";
import { PoolBase, PoolsGridItem } from "modules/pools";
import { PlusIcon } from "lucide-react";
import { useRouter } from "next/router";
import { intersection } from "lodash";

const fuzzyFilter: FilterFn<any> = (row, columnId, value) => {
  const data: PoolBase = row.getValue(columnId);
  if (typeof value == "string" && value.length != 0) {
    if (columnId == "Pool Name") {
      const tokensInPool =
        data !== undefined
          ? data.tokensListWithTicker
              .map((item) => item.toLowerCase())
              .join(",")
          : [];
      const matches = data !== undefined ? tokensInPool.includes(value) : false;
      const poolTypeMatches = data.poolType.includes(value);
      return matches || poolTypeMatches;
    }
    return false;
  }
  if (
    (value.poolTypes?.length == 0 && value.tokens?.length == 0) ||
    (value.tokens?.length == 0 && value.poolTypes?.length == 3)
  ) {
    return true;
  }

  // In case we want to show every pools with these assets
  if (columnId == "Pool Name") {
    const tokenIntersections = intersection(value.tokens, data.tokensList);
    const poolTypeIntersections = intersection(value.poolTypes, [
      data.poolType,
    ]);
    // TODO: write explanation for this condition
    return (
      (tokenIntersections.length > 0 && poolTypeIntersections.length > 0) ||
      (tokenIntersections.length == 0 &&
        poolTypeIntersections.length > 0 &&
        value.tokens.length == 0)
    );
  }
  return false;
};

interface Props {
  title: string;
  data: any;
  columns: any;
  onItemClick?: (v: any) => void;
  showSwitch?: boolean;
  loading?: boolean;
}

export function Table({
  title,
  data,
  columns,
  onItemClick,
  showSwitch = false,
  loading = false,
}: Props) {
  const [showGrid, setShowGrid] = useState(false);
  const [sorting, setSorting] = useState<SortingState>(
    title === "My Assets"
      ? []
      : [
          title === "My Pools"
            ? { id: "My Liquidity", desc: true }
            : { id: "Pool Liquidity", desc: true },
        ],
  );
  const [globalFilter, setGlobalFilter] = useState<string[]>([]);
  const [isMobile] = useMediaQuery("(max-width: 800px)");
  const router = useRouter();

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      globalFilter,
    },
    globalFilterFn: fuzzyFilter,
    initialState: { pagination: { pageSize: 15 } },
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getColumnCanGlobalFilter(column) {
      if (column.id == "Pool Name") {
        return true;
      }
      return false;
    },
  });

  const pages = useMemo(() => {
    if (table.getPageCount() == 0 || table.getPageCount() == 1) {
      return null;
    }

    return Array.from(Array(table.getPageCount()).keys());
  }, [table.getPageCount()]);
  function renderContent() {
    if (showGrid) {
      return (
        <SimpleGrid columns={[1, 1, 3, 4, 4]} gap={["4", "4", "6", "8", "12"]}>
          {table
            .getRowModel()
            .rows?.sort(
              (a, b) =>
                b.original.getMyLiquidityInUsd() -
                a.original.getMyLiquidityInUsd(),
            )
            .map((row: Row<any>, index: number) => (
              <PoolsGridItem
                key={index}
                pool={row.original}
                onClick={onItemClick}
              />
            ))}
        </SimpleGrid>
      );
    }

    return isMobile && title !== "My Assets" ? (
      <SimpleGrid columns={[1, 1, 3, 4, 4]} gap={["4", "4", "6", "8", "12"]}>
        {data?.map((pool: PoolBase, index: number) => (
          <PoolsGridItem key={index} pool={pool} onClick={onItemClick} />
        ))}
      </SimpleGrid>
    ) : (
      <Skeleton
        startColor="primary.prussianBlue80"
        endColor="primary.ctaDisabled"
        isLoaded={!loading}
      >
        <Wrapper variant="primary" px="2" py="6">
          <TableContainer>
            <ChakraTable variant="primary">
              <Thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <Tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <Th
                          key={header.id}
                          colSpan={header.colSpan}
                          w={header.getSize()}
                        >
                          {header.isPlaceholder ? null : (
                            <HStack
                              {...{
                                cursor: header.column.getCanSort()
                                  ? "pointer"
                                  : "",
                                onClick:
                                  header.column.getToggleSortingHandler(),
                              }}
                            >
                              <Text>
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext(),
                                )}
                              </Text>
                              <Center minW="0">
                                {{
                                  asc: <ChevronUpIcon />,
                                  desc: <ChevronDownIcon />,
                                }[header.column.getIsSorted() as string] ??
                                  null}
                              </Center>
                            </HStack>
                          )}
                        </Th>
                      );
                    })}
                  </Tr>
                ))}
              </Thead>
              <Tbody>
                {table.getRowModel().rows.map((row) => {
                  return (
                    <Tr
                      key={row.id}
                      _hover={{
                        bg: onItemClick != null ? "whiteAlpha.200" : undefined,
                        borderRadius: "10px",
                        cursor: onItemClick != null ? "pointer" : undefined,
                      }}
                      onClick={() => onItemClick?.(row.original)}
                    >
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <Td key={cell.id} w={cell.column.getSize()}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
              </Tbody>
            </ChakraTable>
          </TableContainer>
          {pages != null && (
            <Flex justify="center">
              <Button
                variant="unstyled"
                boxSize="30px"
                minW="auto"
                onClick={() => table.previousPage()}
                isDisabled={!table.getCanPreviousPage()}
              >
                {"<"}
              </Button>
              {pages.map((page) => {
                return (
                  <Button
                    key={page}
                    variant="unstyled"
                    borderRadius="full"
                    boxSize="30px"
                    minW="auto"
                    onClick={() => table.setPageIndex(page)}
                    bg={
                      page == table.getState().pagination.pageIndex
                        ? "primary.cta20"
                        : undefined
                    }
                  >
                    {page + 1}
                  </Button>
                );
              })}
              <Button
                variant="unstyled"
                boxSize="30px"
                minW="auto"
                onClick={() => table.nextPage()}
                isDisabled={!table.getCanNextPage()}
              >
                {">"}
              </Button>
            </Flex>
          )}
        </Wrapper>
      </Skeleton>
    );
  }

  return (
    <Box mt={[0, 0, 0, 8, 8, 8]}>
      <Flex justify="space-between" w="full" mb="6">
        <Heading variant="dexterH1">{title}</Heading>
        <HStack justify={"space-between"} spacing="4">
          {title === "All Pools" && !isMobile ? (
            <Button
              variant={"secondary"}
              size={"lg"}
              leftIcon={<PlusIcon size={16} />}
              onClick={() => {
                router.push("/create-pool");
              }}
            >
              Create New Pool
            </Button>
          ) : null}
          {showSwitch && (
            <HStack
              bgColor="primary.oxfordBlue"
              borderColor="primary.queenBlue"
              borderWidth="1px"
              borderRadius="lg"
              p="1"
            >
              <IconButton
                aria-label="Grid view"
                variant="unstyled"
                h="8"
                size="sm"
                color={showGrid ? "stroke.dexBlue" : "transparent"}
                opacity={showGrid ? 1 : 0.5}
                bg={showGrid ? "primary.prussianBlue" : undefined}
                icon={<GridViewIcon boxSize={6} />}
                onClick={() => setShowGrid(true)}
              />
              <IconButton
                aria-label="Grid view"
                variant="unstyled"
                color={!showGrid ? "stroke.dexBlue" : "transparent"}
                bg={!showGrid ? "primary.prussianBlue" : undefined}
                opacity={!showGrid ? 1 : 0.5}
                h="8"
                size="sm"
                icon={<ListViewIcon boxSize={6} />}
                onClick={() => setShowGrid(false)}
              />
            </HStack>
          )}
          {title !== "My Assets" && !isMobile ? (
            <FilterBar onChange={setGlobalFilter} />
          ) : null}
        </HStack>
      </Flex>

      {renderContent()}
    </Box>
  );
}
