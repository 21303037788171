import { useMemo } from "react";
import { Flex, HStack, Text } from "@chakra-ui/react";
import { NumberFormatSpecifier, formatAmount } from "@wizard-ui/core";
import { flattenDeep, uniq } from "lodash";

import {
  useTokenInfo,
  RouteIcon,
  useDexter,
  useContracts,
  TokenIcon,
} from "modules/common";
import { SwapBase } from "../models";
import { useQuery } from "@tanstack/react-query";
import {
  calculateMinReceivedAfterSlippage,
  calculateTotalSlippage,
} from "../helpers";
import React from "react";
import { useChain } from "@cosmos-kit/react";
import { TooltipWithTouch } from "modules/common/components/TooltipWithTouch";

interface Props {
  tokenIn: string;
  tokenOut: string;
  amountIn: string;
  maxSpread: string;
}

export function SwapInfo({ tokenIn, tokenOut, amountIn, maxSpread }: Props) {
  const { getSymbol } = useTokenInfo();
  const { prices, pools } = useDexter();
  const persistenceChain =
    process.env.NEXT_PUBLIC_ENV === "testnet"
      ? "persistencetestnet2"
      : process.env.NEXT_PUBLIC_ENV === "devnet"
      ? "Dexter Devnet"
      : "persistence";
  const chainContext = useChain(persistenceChain);
  const { getCosmWasmClient } = chainContext;
  const { router } = useContracts();
  const swap = useMemo(() => {
    if (prices == null) {
      return null;
    }
    const client = getCosmWasmClient().then((client) => {
      return client;
    });
    return new SwapBase({
      token1: tokenIn,
      token2: tokenOut,
      amount1: amountIn,
      amount2: "0",
      slippage: maxSpread,
      context: {
        pools,
        client,
        router,
        prices,
      },
    });
  }, [
    prices,
    tokenIn,
    tokenOut,
    amountIn,
    maxSpread,
    pools,
    getCosmWasmClient,
    router,
  ]);

  const { data } = useQuery(
    ["simulateSwap", swap],
    () => {
      if (swap == null || !swap.isReady) {
        throw new Error("swap model is not configured");
      }

      return swap.simulateSwap();
    },
    {
      enabled: swap != null && swap.isReady,
      // keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  const feePercentage =
    (data?.feesInUsd * 100) / (prices?.[tokenIn]?.price * Number(amountIn));

  const slippage = calculateTotalSlippage(data);
  const minReceived = calculateMinReceivedAfterSlippage(data, maxSpread);
  const swapRoute: string[] = useMemo(() => {
    return uniq(
      flattenDeep(
        data?.swaps.map((swap: any) => {
          // return [getSymbol(swap.tokenIn), getSymbol(swap.tokenOut)];
          return [swap.tokenIn, swap.tokenOut];
        }),
      ),
    );
  }, [data?.swaps]);

  return (
    <Flex flexDir="column" gap="2">
      <Flex justify="space-between">
        <Text textStyle="label">Swap Route</Text>
        <HStack textStyle="value">
          {swapRoute.map((value, index) => (
            <HStack key={index}>
              <TooltipWithTouch
                hasArrow
                label={getSymbol(value)}
                aria-label="A tooltip"
              >
                <span>
                  <TokenIcon size="6" token={value} />
                </span>
              </TooltipWithTouch>

              {/* <Text>{getSymbol(value)}</Text> */}
              {(index == 0 || index != swapRoute.length - 1) && (
                <RouteIcon w="8" />
              )}
            </HStack>
          ))}
        </HStack>
      </Flex>
      <Flex justify="space-between">
        <Text textStyle="label">Swap Fee ({feePercentage.toFixed(2)}%)</Text>
        {data?.feesInUsd < 0.01 ? (
          <Text textStyle="value">&lt; $0.01 </Text>
        ) : (
          <Text textStyle="value">
            {formatAmount(data?.feesInUsd, {
              formatSpecifier: NumberFormatSpecifier.CURRENCY,
            })}
          </Text>
        )}
      </Flex>
      <Flex justify="space-between">
        <Text textStyle="label">Price Impact</Text>
        <Text textStyle="value">{Number(slippage)?.toFixed(2)}%</Text>
      </Flex>
      <Flex justify="space-between">
        <Text textStyle="label">
          Min. Received after slippage ({maxSpread}%)
        </Text>
        <Text textStyle="value" textAlign={"end"}>
          {minReceived} {getSymbol(tokenOut)} <br />~{" "}
          {formatAmount(prices?.[tokenOut]?.price * Number(minReceived), {
            formatSpecifier: NumberFormatSpecifier.CURRENCY,
          })}
        </Text>
      </Flex>
    </Flex>
  );
}
