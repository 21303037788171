import { useMemo } from "react";
import { num } from "@wizard-ui/core";

import { getTokenDenom, useDexter } from "modules/common";

export function usePoolUnclaimedRewardsPerDenom(lpToken: string) {
  const { unclaimedRewards } = useDexter();

  return useMemo(() => {
    if (unclaimedRewards == null) {
      return null;
    }

    const unclaimedRewardsFlat = unclaimedRewards[lpToken].map(
      ({ asset, amount }: any) => {
        return { token: getTokenDenom(asset), amount };
      },
    );

    return unclaimedRewardsFlat.reduce((acc, value: Record<string, any>) => {
      const isAlready = acc[value.token];

      if (!isAlready) {
        return { ...acc, [value.token]: value.amount };
      }

      const newValue = { ...acc } as Record<string, any>;

      newValue[value.token] = num(newValue[value.token])
        .plus(value.amount)
        .toString();

      return newValue;
    }, {});
  }, [unclaimedRewards]);
}
