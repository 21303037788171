import React, { useMemo } from "react";
import { Flex, Text, Box, HStack, VStack } from "@chakra-ui/react";
import { NumberFormatSpecifier, formatAmount, num } from "@wizard-ui/core";

import { TokenIcon, useTokenInfo, useDexter } from "modules/common";

interface Props {
  token: string;
  amount?: string;
  isLpToken?: boolean;
  poolType?: string | null;
  showAvailableBalance?: boolean;
}

export function TokenDisplay({
  token,
  amount,
  poolType,
  isLpToken = false,
  showAvailableBalance = false,
}: Props) {
  const { getSymbol } = useTokenInfo();
  const { prices, pools, balances, tokens } = useDexter();

  const balance = isLpToken
    ? num(balances?.[token]).div(10 ** 18)
    : num(balances?.[token]).div(10 ** tokens[token].decimals);

  const totalPrice = useMemo(() => {
    if (amount == null || prices == null) {
      return null;
    }
    if (isLpToken) {
      return (
        pools
          ?.filter((pool) => pool.lpToken == token)[0]
          .getLpAmountInUsd(
            num(amount)
              .times(10 ** 18)
              .toFixed(0),
          ) || "0"
      );
    }
    return num(prices?.[token]?.price).times(amount).toString();
  }, [amount, isLpToken, pools, prices, token]);

  const pool = useMemo(() => {
    if (!isLpToken || pools == null) {
      return null;
    }

    return pools.find((p) => p.lpToken == token);
  }, [isLpToken]);

  const renderToken = () => {
    if (!isLpToken) {
      return (
        <Flex align="center" gap="2">
          <TokenIcon size={["8", "8", "8", "10", "10", "10"]} token={token} />
          <div>
            <Text variant="dexterP1" fontWeight={700}>
              {getSymbol(token)}
            </Text>
            {showAvailableBalance ? (
              <Text mt="-1" variant="dexterP4" color="#FCFCFC">
                Available: {formatAmount(balance)}
              </Text>
            ) : null}
          </div>
        </Flex>
      );
    }

    return (
      <Flex align="center" gap={[4, 4, 4, 2, 2, 2]}>
        <HStack spacing="-2">
          {pool?.tokensList.map((t) => {
            return (
              <TokenIcon
                size={["8", "8", "8", "10", "10", "10"]}
                key={t}
                token={t}
              />
            );
          })}
        </HStack>
        <Text
          variant="dexterP1"
          fontSize={["md", "md", "md", "lg", "lg", "lg"]}
        >
          {`${pool?.tokensList.map((t) => getSymbol(t)).join("-")} LP`}
        </Text>
      </Flex>
    );
  };

  return (
    <Flex justify="space-between" width={"100%"}>
      {renderToken()}
      {amount && (
        <Box textAlign="right">
          <Text fontWeight="600" fontSize="xl">
            {formatAmount(amount, {
              formatSpecifier: NumberFormatSpecifier.FLOAT,
            })}
          </Text>
          <Text color="whiteAlpha.800" fontSize="sm" fontWeight="600">
            {formatAmount(totalPrice, {
              formatSpecifier: NumberFormatSpecifier.CURRENCY,
            })}
          </Text>
        </Box>
      )}
    </Flex>
  );
}
