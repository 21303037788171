import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { ExecuteResult } from "@cosmjs/cosmwasm-stargate";

import { useContracts, useDexter } from "modules/common";
import { useChain } from "@cosmos-kit/react";

export interface PoolClaimRewardsMutation {
  msg: {
    lpToken: string;
  };
}

export function usePoolClaimRewardsMutation(
  options?: Omit<
    UseMutationOptions<ExecuteResult, Error, PoolClaimRewardsMutation>,
    "mutationFn"
  >,
) {
  const { multistaking } = useContracts();
  const persistenceChain =
    process.env.NEXT_PUBLIC_ENV === "testnet"
      ? "persistencetestnet2"
      : process.env.NEXT_PUBLIC_ENV === "devnet"
      ? "Dexter Devnet"
      : "persistence";
  const chainContext = useChain(persistenceChain);
  const { address, getSigningCosmWasmClient } = chainContext;
  const { lpTokens } = useDexter();
  const queryClient = useQueryClient();

  return useMutation<ExecuteResult, Error, PoolClaimRewardsMutation>(
    async ({ msg: { lpToken } }) => {
      const signingClient = await getSigningCosmWasmClient();
      if (signingClient == null || address == null || lpTokens == null) {
        throw new Error("Missing signingClient in usePoolClaimRewardsMutation");
      }
      const msg = {
        withdraw: {
          lp_token: lpToken,
        },
      };

      return signingClient.execute(address, multistaking, msg, "auto", "", []);
    },
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries(["balances"]);
        queryClient.invalidateQueries(["unclaimedRewards"]);
      },
    },
  );
}
