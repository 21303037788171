import { Icon, IconProps } from "@chakra-ui/react";

export function GridViewIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        d="M4.5 14.5C4.5 14.258 4.60932 14.0099 4.83136 13.8156C5.05553 13.6195 5.37228 13.5 5.71429 13.5H9.14286C9.48487 13.5 9.80162 13.6195 10.0258 13.8156C10.2478 14.0099 10.3571 14.258 10.3571 14.5V17.5C10.3571 17.742 10.2478 17.9901 10.0258 18.1844C9.80162 18.3805 9.48487 18.5 9.14286 18.5H5.71429C5.37228 18.5 5.05553 18.3805 4.83135 18.1844C4.60932 17.9901 4.5 17.742 4.5 17.5V14.5ZM13.6429 14.5C13.6429 14.258 13.7522 14.0099 13.9742 13.8156C14.1984 13.6195 14.5151 13.5 14.8571 13.5H18.2857C18.6277 13.5 18.9445 13.6195 19.1686 13.8156C19.3907 14.0099 19.5 14.258 19.5 14.5V17.5C19.5 17.742 19.3907 17.9901 19.1686 18.1844C18.9445 18.3805 18.6277 18.5 18.2857 18.5H14.8571C14.5151 18.5 14.1984 18.3805 13.9742 18.1844C13.7522 17.9901 13.6429 17.742 13.6429 17.5V14.5Z"
        fill="currentColor"
        stroke="#32A2BA"
      />
      <path
        d="M4.5 6.5C4.5 6.25797 4.60932 6.00991 4.83136 5.81563C5.05553 5.61948 5.37228 5.5 5.71429 5.5H9.14286C9.48487 5.5 9.80162 5.61948 10.0258 5.81563C10.2478 6.00991 10.3571 6.25797 10.3571 6.5V9.5C10.3571 9.74203 10.2478 9.99009 10.0258 10.1844C9.80162 10.3805 9.48487 10.5 9.14286 10.5H5.71429C5.37228 10.5 5.05553 10.3805 4.83135 10.1844C4.60932 9.99009 4.5 9.74203 4.5 9.5V6.5ZM13.6429 6.5C13.6429 6.25797 13.7522 6.00991 13.9742 5.81563C14.1984 5.61948 14.5151 5.5 14.8571 5.5H18.2857C18.6277 5.5 18.9445 5.61948 19.1686 5.81563C19.3907 6.00991 19.5 6.25797 19.5 6.5V9.5C19.5 9.74203 19.3907 9.99009 19.1686 10.1844C18.9445 10.3805 18.6277 10.5 18.2857 10.5H14.8571C14.5151 10.5 14.1984 10.3805 13.9742 10.1844C13.7522 9.99009 13.6429 9.74203 13.6429 9.5V6.5Z"
        fill="currentColor"
        stroke="#32A2BA"
      />
    </Icon>
  );
}
