import { stkAtomCValueEndpoint } from "config";
import { useEffect, useState } from "react";

export function useCValue() {
  const [cValue, setCValue] = useState(0.84);

  useEffect(() => {
    const getCValue = async () => {
      const cValueData = await fetch(stkAtomCValueEndpoint);
      const cValue = await cValueData.json();
      setCValue(cValue);
    };

    getCValue();
  });
  return cValue;
}
