export default {
  variants: {
    dexterSearchSmall: {
      field: {
        textStyle: "dexterP3",
        fontSize: null,
        color: "primary.white",
        bgColor: "primary.oxfordBlue",
        px: "8px",
        height: "40px",
        display: "flex",
        alignItems: "center",
        borderRadius: "6px",
        borderWidth: "1px",
        borderColor: "primary.queenBlue",
        w: "244px",
      },
    },
    dexterModalInput: {
      field: {
        color: "primary.white",
        bgColor: "primary.prussianBlue20",
        borderColor: "primary.queenBlue",
        borderWidth: "1px",
        borderRadius: "5px",
        textAlign: "right",
        pl: "8px",
        py: "8px",
        pr: "24px",
        height: "45px",
        textStyle: "dexterP3",
        fontSize: "18px",
        lineHeight: "160%",
        fontWeight: 600,
      },
    },
    dexterInline: (props: {
      [key: string]: string;
    }): Record<string, unknown> => ({
      field: {
        textStyle: () => {
          switch (props.size) {
            case "sm":
              return "dexterP5";
            case "md":
              return "dexterP2";
            case "lg":
              return "dexterP1";
          }
        },
        borderRadius: "0px",
        fontSize: null,
        fontWeight: null,
        lineHeight: null,
        bgColor: "transparent",
        height: "fit-content",
        // width: "auto",
        p: "0",
        textAlign: "right",
      },
    }),
  },
};
