import { num } from "@wizard-ui/core";
import { cloneDeep } from "lodash";
import { getTokenDenom } from "modules/common";

import { PoolBase } from "modules/pools";
import { PoolDictionary, SwapHop, hopDictionary } from "modules/swap";

/*
The purpose of this function is to build dictionaries of direct pools
and plausible hop pools.
*/
export function filterPoolsOfInterest(
  allPools: PoolDictionary,
  tokenIn: string,
  tokenOut: string,
): [PoolDictionary, hopDictionary, hopDictionary, hopDictionary] {
  const directPools: PoolDictionary = {};
  const hopsIn: hopDictionary = {};
  const hopsOut: hopDictionary = {};
  const hopsMiddle: hopDictionary = {};

  Object.keys(allPools).forEach((id) => {
    const pool = allPools[id];
    const tokenListSet = new Set(pool.tokensList);
    const containsTokenIn = tokenListSet.has(tokenIn);
    const containsTokenOut = tokenListSet.has(tokenOut);
    // This is a direct pool as has both tokenIn and tokenOut
    if (containsTokenIn && containsTokenOut) {
      directPools[pool.id] = pool;
      return;
    }

    if (containsTokenIn && !containsTokenOut) {
      // @ts-expect-error - TODO
      for (const hopToken of tokenListSet) {
        if (!hopsIn[hopToken]) hopsIn[hopToken] = new Set([]);
        hopsIn[hopToken].add(String(pool.id));
      }
    } else if (!containsTokenIn && containsTokenOut) {
      // @ts-expect-error - TODO
      for (const hopToken of [...tokenListSet]) {
        if (!hopsOut[hopToken]) hopsOut[hopToken] = new Set([]);
        hopsOut[hopToken].add(String(pool.id));
      }
    } else if (
      !containsTokenIn &&
      !containsTokenOut &&
      ((tokenIn === "stk/uatom" &&
        tokenOut ===
          "ibc/A6E3AF63B3C906416A9AF7A556C59EA4BD50E617EFFE6299B99700CCB780E444") ||
        (tokenIn ===
          "ibc/A6E3AF63B3C906416A9AF7A556C59EA4BD50E617EFFE6299B99700CCB780E444" &&
          tokenOut === "stk/uatom"))
    ) {
      // @ts-expect-error - TODO
      for (const hopToken of [...tokenListSet]) {
        if (!hopsMiddle[hopToken]) hopsMiddle[hopToken] = new Set([]);
        hopsMiddle[hopToken].add(String(pool.id));
      }
      return;
    }
  });
  return [directPools, hopsIn, hopsOut, hopsMiddle];
}

export function producePaths(
  tokenIn: string,
  tokenOut: string,
  directPools: PoolDictionary,
  hopsIn: hopDictionary,
  hopsOut: hopDictionary,
  pools: PoolDictionary,
): SwapHop[][] {
  const paths: any = [];

  // Create direct paths
  for (const id in directPools) {
    const path = createPath([tokenIn, tokenOut], [pools[id]]);
    paths.push(path);
  }

  for (const hopToken in hopsIn) {
    if (hopsOut[hopToken]) {
      let highestNormalizedLiquidityFirst = num(0);
      let highestNormalizedLiquidityFirstPoolId: string | undefined; // Aux variable to find pool with most liquidity for pair (tokenIn -> hopToken)
      let highestNormalizedLiquiditySecond = num(0);
      let highestNormalizedLiquiditySecondPoolId: string | undefined; // Aux variable to find pool with most liquidity for pair (hopToken -> tokenOut)

      // @ts-expect-error - TODO
      for (const poolInId of [...hopsIn[hopToken]]) {
        const poolIn = pools[poolInId];
        const normalizedLiquidity = poolIn.getTotalLiquidityInUsd();

        if (
          normalizedLiquidity != null &&
          num(normalizedLiquidity).gte(highestNormalizedLiquidityFirst)
        ) {
          highestNormalizedLiquidityFirst = num(normalizedLiquidity);
          highestNormalizedLiquidityFirstPoolId = String(poolIn.id);
        }
      }

      // @ts-expect-error - TODO
      for (const poolOutId of [...hopsOut[hopToken]]) {
        const poolOut = pools[poolOutId];
        const normalizedLiquidity = poolOut.getTotalLiquidityInUsd();

        if (
          normalizedLiquidity != null &&
          num(normalizedLiquidity).gte(highestNormalizedLiquiditySecond)
        ) {
          highestNormalizedLiquiditySecond = num(normalizedLiquidity);
          highestNormalizedLiquiditySecondPoolId = String(poolOut.id);
        }
      }

      if (
        highestNormalizedLiquidityFirstPoolId &&
        highestNormalizedLiquiditySecondPoolId
      ) {
        const path = createPath(
          [tokenIn, hopToken, tokenOut],
          [
            pools[highestNormalizedLiquidityFirstPoolId],
            pools[highestNormalizedLiquiditySecondPoolId],
          ],
        );
        paths.push(path);
      }
    }
  }
  return paths;
}

// Creates a path with pools.length hops
// i.e. tokens[0]>[Pool0]>tokens[1]>[Pool1]>tokens[2]>[Pool2]>tokens[3]
export function createPath(tokens: string[], pools: PoolBase[]): SwapHop[] {
  let tI: string, tO: string;
  const swaps: SwapHop[] = [];
  let id = "";

  for (let i = 0; i < pools.length; i++) {
    tI = tokens[i];
    tO = tokens[i + 1];
    id = id + pools[i].id;

    const swap: SwapHop = {
      pool: pools[i],
      tokenIn: tI,
      tokenOut: tO,
      tokenInDecimals: 6,
      tokenOutDecimals: 6,
    };

    swaps.push(swap);
  }

  return swaps;
}

export function createPathCustom(
  tokenIn: string,
  tokenOut: string,
  hopsIn: hopDictionary,
  hopsMiddle: hopDictionary,
  hopsOut: hopDictionary,
  pools: PoolDictionary,
): SwapHop[] {
  const path: SwapHop[] = [];

  // Add the first hop from the input token to the first pool
  const firstPool = pools[hopsIn[tokenIn].values().next().value];
  path.push({
    pool: firstPool,
    tokenIn: tokenIn,
    tokenOut: firstPool.tokensList.find((token) => token !== tokenIn),
    tokenInDecimals: 6,
    tokenOutDecimals: 6,
  });

  // Add the middle hops
  let currentToken = path[0].tokenOut;
  const nextPool = pools[hopsMiddle[currentToken].values().next().value];
  path.push({
    pool: nextPool,
    tokenIn: currentToken,
    tokenOut: nextPool.tokensList.find((token) => token !== currentToken),
    tokenInDecimals: 6,
    tokenOutDecimals: 6,
  });
  currentToken = path[path.length - 1].tokenOut;

  // Add the last hop from the last pool to the output token
  const lastPool = pools[hopsOut[tokenOut].values().next().value];
  path.push({
    pool: lastPool,
    tokenIn: lastPool.tokensList.find((token) => token !== tokenOut),
    tokenOut: tokenOut,
    tokenInDecimals: 6,
    tokenOutDecimals: 6,
  });

  return path;
}

export function parseToPoolsDict(pools: PoolBase[]): PoolDictionary {
  return Object.fromEntries(
    cloneDeep(pools)
      .filter((pool) => pool.tokensList.length > 0)
      .map((pool) => [pool.id, pool]),
  );
}

export function getTotalFeesInUsd(fees: any[], prices: Record<string, number>) {
  return fees.reduce((acc, value) => {
    const token = getTokenDenom(value.info);
    return num(value.amount)
      .div(
        token ==
          "ibc/A6E3AF63B3C906416A9AF7A556C59EA4BD50E617EFFE6299B99700CCB780E444" ||
          token ==
            "ibc/23DC3FF0E4CBB53A1915E4C62507CB7796956E84C68CA49707787CB8BDE90A1E" ||
          token == "stk/adydx"
          ? 10 ** 18
          : token ==
              "ibc/5D3B6445EA1D7064C4B1CCB588638589529556E1BCBADF13475021B42EA8C73B" ||
            token ==
              "ibc/CCA9F9B22D39884C09975D45E1869B73A12B87080EE53CB44905CE2C422CA228"
          ? 10 ** 8
          : 10 ** 6,
      )
      .times(prices[token].price)
      .plus(acc)
      .toNumber();
  }, 0);
}

/** 
  @description 
  Current pool price (cpp) = (askAssetCurrentPoolTokenCount * askAssetWeight / offerAssetCurrentPoolTokenCount * offerAssetWeight ))
  Current trade price (ctp) = (askAmount (as given by swap query)) / offerAmount (as input) - fee)

  Price Impact total (PIT) = cpp - ctp

  slippage = (100*PIT)/cpp --> PIT is what percent of cpp 
  */
export function calculateTotalSlippage(data) {
  const calculateSlippage = (
    poolAssets,
    tokenIn: string,
    amountIn,
    tokenOut: string,
    amountOut,
    feePercentage,
  ) => {
    const ibcAtom =
      process.env.NEXT_PUBLIC_ENV == "mainnet"
        ? "ibc/C8A74ABBE2AF892E15680D916A7C22130585CE5704F9B17A10F184A90D53BECA"
        : "ibc/C4CFF46FD6DE35CA4CF4CE031E643C8FDC9BA4B99AE598E9B0ED98FE3A2319F9";
    const ibcPstake =
      process.env.NEXT_PUBLIC_ENV == "mainnet"
        ? "ibc/A6E3AF63B3C906416A9AF7A556C59EA4BD50E617EFFE6299B99700CCB780E444"
        : "";
    const ibcUSDT =
      process.env.NEXT_PUBLIC_ENV == "mainnet"
        ? "ibc/C559977F5797BDC1D74C0836A10C379C991D664166CB60D776A83029852431B4"
        : "";

    const ibcUSDC =
      process.env.NEXT_PUBLIC_ENV == "mainnet"
        ? "ibc/B3792E4A62DF4A934EF2DF5968556DB56F5776ED25BDE11188A4F58A7DD406F0"
        : "";

    const ibcDYDX =
      process.env.NEXT_PUBLIC_ENV == "mainnet"
        ? "ibc/23DC3FF0E4CBB53A1915E4C62507CB7796956E84C68CA49707787CB8BDE90A1E"
        : "ibc/18200EAA7E5BB3D235FF517F04045F4DCB0691CE6FC1B32E4297BEA8EF7710E3";

    const ibcSHD =
      "ibc/5D3B6445EA1D7064C4B1CCB588638589529556E1BCBADF13475021B42EA8C73B";

    const ibcWBTC =
      "ibc/CCA9F9B22D39884C09975D45E1869B73A12B87080EE53CB44905CE2C422CA228";

    const ibcStars =
      "ibc/AD8E1D4AC4EA8FC79CC46E33319A3791477D4DEBFC30D5D874074B993422B41B";

    const mainnetWeightsAtom = {
      uxprt: "50",
      [ibcAtom]: "50",
    };
    const mainnetWeightsPStake = {
      uxprt: "50",
      [ibcPstake]: "50",
    };
    const testnetWeightsAtom = {
      uxprt: "90",
      [ibcAtom]: "10",
    };
    const testnetWeightsPStake = {
      uxprt: "90",
      [ibcPstake]: "10",
    };
    const mainnetWeightsXprtUsdt = {
      uxprt: "50",
      [ibcUSDT]: "50",
    };
    const mainnetWeightsUsdtUsdc = {
      uxprt: "50",
      [ibcUSDC]: "50",
    };

    const mainnetWeightsDydxUsdc = {
      [ibcUSDC]: "50",
      [ibcDYDX]: "50",
    };
    const mainnetWeightsShdXprt = {
      [ibcSHD]: "50",
      uxprt: "50",
    };
    const mainnetWeightsStarsXprt = {
      [ibcStars]: "50",
      uxprt: "50",
    };
    const mainnetWeightsWBTCXprt = {
      [ibcWBTC]: "50",
      uxprt: "50",
    };
    const weightsAtom =
      process.env.NEXT_PUBLIC_ENV == "mainnet"
        ? mainnetWeightsAtom
        : testnetWeightsAtom;
    const weightsPStake =
      process.env.NEXT_PUBLIC_ENV == "mainnet"
        ? mainnetWeightsPStake
        : testnetWeightsPStake;

    const weightsXprtUsdt = mainnetWeightsXprtUsdt;
    const weightsUsdtUsdc = mainnetWeightsUsdtUsdc;
    const weightsDydxUsdc = mainnetWeightsDydxUsdc;
    const weightsShdXprt = mainnetWeightsShdXprt;
    const weightsStarsXprt = mainnetWeightsStarsXprt;
    const weightsWbtcXprt = mainnetWeightsWBTCXprt;

    const weights = poolAssets.some(
      (asset) =>
        asset.identifier ===
        "ibc/A6E3AF63B3C906416A9AF7A556C59EA4BD50E617EFFE6299B99700CCB780E444",
    )
      ? weightsPStake
      : poolAssets.some(
          (asset) =>
            asset.identifier ===
            "ibc/23DC3FF0E4CBB53A1915E4C62507CB7796956E84C68CA49707787CB8BDE90A1E",
        )
      ? weightsDydxUsdc
      : poolAssets.some(
          (asset) =>
            asset.identifier ===
            "ibc/B3792E4A62DF4A934EF2DF5968556DB56F5776ED25BDE11188A4F58A7DD406F0",
        )
      ? weightsUsdtUsdc
      : poolAssets.some(
          (asset) =>
            asset.identifier ===
            "ibc/C559977F5797BDC1D74C0836A10C379C991D664166CB60D776A83029852431B4",
        )
      ? weightsXprtUsdt
      : poolAssets.some(
          (asset) =>
            asset.identifier ===
            "ibc/5D3B6445EA1D7064C4B1CCB588638589529556E1BCBADF13475021B42EA8C73B",
        )
      ? weightsShdXprt
      : poolAssets.some(
          (asset) =>
            asset.identifier ===
            "ibc/AD8E1D4AC4EA8FC79CC46E33319A3791477D4DEBFC30D5D874074B993422B41B",
        )
      ? weightsStarsXprt
      : poolAssets.some(
          (asset) =>
            asset.identifier ===
            "ibc/CCA9F9B22D39884C09975D45E1869B73A12B87080EE53CB44905CE2C422CA228",
        )
      ? weightsWbtcXprt
      : weightsAtom;

    const askAssetCurrentPoolTokenCount = num(
      poolAssets.filter((poolAsset) => poolAsset.identifier === tokenOut)[0]
        .amount,
    ).div(
      tokenOut ==
        "ibc/A6E3AF63B3C906416A9AF7A556C59EA4BD50E617EFFE6299B99700CCB780E444" ||
        tokenOut ==
          "ibc/23DC3FF0E4CBB53A1915E4C62507CB7796956E84C68CA49707787CB8BDE90A1E" ||
        tokenOut == "stk/adydx"
        ? 10 ** 18
        : tokenOut ==
            "ibc/5D3B6445EA1D7064C4B1CCB588638589529556E1BCBADF13475021B42EA8C73B" ||
          tokenOut ==
            "ibc/CCA9F9B22D39884C09975D45E1869B73A12B87080EE53CB44905CE2C422CA228"
        ? 10 ** 8
        : 10 ** 6,
    );
    const offerAssetCurrentPoolTokenCount = num(
      poolAssets.filter((poolAsset) => poolAsset.identifier === tokenIn)[0]
        .amount,
    ).div(
      tokenIn ==
        "ibc/A6E3AF63B3C906416A9AF7A556C59EA4BD50E617EFFE6299B99700CCB780E444" ||
        tokenIn ==
          "ibc/23DC3FF0E4CBB53A1915E4C62507CB7796956E84C68CA49707787CB8BDE90A1E" ||
        tokenIn == "stk/adydx"
        ? 10 ** 18
        : tokenIn ==
            "ibc/5D3B6445EA1D7064C4B1CCB588638589529556E1BCBADF13475021B42EA8C73B" ||
          tokenIn ==
            "ibc/CCA9F9B22D39884C09975D45E1869B73A12B87080EE53CB44905CE2C422CA228"
        ? 10 ** 8
        : 10 ** 6,
    );

    const currentPoolPrice = askAssetCurrentPoolTokenCount
      .div(weights[tokenOut])
      .div(offerAssetCurrentPoolTokenCount.div(weights[tokenIn]));
    const currentTradePrice = num(amountOut)
      .div(
        tokenOut ===
          "ibc/A6E3AF63B3C906416A9AF7A556C59EA4BD50E617EFFE6299B99700CCB780E444" ||
          tokenOut ===
            "ibc/23DC3FF0E4CBB53A1915E4C62507CB7796956E84C68CA49707787CB8BDE90A1E" ||
          tokenOut === "stk/adydx"
          ? 10 ** 18
          : tokenOut ==
              "ibc/5D3B6445EA1D7064C4B1CCB588638589529556E1BCBADF13475021B42EA8C73B" ||
            tokenOut ==
              "ibc/CCA9F9B22D39884C09975D45E1869B73A12B87080EE53CB44905CE2C422CA228"
          ? 10 ** 8
          : 10 ** 6,
      )
      .div(
        num(amountIn)
          .div(
            tokenIn ===
              "ibc/A6E3AF63B3C906416A9AF7A556C59EA4BD50E617EFFE6299B99700CCB780E444" ||
              tokenIn ===
                "ibc/23DC3FF0E4CBB53A1915E4C62507CB7796956E84C68CA49707787CB8BDE90A1E" ||
              tokenIn === "stk/adydx"
              ? 10 ** 18
              : tokenIn ==
                  "ibc/5D3B6445EA1D7064C4B1CCB588638589529556E1BCBADF13475021B42EA8C73B" ||
                tokenIn ==
                  "ibc/CCA9F9B22D39884C09975D45E1869B73A12B87080EE53CB44905CE2C422CA228"
              ? 10 ** 8
              : 10 ** 6,
          )
          .minus(
            num(feePercentage)
              .div(100)
              .times(
                num(amountIn).div(
                  tokenIn ===
                    "ibc/A6E3AF63B3C906416A9AF7A556C59EA4BD50E617EFFE6299B99700CCB780E444" ||
                    tokenIn ===
                      "ibc/23DC3FF0E4CBB53A1915E4C62507CB7796956E84C68CA49707787CB8BDE90A1E" ||
                    tokenIn === "stk/adydx"
                    ? 10 ** 18
                    : tokenIn ==
                        "ibc/5D3B6445EA1D7064C4B1CCB588638589529556E1BCBADF13475021B42EA8C73B" ||
                      tokenIn ==
                        "ibc/CCA9F9B22D39884C09975D45E1869B73A12B87080EE53CB44905CE2C422CA228"
                    ? 10 ** 8
                    : 10 ** 6,
                ),
              ),
          ),
      );

    const priceImpactTotal = currentPoolPrice.minus(currentTradePrice);
    const slippage = priceImpactTotal
      .times(100)
      .div(currentPoolPrice)
      .toFixed(4);
    return slippage;
  };

  const slippageArr = data?.swaps.map((swap, index) => {
    if (swap.pool.poolType === "weighted") {
      return calculateSlippage(
        swap.pool.assets,
        swap.tokenIn,
        swap.amountIn,
        swap.tokenOut,
        swap.amountOut,
        0.3,
      );
    } else {
      return calculatePriceImpactStableSwap(data);
    }
  });
  const slippage = slippageArr?.reduce((a, b) => Number(a) + Number(b));
  return slippage;
}

export const calculatePriceImpactStableSwap = (data) => {
  const spread = num(data?.swaps[0].spread).div(10 ** 6);
  const priceImpact = spread
    .times(100)
    .div(num(data?.swaps[0].amountIn).div(10 ** 6))
    .toFixed(4);
  return priceImpact;
};

export const calculateMinReceivedAfterSlippage = (data, maxSpread) => {
  const swapInfo = data?.swaps[data.swaps.length - 1];
  const askAsset = swapInfo?.tokenOut;
  const askAssetAmount = num(swapInfo?.amountOut).div(
    askAsset ===
      "ibc/A6E3AF63B3C906416A9AF7A556C59EA4BD50E617EFFE6299B99700CCB780E444" ||
      askAsset ===
        "ibc/23DC3FF0E4CBB53A1915E4C62507CB7796956E84C68CA49707787CB8BDE90A1E" ||
      askAsset === "stk/adydx"
      ? 10 ** 18
      : askAsset ==
          "ibc/5D3B6445EA1D7064C4B1CCB588638589529556E1BCBADF13475021B42EA8C73B" ||
        askAsset ==
          "ibc/CCA9F9B22D39884C09975D45E1869B73A12B87080EE53CB44905CE2C422CA228"
      ? 10 ** 8
      : 10 ** 6,
  );
  const minReceived = askAssetAmount.minus(
    num(maxSpread).div(100).times(askAssetAmount),
  );

  return minReceived.toFixed(6);
};

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find(
    (key) => object[key].ticker.toLowerCase() === value.toLowerCase(),
  );
};
