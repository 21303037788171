import { useState } from "react";
import { Box, HStack, Button, useMediaQuery } from "@chakra-ui/react";

import {
  DepositAssetModal,
  ExternalLinkIcon,
  WithdrawAssetModal,
} from "modules/common";
import LazyHydrate from "react-lazy-hydration";
import Link from "next/link";
import { ExternalLink } from "lucide-react";

interface Props {
  data: {
    token: string;
    hasIBC: boolean;
  };
}

export function MyAssetsActions({ data }: Props) {
  const [isMobile] = useMediaQuery("(max-width: 800px)");
  const [showDepositAssetModal, setShowDepositAssetModal] = useState(false);
  const [showWithdrawAssetModal, setShowWithdrawAssetModal] = useState(false);
  return (
    <Box mt={[2, 2, 0, 0, 0]}>
      {data.hasIBC && (
        <>
          <HStack
            spacing={[4, 4, 4, 12, 12, 12]}
            justify={[
              "space-between",
              "space-between",
              "flex-start",
              "flex-start",
              "flex-start",
            ]}
          >
            {data.token ===
            "ibc/5D3B6445EA1D7064C4B1CCB588638589529556E1BCBADF13475021B42EA8C73B" ? (
              <Link
                href={
                  "https://dash.scrt.network/ibc?chain=persistence&mode=withdrawal&token=shd"
                }
                target="__blank"
                rel="nopenner noreferrer"
              >
                <Button
                  variant="primary"
                  size="sm"
                  width={["full", "full", "full", "8rem", "8rem", "8rem"]}
                  //  onClick={() => setShowDepositAssetModal(true)}
                  rightIcon={<ExternalLink size={16} />}
                >
                  Deposit
                </Button>
              </Link>
            ) : (
              <Button
                variant="primary"
                size="sm"
                width={["full", "full", "full", "8rem", "8rem", "8rem"]}
                onClick={() => setShowDepositAssetModal(true)}
              >
                Deposit
              </Button>
            )}
            <Button
              variant="secondary"
              size="sm"
              width={["full", "full", "full", "8rem", "8rem", "8rem"]}
              onClick={() => setShowWithdrawAssetModal(true)}
            >
              Withdraw
            </Button>
          </HStack>
          {showDepositAssetModal ? (
            <LazyHydrate whenVisible>
              <DepositAssetModal
                token={data.token}
                isOpen={showDepositAssetModal}
                onClose={() => setShowDepositAssetModal(false)}
              />
            </LazyHydrate>
          ) : null}
          {showWithdrawAssetModal ? (
            <LazyHydrate whenVisible>
              <WithdrawAssetModal
                token={data.token}
                isOpen={showWithdrawAssetModal}
                onClose={() => setShowWithdrawAssetModal(false)}
              />
            </LazyHydrate>
          ) : null}
        </>
      )}
    </Box>
  );
}
