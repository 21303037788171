import React, { useEffect, useMemo } from "react";
import { usePoolUnclaimedRewardsInUsd } from "../hooks/usePoolUnclaimedRewardsInUsd";
import { usePoolUnclaimedRewardsPerDenom } from "../hooks/usePoolUnclaimedRewardsPerDenom";
import {
  Flex,
  VStack,
  SimpleGrid,
  Text,
  Button,
  CloseButton,
} from "@chakra-ui/react";
import { formatAmount, NumberFormatSpecifier } from "@wizard-ui/core";
import { PoolClaimRewardsItem } from "./PoolClaimRewardsItem";
import { usePoolClaimRewardsMutation } from "modules/contracts/usePoolClaimRewardsMutation";
import { toast } from "react-toastify";
import { TxError, TxPending, TxSuccess } from "modules/common";

const PoolUnclaimedRewardsItem = ({ item }) => {
  const unclaimedRewardsInUsd = usePoolUnclaimedRewardsInUsd(item.pool.lpToken);
  const unclaimedRewardsPerDenom = usePoolUnclaimedRewardsPerDenom(
    item.pool.lpToken,
  );
  const items = useMemo(() => {
    if (unclaimedRewardsPerDenom == null) {
      return [];
    }

    return Object.keys(unclaimedRewardsPerDenom).map((key) => {
      return {
        token: key,
        amount: unclaimedRewardsPerDenom[key],
      };
    });
  }, [unclaimedRewardsPerDenom]);

  const { mutate, isLoading, data, reset, error } =
    usePoolClaimRewardsMutation();

  const handleClick = () => {
    mutate({
      msg: {
        lpToken: item.pool.lpToken,
      },
    });
  };
  const CustomCloseButton = ({ closeToast }) => (
    <CloseButton
      pos={"absolute"}
      left="0"
      top={"0"}
      color="#FCFCFC"
      w={34}
      h={34}
      borderRadius={"50%"}
      border={"2px solid #386794"}
      bg={"#0a1d32"}
      onClick={() => {
        // handleClose();
        closeToast();
      }}
    />
  );

  useEffect(() => {
    const renderFinal = () => {
      if (isLoading) {
        return toast(<TxPending />, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: false,
          toastId: "",
          closeButton: CustomCloseButton,
        });
      } else {
        if (data == null && error != null) {
          return toast.update("", {
            render: <TxError />,
            closeButton: CustomCloseButton,
            autoClose: 7000,
          });
        }

        if (data == null) {
          return null;
        }

        return toast.update("", {
          render: () => (
            <TxSuccess
              subtitle="Rewards Successfully Claimed"
              data={data}
              title="Transaction Successful"
            />
          ),
          closeButton: CustomCloseButton,
          autoClose: 7000,
        });
      }
    };
    if (!isLoading || data == null || error == null) {
      renderFinal();
    }

    // return () => {
    //   reset();
    // };
  }, [isLoading, data, error]);
  return (
    <Flex
      bg={"primary.dexBlue20"}
      p="4"
      gap={4}
      justify={"center"}
      borderRadius={"12"}
      w="full"
      // h={16}
    >
      <SimpleGrid columns={2} gap={2} alignItems="center" rowGap={0}>
        {items?.map((data) => {
          return <PoolClaimRewardsItem key={data.token} data={data} />;
        })}
      </SimpleGrid>
      <Button
        variant="secondary"
        size="sm"
        isDisabled={unclaimedRewardsInUsd === null}
        alignSelf={"center"}
        onClick={handleClick}
      >
        Claim Rewards
      </Button>
    </Flex>
  );
};

export default PoolUnclaimedRewardsItem;
