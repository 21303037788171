import { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Text,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  Divider,
  Flex,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Image,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  SimpleGrid,
} from "@chakra-ui/react";

import {
  ArrowDownIcon,
  TokenIcon,
  useTokenInfo,
  useDexter,
  SearchIcon,
  ArrowUpIcon,
} from "modules/common";
import { MinusIcon, PlusIcon } from "lucide-react";
interface Props {
  onChange: (val: string[]) => void;
}
const poolTypes = [
  {
    label: "Metastable",
    value: "metastable",
    icon: "./images/metastable_pool_ticker.svg",
  },
  {
    label: "Stable Swap",
    value: "stableswap",
    icon: "./images/stableswap_pool_ticker.svg",
  },
  {
    label: "Weighted",
    value: "weighted",
    icon: "./images/weighted_pool_ticker.svg",
  },
];
export function FilterBar({ onChange }: Props) {
  const { tokens } = useDexter();
  const { getSymbol } = useTokenInfo();
  const [selectedFilters, setSelectedFilters] = useState<any>({
    tokens: [],
    poolTypes: [],
  });
  const [showAllTokens, setShowAllTokens] = useState(false);
  const [search, setSearch] = useState("");

  const handleChangeToken = (token: string, checked: boolean) => {
    setSearch("");
    if (checked) {
      selectedFilters.poolTypes.length == 0
        ? setSelectedFilters((prev) => ({
            ...prev,
            tokens: [...prev.tokens, token],
            poolTypes: [
              ...prev.poolTypes,
              "metastable",
              "stableswap",
              "weighted",
            ],
          }))
        : setSelectedFilters((prev) => ({
            ...prev,
            tokens: [...prev.tokens, token],
          }));
    } else {
      selectedFilters.poolTypes.length == 0
        ? setSelectedFilters((prev) => ({
            ...prev,
            tokens: prev.tokens.filter((t) => t !== token),
            poolTypes: [
              ...prev.poolTypes,
              "metastable",
              "stableswap",
              "weighted",
            ],
          }))
        : setSelectedFilters((prev) => ({
            ...prev,
            tokens: prev.tokens.filter((t) => t !== token),
          }));
    }
  };
  const handleChangePoolType = (poolType: string, checked: boolean) => {
    setSearch("");
    if (checked) {
      setSelectedFilters((prev) => ({
        ...prev,
        poolTypes: [...prev.poolTypes, poolType],
      }));
    } else {
      setSelectedFilters((prev) => ({
        ...prev,
        poolTypes: prev.poolTypes.filter((t) => t !== poolType),
      }));
    }
  };

  // const onSelectAll = () => {
  //   setSelectedTokens(Object.keys(tokens));
  // };

  const onClear = () => {
    setSelectedFilters({ tokens: [], poolTypes: [] });
  };

  const handleSearchFrom = (search: string) => {
    setSelectedFilters({ tokens: [], poolTypes: [] });
    setSearch(search);
  };

  useEffect(() => {
    if (search !== "") {
      onChange(search);
    } else {
      onChange(selectedFilters);
    }
  }, [selectedFilters, search]);

  if (tokens == null) {
    return null;
  }

  return (
    <HStack>
      <InputGroup w={"184px"}>
        <InputLeftElement pointerEvents="none">
          <SearchIcon boxSize={6} />
        </InputLeftElement>
        <Input
          variant={"dexterSearchSmall"}
          placeholder="Search"
          // h="40px"
          value={search}
          onChange={(e) => handleSearchFrom(e.target.value)}
        />
      </InputGroup>
      <Menu
        variant="filterBar"
        placement="bottom-end"
        isLazy
        closeOnSelect={false}
        onClose={() => setShowAllTokens(false)}
      >
        {({ isOpen }) => (
          <>
            <MenuButton>
              <HStack>
                <Text fontSize={"16px"} fontWeight={500}>
                  Filter
                  {selectedFilters.tokens.length +
                    selectedFilters.poolTypes.length >
                  0
                    ? ` / ${
                        selectedFilters.tokens.length +
                        selectedFilters.poolTypes.length
                      }`
                    : null}
                </Text>
                {isOpen ? <ArrowUpIcon /> : <ArrowDownIcon boxSize="4" />}
              </HStack>
            </MenuButton>

            <MenuList w={"350px"}>
              <Flex justify={"space-between"} px={4} py={2} align={"center"}>
                <Text fontSize={"14px"} fontWeight={700}>
                  Filter By
                </Text>
                <Text
                  onClick={onClear}
                  _hover={{ cursor: "pointer", textDecoration: "underline" }}
                  textTransform={"uppercase"}
                  fontSize={"10px"}
                >
                  Clear All
                </Text>
              </Flex>
              <Accordion allowToggle>
                <AccordionItem borderColor={"stroke.dexBlue"}>
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton px={4}>
                        <Box
                          fontSize={"12px"}
                          color="#D2D2D2"
                          fontWeight={700}
                          as="span"
                          flex="1"
                          textAlign="left"
                        >
                          Asset
                        </Box>
                        {isExpanded ? (
                          <MinusIcon size={16} />
                        ) : (
                          <PlusIcon size={16} />
                        )}
                      </AccordionButton>
                      <AccordionPanel pb={4} px={0}>
                        <SimpleGrid ml={4} columns={2}>
                          {Object.keys(tokens)
                            .slice(0, 4)
                            .map((token) => (
                              <Box key={token}>
                                <Checkbox
                                  _hover={{ bg: "primary.dexBlue" }}
                                  isChecked={selectedFilters.tokens.includes(
                                    token,
                                  )}
                                  onChange={(e) =>
                                    handleChangeToken(token, e.target.checked)
                                  }
                                  px="3"
                                  py="2"
                                  w="full"
                                  className="pool-filter-checkbox"
                                >
                                  <HStack spacing="2">
                                    <TokenIcon token={token} size="6" />
                                    <Text fontSize={12}>
                                      {getSymbol(token)}
                                    </Text>
                                  </HStack>
                                </Checkbox>
                              </Box>
                            ))}
                        </SimpleGrid>
                        {Object.keys(tokens).length > 4 && !showAllTokens ? (
                          <Button
                            variant="link"
                            leftIcon={<PlusIcon size={16} />}
                            onClick={() => setShowAllTokens(true)}
                            color={"#ECECEC"}
                            iconSpacing={1}
                            ml={6}
                            mt={2}
                            fontSize={12}
                            textUnderlineOffset={"2px"}
                          >
                            {Object.keys(tokens).length - 4} More
                          </Button>
                        ) : null}
                        <SimpleGrid ml={4} columns={2}>
                          {showAllTokens &&
                            Object.keys(tokens)
                              .slice(4)
                              .map((token) => (
                                <Box key={token}>
                                  <Checkbox
                                    _hover={{ bg: "primary.dexBlue" }}
                                    isChecked={selectedFilters.tokens.includes(
                                      token,
                                    )}
                                    onChange={(e) =>
                                      handleChangeToken(token, e.target.checked)
                                    }
                                    px="3"
                                    py="2"
                                    w="full"
                                    className="pool-filter-checkbox"
                                  >
                                    <HStack spacing="2">
                                      <TokenIcon token={token} size="6" />
                                      <Text fontSize={12}>
                                        {getSymbol(token)}
                                      </Text>
                                    </HStack>
                                  </Checkbox>
                                </Box>
                              ))}
                        </SimpleGrid>
                        {Object.keys(tokens).length > 4 && showAllTokens ? (
                          <Button
                            variant="link"
                            leftIcon={<MinusIcon size={16} />}
                            onClick={() => setShowAllTokens(false)}
                            color={"#ECECEC"}
                            iconSpacing={1}
                            ml={6}
                            mt={2}
                            fontSize={12}
                            textUnderlineOffset={"2px"}
                          >
                            Show less
                          </Button>
                        ) : null}
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>

                <AccordionItem
                  borderColor={"stroke.dexBlue"}
                  borderBottom={"none"}
                >
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton px={4}>
                        <Box
                          fontSize={"12px"}
                          color="#D2D2D2"
                          fontWeight={700}
                          as="span"
                          flex="1"
                          textAlign="left"
                        >
                          Pool Type
                        </Box>
                        {isExpanded ? (
                          <MinusIcon size={16} />
                        ) : (
                          <PlusIcon size={16} />
                        )}
                      </AccordionButton>
                      <AccordionPanel pb={4} px={0}>
                        {poolTypes.map((poolType) => (
                          <Box key={poolType.value} ml={4}>
                            <Checkbox
                              _hover={{ bg: "primary.dexBlue" }}
                              isChecked={selectedFilters.poolTypes.includes(
                                poolType.value,
                              )}
                              onChange={(e) =>
                                handleChangePoolType(
                                  poolType.value,
                                  e.target.checked,
                                )
                              }
                              // width={"10px"}
                              px="3"
                              py="2"
                              w="full"
                              className="pool-filter-checkbox"
                            >
                              <HStack spacing="2" justify={"space-between"}>
                                <Image
                                  src={poolType.icon}
                                  boxSize={"24px"}
                                  alt="pool icon"
                                />
                                <Text>{poolType.label}</Text>
                              </HStack>
                            </Checkbox>
                          </Box>
                        ))}
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              </Accordion>

              <Divider size={"6"} borderColor="#909090" />
            </MenuList>
          </>
        )}
      </Menu>
    </HStack>
  );
}
