import { Box, Flex, Text } from "@chakra-ui/react";
import { formatAmount, num, NumberFormatSpecifier } from "@wizard-ui/core";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  TokenIcon,
  useDexter,
  useTokenInfo,
  Wrapper,
} from "modules/common";
import React, { useState } from "react";
import { MyAssetsActions } from "./MyAssetsActions";

const AssetItemMobile = ({ asset }) => {
  const { prices, balances } = useDexter();
  const { getSymbol } = useTokenInfo();
  const [showButtons, setShowButtons] = useState(false);
  const token = asset.token;
  const balance = balances?.[token as keyof typeof balances];
  const value = num(balances?.[token])
    .div(10 ** 6)
    // @ts-expect-error - TODO
    .times(prices?.[token].price)
    .toNumber();
  return (
    <Wrapper variant="secondary" p={3} borderRadius={6}>
      <Flex justify="space-between">
        <Flex align="center" gap="3">
          <TokenIcon size="8" token={token} />
          <Text variant="dexterP1" fontWeight="bold" fontSize={"xl"}>
            {getSymbol(token)}
          </Text>
        </Flex>
        <Flex align={"center"}>
          <Box textAlign="right">
            <Text fontWeight="600" fontSize="xl">
              {formatAmount(
                token ===
                  "ibc/A6E3AF63B3C906416A9AF7A556C59EA4BD50E617EFFE6299B99700CCB780E444" ||
                  token ===
                    "ibc/23DC3FF0E4CBB53A1915E4C62507CB7796956E84C68CA49707787CB8BDE90A1E" ||
                  token === "stk/adydx"
                  ? num(balance).div(10 ** 12)
                  : token ===
                      "ibc/5D3B6445EA1D7064C4B1CCB588638589529556E1BCBADF13475021B42EA8C73B" ||
                    token ===
                      "ibc/CCA9F9B22D39884C09975D45E1869B73A12B87080EE53CB44905CE2C422CA228"
                  ? num(balance).div(10 ** 2)
                  : num(balance),
                {
                  formatSpecifier: NumberFormatSpecifier.FLOAT,
                  shouldDivide: true,
                },
              )}
            </Text>
            <Text color="whiteAlpha.600" fontSize="sm">
              {formatAmount(
                token ===
                  "ibc/A6E3AF63B3C906416A9AF7A556C59EA4BD50E617EFFE6299B99700CCB780E444" ||
                  token ===
                    "ibc/23DC3FF0E4CBB53A1915E4C62507CB7796956E84C68CA49707787CB8BDE90A1E" ||
                  token === "stk/adydx"
                  ? num(value).div(10 ** 12)
                  : token ===
                      "ibc/5D3B6445EA1D7064C4B1CCB588638589529556E1BCBADF13475021B42EA8C73B" ||
                    token ===
                      "ibc/CCA9F9B22D39884C09975D45E1869B73A12B87080EE53CB44905CE2C422CA228"
                  ? num(value).div(10 ** 2)
                  : num(value),
                {
                  formatSpecifier: NumberFormatSpecifier.CURRENCY,
                },
              )}
            </Text>
          </Box>
          {asset.hasIBC ? (
            <Box
              onClick={() => setShowButtons((showButtons) => !showButtons)}
              ml={1}
            >
              {showButtons ? (
                <ChevronUpIcon boxSize={5} />
              ) : (
                <ChevronDownIcon boxSize={5} />
              )}
            </Box>
          ) : null}
        </Flex>
      </Flex>
      {showButtons ? <MyAssetsActions data={asset} /> : null}
    </Wrapper>
  );
};

export default AssetItemMobile;
