import { Icon, IconProps } from "@chakra-ui/react";

export function RefreshIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path
        d="M13.6485 2.35C12.908 1.60485 12.0271 1.01356 11.0568 0.610231C10.0865 0.206901 9.0459 -0.000494355 7.995 8.84845e-07C3.57223 8.84845e-07 0 3.58 0 8C0 12.42 3.57223 16 7.995 16C11.7273 16 14.8393 13.45 15.7298 10H13.6485C13.2363 11.1695 12.4711 12.1824 11.4585 12.8988C10.4459 13.6153 9.23572 14 7.995 14C4.68293 14 1.99124 11.31 1.99124 8C1.99124 4.69 4.68293 2 7.995 2C9.65603 2 11.137 2.69 12.2176 3.78L8.99562 7H16V8.84845e-07L13.6485 2.35Z"
        fill="currentColor"
      />
      <defs>
        <linearGradient
          id="paint0_linear_619_7658"
          x1="12.0202"
          y1="70.5185"
          x2="-29.4403"
          y2="59.8549"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00AFFA" />
          <stop offset="0.47403" stopColor="#32A2BA" />
          <stop offset="1" stopColor="#24FFCA" />
        </linearGradient>
      </defs>
    </Icon>
  );
}
