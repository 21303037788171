import {
  ModalProps,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  Box,
  chakra,
  Flex,
  Image,
  CloseButton,
} from "@chakra-ui/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { num } from "@wizard-ui/core";

import {
  IbcChainItem,
  useTokenInfo,
  TxPending,
  TxSuccess,
  stringTruncate,
  TxError,
  useDexter,
} from "modules/common";
import { useIBCData } from "modules/assets";
import { TokenInput } from "modules/swap";
import { toast } from "react-toastify";
import { useIBCTransferWithdrawMutation } from "modules/assets/hooks/useIBCTransferWithdrawMutation";
import { useReactiveVar } from "@deevoid/reactive-var";
import { withdrawSuccessDataVar } from "state/globalState";
import { useChain } from "@cosmos-kit/react";
import useStore from "state/store";

interface FormValues {
  amount: string;
}

interface Props extends Omit<ModalProps, "children"> {
  token: string;
}

export function WithdrawAssetModal({ token, isOpen, onClose, ...rest }: Props) {
  const persistenceChain =
    process.env.NEXT_PUBLIC_ENV === "testnet"
      ? "persistencetestnet2"
      : process.env.NEXT_PUBLIC_ENV === "devnet"
      ? "Dexter Devnet"
      : "persistence";
  const { address, getSigningStargateClient } = useChain(persistenceChain);

  const { balances, tokens } = useDexter();
  // const withdrawSuccessData = useReactiveVar(withdrawSuccessDataVar);
  const withdrawSuccessData = useStore(
    (state: any) => state.withdrawSuccessDataVar,
  );
  const setWithdrawSuccessDataVar = useStore(
    (state: any) => state.setWithdrawSuccessDataVar,
  );

  const { otherAddress, ibcDenom, withdrawChannel, remoteChainExplorer } =
    useIBCData({
      token,
      isOpen,
    });
  const [isConfirmStep, setIsConfirmStep] = useState(false);
  const { getIcon, getChain } = useTokenInfo();
  const { mutate, isLoading, data, reset, error, isError, isSuccess } =
    useIBCTransferWithdrawMutation();
  const {
    handleSubmit,
    control,
    watch,
    reset: resetForm,
  } = useForm<FormValues>({
    mode: "onChange",
    defaultValues: {
      amount: "",
    },
  });

  const { amount } = watch();

  const onSubmit = async (values: FormValues) => {
    const signingStargateClient = await getSigningStargateClient();
    if (otherAddress == null || signingStargateClient == null) {
      throw new Error("Something went wrong");
    }

    mutate({
      msg: {
        otherAddress,
        otherSigningStargateClient: signingStargateClient,
        denom: ibcDenom,
        channel: withdrawChannel,
        amount: num(values.amount)
          .times(10 ** tokens[token].decimals)
          .toFixed(0),
      },
    });
  };
  const handleClose = () => {
    setIsConfirmStep(false);
    setWithdrawSuccessDataVar(null);
    reset();
    resetForm();
    onClose();
  };
  const renderForm = () => {
    if (
      isConfirmStep ||
      isLoading ||
      withdrawSuccessData != null ||
      error != null
    ) {
      return null;
    }

    return (
      <Box>
        <Flex
          gap={[2, 2, 2, 4, 4, 4]}
          align="center"
          justify={[
            "space-between",
            "space-between",
            "space-between",
            "center",
            "center",
            "center",
          ]}
          mb="6"
        >
          <IbcChainItem
            title="Source"
            icon="./icons/xprt.svg"
            name="Persistence"
            address={stringTruncate(address, 6)}
          />
          {/* <Box> */}
          <Image
            boxSize={"42px"}
            src="./icons/right_arrow.svg"
            alt="right arrow"
          />
          {/* </Box> */}
          <IbcChainItem
            title="Destination"
            icon={
              getChain(token) == "Cosmos"
                ? getIcon(token)
                : `/icons/${getChain(token).toLowerCase()}.svg`
            }
            name={getChain(token)}
            address={otherAddress && stringTruncate(otherAddress, 6)}
          />
        </Flex>
        <Box mb="6">
          <TokenInput
            amountInputName="amount"
            tokenInputName="token"
            token={token}
            control={control}
            hideSelect
          />
        </Box>
        <Button
          variant="primary"
          type="submit"
          w="full"
          size="xl"
          isDisabled={
            amount === "" ||
            Number(amount) === 0 ||
            num(balances?.[token])
              .div(10 ** 6)
              .lt(num(amount))
          }
          // onClick={() => setIsConfirmStep(true)}
        >
          Withdraw
        </Button>
      </Box>
    );
  };

  const CustomCloseButton = ({ closeToast }) => (
    <CloseButton
      pos={"absolute"}
      left="0"
      top={"0"}
      color="#FCFCFC"
      w={34}
      h={34}
      borderRadius={"50%"}
      border={"2px solid #386794"}
      bg={"#0a1d32"}
      onClick={() => {
        handleClose();
        closeToast();
      }}
    />
  );

  const renderFinal = () => {
    if (isLoading && withdrawSuccessData == null) {
      return toast(<TxPending />, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: false,
        toastId: "  ",
        closeButton: CustomCloseButton,
      });
    } else {
      if (isError) {
        return toast.update("  ", {
          render: <TxError />,
          closeButton: CustomCloseButton,
          autoClose: 7000,
        });
      }

      // if (data == null) {
      // return null;
      // }

      return toast.update("  ", {
        render: () => (
          <TxSuccess
            subtitle="Assets Successfully Withdrawn"
            data={withdrawSuccessData}
            title="Transaction Successful"
            // href={remoteChainExplorer}
          />
        ),
        closeButton: CustomCloseButton,
        autoClose: 7000,
      });
    }
  };
  const hideTitle = isLoading || withdrawSuccessData != null || error != null;

  return !hideTitle ? (
    <Modal size="lg" isOpen={isOpen} onClose={handleClose} isCentered {...rest}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader mt="-4">
          Withdraw Asset
          <ModalCloseButton mt="0" />
        </ModalHeader>
        <ModalBody>
          <chakra.form onSubmit={handleSubmit(onSubmit)}>
            {renderForm()}
            {/* {renderConfirm()} */}
            {/* {renderPending()}
            {renderFinal()} */}
          </chakra.form>
        </ModalBody>
      </ModalContent>
    </Modal>
  ) : (
    renderFinal()
  );
}
