import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { ExecuteResult } from "@cosmjs/cosmwasm-stargate";

import { useContracts } from "modules/common";
import { useChain } from "@cosmos-kit/react";

export interface UnbondMultistakingMutation {
  msg: {
    lpTokenAddr: string;
    amount: string;
    instantUnbond: boolean;
  };
}

export function useUnbondMultistakingMutation(
  options?: Omit<
    UseMutationOptions<ExecuteResult, Error, UnbondMultistakingMutation>,
    "mutationFn" | "onSuccess"
  >,
) {
  const { multistaking } = useContracts();
  const queryClient = useQueryClient();
  const persistenceChain =
    process.env.NEXT_PUBLIC_ENV === "testnet"
      ? "persistencetestnet2"
      : process.env.NEXT_PUBLIC_ENV === "devnet"
      ? "Dexter Devnet"
      : "persistence";
  const chainContext = useChain(persistenceChain);
  const { address, getSigningCosmWasmClient } = chainContext;

  return useMutation<ExecuteResult, Error, UnbondMultistakingMutation>(
    async ({ msg: { lpTokenAddr, amount, instantUnbond } }) => {
      const signingClient = await getSigningCosmWasmClient();
      if (signingClient == null || address == null) {
        throw new Error(
          "Missing signingClient in useUnbondMultistakingMutation",
        );
      }
      if (instantUnbond) {
        return signingClient.executeMultiple(
          address,
          [
            {
              contractAddress: multistaking,
              msg: {
                instant_unbond: {
                  lp_token: lpTokenAddr,
                  amount,
                },
              },
              funds: [],
            },
          ],
          "auto",
          "",
        );
      } else {
        return signingClient.executeMultiple(
          address,
          [
            {
              contractAddress: multistaking,
              msg: {
                unbond: {
                  lp_token: lpTokenAddr,
                  amount,
                },
              },
              funds: [],
            },
          ],
          "auto",
          "",
        );
      }
    },
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries(["balances"]);
        queryClient.invalidateQueries(["bondedLpTokens"]);
        queryClient.invalidateQueries(["tokenLocks"]);
        queryClient.invalidateQueries(["rawTokenLocks"]);
      },
    },
  );
}
