import { useMemo } from "react";

type Contracts = {
  vault: string;
  router: string;
  multistaking: string;
  governance_admin: string;
  superfluid_lp: string;
};

type Networks = {
  mainnet: Contracts;
  devnet: Contracts;
  testnet: Contracts;
};

const defaultContracts: { [key: string]: any } = {
  mainnet: {
    vault:
      "persistence1k8re7jwz6rnnwrktnejdwkwnncte7ek7gt29gvnl3sdrg9mtnqkstujtpg",
    router:
      "persistence132xmxm33vwjlur2pszl4hu9r32lqmqagvunnuc5hq4htps7rr3kqsf4dsk",
    multistaking:
      "persistence1ery8l6jquynn9a4cz2pff6khg8c68f7urt33l5n9dng2cwzz4c4qs72n0q",
    governance_admin:
      "persistence16vcsfzdtas7jflq6mjtmhp03zga8dggpepp70nycx7h6p2rwe84su3czty",
    superfluid_lp:
      "persistence1dxxplvjx92e5gyvtzg8zz7a3n9758mxgr0ka4vym0mzz5fk3u9as4wqrqw",
  },
  devnet: {
    vault:
      "persistence1qg5ega6dykkxc307y25pecuufrjkxkaggkkxh7nad0vhyhtuhw3sa8s9dq",
    router:
      "persistence124tapgv8wsn5t3rv2cvywh4ckkmj6mc6fkya005qjmshnzewwm9qm29dhc",
    multistaking:
      "persistence1ufs3tlq4umljk0qfe8k5ya0x6hpavn897u2cnf9k0en9jr7qarqqv0rkes",
    governance_admin:
      "persistence1d6vqd34c6edwwjj6men88vwyqskxegfpe5v0w56wa0ehwdeehznq49qjju",
  },
  testnet: {
    vault:
      "persistence1hrpna9v7vs3stzyd4z3xf00676kf78zpe2u5ksvljswn2vnjp3ys64pna7",
    router:
      "persistence1f6jlx7d9y408tlzue7r2qcf79plp549n30yzqjajjud8vm7m4vds8udaex",
    multistaking:
      "persistence1wkwy0xh89ksdgj9hr347dyd2dw7zesmtrue6kfzyml4vdtz6e5wsmt95up",
    governance_admin:
      "persistence1jjf58fy7muteh2r02qfgwythntn6ze5g0kj8vfe6u7f2zwsa07yqgz5f9k",
    superfluid_lp:
      "persistence1z0uz82yle9tavl4qpq86a34z4hn7gsdd8n56t3qzr0nf4nwptv8q3h274d",
  },
};

export const useContracts = (initial?: Networks): Contracts => {
  const contracts = initial ?? defaultContracts;
  const env = process.env.NEXT_PUBLIC_ENV;
  return useMemo(() => {
    return contracts[env as keyof typeof contracts];
  }, [contracts, env]);
};

export default useContracts;
