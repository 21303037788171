import {
  ModalProps,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  SimpleGrid,
  Input,
  InputGroup,
  InputRightElement,
  Button,
} from "@chakra-ui/react";
import type { Control, ControllerProps } from "react-hook-form";
import { useController } from "react-hook-form";
import { useLocalStorageState } from "ahooks";
import { InfoIcon } from "modules/common";
import { num } from "@wizard-ui/core";
import { useState } from "react";
import { TooltipWithTouch } from "modules/common/components/TooltipWithTouch";

interface Props extends Pick<ModalProps, "isOpen" | "onClose"> {
  name?: ControllerProps["name"];
  control: Control<any>;
  rules?: ControllerProps["rules"];
}

export function SwapSettingsModal({ control, rules, isOpen, onClose }: Props) {
  const [value, setValue] = useLocalStorageState<number | undefined>(
    "dexterMaxSpread",
    {
      defaultValue: 1,
    },
  );
  const [slippageValue, setSlippageValue] = useState(value);

  const { field } = useController({
    name: "maxSpread",
    control,
    rules: {
      validate: (value) => {
        return num(value).gt(0) || num(value).lte(100);
      },
    },
    defaultValue: value,
  });

  const handleClick = () => {
    field.onChange(slippageValue);
    setValue(slippageValue);
    onClose();
  };

  const handleChange = (e) => {
    const value = Math.max(0, Math.min(100, Number(e.target.value)));
    setSlippageValue(value);
  };

  return (
    <Modal
      variant="secondary"
      size="md"
      isCentered
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader display={"flex"}>
          Slippage Tolerance
          <TooltipWithTouch
            pl="2"
            hasArrow
            label="Your transaction will revert if the price changes unfavourably more than the input percentage"
            bg="#00506B"
            color="white"
            borderRadius="lg"
            p="3"
            maxW="56"
          >
            <span>
              <InfoIcon
                ml="2"
                mb="1"
                boxSize={"5"}
                _hover={{ cursor: "pointer" }}
              />
            </span>
          </TooltipWithTouch>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <InputGroup mb="4">
            <Input
              variant="dexterModalInput"
              type="number"
              value={slippageValue}
              onChange={(e) => handleChange(e)}
            />
            <InputRightElement
              h="45px"
              px="0"
              pr="8px"
              w="fit-content"
              textStyle="dexterP3"
              fontSize="18px"
              lineHeight="160%"
              fontWeight={600}
              display="flex"
              pt="2px"
              alignItems="center"
              inputMode="tel"
            >
              %
            </InputRightElement>
          </InputGroup>
          <SimpleGrid columns={4} gap="4" mb="5">
            {[0.5, 1, 3, 5].map((value) => (
              <Button
                key={value}
                variant="tertiary"
                size="sm"
                onClick={() => setSlippageValue(value)}
              >
                {value}%
              </Button>
            ))}
          </SimpleGrid>
          <Button
            variant="primary"
            size="lg"
            w="full"
            onClick={handleClick}
            mb="4"
            isDisabled={slippageValue == null}
          >
            Save Settings
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
