import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { ExecuteResult } from "@cosmjs/cosmwasm-stargate";
import { Coin, coin } from "@cosmjs/amino";

import {
  isNativeAsset,
  useContracts,
  getTokenDenom,
  toAsset,
} from "modules/common";
import { useChain } from "@cosmos-kit/react";
import {
  AminoConverters,
  AminoTypes, createDefaultAminoConverters,
  GasPrice,
} from "@cosmjs/stargate";
const { defaultRegistryTypes } = require("@cosmjs/stargate");
import { MsgStakeToLP } from "persistenceonejs/pstake/liquidstake/v1beta1/tx";
import { Registry } from "@cosmjs/proto-signing";
import { SigningCosmWasmClient } from "@cosmjs/cosmwasm-stargate";
import { num } from "@wizard-ui/core";
import { PERSISTENCE_STAKE_TO_LP_URL, stkXprtCValueEndpoint } from "config";
import { createStakeToLpAminoConverters } from "modules/pools/amino-converter";
import { MsgExecuteContract } from "cosmjs-types/cosmwasm/wasm/v1/tx";
import BigNumber from "bignumber.js";
import { floor } from "lodash";
import { AminoConverter as CosmWasmAminoConverter } from "persistenceonejs/cosmwasm/wasm/v1/tx.amino";

export interface SuperfluidLpMutation {
  msg: {
    poolId: string;
    assets: any;
    validatorAddress: string;
    normalJoinPool: boolean;
    recipient?: string;
    lpToMint?: any;
    slippageTolerance?: any;
    autoStake?: boolean;
  };
}

function createAminoTypes(): AminoConverters {
  return {
    ...createDefaultAminoConverters(),
    ...createStakeToLpAminoConverters(),
    ...CosmWasmAminoConverter,
  };
}

export function useSuperfluidLpMutation(
  options?: Omit<
    UseMutationOptions<ExecuteResult, Error, SuperfluidLpMutation>,
    "mutationFn"
  >,
) {
  const { superfluid_lp, vault } = useContracts();
  const persistenceChain =
    process.env.NEXT_PUBLIC_ENV === "testnet"
      ? "persistencetestnet2"
      : process.env.NEXT_PUBLIC_ENV === "devnet"
      ? "Dexter Devnet"
      : "persistence";
  const chainContext = useChain(persistenceChain);
  const { address, getOfflineSignerDirect, getSigningCosmWasmClient, getOfflineSignerAmino } =
    chainContext;
  const queryClient = useQueryClient();

  return useMutation<ExecuteResult, Error, SuperfluidLpMutation>(
    async ({
      msg: { poolId, assets, validatorAddress, normalJoinPool, autoStake },
    }) => {
      if (normalJoinPool) {
        const signingClient = await getSigningCosmWasmClient();
        if (signingClient == null || address == null) {
          throw new Error("Missing signingClient in useJoinPoolMutation");
        }
        const funds = assets
          .filter(({ info }: any) => isNativeAsset(info))
          .map(({ amount, info }: any) => coin(amount, getTokenDenom(info)))
          .sort(function (a: Coin, b: Coin) {
            if (a.denom < b.denom) {
              return -1;
            }
            if (a.denom > b.denom) {
              return 1;
            }
            return 0;
          });

        const cw20Assets = assets.filter(
          ({ info }: any) => !isNativeAsset(info),
        );
        // TODO Remove Hardcoding of contractAddr and amount
        const allowanceMsgs = cw20Assets.map((asset) => {
          return {
            contractAddress: asset.info.token.contract_addr,
            msg: {
              increase_allowance: {
                amount: asset.amount,
                spender: vault,
              },
            },
            funds: [],
          };
        });

        return signingClient.executeMultiple(
          address,
          [
            ...allowanceMsgs,
            {
              contractAddress: vault,
              msg: {
                join_pool: {
                  pool_id: poolId,
                  assets,
                  auto_stake: autoStake,
                  // slippage_tolerance: "0.5",
                },
              },
              funds,
            },
          ],
          "auto",
          "",
        );
      } else {
      const signer = await getOfflineSignerAmino();
      const rpc =
          process.env.NEXT_PUBLIC_ENV === "testnet"
              ? "https://rpc.testnet2.persistence.one"
              : "https://rpc.core.persistence.one";
      const signingClient = await SigningCosmWasmClient.connectWithSigner( rpc,
          signer,
          {
            registry: new Registry([
              ...defaultRegistryTypes,
              ["/pstake.liquidstake.v1beta1.MsgStakeToLP", MsgStakeToLP],
              ["/cosmwasm.wasm.v1.MsgExecuteContract", MsgExecuteContract],
            ]),
            gasPrice: GasPrice.fromString("0.020uxprt"),
            aminoTypes: new AminoTypes(createAminoTypes()),
          })

        if (signingClient === null || address == null) {
          throw new Error("Missing signingClient in useJoinPoolMutation");
        }

        const cValueData = await fetch(stkXprtCValueEndpoint, {
          cache: "no-store",
        });
        const cValue = await cValueData.json();
        /**
         * Transforms the assets array by mapping each asset to a new object.
         * If the asset's native_token.denom is "staked-uxprt", the amount is multiplied by cValue to get stkXPRT amount.
         * Otherwise, the asset remains unchanged.
         */
        const finalAssets = assets.map(
          (asset: {
            info: { native_token: { denom: string } };
            amount: BigNumber.Value | undefined;
          }) => {
            if (asset.info.native_token.denom === "staked-uxprt") {
              const stkXprtAmount = num(asset.amount)
                .times(num(floor(cValue, 5)))
                .toFixed(0);
              return toAsset({ amount: stkXprtAmount, token: "stk/uxprt" });
            }
            return {
              info: asset.info,
              amount: asset.amount,
            };
          },
        );

        /**
         * Calculates the total amount of stkXprt based on the given assets.
         */
        const stkXprtAmount = finalAssets.reduce((acc: any, asset: any) => {
          if (asset.info.native_token.denom === "stk/uxprt") {
            return acc + num(asset.amount).toNumber();
          }
          return acc;
        }, 0);
        const stkXprtAsset = toAsset({
          amount: stkXprtAmount.toString(),
          token: "stk/uxprt",
        });

        const xprtAmount = finalAssets.find(
          ({ info }: any) => info.native_token.denom === "uxprt",
        )?.amount;

        const stakedXprtAmount = assets.find(
          ({ info }: any) => info.native_token.denom === "staked-uxprt",
        )?.amount;
        const xprtAsset = toAsset({
          amount: xprtAmount || "0",
          token: "uxprt",
        });

        const msg = {
          typeUrl: PERSISTENCE_STAKE_TO_LP_URL,
          value: MsgStakeToLP.fromPartial({
            delegatorAddress: address,
            validatorAddress: validatorAddress,
            stakedAmount: { denom: "uxprt", amount: stakedXprtAmount },
            liquidAmount: { denom: "uxprt", amount: "0" },
          }),
        };

        const totalAssets = [xprtAsset, stkXprtAsset];

        // only send xprt and stkXPRT input by user as funds
        const funds = assets
          .filter(({ info }) => info.native_token.denom !== "staked-uxprt")
          .map(({ amount, info }: any) => coin(amount, getTokenDenom(info)))
          .sort(function (a: Coin, b: Coin) {
            if (a.denom < b.denom) {
              return -1;
            }
            if (a.denom > b.denom) {
              return 1;
            }
            return 0;
          });

        const textEncoder = new TextEncoder();
        const msgUint8Array = textEncoder.encode(
          JSON.stringify({
            join_pool_and_bond_using_locked_lst: {
              pool_id: poolId,
              total_assets: totalAssets,
              // slippage_tolerance: "0.5",
            },
          }),
        );
        const msg2 = {
          typeUrl: "/cosmwasm.wasm.v1.MsgExecuteContract",
          value: MsgExecuteContract.fromPartial({
            sender: address,
            contract: superfluid_lp,
            msg: msgUint8Array,
            funds,
          }),
        };
        console.log(msg, msg2, "msgs-1")
        return signingClient.signAndBroadcast(address!, [msg, msg2], "auto", "");
      }
    },
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries(["balances"]);
        queryClient.invalidateQueries(["bondedLpTokens"]);
      },
    },
  );
}
