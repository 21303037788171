import { useEffect, useMemo, useState } from "react";
import {
  ModalProps,
  Modal,
  Button,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  chakra,
  VStack,
  Box,
  Text,
  Flex,
  CloseButton,
  IconButton,
  Skeleton,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { formatAmount, num, NumberFormatSpecifier } from "@wizard-ui/core";

import {
  TxPending,
  TxSuccess,
  TxError,
  useDexter,
  DoubleArrowIcon,
  useTokenInfo,
  useContracts,
  TxErrorKeplr,
  Wrapper,
  ChevronUpIcon,
  ChevronDownIcon,
} from "modules/common";
import { PoolBase } from "modules/pools";

import { toast } from "react-toastify";
import { useChain, useManager } from "@cosmos-kit/react";
import {
  calculateMinReceivedAfterSlippage,
  SwapBase,
  SwapInfo,
  TokenInput,
  useSwapMutation,
} from "modules/swap";
import { useDebounce } from "ahooks";
import { useQuery } from "@tanstack/react-query";
import { motion } from "framer-motion";

const MotionBox = motion(Box);

type FormValues = {
  token1: string;
  amount1: string;
  token2: string;
  amount2: string;
  maxSpread: string;
};

interface AddLiquidityModalProps extends Omit<ModalProps, "children"> {
  pool: PoolBase;
}

export function TradePairModal({
  pool,
  onClose,
  ...rest
}: AddLiquidityModalProps) {
  const { pools, prices, balances, isLoading, tokens } = useDexter();
  const { router } = useContracts();
  const persistenceChain =
    process.env.NEXT_PUBLIC_ENV === "testnet"
      ? "persistencetestnet2"
      : process.env.NEXT_PUBLIC_ENV === "devnet"
      ? "Dexter Devnet"
      : "persistence";
  const chainContext = useChain(persistenceChain);
  const { getWalletRepo, mainWallets } = useManager();
  const walletRepo = getWalletRepo(persistenceChain);

  const { connect, address, isWalletConnected, getCosmWasmClient } =
    chainContext;
  const {
    mutate,
    isLoading: swapMutationLoading,
    data: swapMutationData,
    error: swapMutationError,
    reset,
  } = useSwapMutation();
  const [isConfirmStep, setIsConfirmStep] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [showSwapInfo, setShowSwapInfo] = useState<boolean>(false);
  const poolTokens = pool.assets.map(({ identifier }) => identifier);
  const defaultTokenValues = poolTokens.reduce((acc, value, index) => {
    return {
      ...acc,
      [`token${index + 1}`]: value,
      [`amount${index + 1}`]: "",
      maxSpread: localStorage.getItem("dexterMaxSpread") || "0.5",
    };
  }, {});
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    resetField,
    reset: resetForm,
  } = useForm<FormValues>({
    mode: "onChange",
    defaultValues: defaultTokenValues,
  });

  const { token1, token2, amount1, amount2, maxSpread } = watch();
  const handleSwapTokens = () => {
    setValue("amount1", amount2);
    setValue("amount2", amount1);
    setValue("token1", token2);
    setValue("token2", token1);
  };
  const dAmount1 = useDebounce(amount1, { wait: 500 });
  const { getSymbol } = useTokenInfo();
  const swap = useMemo(() => {
    if (prices == null) {
      return null;
    }
    const client = getCosmWasmClient().then((client) => {
      return client;
    });

    return new SwapBase({
      token1,
      token2,
      amount1: dAmount1,
      amount2: "0",
      slippage: maxSpread,
      context: {
        pools,
        client,
        router,
        prices,
      },
    });
  }, [
    token1,
    token2,
    dAmount1,
    maxSpread,
    router,
    getCosmWasmClient,
    pools,
    prices,
  ]);

  const {
    data,
    isInitialLoading,
    error: swapErr,
  } = useQuery(
    ["simulateSwap", swap],
    () => {
      if (swap == null || !swap.isReady) {
        throw new Error("swap model is not configured");
      }

      return swap.simulateSwap();
    },
    {
      enabled: swap != null && swap.isReady,
      // Revisit: disable keepPreviousData
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );
  useEffect(() => {
    if (data) {
      if (data.amountOut === "") {
        setValue("amount2", "");
      } else {
        setValue(
          "amount2",
          num(data.amountOut)
            .div(10 ** tokens[data.tokenOut].decimals)
            .toString(),

          { shouldValidate: true },
        );
      }
    }

    if (swapErr !== null) {
      setValue("amount2", "");
      if (swapErr.message.includes("Operation exceeds max spread limit")) {
        toast(
          <TxErrorKeplr
            errorMessage={"The spread for this transaction is too high."}
          />,
          {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 5000,
            toastId: "swap error",
            // closeButton: CustomCloseButton,
          },
        );
      }
    }
  }, [data, setValue, swapErr]);

  useEffect(() => {
    if (error != null) {
      setError(null);
    }
    if (amount1 == "") {
      setValue("amount2", "");
    }
  }, [amount1, error, setValue]);

  const onSubmit = (values: FormValues) => {
    if (isWalletConnected) {
      mutate(
        {
          swapPath: swap?.bestPath1,
          amount: values.amount1,
          assetIn: values.token1,
          assetOut: values.token2,
          beliefPrice: data?.beliefPrice,
          maxSpread: num(values.maxSpread).div(100).toFixed(2),
          minimum_receive: calculateMinReceivedAfterSlippage(
            data,
            values.maxSpread,
          ),
          swapType: {
            give_in: {},
          },
        },
        {
          onSettled: () => {
            resetField("amount1");
            resetField("amount2");
          },
        },
      );
    }
  };

  const handleClose = () => {
    onClose();
    setIsConfirmStep(false);
    reset();
    resetForm();
  };

  const mobileWebModeName = "mobile-web";

  const connectHandler = () => {
    sessionStorage.setItem("terms", "show");
    if (
      (window?.leap && window?.leap?.mode === mobileWebModeName) ||
      window?.navigator?.userAgent?.includes("LeapCosmos")
    ) {
      const wallet = walletRepo.wallets.find(
        (wallet) => wallet._walletInfo.name === "leap-extension",
      );
      wallet._walletInfo.mobileDisabled = false;
      walletRepo.connect("leap-extension");
      return;
    } else if (window?.keplr && window?.keplr?.mode === mobileWebModeName) {
      const wallet = walletRepo.wallets.find(
        (wallet) => wallet._walletInfo.name === "keplr-extension",
      );
      wallet._walletInfo.mobileDisabled = false;
      walletRepo.connect("keplr-extension");
      return;
    } else {
      connect();
      return;
    }
  };

  const renderForm = () => {
    if (
      isConfirmStep ||
      swapMutationLoading ||
      swapMutationData != null ||
      swapMutationError != null
    ) {
      return null;
    }

    return (
      <Box>
        <VStack gap="0" mb="4" align="auto">
          <TokenInput
            amountInputName="amount1"
            tokenInputName="token1"
            token={token1}
            onTokenChange={(v) => {
              if (v.to) {
                setValue("token2", v.to);
              }
            }}
            hideSelect
            control={control}
          />

          <IconButton
            // mt="12"
            aria-label="Change"
            bg="transparent"
            _focus={{ bg: "transparent" }}
            _hover={{ bg: "transparent" }}
            _active={{ bg: "transparent" }}
            type="button"
            onClick={handleSwapTokens}
          >
            <DoubleArrowIcon boxSize="9" transform={"rotate(90deg)"} />
          </IconButton>

          <TokenInput
            token={token2}
            amountInputName="amount2"
            tokenInputName="token2"
            control={control}
            hideSelect
            isLoading={isInitialLoading}
            isDisabled
          />
        </VStack>

        <Flex flexDir="column" align="center">
          {error && (
            <Box mt="6" minW="xl" textAlign="center">
              <Text color="red.500">{error}</Text>
            </Box>
          )}
          <Button
            w="full"
            isDisabled={
              isWalletConnected &&
              (data == null ||
                Number(amount1) == 0 ||
                num(balances?.[token1])
                  .div(10 ** tokens[token1].decimals)
                  .lt(num(amount1)))
            }
            variant="primary"
            size="lg"
            type="submit"
            onClick={() => !isWalletConnected && connectHandler()}
          >
            {isWalletConnected
              ? num(balances?.[token1])
                  .div(10 ** tokens[token1].decimals)
                  .lt(num(amount1))
                ? "Insufficient Balance"
                : "Swap"
              : "Connect Wallet"}
          </Button>
          {/* <Box w="500px"> */}
          {/* {showSwapInfo === false && ( */}
          <Wrapper variant="primary" w="full" mx="auto" mt="4" p="4">
            <Skeleton
              isLoaded={data !== null && !isLoading}
              startColor="primary.prussianBlue80"
              endColor="primary.ctaDisabled"
            >
              <Flex
                flexDir="row"
                align="center"
                justify={"space-between"}
                _hover={{ cursor: "pointer" }}
                onClick={() =>
                  data != null &&
                  setShowSwapInfo((showSwapInfo) => !showSwapInfo)
                }
              >
                <Text textStyle="label">
                  {" "}
                  1 {getSymbol(token1)} ≈{" "}
                  {formatAmount(data?.beliefPrice, {
                    formatSpecifier: NumberFormatSpecifier.FLOAT,
                  })}{" "}
                  {getSymbol(token2)}
                </Text>
                {data != null &&
                  Number(amount1) != 0 &&
                  (showSwapInfo === true ? (
                    <ChevronUpIcon boxSize={6} />
                  ) : (
                    <ChevronDownIcon boxSize={6} />
                  ))}
              </Flex>
              {data != null &&
                Number(amount1) != 0 &&
                showSwapInfo === true && (
                  <MotionBox
                    mt="4"
                    initial={{ opacity: 0, y: -100 }}
                    animate={{ opacity: 1, y: 0 }}
                  >
                    <SwapInfo
                      tokenIn={token1}
                      tokenOut={token2}
                      amountIn={amount1}
                      maxSpread={maxSpread}
                    />
                    {/* <SwapInfoTokens data={data} /> */}
                  </MotionBox>
                )}
            </Skeleton>
          </Wrapper>
          {/* )} */}
          {/* </Box> */}
        </Flex>
      </Box>
    );
  };

  const CustomCloseButton = ({ closeToast }) => (
    <CloseButton
      pos={"absolute"}
      left="0"
      top={"0"}
      color="#FCFCFC"
      w={34}
      h={34}
      borderRadius={"50%"}
      border={"2px solid #386794"}
      bg={"#0a1d32"}
      onClick={() => {
        handleClose();
        closeToast();
      }}
    />
  );

  const renderFinal = () => {
    if (swapMutationLoading) {
      return toast(<TxPending />, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: false,
        toastId: "",
        closeButton: CustomCloseButton,
      });
    } else {
      if (swapMutationData == null && swapMutationError != null) {
        return toast.update("", {
          render: <TxError />,
          closeButton: CustomCloseButton,
        });
      }

      if (swapMutationData == null) {
        return null;
      }

      return toast.update("", {
        render: () => (
          <TxSuccess
            subtitle="Assets Swapped Successfully"
            data={swapMutationData}
            title="Transaction Successful"
          />
        ),
        closeButton: CustomCloseButton,
      });
    }
  };

  const hideTitle =
    swapMutationLoading ||
    swapMutationData != null ||
    swapMutationError != null;

  return !hideTitle ? (
    <Modal size="lg" onClose={handleClose} isCentered {...rest}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {!hideTitle && "Swap"}
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <chakra.form onSubmit={handleSubmit(onSubmit)}>
            {renderForm()}
            {/* {renderConfirm()} */}
          </chakra.form>
        </ModalBody>
      </ModalContent>
    </Modal>
  ) : (
    //  renderPending()
    renderFinal()
  );
}
