import { num } from "@wizard-ui/core";
import { stkAtomAPREndpoint, stkAtomCValueEndpoint } from "config";

import { PoolAsset } from "modules/common";

interface Opts {
  assets: PoolAsset[];
  amount: string;
  totalSupply: string;
}

export function lpToTokens({ amount, assets, totalSupply }: Opts) {
  return assets.map((asset) => {
    return {
      amount:
        totalSupply === "0"
          ? 0
          : num(amount).times(asset.amount).div(totalSupply).toFixed(0),
      token: asset.identifier,
    };
  });
}

export const POOL_TYPES: { [key: string]: string } = {
  weighted: "Weighted",
  xyk: "XYK",
  stableswap: "StableSwap",
  "stable-5-pool": "StableSwap",
  metastable: "Metastable",
};
export const POOL_TYPES_ICONS: { [key: string]: string } = {
  weighted: "./images/weighted_pool_ticker.svg",
  xyk: "XYK",
  stableswap: "./images/stableswap_pool_ticker.svg",
  "stable-5-pool": "./images/stableswap_pool_ticker.svg",
  metastable: "./images/metastable_pool_ticker.svg",
};
export const POOL_TYPES_ICONS_CREATE_POOL: { [key: string]: string } = {
  "Weighted Pool": "./images/weighted_pool_ticker.svg",
  xyk: "XYK",
  "Stable Swap Pool": "./images/stableswap_pool_ticker.svg",
  "Metastable Pool": "./images/metastable_pool_ticker.svg",
};

export const POOL_TYPES_TOOLTIPS: { [key: string]: string } = {
  "Weighted Pool":
    "Weighted pools assign varying weights to assets and enable customizable liquidity provision.",
  xyk: "XYK",
  "Stable Swap Pool":
    "Stableswap Pools maintain a constant 1:1 exchange ratio between assets, ideal for similarly priced tokens like stablecoins, ensuring minimal slippage and effective liquidity provision.",
  "Metastable Pool":
    "Metastable pools dynamically adapt to changing asset values, optimizing capital efficiency and enhancing trading precision.",
};

interface StakingAPRParams {
  lpUSDPrice: number;
  totalLpShares: string;
  assets: PoolAsset[];
  prices: Record<string, any>;
}

export const calculateStakingAPR = async ({
  lpUSDPrice,
  totalLpShares,
  assets,
  prices,
}: StakingAPRParams) => {
  const stkAtomAPRData = await fetch(stkAtomAPREndpoint);
  const stkAtomAPR = await stkAtomAPRData.json();

  const stkAtomTokensCount = assets.find(
    (asset) => asset.identifier === "stk/uatom",
  )?.amount;
  const stkAtomTokensInLp = num(stkAtomTokensCount ?? 0)
    .div(10 ** 6)
    .div(num(totalLpShares).div(10 ** 18))
    .toNumber();

  const currentStkAtomUSDPriceInLp = num(stkAtomTokensInLp)
    .times(prices?.["stk/uatom"]?.price ?? 0)
    .toNumber();

  const stakingRewardsUSDPrice = stkAtomAPR * currentStkAtomUSDPriceInLp;
  const stakingAPR = (stakingRewardsUSDPrice * 100) / lpUSDPrice;
  return stakingAPR;
};

export const doesPoolExist = (
  existingPools,
  selectedAssets: any[],
): boolean => {
  return existingPools.some((existingPool) => {
    if (existingPool.assets.length !== selectedAssets.length) return false;
    const sortedExistingPoolAssets = existingPool.assets.sort((a, b) =>
      a.identifier.localeCompare(b.identifier),
    );
    const sortedSelectedAssets = [...selectedAssets].sort((a, b) =>
      a.token.localeCompare(b.token),
    );
    return sortedExistingPoolAssets.every(
      (asset, index) => asset.identifier === sortedSelectedAssets[index].token,
    );
  });
};
export const getCValue = async () => {
  const cValueData = await fetch(stkAtomCValueEndpoint);
  const cValue = await cValueData.json();
  return cValue;
};

export function adjustWeights(tokens: Token[], precision: number): Token[] {
  // Clone the array to avoid mutating the original tokens
  let adjustedTokens = tokens.map((token) => ({ ...token }));

  // Determine the multiplier for the specified precision
  const multiplier = Math.pow(10, precision + 2);

  // Round weights to the specified precision and convert to percentage
  adjustedTokens = adjustedTokens.map((token) => ({
    ...token,
    weight: Math.round(token.weight * multiplier) / Math.pow(10, precision),
  }));

  // Calculate the total weight after rounding
  const totalWeight = adjustedTokens.reduce(
    (sum, token) => sum + token.weight,
    0,
  );

  // Calculate the discrepancy due to rounding
  const discrepancy = 100 - totalWeight;

  // Find the index of the token with the largest weight
  const indexLargestWeight = adjustedTokens.reduce(
    (largestIdx, token, idx, arr) =>
      token.weight > arr[largestIdx].weight ? idx : largestIdx,
    0,
  );

  // Adjust the largest weight by the discrepancy
  adjustedTokens[indexLargestWeight].weight += discrepancy;

  return adjustedTokens;
}

export function tokenWeights(pool, cValue, precision = 2) {
  let assets = [];
  let totalTokensInPool = pool._totalTokensInPool;
  if (pool.id == 1 || pool.id == 7 || pool.id == 9 || pool.id == 11) {
    assets = pool.assets.map((asset) => {
      return {
        identifier: asset.identifier,
        amount:
          asset.identifier == "stk/uatom" ||
          asset.identifier == "stk/adydx" ||
          asset.identifier == "stk/ustars" ||
          asset.identifier === "stk/uhuahua"
            ? num(asset.amount).div(cValue).toFixed(0)
            : asset.amount,
      };
    });
    totalTokensInPool = assets.reduce((acc: number, asset: PoolAsset) => {
      return num(asset.amount)
        .div(
          asset.identifier ===
            "ibc/A6E3AF63B3C906416A9AF7A556C59EA4BD50E617EFFE6299B99700CCB780E444" ||
            asset.identifier ===
              "ibc/23DC3FF0E4CBB53A1915E4C62507CB7796956E84C68CA49707787CB8BDE90A1E" ||
            asset.identifier === "stk/adydx"
            ? 10 ** 18
            : asset.identifier ===
                "ibc/5D3B6445EA1D7064C4B1CCB588638589529556E1BCBADF13475021B42EA8C73B" ||
              asset.identifier ===
                "ibc/CCA9F9B22D39884C09975D45E1869B73A12B87080EE53CB44905CE2C422CA228"
            ? 10 ** 8
            : 10 ** 6,
        )
        .plus(acc)
        .toNumber();
    }, 0);
  } else {
    totalTokensInPool = pool._totalTokensInPool;
    assets = pool.assets;
  }
  const ratios = assets.reduce(
    (acc: Record<string, number>, asset: PoolAsset) => {
      return {
        ...acc,
        [asset.identifier]: num(asset.amount)
          .div(
            asset.identifier ===
              "ibc/A6E3AF63B3C906416A9AF7A556C59EA4BD50E617EFFE6299B99700CCB780E444" ||
              asset.identifier ===
                "ibc/23DC3FF0E4CBB53A1915E4C62507CB7796956E84C68CA49707787CB8BDE90A1E" ||
              asset.identifier === "stk/adydx"
              ? 10 ** 18
              : asset.identifier ===
                  "ibc/5D3B6445EA1D7064C4B1CCB588638589529556E1BCBADF13475021B42EA8C73B" ||
                asset.identifier ===
                  "ibc/CCA9F9B22D39884C09975D45E1869B73A12B87080EE53CB44905CE2C422CA228"
              ? 10 ** 8
              : 10 ** 6,
          )
          .div(totalTokensInPool)
          .toNumber(),
      };
    },
    {},
  );
  const tokens = pool.tokensList.map((token) => {
    return {
      token,
      weight: ratios[token],
    };
  });
  return adjustWeights(tokens, precision);
}

export const getPoolAssetsFromLpToken = (pools, lpToken) => {
  const pool = pools.find(
    (pool) => pool.pool.lpTokenContractAddress === lpToken,
  );
  return pool?.pool?.poolAssets.map((asset) => asset.identifier);
};
