import { useQuery } from "@tanstack/react-query";
import { GraphURL } from "config";
import { request, gql } from "graphql-request";

import { Pool } from "modules/common";

export function usePoolsQuery(options = {}) {
  const url = GraphURL[process.env.NEXT_PUBLIC_ENV as keyof typeof GraphURL];
  return useQuery<Pool[]>(
    ["pools"],
    async () => {
      try {
        const { pools } = await request(
          url,
          gql`
            query {
              pools(where: { poolId_not_in: [2] }) {
                poolId
                poolContractAddress
                vaultContractAddress
                poolType {
                  id
                  poolType
                }
                lpTokenName
                lpTokensInSupply
                lpTokenContractAddress

                poolAssets {
                  identifier
                  assetType
                  amount
                }
              }
            }
          `,
        );

        const { pool_weekly_aggregate_with_apr } = await request(
          url,
          gql`
            query {
              pool_weekly_aggregate_with_apr {
                apr
                total_volume
                current_liquidity_usd
                total_swap_fee
                pool_id
              }
            }
          `,
        );

        const { pool_current_incentive_apr } = await request(
          url,
          gql`
            query {
              pool_current_incentive_apr {
                incentive_apr
                pool_id
                recording_timestamp
                reward_asset_id
              }
            }
          `,
        );

        return pools.map((item: any) => {
          const extra = pool_weekly_aggregate_with_apr.find(
            ({ pool_id, vault_address }: any) => item.poolId == pool_id,
          );

          const extra1 = pool_current_incentive_apr.filter(
            ({ pool_id, vault_address }: any) => item.poolId == pool_id,
          );

          return {
            ...item,
            volume7d: extra?.total_volume ?? null,
            apr7d:
              (extra?.apr || 0) +
                (extra1.reduce(
                  (n, { incentive_apr }) => n + incentive_apr,
                  0,
                ) || 0) ?? null,
            fee7d: extra?.total_swap_fee ?? null,
            extApr: extra1 ?? null,
            feeApr: extra?.apr ?? null,
          };
        });
      } catch (e) {
        console.log({ e });
        throw new Error("Something is wrong about pools");
      }
    },
    options,
  );
}
