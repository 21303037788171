import { Image } from "@chakra-ui/react";

import { useTokenInfo } from "modules/common";

interface TokensProps {
  token: string;
  size?: string | string[];
}

export function TokenIcon({ token, size = "6" }: TokensProps) {
  const { getIcon } = useTokenInfo();

  return <Image src={getIcon(token)} boxSize={size} />;
}
