import { Box, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";

const MaintenancePage = () => {
  return (
    <>
      <Flex
        justify="center"
        alignItems={"center"}
        flexDirection="column"
        h={"100vh"}
        // mt={"4rem"}
      >
        <Image
          src="./images/dexter_with_head.svg"
          // boxSize={"90"}
          alt="Dexter Logo"
          zIndex={"1"}
        />
        <Text
          mt="4"
          fontFamily={"Populaire"}
          fontSize="40px"
          lineHeight="130%"
          fontWeight={500}
          letterSpacing="4px"
          textAlign="center"
          color={"#FCFCFC"}
          zIndex={"100"}
        >
          Persistence Core-1 chain is down and under maintenance but will be
          back online shortly. <br /> During this time, transactions will not be
          processed. We apologize for any inconvenience this may cause and will
          provide regular updates on our progress. <br />
          Thank you for your patience.
        </Text>
      </Flex>
    </>
  );
};

export default MaintenancePage;
