import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Box,
  Flex,
  Text,
  Checkbox,
  Link,
} from "@chakra-ui/react";
import { useChain } from "@cosmos-kit/react";
import { useEffect, useRef, useState } from "react";

export function TermsModal() {
  const [show, setShow] = useState(false);
  const [checkedItems, setCheckedItems] = useState([false, false, false]);

  const allChecked = checkedItems.every(Boolean);
  const persistenceChain =
    process.env.NEXT_PUBLIC_ENV === "testnet"
      ? "persistencetestnet"
      : "persistence";
  const chainContext = useChain(persistenceChain);

  const { isWalletConnected } = chainContext;

  useEffect(() => {
    if (sessionStorage.getItem("terms") !== "hide" && isWalletConnected) {
      setShow(true);
    } else {
      setShow(false);
    }
    return () => {
      setCheckedItems([false, false, false]);
    };
  }, [isWalletConnected]);

  const handleClose = () => {
    sessionStorage.setItem("terms", "hide");
    setShow(false);
    // onClose();
  };
  return (
    <Modal
      variant="dexterLandingModal"
      scrollBehavior="inside"
      isCentered
      closeOnOverlayClick={false}
      isOpen={show}
      onClose={handleClose}
    >
      <ModalOverlay variants={{}} />
      <ModalContent maxWidth="720px">
        <ModalHeader>Terms and Conditions</ModalHeader>
        <ModalBody>
          <Box
            gap="12px"
            // bg="primary.prussianBlue50"
            // borderColor="primary.queenBlue"
            // borderWidth="1.5px"
            borderRadius="12px"
            p="20px"
            py="20px"
            textStyle="dexterP4"
            color={"#FCFCFC"}
            maxH={500}
            // overflowY={"auto"}
            // onScroll={() => onScroll()}
            // ref={listInnerRef}
          >
            <Text fontSize={16} fontWeight={700} mb={"18px"}>
              Important Disclaimer:{" "}
              <Text fontSize={16} fontWeight={500} mb={0} as={"span"}>
                Before proceeding, please confirm your agreement to the terms by
                checking the boxes below. If you do not agree, please leave the
                website:
              </Text>
            </Text>

            <Checkbox
              mb={"14px"}
              size="lg"
              className="my-small-checkbox"
              isChecked={checkedItems[0]}
              onChange={(e) =>
                setCheckedItems([
                  e.target.checked,
                  checkedItems[1],
                  checkedItems[2],
                ])
              }
              alignItems={"center"}
            >
              <Text
                fontSize={14}
                ml="8px"
                // mb={"12px"}
                color={"whiteAlpha.900"}
                fontWeight={300}
              >
                I have read and understood, and hereby agree to be legally bound
                as a ‘User’ to all the terms contained in the{" "}
                <Link
                  href="https://www.dexter.zone/terms"
                  target={"_blank"}
                  rel="noreferrer"
                  textDecoration={"underline"}
                  textUnderlineOffset={2}
                  color={"cyan"}
                >
                  Terms and Conditions
                </Link>{" "}
                (including the{" "}
                <Link
                  href="https://www.dexter.zone/privacy"
                  target={"_blank"}
                  rel="noreferrer"
                  textDecoration={"underline"}
                  textUnderlineOffset={2}
                  color={"cyan"}
                >
                  Privacy Policy
                </Link>
                ) without qualification.
              </Text>
            </Checkbox>
            <Checkbox
              mb={"14px"}
              className="my-small-checkbox"
              isChecked={checkedItems[1]}
              onChange={(e) =>
                setCheckedItems([
                  checkedItems[0],
                  e.target.checked,
                  checkedItems[2],
                ])
              }
              alignItems={"center"}
            >
              <Text
                ml="8px"
                fontSize={14}
                color={"whiteAlpha.900"}
                fontWeight={500}
              >
                I declare that I am not an Excluded Person as defined in the{" "}
                <Link
                  href="https://www.dexter.zone/terms"
                  target={"_blank"}
                  rel="noreferrer"
                  textDecoration={"underline"}
                  textUnderlineOffset={2}
                  color={"cyan"}
                >
                  Terms and Conditions.
                </Link>{" "}
              </Text>
            </Checkbox>
            <Checkbox
              mb={"14px"}
              className="my-small-checkbox"
              isChecked={checkedItems[2]}
              onChange={(e) =>
                setCheckedItems([
                  checkedItems[0],
                  checkedItems[1],
                  e.target.checked,
                ])
              }
              alignItems={"center"}
            >
              <Text
                ml="8px"
                fontSize={14}
                color={"whiteAlpha.900"}
                fontWeight={500}
              >
                I agree that my use and continued use of this site is subject to
                my continued agreement to the prevailing{" "}
                <Link
                  href="https://www.dexter.zone/terms"
                  target={"_blank"}
                  rel="noreferrer"
                  textDecoration={"underline"}
                  textUnderlineOffset={2}
                  color={"cyan"}
                >
                  Terms and Conditions
                </Link>{" "}
                (which may change from time to time) and will apply to all
                actions I take on the site without requiring my confirmation in
                each specific instance.
              </Text>
            </Checkbox>
            <Checkbox
              isChecked={allChecked}
              className="my-small-checkbox"
              mb={[4, 4, 4, 0, 0, 0]}
              // isIndeterminate={isIndeterminate}
              onChange={(e) =>
                setCheckedItems([
                  e.target.checked,
                  e.target.checked,
                  e.target.checked,
                ])
              }
              alignItems={"center"}
            >
              <Text
                ml="8px"
                fontSize={14}
                color={"whiteAlpha.900"}
                fontWeight={300}
              >
                Select All
              </Text>
            </Checkbox>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Flex
            justifyContent="center"
            mt={[0, 0, 0, -4, -4, -4]}
            gap="72px"
            w="full"
            mb="20px"
          >
            <Button
              flex={0.4}
              size="lg"
              onClick={handleClose}
              isDisabled={!allChecked}
            >
              Continue
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
