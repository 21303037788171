import React, { useMemo } from "react";
import { Flex, Text, Box } from "@chakra-ui/react";
import { NumberFormatSpecifier, formatAmount, num } from "@wizard-ui/core";

import { TokenIcon, useTokenInfo, useDexter } from "modules/common";

interface Props {
  token: string;
  amount?: string;
}

export function AddLiquidityAsset({ token, amount }: Props) {
  const { getSymbol } = useTokenInfo();
  const { prices, pools } = useDexter();
  const totalPrice = useMemo(() => {
    if (amount == null || prices == null) {
      return null;
    }

    return num(prices[token].price).times(amount).toString();
  }, [amount, prices, token]);

  return (
    <Flex justify="space-between">
      <Flex align="center" gap="3">
        <TokenIcon size="10" token={token} />
        <Text variant="dexterP1" fontWeight="bold">
          {getSymbol(token)}
        </Text>
      </Flex>
      {amount && (
        <Box textAlign="right">
          <Text fontWeight="600" fontSize="xl">
            {formatAmount(amount, {
              formatSpecifier: NumberFormatSpecifier.FLOAT,
            })}
          </Text>
          <Text color="whiteAlpha.600" fontSize="sm">
            {formatAmount(totalPrice, {
              formatSpecifier: NumberFormatSpecifier.CURRENCY,
            })}
          </Text>
        </Box>
      )}
    </Flex>
  );
}
