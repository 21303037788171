import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ExecuteResult, ExecuteInstruction } from "@cosmjs/cosmwasm-stargate";
import { Coin } from "@cosmjs/amino";

import {
  SwapType,
  useContracts,
  toAssetInfo,
  isNativeToken,
  useDexter,
} from "modules/common";
import { num } from "@wizard-ui/core";
import { useChain } from "@cosmos-kit/react";

export interface CreateSingleSwapMsgOpts {
  swapPath: any;
  amount: string;
  assetIn: string;
  assetOut: string;
  swapType: SwapType;
  beliefPrice?: string | null;
  maxSpread?: string | null;
  minimum_receive: string | null;
}

function createSingleSwapMsg({
  amount,
  assetIn,
  assetOut,
  swapPath,
  swapType,
  beliefPrice,
  maxSpread,
  minimum_receive,
}: CreateSingleSwapMsgOpts) {
  return {
    swap: {
      swap_request: {
        asset_in: toAssetInfo(assetIn),
        asset_out: toAssetInfo(assetOut),
        amount,
        pool_id: String(swapPath[0].pool.id),
        swap_type: swapType,
        // belief_price: beliefPrice,
        // max_spread: "0.02",
      },
      min_receive: num(minimum_receive)
        .times(10 ** 6)
        .toFixed(0),
    },
  };
}

export interface CreateMultiSwapMsgOpts {
  swapPath: any;
  amount: string;
  minimum_receive: string | null;
}

function createMultiSwapMsg({
  amount,
  swapPath,
  minimum_receive,
}: CreateMultiSwapMsgOpts) {
  const multiswapRequest = swapPath.map(({ pool, tokenIn, tokenOut }: any) => {
    return {
      asset_in: toAssetInfo(tokenIn),
      asset_out: toAssetInfo(tokenOut),
      pool_id: String(pool.id),
      // max_spread: "0.02",
    };
  });

  return {
    execute_multihop_swap: {
      requests: multiswapRequest,
      offer_amount: amount,
      minimum_receive: num(minimum_receive)
        .times(10 ** 6)
        .toFixed(0),
    },
  };
}

export type SwapMutation = {
  swapPath: any;
  amount: string;
  assetIn: string;
  assetOut: string;
  swapType: SwapType;
  beliefPrice?: string | null;
  maxSpread?: string | null;
  minimum_receive: string | null;
};

export function useSwapMutation() {
  const { tokens } = useDexter();
  const { vault, router } = useContracts();
  const queryClient = useQueryClient();
  const persistenceChain =
    process.env.NEXT_PUBLIC_ENV === "testnet"
      ? "persistencetestnet2"
      : process.env.NEXT_PUBLIC_ENV === "devnet"
      ? "Dexter Devnet"
      : "persistence";
  const chainContext = useChain(persistenceChain);
  const { address, getSigningCosmWasmClient } = chainContext;

  return useMutation<ExecuteResult, Error, SwapMutation>(
    async ({
      swapPath,
      amount,
      assetIn,
      assetOut,
      swapType,
      beliefPrice,
      maxSpread,
      minimum_receive,
    }) => {
      const signingClient = await getSigningCosmWasmClient();
      if (signingClient == null || address == null) {
        throw new Error("Missing signingClient in useSwapMutation");
      }
      const isMulti = swapPath.length > 1;
      const amountMicro = num(amount)
        .times(10 ** tokens[assetIn].decimals)
        .toFixed(0);
      let allowanceMsgs: ExecuteInstruction[] = [];
      let swapMsg: ExecuteInstruction["msg"] | undefined;
      let funds: Coin[] = [];

      if (!isNativeToken(assetIn)) {
        allowanceMsgs = [
          {
            contractAddress: assetIn,
            msg: {
              increase_allowance: {
                amount: amountMicro,
                spender: isMulti ? router : vault,
              },
            },
            funds: [],
          },
        ];
      } else {
        funds = [
          {
            denom: assetIn,
            amount: amountMicro,
          },
        ];
      }

      if (!isMulti) {
        swapMsg = createSingleSwapMsg({
          swapPath,
          amount: amountMicro,
          assetIn,
          assetOut,
          swapType,
          beliefPrice,
          maxSpread,
          minimum_receive,
        });
      } else {
        swapMsg = createMultiSwapMsg({
          swapPath,
          amount: amountMicro,
          minimum_receive,
        });
      }

      return signingClient.executeMultiple(
        address,
        [
          ...allowanceMsgs,
          {
            contractAddress: isMulti ? router : vault,
            msg: swapMsg,
            funds,
          },
        ],
        "auto",
        "",
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["balances"]);
      },
    },
  );
}
