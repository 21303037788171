import { useMemo } from "react";
import {
  ModalProps,
  Button,
  Box,
  Text,
  Image,
  Center,
  Flex,
  CloseButton,
} from "@chakra-ui/react";
import { formatAmount, num, NumberFormatSpecifier } from "@wizard-ui/core";

import {
  useTokenInfo,
  useDexter,
  TxPending,
  TxSuccess,
  TxError,
  WarningIcon,
  Wrapper,
} from "modules/common";
import {
  calculatePriceImpactStableSwap,
  calculateTotalSlippage,
  SwapInfo,
} from "modules/swap";
import { toast } from "react-toastify";

interface TokenItemProps {
  amount: string;
  token: string;
}

export function TokenItem({ amount, token }: TokenItemProps) {
  const { getSymbol, getIcon } = useTokenInfo();
  const { prices } = useDexter();

  const totalPrice = useMemo(() => {
    if (prices == null) {
      return null;
    }

    return num(amount).times(prices?.[token]?.price).toNumber();
  }, []);

  return (
    <Box
      bg="primary.prussianBlue50"
      px="2"
      py="3"
      borderRadius="xl"
      flex="1"
      textAlign="center"
    >
      <Center
        bg="primary.prussianBlue"
        p="4"
        borderRadius="xl"
        flexDir="column"
      >
        <Image src={getIcon(token)} boxSize="45px" mb="3" alt="token icon" />
        <Text fontSize="lg" fontWeight="700">
          {formatAmount(amount, {
            shouldDivide: true,
          })}{" "}
          {getSymbol(token)}
        </Text>
        <Text fontSize="xs">
          {formatAmount(totalPrice, {
            formatSpecifier: NumberFormatSpecifier.CURRENCY,
            shouldDivide: true,
          })}
        </Text>
      </Center>
    </Box>
  );
}

interface Props extends Omit<ModalProps, "children"> {
  tokenIn: string;
  tokenOut: string;
  amountIn: string;
  amountOut: string;
  data: any;
  maxSpread: string;
  error: any;
  txData: any;
  isLoading: boolean;
  onSubmit: (evt: any) => any;
}

export function SwapModal({
  tokenIn,
  tokenOut,
  amountIn,
  amountOut,
  data,
  maxSpread,
  error,
  txData,
  onSubmit,
  isLoading,
  ...rest
}: Props) {
  const renderForm = () => {
    const slippage =
      data?.swaps[0]?.pool?.poolType === "weighted"
        ? calculateTotalSlippage(data)
        : calculatePriceImpactStableSwap(data);
    if (isLoading || txData != null || amountOut == null || error != null) {
      return null;
    }

    return (
      <Box>
        <Flex gap="4" align="center" mb="6">
          <TokenItem
            token={tokenIn}
            amount={num(amountIn)
              .times(10 ** 6)
              .toString()}
          />
          <Box>
            <Image
              boxSize={"42px"}
              src="./icons/right_arrow.svg"
              alt="right arrow"
            />
          </Box>
          <TokenItem
            token={tokenOut}
            amount={num(amountOut)
              .times(10 ** 6)
              .toString()}
          />
        </Flex>
        <SwapInfo
          tokenIn={tokenIn}
          tokenOut={tokenOut}
          amountIn={amountIn}
          maxSpread={maxSpread}
        />
        {Number(slippage) > 15 && (
          <Wrapper mt="2" variant="swapError" justifyContent="center">
            <WarningIcon boxSize="20px" />
            <Text variant="dexterP4" color="primary.white">
              The price impact of the trade is too high.
            </Text>
          </Wrapper>
        )}
        <Button
          type="button"
          variant="primary"
          w="full"
          onClick={onSubmit}
          isLoading={isLoading}
          isDisabled={Number(slippage) > 15}
          mt="4"
        >
          Swap
        </Button>
      </Box>
    );
  };

  const CustomCloseButton = ({ closeToast }) => (
    <CloseButton
      pos={"absolute"}
      left="0"
      top={"0"}
      color="#FCFCFC"
      w={34}
      h={34}
      borderRadius={"50%"}
      border={"2px solid #386794"}
      bg={"#0a1d32"}
      onClick={() => {
        // handleClose();
        closeToast();
      }}
    />
  );
  const renderPending = () => {
    if (!isLoading) {
      return null;
    }

    return <TxPending />;
  };

  // const renderFinal = () => {
  //   if (txData == null && error != null) {
  //     return <TxError />;
  //   }

  //   if (txData == null) {
  //     return null;
  //   }

  //   return (
  //     <TxSuccess
  //       title="Transaction Successful!"
  //       subtitle="You've Successfully Swapped Your Assets!"
  //       data={txData}
  //     />
  //   );
  // };

  const renderFinal = () => {
    if (isLoading) {
      return toast(<TxPending />, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: false,
        toastId: "",
        closeButton: CustomCloseButton,
      });
    } else {
      if (txData == null && error != null) {
        return toast.update("", {
          render: <TxError />,
          closeButton: CustomCloseButton,
          autoClose: 7000,
        });
      }

      if (txData == null) {
        return null;
      }

      return toast.update("", {
        render: () => (
          <TxSuccess
            subtitle="Assets Swapped Successfully"
            data={txData}
            title="Transaction Successful"
          />
        ),
        closeButton: CustomCloseButton,
        autoClose: 7000,
      });
    }
  };

  const hideTitle = isLoading || txData != null || error != null;

  return !hideTitle ? null : renderFinal();
}
