import { Text, Center, Flex, useMediaQuery } from "@chakra-ui/react";
import { Player } from "@lottiefiles/react-lottie-player";

import error from "./error.json";

export function TxError({ error: errorMsg }) {
  console.log({ errorMsg });
  const [isMobile] = useMediaQuery("(max-width: 800px)");
  return isMobile ? (
    <Flex justifyItems={"center"} align="center">
      <Player
        src={error}
        autoplay={true}
        loop={true}
        style={{ width: "100px", margin: 0 }}
      />
      <Text
        fontSize="16px"
        fontWeight="600"
        mt="-4"
        color={"#FCFCFC"}
        fontFamily={"Nunito Sans"}
      >
        Transaction Failed
      </Text>
    </Flex>
  ) : (
    <Flex justifyItems={"center"} align="center">
      <Player
        src={error}
        autoplay={true}
        loop={true}
        style={{ width: "150px", margin: 0 }}
      />
      <Text
        fontSize={["10px", "10px", "10px", "24px", "24px", "24px"]}
        fontWeight="600"
        mt="-4"
        color={"#FCFCFC"}
        fontFamily={"Nunito Sans"}
      >
        Transaction Failed
      </Text>
    </Flex>
  );
}
