import React from "react";
import { Control, ControllerProps } from "react-hook-form";

import { ChevronDownIcon, InfoIcon, Wrapper } from "modules/common";
import { AmountInput, TokenSelect, TokenDisplay } from "modules/swap";
import { BalanceInput } from "./BalanceInput";
import { Box, Flex, HStack, Text } from "@chakra-ui/react";
import { num } from "@wizard-ui/core";
import { TooltipWithTouch } from "modules/common/components/TooltipWithTouch";
import Link from "next/link";

interface Props {
  /**
   *  provide `name`, `control` & optional `rules`
   */
  amountInputName?: ControllerProps["name"];
  tokenInputName?: ControllerProps["name"];
  control: Control<any>;
  rules?: ControllerProps["rules"];
  /**
   * for readonly field, provide `value`
   */
  value?: string;
  token: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  hideSelect?: boolean;
  isLpToken?: boolean;
  max?: number | string;
  onTokenChange?: (value: any) => void;
  onAmountChange?: (value: any) => void;
  onAmountChangeAvailable?: (value: any) => void;
}

export function StakedTokenInput({
  amountInputName,
  tokenInputName,
  token,
  control,
  isDisabled,
  isLoading,
  hideSelect = false,
  isLpToken = false,
  max,
  onTokenChange,
  onAmountChange,
  onAmountChangeAvailable,
  selectedValidator,
  handleChooseValidatorClick,
}: Props) {
  return (
    <>
      <Wrapper variant="primary" p="3">
        <BalanceInput
          token={token}
          name={amountInputName}
          control={control}
          isDisabled={!selectedValidator?.eligible || isDisabled}
          max={
            selectedValidator
              ? num(selectedValidator?.balance.amount)
                  .div(10 ** 6)
                  .toString()
              : "0"
          }
          onChange={onAmountChangeAvailable}
        />
        <Wrapper variant="swapInner" mt="3">
          {hideSelect ? (
            <TokenDisplay token={token} isLpToken={isLpToken} />
          ) : (
            <TokenSelect
              name={tokenInputName}
              control={control}
              isDisabled={!selectedValidator?.eligible || isDisabled}
              onChange={onTokenChange}
            />
          )}
          <AmountInput
            token={token}
            name={amountInputName}
            control={control}
            isDisabled={!selectedValidator?.eligible || isDisabled}
            isLoading={isLoading}
            onChange={onAmountChange}
          />
        </Wrapper>
        <HStack mt={2}>
          <Text color={"#A6A6A6"} fontSize={"16px"}>
            Validator:
          </Text>
          <Flex
            align={"center"}
            bg={"primary.prussianBlue"}
            gap={2}
            padding={"8px"}
            px={"12px"}
            borderRadius={"48px"}
            borderWidth={"1px"}
            borderColor={"stroke.dexBlue"}
            onClick={() => handleChooseValidatorClick()}
            _hover={{
              cursor: "pointer",
            }}
          >
            <Text fontSize={"16px"}>
              {selectedValidator
                ? selectedValidator?.eligible
                  ? selectedValidator?.moniker
                  : `${selectedValidator?.moniker} (Not Eligible)`
                : "Choose Validator"}
            </Text>
            <ChevronDownIcon />
          </Flex>
          <TooltipWithTouch
            hasArrow
            label={
              "Staked XPRT can only be converted into Superfluid stkXPRT LP if your validator is part of pSTAKE's active set. You can choose to redelegate to an active validator to proceed with Superfluid LP."
            }
            bg="#00506B"
            color="white"
            borderRadius="lg"
            p="3"
            maxW="56"
          >
            <span>
              <InfoIcon _hover={{ cursor: "pointer" }} />
            </span>
          </TooltipWithTouch>
        </HStack>
        <Link
          href={"https://pstake.finance/xprt/validators"}
          target="_blank"
          rel="noreferrer noopenner"
        >
          <Text
            mt={2}
            textDecoration={"underline"}
            textUnderlineOffset={2}
            fontSize={14}
          >
            Learn more about validator eligibility.
          </Text>
        </Link>
      </Wrapper>
    </>
  );
}
