import { Icon, IconProps } from "@chakra-ui/react";

export function ChevronUpIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 18 18" fill="none" {...props}>
      <path
        d="M14 11L9 6L4 11"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
