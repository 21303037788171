import {
  Flex,
  InputGroup,
  InputLeftElement,
  Input,
  Divider,
  HStack,
  Box,
  Text,
} from "@chakra-ui/react";
import { formatAmount, num, NumberFormatSpecifier } from "@wizard-ui/core";
import { SearchIcon } from "lucide-react";
import React, { useMemo, useState } from "react";
import { WarningIcon, Wrapper, useDexter } from "modules/common";

export const ValidatorDelegations = ({ handleSelect }) => {
  const { balances, prices } = useDexter();

  const [search, setSearch] = useState("");
  const [searchedValidators, setSearchedValidators] = useState<any[]>([]);
  const validatorList =
    balances === undefined
      ? []
      : Object.entries(balances)
          .filter(([key]) => key.includes("valoper"))
          .map(([key, value]) => ({
            validator: key,
            balance: value.balance,
            jailed: value.jailed,
            moniker: value.moniker,
            eligible: value.eligible,
          }));

  const handleSearchFrom = (search: string) => {
    setSearch(search);

    const newVals = validatorList
      .filter((validator) =>
        validator.moniker.toLowerCase().includes(search.toLowerCase()),
      )
      .sort((a, b) => {
        if (a.eligible !== b.eligible) {
          return a.eligible ? -1 : 1;
        }
        return b.balance?.amount - a.balance?.amount;
      });
    setSearchedValidators(newVals);
  };

  const searchedElem =
    searchedValidators.length > 0 ? (
      <>
        {searchedValidators
          .sort((a, b) => {
            if (a.eligible !== b.eligible) {
              return a.eligible ? -1 : 1;
            }

            // If both validators have the same eligibility, sort by balance amount in descending order
            return b.balance?.amount - a.balance?.amount;
          })
          .map((validator, index) => (
            <Flex
              as="button"
              py="2"
              px="4"
              align="center"
              justify="space-between"
              cursor={validator?.eligible === false ? "auto" : "pointer"}
              w="full"
              disabled={validator?.eligible === false}
              onClick={() => handleSelect(validator)}
              type="button"
              opacity={validator?.eligible === false ? 0.5 : 1}
              _hover={
                validator?.eligible === false
                  ? { bg: "none" }
                  : { bg: "whiteAlpha.200" }
              }
              _last={{ mb: "0" }}
            >
              <HStack spacing="4">
                <Box
                  borderRadius={"full"}
                  bgImage={`https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/persistence/moniker/${validator.validator}.png`}
                  boxSize={"10"}
                  bgSize={"cover"}
                />
                <Box textAlign="left">
                  <Text fontWeight="700">{validator?.moniker}</Text>
                  {validator?.jailed ? (
                    <Text fontSize="sm" color="#D2D2D2">
                      Jailed
                    </Text>
                  ) : null}
                  {validator?.eligible === false ? (
                    <Text fontSize="sm" color="#D2D2D2">
                      Ineligible
                    </Text>
                  ) : null}
                </Box>
              </HStack>
              <Box textAlign="right">
                <Text fontWeight="700">
                  {formatAmount(num(validator.balance?.amount).div(10 ** 6), {
                    formatSpecifier: NumberFormatSpecifier.FLOAT,
                    // shouldDivide: true,
                  })}
                </Text>
                <Text fontSize="sm" color="#D2D2D2">
                  {formatAmount(
                    num(validator.balance?.amount)
                      .div(10 ** 6)
                      .times(prices?.[validator.balance.denom].price),
                    {
                      formatSpecifier: NumberFormatSpecifier.CURRENCY,
                    },
                  )}
                </Text>
              </Box>
            </Flex>
          ))}
      </>
    ) : (
      <Wrapper variant="swapError" mx="4">
        <WarningIcon boxSize="20px" />
        <Text variant="dexterP4" color="primary.white">
          Validator Not Available
        </Text>
      </Wrapper>
    );

  const suggestedElem = (
    <Box>
      {validatorList.length > 0 &&
        validatorList
          .sort((a, b) => {
            if (a.eligible !== b.eligible) {
              return a.eligible ? -1 : 1;
            }

            // If both validators have the same eligibility, sort by balance amount in descending order
            return b.balance?.amount - a.balance?.amount;
          })
          .map((validator, index) => (
            <Flex
              as="button"
              py="2"
              px="4"
              align="center"
              justify="space-between"
              cursor={validator?.eligible === false ? "auto" : "pointer"}
              w="full"
              disabled={validator?.eligible === false}
              onClick={() => handleSelect(validator)}
              type="button"
              opacity={validator?.eligible === false ? 0.5 : 1}
              _hover={
                validator?.eligible === false
                  ? { bg: "none" }
                  : { bg: "whiteAlpha.200" }
              }
              _last={{ mb: "0" }}
            >
              <HStack spacing="4">
                <Box
                  borderRadius={"full"}
                  bgImage={`https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/persistence/moniker/${validator.validator}.png`}
                  boxSize={"10"}
                  bgSize={"cover"}
                />
                <Box textAlign="left">
                  <Text fontWeight="700">{validator?.moniker}</Text>
                  {validator?.jailed ? (
                    <Text fontSize="sm" color="#D2D2D2">
                      Jailed
                    </Text>
                  ) : null}
                  {validator?.eligible === false ? (
                    <Text fontSize="sm" color="#D2D2D2">
                      Ineligible
                    </Text>
                  ) : null}
                </Box>
              </HStack>
              <Box textAlign="right">
                <Text fontWeight="700">
                  {formatAmount(num(validator.balance?.amount).div(10 ** 6), {
                    formatSpecifier: NumberFormatSpecifier.FLOAT,
                    // shouldDivide: true,
                  })}
                </Text>
                <Text fontSize="sm" color="#D2D2D2">
                  {formatAmount(
                    num(validator.balance?.amount)
                      .div(10 ** 6)
                      .times(prices?.[validator.balance?.denom]?.price),
                    {
                      formatSpecifier: NumberFormatSpecifier.CURRENCY,
                    },
                  )}
                </Text>
              </Box>
            </Flex>
          ))}
    </Box>
  );

  const addonElem = search.length > 0 ? searchedElem : suggestedElem;

  return (
    <Flex flexDir="column" h="full" flex="1">
      <Box px="2">
        <Text color="#A6A6A6" fontSize={"14px"}>
          Select a validator to directly convert your natively staked XPRT into
          an LP position using Superfluid LP.
        </Text>
        <InputGroup mt="2" mb="4">
          <InputLeftElement pointerEvents="none">
            <SearchIcon boxSize={6} />
          </InputLeftElement>
          <Input
            w="full"
            variant={"dexterSearchSmall"}
            placeholder="Search by validator"
            value={search}
            onChange={(e) => handleSearchFrom(e.target.value)}
            // {...rest}
          />
        </InputGroup>
        <Divider borderColor={"stroke.dexBlue"} mb="4" />
      </Box>
      <Box maxH="60vh" overflowY="auto">
        <Box flex="1">{addonElem}</Box>
      </Box>
    </Flex>
  );
};
