import { useState } from "react";
import {
  Box,
  ModalProps,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  VStack,
  chakra,
  Checkbox,
  Center,
  Text,
  CloseButton,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { num } from "@wizard-ui/core";

import {
  TxPending,
  TxSuccess,
  TxError,
  useDexter,
  Wrapper,
  WarningIcon,
} from "modules/common";
import { PoolBase } from "modules/pools";
import { TokenDisplay, TokenInput } from "modules/swap";
import { useBondMultiStakingMutation } from "modules/contracts";

import { toast } from "react-toastify";
import { UnbondingPeriod } from "config";
import { useChain, useManager } from "@cosmos-kit/react";

interface FormValues {
  amount: string;
}

interface Props extends Omit<ModalProps, "children"> {
  pool: PoolBase;
}

export function BondModal({ pool, onClose, ...rest }: Props) {
  const { mutate, isLoading, data, reset, error } =
    useBondMultiStakingMutation();
  const { balances } = useDexter();
  const persistenceChain =
    process.env.NEXT_PUBLIC_ENV === "testnet"
      ? "persistencetestnet2"
      : process.env.NEXT_PUBLIC_ENV === "devnet"
      ? "Dexter Devnet"
      : "persistence";
  const chainContext = useChain(persistenceChain);
  const { getWalletRepo } = useManager();
  const walletRepo = getWalletRepo(persistenceChain);
  const { connect, address, isWalletConnected } = chainContext;
  const [isConfirmStep, setIsConfirmStep] = useState(false);
  const {
    handleSubmit,
    control,
    reset: resetForm,
    watch,
  } = useForm<FormValues>({
    mode: "onChange",
    defaultValues: {
      amount: "",
    },
  });
  const unbondingPeriod =
    UnbondingPeriod[
      process.env.NEXT_PUBLIC_ENV as keyof typeof UnbondingPeriod
    ];
  const { amount } = watch();
  const onSubmit = (values: FormValues) => {
    mutate({
      msg: {
        lpTokenAddr: pool.lpToken,
        amount: num(values.amount)
          .times(10 ** 18)
          .toFixed(0),
      },
    });
  };

  const handleClose = () => {
    setIsConfirmStep(false);
    reset();
    resetForm();
    onClose();
  };

  const mobileWebModeName = "mobile-web";

  const connectHandler = () => {
    sessionStorage.setItem("terms", "show");
    if (
      (window?.leap && window?.leap?.mode === mobileWebModeName) ||
      window?.navigator?.userAgent?.includes("LeapCosmos")
    ) {
      const wallet = walletRepo.wallets.find(
        (wallet) => wallet._walletInfo.name === "leap-extension",
      );
      wallet._walletInfo.mobileDisabled = false;
      walletRepo.connect("leap-extension");
      return;
    } else if (window?.keplr && window?.keplr?.mode === mobileWebModeName) {
      const wallet = walletRepo.wallets.find(
        (wallet) => wallet._walletInfo.name === "keplr-extension",
      );
      wallet._walletInfo.mobileDisabled = false;
      walletRepo.connect("keplr-extension");
      return;
    } else {
      connect();
      return;
    }
  };

  const renderForm = () => {
    if (isConfirmStep || isLoading || data != null || error != null) {
      return null;
    }

    return (
      <Box>
        <Box mb="6">
          <TokenInput
            amountInputName="amount"
            tokenInputName="token"
            token={pool.lpToken}
            control={control}
            poolType={pool.poolType}
            isLpToken
            hideSelect
          />
        </Box>
        <Wrapper variant="swapError" justifyContent="center">
          <WarningIcon boxSize="20px" />
          <Text variant="dexterP4" color="primary.white">
            LP Tokens will have an unbonding period of {unbondingPeriod}.
          </Text>
        </Wrapper>
        <Button
          mt="8"
          variant="primary"
          type="submit"
          w="full"
          size="xl"
          isDisabled={
            isWalletConnected &&
            (num(balances?.[pool.lpToken])
              .div(10 ** 18)
              .lt(num(amount)) ||
              Number(amount) === 0)
          }
          onClick={() => !isWalletConnected && connectHandler()}
        >
          {isWalletConnected ? "Bond Assets" : "Connect Wallet"}
        </Button>
      </Box>
    );
  };

  const CustomCloseButton = ({ closeToast }) => (
    <CloseButton
      pos={"absolute"}
      left="0"
      top={"0"}
      color="#FCFCFC"
      w={34}
      h={34}
      borderRadius={"50%"}
      border={"2px solid #386794"}
      bg={"#0a1d32"}
      onClick={() => {
        handleClose();
        closeToast();
      }}
    />
  );

  const renderFinal = () => {
    if (isLoading) {
      return toast(<TxPending />, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: false,
        toastId: "  ",
        closeButton: CustomCloseButton,
      });
    } else {
      if (data == null && error != null) {
        handleClose();
        return toast.update("  ", {
          render: <TxError error={error} />,
          closeButton: CustomCloseButton,
          autoClose: 7000,
        });
      }

      if (data == null) {
        return null;
      }
      handleClose();
      return toast.update("  ", {
        render: () => (
          <TxSuccess
            subtitle="LP Tokens Bonded Successfully"
            data={data}
            title="Transaction Successful"
          />
        ),
        closeButton: CustomCloseButton,
        autoClose: 7000,
      });
    }
  };

  const hideTitle = isLoading || data != null || error != null;

  return !hideTitle ? (
    <Modal onClose={handleClose} size="lg" isCentered {...rest}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {!hideTitle && "Bond"}
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <chakra.form onSubmit={handleSubmit(onSubmit)}>
            {renderForm()}
            {/* {renderConfirm()} */}
            {/* {renderPending()}
            {renderFinal()} */}
          </chakra.form>
        </ModalBody>
      </ModalContent>
    </Modal>
  ) : (
    renderFinal()
  );
}
