import { Text, Flex, Box } from "@chakra-ui/react";
import { Player } from "@lottiefiles/react-lottie-player";

import error from "./error.json";

export function TxErrorKeplr({ errorMessage }) {
  return (
    <Flex justify={"center"} align="center" p={4}>
      <Player
        src={error}
        autoplay={true}
        loop={true}
        style={{ width: "150px", margin: 0, marginRight: 20 }}
      />
      <Box>
        <Text fontSize="20px" fontWeight="600" mt="0" color={"#FCFCFC"}>
          {errorMessage}
        </Text>
      </Box>
    </Flex>
  );
}
