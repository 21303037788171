import { Icon, IconProps } from "@chakra-ui/react";

export function FilterIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 28 28" {...props}>
      <path
        d="M11.6667 21V18.6667H16.3333V21H11.6667ZM7 15.1667V12.8333H21V15.1667H7ZM3.5 9.33333V7H24.5V9.33333H3.5Z"
        fill="currentColor"
      />
    </Icon>
  );
}
