import { defineStyleConfig } from "@chakra-ui/react";

const Wrapper = defineStyleConfig({
  variants: {
    primary: {
      background:
        "linear-gradient(284.42deg, rgba(0, 175, 250, 0.2) -49.92%, rgba(50, 162, 186, 0.2) 54.24%, rgba(36, 255, 202, 0.2) 169.83%);",
      borderColor: "stroke.dexBlue",
      borderWidth: "1px",
      borderRadius: "2xl",
      px: "16",
      py: "6",
      backdropFilter: "blur(10px)",
    },
    createPoolLiq: {
      bg: "rgba(0, 53.20, 71.19, 0.50)",
      borderColor: "stroke.dexBlue",
      borderWidth: "1px",
      borderRadius: "2xl",
      px: "16",
      py: "6",
      backdropFilter: "blur(10px)",
    },
    secondary: {
      py: "3",
      background: "primary.prussianBlue50",
      borderColor: "primary.queenBlue",
      borderRadius: "2xl",
      borderWidth: "1px",
    },
    asset: {
      background:
        "linear-gradient(284.42deg, rgba(0, 175, 250, 0.2) -49.92%, rgba(50, 162, 186, 0.2) 54.24%, rgba(36, 255, 202, 0.2) 169.83%);",
      borderColor: "stroke.dexBlue",
      borderWidth: "1px",
      borderRadius: "2xl",
      px: "2",
      py: "3",
      display: "flex",
      flexDir: "column",
      gap: 2,
    },
    empty: {
      h: "316px",
      w: "full",
      display: "flex",
      flexDir: "column",
      alignItems: "center",
      justifyContent: "center",
      background:
        "linear-gradient(284.42deg, rgba(0, 175, 250, 0.2) -49.92%, rgba(50, 162, 186, 0.2) 54.24%, rgba(36, 255, 202, 0.2) 169.83%);",
      borderColor: "stroke.dexBlue",
      borderWidth: "1px",
      borderRadius: "lg",
      gap: "24px",
    },
    swapInner: {
      p: "2",
      bgColor: "primary.prussianBlue",
      borderWidth: "1px",
      borderRadius: "lg",
      borderColor: "primary.queenBlue",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    unbond: {
      px: "3",
      py: "3",
      bgColor: "primary.prussianBlue",
      borderWidth: "1px",
      borderRadius: "lg",
      borderColor: "primary.queenBlue",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    singleAssetLiquidity: {
      p: "2",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    swapError: {
      display: "flex",
      gap: "8px",
      alignItems: "center",
      borderRadius: "lg",
      bgColor: "primary.popStar50",
      p: "8px",
      color: "primary.popStar",
    },
  },
  defaultProps: {
    variant: "primary",
  },
});

export default Wrapper;
