import { Button } from "@chakra-ui/react";
import Link from "next/link";
import { useRouter } from "next/router";

import { RightArrowIcon } from "modules/common";

interface Props {
  children: React.ReactNode;
  link: string;
  exact?: boolean;
  isExternal?: boolean;
}

export function NavbarItem({
  children,
  link,
  exact = false,
  isExternal = false,
}: Props) {
  const router = useRouter();
  const isActive = exact ? router.route == link : router.route.includes(link);
  const path = router.asPath;
  const ipfsMatch = /.*\/Qm\w{44}\//.exec(path);

  const base = ipfsMatch ? ipfsMatch[0] : "/";

  return (
    <Link href={`${base}${link}`} passHref prefetch>
      <Button
        as="a"
        variant="nav"
        fontSize={
          isActive
            ? ["1.4rem", "1.4rem", "1rem", "1.2rem", "1.5rem"]
            : ["1.3rem", "1.3rem", "0.9rem", "1.1rem", "1.4rem"]
        }
        color={isActive ? "primary.white" : "whiteAlpha.700"}
        target={isExternal ? "_blank" : "_self"}
        // onClick={() => router.push(`${router.asPath}${link}`)}
      >
        {children}
        {isExternal && <RightArrowIcon />}
      </Button>
    </Link>
  );
}
