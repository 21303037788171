import { useChain } from "@cosmos-kit/react";
import { useQuery } from "@tanstack/react-query";

import { useContracts } from "modules/common";
import { getInstantUnbondConfig, getRawTokenLocks } from "modules/contracts";

export const useInstantUnbondConfig = (lpToken: string) => {
  const persistenceChain =
    process.env.NEXT_PUBLIC_ENV === "testnet"
      ? "persistencetestnet2"
      : process.env.NEXT_PUBLIC_ENV === "devnet"
      ? "Dexter Devnet"
      : "persistence";
  const chainContext = useChain(persistenceChain);
  const { address, getCosmWasmClient } = chainContext;
  const { multistaking } = useContracts();
  return useQuery(
    ["instantUnbondConfig", getCosmWasmClient, multistaking, address, lpToken],
    async () => {
      const client = await getCosmWasmClient();
      if (
        client == null ||
        multistaking == null ||
        address == null ||
        lpToken == null
      ) {
        throw new Error("Error in useInstantUnbondConfig");
      }

      const unbondConfig = await getInstantUnbondConfig({
        client,
        lpToken,
        multistaking,
        address,
      });

      return unbondConfig;
    },
    {
      // Revisit the refetch interval
      enabled:
        getCosmWasmClient != null &&
        multistaking != null &&
        address != null &&
        lpToken != null,
    },
  );
};
