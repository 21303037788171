import { CopyToClipboard } from "react-copy-to-clipboard";
import React, { useState } from "react";
import { CopyIcon } from "./Icons";
import { Box, HStack, Text } from "@chakra-ui/react";

interface IconTypes {
  id: string;
}

const Copy = ({ id }: IconTypes) => {
  const [copyValue, setCopyValue] = useState(false);
  const onCopy = () => {
    setCopyValue(true);
    setTimeout(() => {
      setCopyValue(false);
    }, 2000);
  };
  return (
    <div className="relative flex">
      <CopyToClipboard onCopy={onCopy} text={id}>
        <HStack cursor="pointer" spacing="3">
          <CopyIcon boxSize="6" />
          <span>
            {copyValue ? <Text>Copied!</Text> : <Text>Copy Address</Text>}
          </span>
        </HStack>
      </CopyToClipboard>
    </div>
  );
};

export default Copy;
