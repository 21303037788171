import React from "react";
import type { Control, ControllerProps } from "react-hook-form";
import { useController } from "react-hook-form";
import { Flex, Text, Button, HStack } from "@chakra-ui/react";
import { formatAmount, num, NumberFormatSpecifier } from "@wizard-ui/core";

import { MIN_FEE, useDexter } from "modules/common";

interface Props extends React.ComponentProps<"input"> {
  /**
   *  provide `name`, `control` & optional `rules`
   */
  name?: ControllerProps["name"];
  control: Control<any>;
  rules?: ControllerProps["rules"];
  /**
   * for readonly field, provide `value`
   */
  value?: string;
  token?: string | null;
  isDisabled?: boolean;
  max?: number | string;
}

export function BalanceInput({
  name = "",
  control,
  rules,
  token,
  max,
  isDisabled = false,
  onChange,
  ...props
}: Props) {
  // we decided decimal input needs rifm mask for UX
  // mask needs controller to expose `onChange` & `value` for transforms
  const { balances, tokens } = useDexter();
  const { field } = useController({
    name,
    control,
    rules,
    defaultValue: props.value,
  });
  // @ts-expect-error - TODO
  const balance = num(balances?.[token]).div(10 ** tokens[token].decimals);

  function handleChange(multiplier: number) {
    const newValue = num(max ?? balance).gt(0)
      ? num(max ?? balance)
          .minus(token === "uxprt" ? num(MIN_FEE) : 0)
          .times(multiplier)
          .toFixed(18)
      : num(max ?? balance)
          .times(multiplier)
          .toFixed(18);
    field.onChange(newValue);

    const changeEvent = {
      target: { value: newValue },
    } as React.ChangeEvent<HTMLInputElement>;
    onChange?.(changeEvent);
  }

  return (
    <Flex justify="flex-end">
      <HStack>
        <Text fontSize="14px" color="#FCFCFC" fontWeight={600}>
          {"Available"}:
        </Text>
        <Text
          _hover={{ cursor: "pointer" }}
          fontSize="14px"
          color="#FCFCFC"
          fontWeight={600}
          onClick={() => !isDisabled && handleChange(1)}
        >
          {formatAmount(max ?? balance, {
            formatSpecifier: NumberFormatSpecifier.FLOAT,
          })}
        </Text>
      </HStack>
    </Flex>
  );
}
