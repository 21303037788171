import { useMemo } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { formatAmount, num, NumberFormatSpecifier } from "@wizard-ui/core";
import { Button, Heading, HStack, Text, useMediaQuery } from "@chakra-ui/react";

import {
  Wrapper,
  Table,
  TokenIcon,
  useTokenInfo,
  useDexter,
  byObjectValues,
} from "modules/common";
import { MyAssetsActions, useMyAssets } from "modules/assets";
import AssetItemMobile from "./AssetItemMobile";

interface AssetItem {
  token: string;
  hasIBC: boolean;
}

export function MyAssets() {
  const [isMobile] = useMediaQuery("(max-width: 800px)");
  const assets = useMyAssets();
  const { prices, balances, tokens } = useDexter();
  const { getSymbol } = useTokenInfo();
  const columns = useMemo<ColumnDef<AssetItem>[]>(
    () => [
      {
        header: "Asset",
        accessorFn: (row) => {
          return row.token;
        },
        cell: (info) => {
          const token = info.getValue() as string;
          return (
            <HStack>
              <TokenIcon token={token} />
              <Text fontWeight="700">{getSymbol(token)}</Text>
            </HStack>
          );
        },
      },
      {
        header: "Amount",
        accessorFn: (row) => {
          return row.token;
        },
        cell: (info) => {
          const token = info.getValue() as string;
          const balance = balances?.[info.getValue() as keyof typeof balances];
          return formatAmount(
            token ===
              "ibc/A6E3AF63B3C906416A9AF7A556C59EA4BD50E617EFFE6299B99700CCB780E444" ||
              token ===
                "ibc/23DC3FF0E4CBB53A1915E4C62507CB7796956E84C68CA49707787CB8BDE90A1E" ||
              token === "stk/adydx"
              ? num(balance).div(10 ** 12)
              : token ===
                  "ibc/5D3B6445EA1D7064C4B1CCB588638589529556E1BCBADF13475021B42EA8C73B" ||
                token ===
                  "ibc/CCA9F9B22D39884C09975D45E1869B73A12B87080EE53CB44905CE2C422CA228"
              ? num(balance).div(10 ** 2)
              : num(balance),
            {
              formatSpecifier: NumberFormatSpecifier.FLOAT,
              shouldDivide: true,
            },
          );
        },
      },
      {
        header: "Price",
        accessorFn: (row) => {
          return prices?.[row.token].price;
        },
        cell: (info) => {
          const token = info.getValue() as number;
          // return Math.ceil(token * 100) / 100;
          return formatAmount(Math.ceil(token * 100) / 100, {
            formatSpecifier: NumberFormatSpecifier.CURRENCY,
          });
        },
      },
      {
        header: "Value",
        accessorFn: (row) => {
          return row.token;
        },
        cell: (info) => {
          const token = info.getValue() as string;
          const value = num(balances?.[token])
            .div(10 ** 6)
            // @ts-expect-error - TODO
            .times(prices?.[token].price)
            .toNumber();

          return formatAmount(
            token ===
              "ibc/A6E3AF63B3C906416A9AF7A556C59EA4BD50E617EFFE6299B99700CCB780E444" ||
              token ===
                "ibc/23DC3FF0E4CBB53A1915E4C62507CB7796956E84C68CA49707787CB8BDE90A1E" ||
              token === "stk/adydx"
              ? num(value).div(10 ** 12)
              : token ===
                  "ibc/5D3B6445EA1D7064C4B1CCB588638589529556E1BCBADF13475021B42EA8C73B" ||
                token ===
                  "ibc/CCA9F9B22D39884C09975D45E1869B73A12B87080EE53CB44905CE2C422CA228"
              ? num(value).div(10 ** 2)
              : num(value),
            {
              formatSpecifier: NumberFormatSpecifier.CURRENCY,
            },
          );
        },
      },
      {
        header: "",
        accessorKey: "actions",
        cell: (info) => {
          return (
            <MyAssetsActions
              key={info.row.original.token}
              data={info.row.original}
            />
          );
        },
      },
    ],
    [balances, getSymbol, prices],
  );
  return !isMobile ? (
    <Table
      title="My Assets"
      data={
        assets
          .filter((asset) => asset.token !== "staked-uxprt")
          .sort(byObjectValues(["hasIBC"])) ?? []
        // assets.filter(
        //   (asset) =>
        //     asset.token !==
        //     "persistence1yu43c3fd89gh4rsdvfux3q6ekwfjtk06nnr9rze6wumys3y2plssjx5s63",
        // ) ?? []
      }
      columns={columns}
    />
  ) : (
    <>
      <Heading variant="dexterH1" my={4}>
        My Assets
      </Heading>
      <MyAssetsActions
        data={assets.filter((asset) => asset.hasIBC === true)[0]}
      />
      <Wrapper variant="asset" mt={4}>
        {assets
          .filter((asset) => asset.token !== "staked-uxprt")
          .sort(byObjectValues(["hasIBC"]))
          .map((asset) => (
            <AssetItemMobile asset={asset} />
          ))}
      </Wrapper>
    </>
  );
}
