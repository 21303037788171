export default {
  variants: {
    dexterH0: {
      textStyle: "dexterH0",
      fontSize: null,
      fontFamily: null,
      fontWeight: null,
      lineHeight: null,
    },
    dexterH1: {
      textStyle: "dexterH1",
      fontSize: null,
      fontFamily: null,
      fontWeight: null,
      lineHeight: null,
    },
    dexterH2: {
      textStyle: "dexterH2",
      fontSize: null,
      fontFamily: null,
      fontWeight: null,
      lineHeight: null,
    },
    dexterH3: {
      textStyle: "dexterH3",
      fontSize: null,
      fontFamily: null,
      fontWeight: null,
      lineHeight: null,
    },
    dexterH4: {
      textStyle: "dexterH4",
      fontSize: null,
      fontFamily: null,
      fontWeight: null,
      lineHeight: null,
    },
  },
};
