import { Icon, IconProps } from "@chakra-ui/react";

export function TwitterIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 33 28" {...props}>
      <path
        d="M29.489 6.97053C29.5089 7.27431 29.5089 7.57809 29.5089 7.88467C29.5089 17.2263 22.7672 28 10.4397 28V27.9944C6.79817 28 3.23224 26.8997 0.166626 24.825C0.696141 24.8922 1.22831 24.9258 1.76181 24.9272C4.77964 24.93 7.71121 23.8619 10.0854 21.895C7.21753 21.8376 4.70267 19.8651 3.82412 16.9855C4.82874 17.1899 5.86388 17.1479 6.84992 16.8637C3.72327 16.1973 1.47382 13.2995 1.47382 9.93414C1.47382 9.90334 1.47382 9.87394 1.47382 9.84455C2.40545 10.3919 3.44855 10.6957 4.51555 10.7293C1.5707 8.65322 0.662963 4.52068 2.44128 1.28969C5.84398 5.70641 10.8644 8.39144 16.2538 8.67562C15.7137 6.22017 16.4515 3.64714 18.1927 1.92105C20.892 -0.755585 25.1374 -0.618394 27.6748 2.22763C29.1758 1.91545 30.6144 1.33448 31.9309 0.511335C31.4305 2.14783 30.3835 3.53794 28.9847 4.42129C30.3131 4.2561 31.611 3.88092 32.8333 3.30836C31.9335 4.73067 30.8002 5.96959 29.489 6.97053Z"
        fill="url(#paint0_linear_1059_14163)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1059_14163"
          x1="24.7079"
          y1="123.407"
          x2="-58.0856"
          y2="98.564"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00AFFA" />
          <stop offset="0.47403" stopColor="#32A2BA" />
          <stop offset="1" stopColor="#24FFCA" />
        </linearGradient>
      </defs>
    </Icon>
  );
}
