import { useMemo } from "react";
import { num } from "@wizard-ui/core";

import { useDexter } from "modules/common";
import { lpToTokens } from "modules/pools";

export function useAssetBalanceInUsd() {
  const { prices, pools, balances, tokens, tokenLocks } = useDexter();
  return useMemo(() => {
    if (
      balances == null ||
      prices == null ||
      tokens == null ||
      pools == null ||
      tokenLocks == null
    ) {
      return null;
    }

    const unbondingTokens = Object.keys(tokenLocks).reduce(
      (acc: any, lpToken: string) => {
        const info = tokenLocks[lpToken];

        if (info.locks.length == 0) {
          return [...acc];
        }

        const pool = pools.find((d) => d.lpToken == lpToken);

        if (pool == null) {
          return [...acc];
        }

        const amount = info.locks.reduce((acc: any, data: any) => {
          return num(data.amount).plus(acc).toFixed();
        }, 0);

        const tokens = lpToTokens({
          amount,
          assets: pool.assets,
          totalSupply: pool.lpTokenSupply,
        });

        return [...acc, ...tokens];
      },
      [],
    );

    const unbondingTokensValueInUsd = unbondingTokens.reduce(
      (acc: any, value: any) => {
        return num(value.amount)
          .div(10 ** tokens[value.token].decimals)
          .times(prices[value.token].price)
          .plus(acc);
      },
      0,
    );

    const balancesInUsd = Object.keys(tokens).reduce(
      (acc: any, token: any): any => {
        return num(balances[token])
          .div(10 ** tokens[token].decimals)
          .times(prices[token].price)
          .plus(acc);
      },
      0,
    );

    return num(balancesInUsd).plus(unbondingTokensValueInUsd).toFixed(6);
  }, [balances, prices, tokens, pools, tokenLocks]);
}
