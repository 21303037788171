import { useState, useEffect, useMemo } from "react";
import { formatAmount, num, NumberFormatSpecifier } from "@wizard-ui/core";
import {
  Box,
  Flex,
  Heading,
  chakra,
  Text,
  Button,
  IconButton,
  HStack,
  Skeleton,
  useMediaQuery,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useDebounce } from "ahooks";
import { useQuery } from "@tanstack/react-query";
import { motion } from "framer-motion";

import {
  SettingsIcon,
  useDexter,
  DoubleArrowIcon,
  Wrapper,
  useContracts,
  ChevronDownIcon,
  useTokenInfo,
  ChevronUpIcon,
  TxErrorKeplr,
} from "modules/common";
import {
  TokenInput,
  SwapInfo,
  SwapInfoTokens,
  SwapSettingsModal,
  useSwapMutation,
  SwapBase,
  SwapModal,
  calculateMinReceivedAfterSlippage,
  getKeyByValue,
} from "modules/swap";
import { toast } from "react-toastify";
import { useChain, useManager } from "@cosmos-kit/react";
import { useRouter } from "next/router";
import useStore from "state/store";

const MotionBox = motion(Box);

type FormValues = {
  token1: string;
  amount1: string;
  token2: string;
  amount2: string;
  maxSpread: string;
};

export function SwapForm() {
  const [isMobile] = useMediaQuery("(max-width: 800px)");
  const persistenceChain =
    process.env.NEXT_PUBLIC_ENV === "testnet"
      ? "persistencetestnet2"
      : process.env.NEXT_PUBLIC_ENV === "devnet"
      ? "Dexter Devnet"
      : "persistence";
  const chainContext = useChain(persistenceChain);

  const { getWalletRepo } = useManager();
  const walletRepo = getWalletRepo(persistenceChain);

  const { connect, getCosmWasmClient, isWalletConnected } = chainContext;
  const { pools, prices, balances, isLoading, tokens } = useDexter();
  const { router } = useContracts();

  const nextRouter = useRouter();
  const from = (nextRouter.query.from as string) || "ATOM";
  const to = (nextRouter.query.to as string) || "XPRT";

  const fromToken = getKeyByValue(tokens, from);
  const toToken = getKeyByValue(tokens, to);
  const [swapModalOpen, setSwapModalOpen] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [showSwapInfo, setShowSwapInfo] = useState<boolean>(false);
  const {
    mutate,
    isLoading: swapMutationLoading,
    data: swapMutationData,
    error: swapMutationError,
    reset,
  } = useSwapMutation();
  const [swapSettinsOpen, setSwapSettingsOpen] = useState<boolean>(false);
  const { handleSubmit, control, watch, setValue, resetField } =
    useForm<FormValues>({
      mode: "all",
      defaultValues: {
        // token1: "uxprt",
        token1: fromToken,
        amount1: "",
        token2: toToken,
        // token2:
        //   process.env.NEXT_PUBLIC_ENV == "mainnet"
        //     ? "ibc/C8A74ABBE2AF892E15680D916A7C22130585CE5704F9B17A10F184A90D53BECA"
        //     : "ibc/C4CFF46FD6DE35CA4CF4CE031E643C8FDC9BA4B99AE598E9B0ED98FE3A2319F9",
        amount2: "",
        maxSpread:
          typeof window !== "undefined"
            ? localStorage?.getItem("dexterMaxSpread")
              ? localStorage?.getItem("dexterMaxSpread")
              : "0.5"
            : "0.5",
      },
    });

  const { token1, token2, amount1, amount2, maxSpread } = watch();

  // tokensVar({ token1: token1, token2: token2 });

  const setTokensVar = useStore((state: any) => state.setTokensVar);

  setTokensVar({ token1: token1, token2: token2 });

  const dAmount1 = useDebounce(amount1, { wait: 500 });
  const { getSymbol } = useTokenInfo();
  const swap = useMemo(() => {
    if (prices == null) {
      return null;
    }
    const client = getCosmWasmClient().then((client) => {
      return client;
    });

    return new SwapBase({
      token1,
      token2,
      amount1: dAmount1,
      amount2: "0",
      slippage: maxSpread,
      context: {
        pools,
        client,
        router,
        prices,
      },
    });
  }, [
    token1,
    token2,
    dAmount1,
    maxSpread,
    router,
    getCosmWasmClient,
    pools,
    prices,
  ]);
  const {
    data,
    isInitialLoading,
    error: swapErr,
  } = useQuery(
    ["simulateSwap", swap],
    () => {
      if (swap == null || !swap.isReady) {
        throw new Error("swap model is not configured");
      }

      return swap.simulateSwap();
    },
    {
      enabled: swap != null && swap.isReady,
      // Revisit: disable keepPreviousData
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );
  useEffect(() => {
    if (data) {
      if (data.amountOut === "") {
        setValue("amount2", "");
      } else {
        setValue(
          "amount2",
          num(data.amountOut)
            .div(10 ** tokens[data.tokenOut].decimals)
            .toString(),
          { shouldValidate: true },
        );
      }
    }
    if (swapErr !== null) {
      setValue("amount2", "");
      if (swapErr?.message.includes("Operation exceeds max spread limit")) {
        toast(
          <TxErrorKeplr
            errorMessage={"The spread for this transaction is too high."}
          />,
          {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 5000,
            toastId: "swap error",
            // closeButton: CustomCloseButton,
          },
        );
      }
    }
    if (data?.beliefPrice == "NaN" && swapErr == null) {
      setValue("amount2", "");
      toast(
        <TxErrorKeplr
          errorMessage={"The spread for this transaction is too high."}
        />,
        {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
          toastId: "swap error",
          // closeButton: CustomCloseButton,
        },
      );
    }
  }, [data, setValue, swapErr]);

  const handleSwapTokens = () => {
    setValue("amount1", amount2);
    setValue("amount2", amount1);
    setValue("token1", token2);
    setValue("token2", token1);
  };

  useEffect(() => {
    if (error != null) {
      setError(null);
    }
    if (amount1 == "") {
      setValue("amount2", "");
    }
  }, [amount1, error, setValue]);

  useEffect(() => {
    if (!process.env.NEXT_PUBLIC_IPFS_DEPLOYMENT) {
      const currentQuery = nextRouter.query;
      const updatedQuery = {
        ...currentQuery,
        from: getSymbol(token1),
        to: getSymbol(token2),
      };

      nextRouter.push({
        pathname: nextRouter.asPath,
        query: updatedQuery,
      });
    }
  }, [token1, token2]);

  const onSubmit = (values: FormValues) => {
    if (isWalletConnected) {
      setSwapModalOpen(true);
      mutate(
        {
          swapPath: swap?.bestPath1,
          amount: values.amount1,
          assetIn: values.token1,
          assetOut: values.token2,
          beliefPrice: data?.beliefPrice,
          maxSpread: num(values.maxSpread).div(100).toFixed(2),
          minimum_receive: calculateMinReceivedAfterSlippage(
            data,
            values.maxSpread,
          ),
          swapType: {
            give_in: {},
          },
        },
        {
          onSettled: () => {
            resetField("amount1");
            resetField("amount2");
          },
        },
      );
    }
  };

  const handleClose = () => {
    reset();
    setSwapModalOpen(false);
  };

  const mobileWebModeName = "mobile-web";

  const connectHandler = () => {
    sessionStorage.setItem("terms", "show");
    if (
      (window?.leap && window?.leap?.mode === mobileWebModeName) ||
      window?.navigator?.userAgent?.includes("LeapCosmos")
    ) {
      const wallet = walletRepo.wallets.find(
        (wallet) => wallet._walletInfo.name === "leap-extension",
      );
      wallet._walletInfo.mobileDisabled = false;
      walletRepo.connect("leap-extension");
      return;
    } else if (window?.keplr && window?.keplr?.mode === mobileWebModeName) {
      const wallet = walletRepo.wallets.find(
        (wallet) => wallet._walletInfo.name === "keplr-extension",
      );
      wallet._walletInfo.mobileDisabled = false;
      walletRepo.connect("keplr-extension");
      return;
    } else {
      connect();
      return;
    }
  };

  return (
    <chakra.form
      mt={["4", "4", "12", "12", "12"]}
      maxW="900"
      mx="auto"
      onSubmit={handleSubmit(onSubmit)}
      px={["6px", "16px", "8px", null, null, null]}
    >
      <Flex
        alignItems="center"
        gap={[0, 0, 6, 6, 6]}
        justify="center"
        flexDir={["column", "column", "row", "row", "row"]}
      >
        <Box flex="1">
          {!isMobile ? (
            <Heading variant="dexterH1" mb="4">
              Asset In
            </Heading>
          ) : null}
          {/* <Button
            variant="action"
            w="auto"
            alignSelf={"flex-end"}
            onClick={() => setSwapSettingsOpen(true)}
          >
            <HStack px="2">
              <SettingsIcon />
              <Text fontSize="xs">{maxSpread}%</Text>
            </HStack>
          </Button> */}

          <TokenInput
            amountInputName="amount1"
            tokenInputName="token1"
            token={token1}
            onTokenChange={(v) => {
              if (v.to) {
                setValue("token2", v.to);
              }
            }}
            control={control}
          />
        </Box>
        <IconButton
          mt={["2", "2", "2", "12", "12"]}
          mb={["2", "2", "0", "0", "0"]}
          aria-label="Change"
          bg="transparent"
          _focus={{ bg: "transparent" }}
          _hover={{ bg: "transparent" }}
          _active={{ bg: "transparent" }}
          type="button"
          onClick={handleSwapTokens}
          transform={["rotate(90deg)", "rotate(90deg)", "none", "none", "none"]}
        >
          <DoubleArrowIcon boxSize="10" />
        </IconButton>
        <Box flex="1">
          {!isMobile ? (
            <Flex mb="4" gap="2" justify="space-between">
              <>
                <Heading variant="dexterH1">Asset Out</Heading>
                <Button
                  variant="action"
                  w="auto"
                  onClick={() => setSwapSettingsOpen(true)}
                >
                  <HStack px="2">
                    <SettingsIcon />
                    <Text fontSize="xs">{maxSpread}%</Text>
                  </HStack>
                </Button>
              </>
            </Flex>
          ) : null}
          <TokenInput
            token={token2}
            amountInputName="amount2"
            tokenInputName="token2"
            control={control}
            isLoading={isInitialLoading}
            isDisabled
          />
        </Box>
      </Flex>
      <Flex flexDir="column" align="center">
        {error && (
          <Box mt="6" minW="xl" textAlign="center">
            <Text color="red.500">{error}</Text>
          </Box>
        )}
        <Button
          mt={10}
          w={["full", "full", "lg", "lg", "lg", "lg"]}
          // minW={["xs", "xs", "sm", "lg", "lg", "lg"]}
          isDisabled={
            isWalletConnected &&
            (data == null ||
              Number(amount1) == 0 ||
              data?.beliefPrice == "NaN" ||
              !swap?.isReady ||
              num(balances?.[token1])
                .div(10 ** tokens[token1].decimals)
                .lt(num(amount1)))
          }
          variant="primary"
          size="xl"
          type="submit"
          onClick={() => !isWalletConnected && connectHandler()}
        >
          {isWalletConnected
            ? data?.beliefPrice == "NaN" || !swap?.isReady
              ? "No Trading Route Found"
              : num(balances?.[token1])
                  .div(10 ** tokens[token1].decimals)
                  .lt(num(amount1))
              ? "Insufficient Balance"
              : "Swap"
            : "Connect Wallet"}
        </Button>
        {/* <Box w="500px"> */}
        {/* {showSwapInfo === false && ( */}
        <Wrapper
          variant="primary"
          w={["full", "full", "lg", "lg", "lg", "lg"]}
          // minW={["sm", "sm", "lg", "lg", "lg"]}
          mt="8"
          p="4"
        >
          <Skeleton
            isLoaded={data !== null && !isLoading}
            startColor="primary.prussianBlue80"
            endColor="primary.ctaDisabled"
          >
            <Flex
              flexDir="row"
              align="center"
              justify={"space-between"}
              _hover={{ cursor: "pointer" }}
              onClick={() =>
                data != null &&
                (data?.beliefPrice != "NaN" || swap?.isReady) &&
                setShowSwapInfo((showSwapInfo) => !showSwapInfo)
              }
            >
              {data?.beliefPrice == "NaN" || !swap?.isReady ? (
                <Text textStyle="label" fontWeight={700}>
                  No Trading Route Found
                </Text>
              ) : (
                <Text textStyle="label">
                  {" "}
                  1 {getSymbol(token1)} ≈{" "}
                  {formatAmount(
                    data?.beliefPrice == "NaN" || !swap?.isReady
                      ? undefined
                      : data?.beliefPrice,
                    {
                      formatSpecifier: NumberFormatSpecifier.FLOAT,
                    },
                  )}{" "}
                  {getSymbol(token2)}
                  {data?.beliefPrice == "NaN" || !swap?.isReady
                    ? " - No common pool exists"
                    : ""}
                </Text>
              )}

              {data != null &&
                swap?.isReady &&
                Number(amount1) != 0 &&
                (showSwapInfo === true ? (
                  <ChevronUpIcon boxSize={6} />
                ) : (
                  <ChevronDownIcon boxSize={6} />
                ))}
            </Flex>
            {data != null &&
              (data?.beliefPrice != "NaN" || swap?.isReady) &&
              Number(amount1) != 0 &&
              showSwapInfo === true && (
                <MotionBox
                  mt="4"
                  // minW={["xs", "xs", "lg", "lg", "lg"]}
                  initial={{ opacity: 0, y: -100 }}
                  animate={{ opacity: 1, y: 0 }}
                >
                  {/* <Wrapper variant="primary" maxW="lg" mx="auto" p="6"> */}
                  <SwapInfo
                    tokenIn={token1}
                    tokenOut={token2}
                    amountIn={amount1}
                    maxSpread={maxSpread}
                  />
                  <SwapInfoTokens data={data} />
                  {/* </Wrapper> */}
                </MotionBox>
              )}
          </Skeleton>
        </Wrapper>
        {/* )} */}
        {/* </Box> */}
      </Flex>
      <SwapSettingsModal
        isOpen={swapSettinsOpen}
        control={control}
        onClose={() => setSwapSettingsOpen(false)}
      />
      <SwapModal
        tokenIn={token1}
        tokenOut={token2}
        amountIn={amount1}
        amountOut={amount2}
        data={data}
        maxSpread={maxSpread}
        txData={swapMutationData}
        isLoading={swapMutationLoading}
        error={swapMutationError}
        onSubmit={handleSubmit(onSubmit)}
        isOpen={swapModalOpen}
        onClose={handleClose}
      />
    </chakra.form>
  );
}
