import { useEffect, useMemo, useState } from "react";
import { SigningStargateClient } from "@cosmjs/stargate";
import { num } from "@wizard-ui/core";
import mainnetConfig from "modules/common/assets.mainnet.json";
import testnetConfig from "modules/common/assets.testnet.json";
import { useChainWallet } from "@cosmos-kit/react";

interface Opts {
  token: string;
  isOpen: boolean;
}

export const useIBCData = ({ token, isOpen }: Opts) => {
  // const { data: ibcTokensData, isInitialLoading } = useIBCTokenDataQuery(token);
  // const ibcTokens = useMemo(() => {
  //   if (ibcTokensData == null || undefined) {
  //     return [config.ibc_metadata[token]];
  //   }
  //   return ibcTokensData;
  // }, [ibcTokensData, token]);
  const config =
    process.env.NEXT_PUBLIC_ENV == "mainnet" ? mainnetConfig : testnetConfig;
  const ibcTokens = useMemo(() => {
    return [config.ibc_metadata[token]];
  }, [config.ibc_metadata, token]);
  const [balance, setBalance] = useState<string | null>(null);
  const [otherAddress, setOtherAddress] = useState<string | null>(null);
  const [otherSigningStargateClient, setOtherSigningStargateClient] =
    useState<SigningStargateClient | null>(null);

  const cosmosChain =
    process.env.NEXT_PUBLIC_ENV === "testnet"
      ? "cosmoshubtestnet"
      : "cosmoshub";

  const nobleChain =
    process.env.NEXT_PUBLIC_ENV === "testnet" ? "nobletestnet" : "noble";
  const usdcToken =
    process.env.NEXT_PUBLIC_ENV === "testnet"
      ? "ibc/75F84596DDE9EE93010620701FFED959F3FFA1D0979F6773DE994FFEEA7D32F3"
      : "ibc/B3792E4A62DF4A934EF2DF5968556DB56F5776ED25BDE11188A4F58A7DD406F0";
  const kavaChain = "kava";
  const dydxChain =
    process.env.NEXT_PUBLIC_ENV === "testnet" ? "Dydx-Test" : "dydx";
  const dydxToken =
    process.env.NEXT_PUBLIC_ENV === "testnet"
      ? "ibc/18200EAA7E5BB3D235FF517F04045F4DCB0691CE6FC1B32E4297BEA8EF7710E3"
      : "ibc/23DC3FF0E4CBB53A1915E4C62507CB7796956E84C68CA49707787CB8BDE90A1E";
  const osmosisChain =
    process.env.NEXT_PUBLIC_ENV === "testnet" ? "osmosistestnet" : "osmosis";

  const osmosisToken =
    process.env.NEXT_PUBLIC_ENV === "testnet"
      ? "ibc/6AE2756AA7EAA8FA06E11472EA05CA681BD8D3FBC1AAA9F06C79D1EC1C90DC9B"
      : "ibc/646315E3B0461F5FA4C5C8968A88FC45D4D5D04A45B98F1B8294DD82F386DD85";

  const stargazeChain =
    process.env.NEXT_PUBLIC_ENV === "testnet" ? "stargazetestnet" : "stargaze";

  const stargazeToken =
    process.env.NEXT_PUBLIC_ENV === "testnet"
      ? "ibc/E6ACE9ACB1198998177C8ED4C6BCD3BFB046FEC4559E5F203473157A9CD0C07B"
      : "ibc/AD8E1D4AC4EA8FC79CC46E33319A3791477D4DEBFC30D5D874074B993422B41B"; // TODO: ADD STARGAZE MAINNET TOKEN

  const secretChain =
    process.env.NEXT_PUBLIC_ENV === "testnet"
      ? "secrettestnet"
      : "secretnetwork";

  const secretToken =
    process.env.NEXT_PUBLIC_ENV === "testnet"
      ? ""
      : "ibc/5D3B6445EA1D7064C4B1CCB588638589529556E1BCBADF13475021B42EA8C73B";

  const wbtcToken =
    process.env.NEXT_PUBLIC_ENV === "testnet"
      ? ""
      : "ibc/CCA9F9B22D39884C09975D45E1869B73A12B87080EE53CB44905CE2C422CA228";

  const chihuahuaChain =
    process.env.NEXT_PUBLIC_ENV === "testnet"
      ? "chihuahuatestnet"
      : "chihuahua";

  const chihuahuaToken =
    process.env.NEXT_PUBLIC_ENV === "testnet"
      ? ""
      : "ibc/B597D779FCDD9021263C98A48F1AFA9D2BCCCE980F397CDE5681CCEDE7DEE1A4";
  const chainToConnect = useMemo(() => {
    if (token === usdcToken) {
      return nobleChain;
    } else if (
      token ===
      "ibc/C559977F5797BDC1D74C0836A10C379C991D664166CB60D776A83029852431B4"
    ) {
      return kavaChain;
    } else if (token === dydxToken) {
      return dydxChain;
    } else if (token === osmosisToken || token === wbtcToken) {
      return osmosisChain;
    } else if (token === stargazeToken) {
      return stargazeChain;
    } else if (token === secretToken) {
      return secretChain;
    } else if (token === chihuahuaToken) {
      return chihuahuaChain;
    } else {
      return cosmosChain;
    }
  }, [
    token,
    cosmosChain,
    nobleChain,
    kavaChain,
    dydxChain,
    osmosisChain,
    stargazeChain,
    secretChain,
    chihuahuaChain,
  ]);
  const currentWallet = localStorage.getItem(
    "cosmos-kit@1:core//current-wallet",
  );

  const {
    address,
    getSigningStargateClient,
    getOfflineSigner,
    getOfflineSignerDirect,
    isWalletConnected,
    connect,
  } = useChainWallet(chainToConnect, currentWallet);
  useEffect(() => {
    async function connectToOtherChain() {
      if (isWalletConnected == false) {
        await connect();
      } else {
        //@ts-expect-error - TODO
        // await window.keplr?.enable(ibcTokens[0]?.remote_chain_id);

        //@ts-expect-error - TODO
        // const offlineSigner = await window.keplr?.getOfflineSignerAuto(
        //   ibcTokens.find((ibcToken) => ibcToken.identifier === token)
        //     ?.remote_chain_id,
        // );
        window.keplr.defaultOptions = {
          sign: { preferNoSetFee: !0 },
        };
        const offlineSigner = await getOfflineSignerDirect();

        const accounts = await offlineSigner.getAccounts();

        // Initialize the gaia api with the offline signer that is injected by Keplr extension.
        // const client = await SigningStargateClient.connectWithSigner(
        //   ibcTokens.find((ibcToken) => ibcToken.identifier === token)
        //     ?.remote_chain_rpc,
        //   offlineSigner,
        //   {
        //     gasPrice: GasPrice.fromString(
        //       `0.020${ibcTokens[0]?.remote_identifier}`,
        //     ),
        //   },
        // );
        const client = await getSigningStargateClient();

        const balanceData = await client.getBalance(
          accounts[0].address,
          ibcTokens.find((ibcToken) => ibcToken.identifier === token)
            ?.remote_identifier,
        );
        setBalance(
          token ==
            "ibc/23DC3FF0E4CBB53A1915E4C62507CB7796956E84C68CA49707787CB8BDE90A1E" ||
            token ==
              "ibc/18200EAA7E5BB3D235FF517F04045F4DCB0691CE6FC1B32E4297BEA8EF7710E3"
            ? num(balanceData.amount)
                .div(10 ** 12)
                .toFixed(6)
            : token ==
                "ibc/5D3B6445EA1D7064C4B1CCB588638589529556E1BCBADF13475021B42EA8C73B" ||
              token ==
                "ibc/CCA9F9B22D39884C09975D45E1869B73A12B87080EE53CB44905CE2C422CA228"
            ? num(balanceData.amount)
                .div(10 ** 2)
                .toFixed(6)
            : balanceData.amount,
        );
        setOtherAddress(address);
        setOtherSigningStargateClient(client);
      }
    }

    if (isOpen) {
      window.keplr.defaultOptions = {
        sign: { preferNoSetFee: !0 },
      };
      connectToOtherChain();
    }
  }, [token, isOpen]);

  return useMemo(() => {
    return {
      otherAddress,
      otherSigningStargateClient,
      balance: num(balance || 0)
        .div(10 ** 6)
        .toString(),
      depositChannel: ibcTokens.find(
        (ibcToken) => ibcToken.identifier === token,
      )?.remote_channel_id,
      withdrawChannel: ibcTokens.find(
        (ibcToken) => ibcToken.identifier === token,
      )?.source_channel_id,
      denom: ibcTokens.find((ibcToken) => ibcToken.identifier === token)
        ?.remote_identifier,
      ibcDenom: ibcTokens.find((ibcToken) => ibcToken.identifier === token)
        ?.identifier,
      remoteChainExplorer: ibcTokens.find(
        (ibcToken) => ibcToken.identifier === token,
      )?.remote_chain_explorer,
    };
  }, [otherAddress, otherSigningStargateClient, balance, ibcTokens, token]);
};
