import {
  Text,
  Center,
  Link,
  Flex,
  VStack,
  useMediaQuery,
} from "@chakra-ui/react";
import { DeliverTxResponse } from "@cosmjs/stargate";
import { ExecuteResult } from "@cosmjs/cosmwasm-stargate";
import { Player } from "@lottiefiles/react-lottie-player";
import { RightArrowIcon } from "modules/common";

import success from "./success.json";
import { ArrowRightIcon, ArrowUpRightIcon } from "lucide-react";

interface Props {
  title: string;
  subtitle: string;
  data: ExecuteResult | DeliverTxResponse;
  href?: string;
}

export function TxSuccess({ title, subtitle, data, href }: Props) {
  const [isMobile] = useMediaQuery("(max-width: 800px)");
  return isMobile ? (
    <Flex justifyItems={"center"} align="center">
      <Player
        src={success}
        autoplay
        loop
        style={{ width: "100px", margin: 0 }}
      />
      <VStack align={"flex-start"}>
        <Text
          fontSize={"16px"}
          fontWeight="600"
          mt="-4"
          color={"#FCFCFC"}
          fontFamily={"Nunito Sans"}
        >
          {title}
        </Text>
        <Text
          fontFamily={"Nunito Sans"}
          fontWeight={400}
          color={"#D2D2D2"}
          fontSize="14px"
          my="2"
        >
          {subtitle}
        </Text>
        <Link
          bg="linear-gradient(284.42deg, #00AFFA -49.92%, #32A2BA 54.24%, #24FFCA 169.83%)"
          fontSize="14px"
          href={
            href
              ? `${href}/txs/${data?.transactionHash}`
              : process.env.NEXT_PUBLIC_ENV == "mainnet"
              ? `https://mintscan.io/persistence/txs/${data?.transactionHash}`
              : `https://testnet.mintscan.io/persistence-testnet/txs/${data?.transactionHash}`
          }
          isExternal
          backgroundClip={"text"}
          target="_blank"
          rel="noopener noreferrer"
        >
          View Transaction
          <RightArrowIcon color={"#386794"} />
        </Link>
      </VStack>
    </Flex>
  ) : (
    <Flex justifyItems={"center"} align="center">
      <Player
        src={success}
        autoplay
        loop
        style={{ width: "150px", margin: 0 }}
      />
      <VStack align={"flex-start"}>
        <Text
          fontSize={["10px", "10px", "10px", "24px", "24px", "24px"]}
          fontWeight="600"
          mt="-4"
          color={"#FCFCFC"}
          fontFamily={"Nunito Sans"}
        >
          {title}
        </Text>
        <Text
          color={"#D2D2D2"}
          fontFamily={"Nunito Sans"}
          fontWeight={400}
          my="2"
        >
          {subtitle}
        </Text>
        <Link
          bg="linear-gradient(284.42deg, #00AFFA -49.92%, #32A2BA 54.24%, #24FFCA 169.83%)"
          fontSize="lg"
          href={
            href
              ? `${href}/txs/${data?.transactionHash}`
              : process.env.NEXT_PUBLIC_ENV == "mainnet"
              ? `https://mintscan.io/persistence/txs/${data?.transactionHash}`
              : `https://testnet.mintscan.io/persistence-testnet/txs/${data?.transactionHash}`
          }
          isExternal
          backgroundClip={"text"}
          target="_blank"
          fontWeight={600}
          fontFamily={"Nunito Sans"}
          rel="noopener noreferrer"
          display={"flex"}
          gap={2}
        >
          View Transaction
          <ArrowUpRightIcon color={"#32A2BA"} width={18} />
        </Link>
      </VStack>
    </Flex>
  );
}
