import { Icon, IconProps } from "@chakra-ui/react";

export function DoubleArrowIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 36 37" {...props}>
      <path
        d="M33 18.5C33 14.5218 31.4196 10.7065 28.6066 7.89341C25.7936 5.08037 21.9782 3.50001 18 3.50001C14.0218 3.50001 10.2064 5.08037 7.3934 7.89341C4.58035 10.7065 3 14.5218 3 18.5C3 22.4783 4.58035 26.2936 7.3934 29.1066C10.2064 31.9197 14.0218 33.5 18 33.5C21.9782 33.5 25.7936 31.9197 28.6066 29.1066C31.4196 26.2936 33 22.4783 33 18.5Z"
        fill="url(#paint0_linear_586_22438)"
      />
      <path
        d="M24.6387 21.9646L21.6741 19.0018C21.591 18.9158 21.4917 18.8473 21.3819 18.8001C21.2721 18.7529 21.154 18.7281 21.0345 18.7271C20.915 18.726 20.7965 18.7488 20.6859 18.7941C20.5753 18.8393 20.4748 18.9061 20.3903 18.9906C20.3058 19.0752 20.239 19.1756 20.1937 19.2862C20.1485 19.3969 20.1257 19.5154 20.1267 19.6349C20.1278 19.7544 20.1526 19.8725 20.1998 19.9823C20.2469 20.0921 20.3155 20.1914 20.4015 20.2744L21.8289 21.7018H11.4015C11.1628 21.7018 10.9339 21.7966 10.7651 21.9654C10.5963 22.1342 10.5015 22.3631 10.5015 22.6018C10.5015 22.8405 10.5963 23.0694 10.7651 23.2382C10.9339 23.407 11.1628 23.5018 11.4015 23.5018H21.8289L20.4015 24.9292C20.3155 25.0122 20.2469 25.1115 20.1998 25.2213C20.1526 25.3311 20.1278 25.4492 20.1267 25.5687C20.1257 25.6882 20.1485 25.8068 20.1937 25.9174C20.239 26.028 20.3058 26.1285 20.3903 26.213C20.4748 26.2975 20.5753 26.3643 20.6859 26.4095C20.7965 26.4548 20.915 26.4776 21.0345 26.4765C21.154 26.4755 21.2721 26.4507 21.3819 26.4035C21.4917 26.3563 21.591 26.2878 21.6741 26.2018L24.6387 23.239C24.7225 23.1554 24.789 23.0561 24.8343 22.9467C24.8797 22.8374 24.9031 22.7202 24.9031 22.6018C24.9031 22.4834 24.8797 22.3662 24.8343 22.2569C24.789 22.1475 24.7225 22.0482 24.6387 21.9646Z"
        fill="#FCFCFC"
      />
      <path
        d="M14.3612 11.0002C14.1252 10.9981 13.8979 11.0888 13.7281 11.2528L10.7635 14.2174C10.5948 14.3862 10.5 14.615 10.5 14.8537C10.5 15.0923 10.5948 15.3212 10.7635 15.49L13.7281 18.4528C13.8111 18.5387 13.9104 18.6073 14.0202 18.6545C14.13 18.7016 14.2481 18.7265 14.3676 18.7275C14.4871 18.7285 14.6057 18.7058 14.7163 18.6605C14.8269 18.6153 14.9274 18.5484 15.0119 18.4639C15.0964 18.3794 15.1632 18.2789 15.2084 18.1683C15.2537 18.0577 15.2765 17.9392 15.2754 17.8197C15.2744 17.7002 15.2496 17.5821 15.2024 17.4723C15.1552 17.3625 15.0867 17.2632 15.0007 17.1802L13.5733 15.7528H24.0007C24.2394 15.7528 24.4683 15.658 24.6371 15.4892C24.8059 15.3204 24.9007 15.0915 24.9007 14.8528C24.9007 14.6141 24.8059 14.3852 24.6371 14.2164C24.4683 14.0476 24.2394 13.9528 24.0007 13.9528H13.5733L15.0007 12.5254C15.1646 12.3556 15.2554 12.1283 15.2533 11.8923C15.2513 11.6563 15.1566 11.4306 14.9897 11.2637C14.8229 11.0969 14.5971 11.0022 14.3612 11.0002Z"
        fill="#FCFCFC"
      />
      <defs>
        <linearGradient
          id="paint0_linear_586_22438"
          x1="135.222"
          y1="10.9621"
          x2="115.228"
          y2="88.7005"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00AFFA" />
          <stop offset="0.47403" stopColor="#32A2BA" />
          <stop offset="1" stopColor="#24FFCA" />
        </linearGradient>
      </defs>
    </Icon>
  );
}
