import { Icon, IconProps } from "@chakra-ui/react";

export function InfoIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 21 21" {...props}>
      <path
        d="M10.8596 20.5C8.20839 20.4969 5.66663 19.4424 3.79192 17.5677C1.91722 15.693 0.862673 13.1512 0.859619 10.5C0.862419 7.84869 1.91689 5.30678 3.79164 3.43202C5.6664 1.55727 8.20831 0.5028 10.8596 0.5C13.5108 0.503054 16.0526 1.5576 17.9273 3.43231C19.802 5.30701 20.8566 7.84877 20.8596 10.5C20.8563 13.1511 19.8017 15.6928 17.927 17.5674C16.0524 19.4421 13.5108 20.4967 10.8596 20.5ZM10.8596 1.94231C8.59068 1.9446 6.41533 2.84695 4.81095 4.45133C3.20656 6.05571 2.30422 8.23106 2.30193 10.5C2.30447 12.7689 3.2069 14.9441 4.81123 16.5484C6.41556 18.1527 8.59076 19.0551 10.8596 19.0577C13.1284 19.0549 15.3035 18.1524 16.9077 16.5481C18.512 14.9438 19.4145 12.7688 19.4173 10.5C19.4148 8.23114 18.5123 6.05593 16.908 4.45161C15.3037 2.84728 13.1285 1.94485 10.8596 1.94231Z"
        fill="#EBFAFB"
      />
      <path
        d="M10.9057 16.0775C10.7145 16.0775 10.531 16.0015 10.3958 15.8663C10.2605 15.7311 10.1846 15.5476 10.1846 15.3564V9.01022C10.1846 8.81895 10.2605 8.63553 10.3958 8.50028C10.531 8.36504 10.7145 8.28906 10.9057 8.28906C11.097 8.28906 11.2804 8.36504 11.4157 8.50028C11.5509 8.63553 11.6269 8.81895 11.6269 9.01022V15.3564C11.6269 15.5476 11.5509 15.7311 11.4157 15.8663C11.2804 16.0015 11.097 16.0775 10.9057 16.0775Z"
        fill="#EBFAFB"
      />
      <path
        d="M11.6347 5.55243C11.6347 5.1276 11.2903 4.7832 10.8654 4.7832C10.4406 4.7832 10.0962 5.1276 10.0962 5.55243C10.0962 5.97727 10.4406 6.32166 10.8654 6.32166C11.2903 6.32166 11.6347 5.97727 11.6347 5.55243Z"
        fill="#EBFAFB"
      />
    </Icon>
  );
}
