import { useMemo } from "react";
import { num } from "@wizard-ui/core";

import { useDexter } from "modules/common";
import { lpToTokens, PoolBase } from "modules/pools";

export function useTotalMyLiquidityInUsd() {
  const { pools, prices, balances, tokens, tokenLocks } = useDexter();

  return useMemo(() => {
    if (prices == null || pools == null || balances == null) {
      return 0;
    }

    const poolsWithBondedLp = pools.filter((pool) => {
      const token = pool.lpToken;
      const balance = balances[token];

      if (balance == null) {
        return null;
      }

      return num(balance).isGreaterThan(0);
    });

    const poolsWithUnlockedLp = pools.filter((pool) => {
      const token = pool.lpToken;
      const locks = tokenLocks ? tokenLocks[token] : null;
      if (locks == null) {
        return null;
      }
      return num(locks.unlocked_amount).isGreaterThan(0);
    });

    const valueInUsdUnlocked = poolsWithUnlockedLp.reduce(
      (acc: any, pool: PoolBase) => {
        return num(pool.unlockedAmountInUsd).plus(acc);
      },
      0,
    );
    const allTokens = poolsWithBondedLp.reduce((acc: any, pool: PoolBase) => {
      const token = pool.lpToken;
      const balance = balances[token];

      if (balance == null) {
        return acc;
      }

      const tokens = lpToTokens({
        amount: balance,
        assets: pool.assets,
        totalSupply: pool.lpTokenSupply,
      });

      return [...acc, ...tokens];
    }, []);

    const valueInUsd = allTokens.reduce((acc: number, value: any) => {
      return num(value.amount)
        .div(10 ** tokens[value.token].decimals)
        .times(prices[value.token].price)
        .plus(acc);
    }, 0);

    return num(valueInUsd).plus(valueInUsdUnlocked).toFixed(6);
  }, [prices, pools, balances]);
}
