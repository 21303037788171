import { Tr, Td, Button, SimpleGrid, VStack, Text } from "@chakra-ui/react";
import { NumberFormatSpecifier, formatAmount, num } from "@wizard-ui/core";
import {
  formatDistanceToNow,
  formatISO9075,
  fromUnixTime,
  isBefore,
} from "date-fns";
import { Wrapper } from "modules/common";
import { useUnlockMultistakingMutation } from "modules/contracts";

import { PoolBase, UnbondModal } from "modules/pools";
import { useState } from "react";
import LazyHydrate from "react-lazy-hydration";

interface Props {
  pool: PoolBase;
  data: any;
  isMobile?: boolean;
}

export function LPTokensItemUnlocked({ pool, data, isMobile = false }: Props) {
  const amountInUsd = pool.getLpAmountInUsd(data);
  const { mutate, isLoading } = useUnlockMultistakingMutation();
  // const unlockDate = fromUnixTime(data.unlock_time);
  // const unbondingTime = formatDistanceToNow(new Date(unlockDate));
  // console.log({ unbondingTime });
  // const hasUnbonded = isBefore(unlockDate, new Date());
  // const hasUnbonded = pool.hasUnbonded;
  const handleUnlockClick = () => {
    mutate({ msg: { lpToken: pool.lpToken } });
  };
  return (
    <>
      {isMobile ? (
        <Wrapper variant="secondary" px={4}>
          <SimpleGrid columns={2} spacing={2} gap={0} rowGap={6}>
            <VStack align={"flex-start"} spacing={1}>
              <Text>Liquidity Amount</Text>
              <Text color={"#FCFCFC"} fontWeight={700} fontSize={18}>
                {formatAmount(num(data).div(10 ** 12), {
                  formatSpecifier: NumberFormatSpecifier.FLOAT,
                  shouldDivide: true,
                })}{" "}
                LP Shares
              </Text>
            </VStack>
            <VStack align={"flex-end"} spacing={1}>
              <Text>Amount</Text>
              <Text color={"#FCFCFC"} fontWeight={700} fontSize={18}>
                {formatAmount(amountInUsd, {
                  formatSpecifier: NumberFormatSpecifier.CURRENCY,
                })}
              </Text>
            </VStack>
            <VStack align={"flex-start"} spacing={0}>
              <Text>Unbonding Time</Text>
              <Text color={"#FCFCFC"} fontWeight={700} fontSize={18}>
                {"0 days"}
              </Text>
            </VStack>
            <VStack align={"flex-end"} spacing={0}>
              <Text>Status</Text>
              <Text color={"#FCFCFC"} fontWeight={700} fontSize={18}>
                {"Unlocked"}
              </Text>
            </VStack>
          </SimpleGrid>
          <Button
            mt={4}
            variant="secondary"
            size="sm"
            w="full"
            isLoading={isLoading}
            onClick={handleUnlockClick}
          >
            Claim
          </Button>
        </Wrapper>
      ) : (
        <Tr>
          <Td textAlign="center" px={0} w={"20%"}>
            {formatAmount(num(data).div(10 ** 12), {
              formatSpecifier: NumberFormatSpecifier.FLOAT,
              shouldDivide: true,
            })}{" "}
            LP Shares
          </Td>
          <Td textAlign="center" px={0} w={"20%"}>
            {formatAmount(amountInUsd, {
              formatSpecifier: NumberFormatSpecifier.CURRENCY,
            })}
          </Td>
          <Td textAlign="center" px={0} w={"20%"}>
            {"0 days"}
            {/* {formatISO9075(fromUnixTime(data.unlock_time))} */}
          </Td>
          <Td textAlign="center" px={0} w={"20%"}>
            {"Unlocked"}
          </Td>
          <Td textAlign="center" px={0} w={"20%"}>
            <Button
              variant="secondary"
              size="sm"
              w={150}
              isLoading={isLoading}
              onClick={handleUnlockClick}
            >
              Claim
            </Button>
          </Td>
        </Tr>
      )}
    </>
  );
}
