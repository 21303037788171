import React from "react";
import type { Control, ControllerProps } from "react-hook-form";
import { useController } from "react-hook-form";
import { Flex, Text, Button, HStack, Skeleton } from "@chakra-ui/react";
import { formatAmount, num, NumberFormatSpecifier } from "@wizard-ui/core";

import { MIN_FEE, useDexter } from "modules/common";
import BigNumber from "bignumber.js";

interface Props extends React.ComponentProps<"input"> {
  /**
   *  provide `name`, `control` & optional `rules`
   */
  name?: ControllerProps["name"];
  control: Control<any>;
  rules?: ControllerProps["rules"];
  /**
   * for readonly field, provide `value`
   */
  value?: string;
  token?: string | null;
  poolType?: string | null;
  isDisabled?: boolean;
  isLpToken?: boolean;
  max?: number | string;
  isDeposit?: boolean;
}

export function BalanceInput({
  name = "",
  control,
  rules,
  token,
  max,
  poolType,
  isLpToken = false,
  isDisabled = false,
  onChange,
  isDeposit = false,
  ...props
}: Props) {
  // we decided decimal input needs rifm mask for UX
  // mask needs controller to expose `onChange` & `value` for transforms
  const { balances, isLoading, tokens } = useDexter();
  const { field } = useController({
    name,
    control,
    rules,
    defaultValue: props.value,
  });
  // @ts-expect-error - TODO
  const balance = isLpToken
    ? num(balances?.[token]).div(10 ** 18)
    : num(balances?.[token]).div(
        10 ** (token !== "" ? tokens[token].decimals : 6),
      );

  function handleChange(multiplier: number) {
    const newValue =
      !isLpToken && num(max ?? balance).gt(0)
        ? num(max ?? balance)
            .minus(
              token === "uxprt" ||
                (isDeposit &&
                  (token ==
                    "ibc/C8A74ABBE2AF892E15680D916A7C22130585CE5704F9B17A10F184A90D53BECA" ||
                    token ==
                      "ibc/23DC3FF0E4CBB53A1915E4C62507CB7796956E84C68CA49707787CB8BDE90A1E" ||
                    token ==
                      "ibc/18200EAA7E5BB3D235FF517F04045F4DCB0691CE6FC1B32E4297BEA8EF7710E3"))
                ? num(MIN_FEE)
                : 0,
            )
            .times(multiplier)
            .toFixed(18)
        : num(max ?? balance)
            .times(multiplier)
            .toFixed(18);

    field.onChange(newValue);

    const changeEvent = {
      target: { value: newValue },
    } as React.ChangeEvent<HTMLInputElement>;
    onChange?.(changeEvent);
  }
  return (
    <Flex justify="flex-end">
      <HStack>
        <Text fontSize="14px" color="#FCFCFC" fontWeight={600}>
          Available {isLpToken && "LP Shares"}:
        </Text>
        <Skeleton
          startColor="primary.prussianBlue80"
          endColor="primary.ctaDisabled"
          isLoaded={!isLoading}
        >
          <Text
            fontSize={["xs", "xs", "sm", "sm", "14px", "14px"]}
            color="#FCFCFC"
            fontWeight={600}
          >
            {formatAmount(max ?? balance, {
              formatSpecifier: NumberFormatSpecifier.FLOAT,
            })}{" "}
          </Text>
        </Skeleton>
        {!isDisabled && (
          <>
            <Button
              variant="tertiary"
              borderColor="primary.popStar"
              size="xs"
              p={2}
              onClick={() => handleChange(0.5)}
              _hover={{
                bg: "primary.popStar",
              }}
            >
              HALF
            </Button>
            <Button
              variant="tertiary"
              borderColor="primary.popStar"
              size="xs"
              p={2}
              onClick={() => handleChange(1)}
              _hover={{
                bg: "primary.popStar",
              }}
            >
              MAX
            </Button>
          </>
        )}
      </HStack>
    </Flex>
  );
}
