import React, { useMemo } from "react";
import { Box, Flex, Text, HStack } from "@chakra-ui/react";
import { NumberFormatSpecifier, formatAmount, num } from "@wizard-ui/core";

import { useTokenInfo, TokenIcon, useDexter } from "modules/common";

type Props = {
  token: string;
  amount: string | null;
};

export function RemoveLiquidityAsset({ token, amount }: Props) {
  const { getSymbol } = useTokenInfo();
  const { prices } = useDexter();
  const totalPrice = useMemo(() => {
    if (amount == null || prices == null) {
      return null;
    }

    return num(prices[token].price).times(amount).toString();
  }, [amount, prices, token]);

  return (
    <Box>
      <Flex justify="space-between">
        <HStack spacing="2">
          <TokenIcon token={token} size="8" />
          <Text fontWeight="700" fontSize="xl">
            {getSymbol(token)}
          </Text>
        </HStack>
        <Box textAlign="right">
          <Text fontWeight="600" fontSize="xl">
            {formatAmount(amount, {
              formatSpecifier: NumberFormatSpecifier.FLOAT,
            })}
          </Text>
          <Text color="whiteAlpha.800" fontSize="sm" fontWeight="600">
            {formatAmount(totalPrice, {
              formatSpecifier: NumberFormatSpecifier.CURRENCY,
            })}
          </Text>
        </Box>
      </Flex>
    </Box>
  );
}
