import { Flex, Text } from "@chakra-ui/react";
import { Wrapper } from "modules/common";
import { TokenDisplay } from "modules/swap";
import React from "react";

const TokenInfo = ({ token, isLpToken = false }) => {
  return (
    <Wrapper variant="singleAssetLiquidity" mt="0">
      <TokenDisplay showAvailableBalance token={token} isLpToken={isLpToken} />
      <Flex textAlign="right" flexDir="column">
        <Text fontSize="18px" fontWeight={600} color="#FCFCFC">
          0.00
        </Text>
        <Text variant="dexterP6" color="#D2D2D2">
          ~$0.00
        </Text>
      </Flex>
    </Wrapper>
  );
};

export default TokenInfo;
