import {
  Box,
  Text,
  Image,
  Center,
  Menu,
  Button,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "./Icons";

interface Props {
  title: string;
  name: string;
  icon: string;
  address: string | null;
}
const chains = [
  {
    chainName: "Cosmos",
    chainId: "cosmoshub",
    token:
      "ibc/C8A74ABBE2AF892E15680D916A7C22130585CE5704F9B17A10F184A90D53BECA",
  },
  {
    chainName: "Osmosis",
    chainId: "osmosis",
    token:
      "ibc/646315E3B0461F5FA4C5C8968A88FC45D4D5D04A45B98F1B8294DD82F386DD85",
  },
  {
    chainName: "Dydx",
    chainId: "dydx",
    token:
      "ibc/23DC3FF0E4CBB53A1915E4C62507CB7796956E84C68CA49707787CB8BDE90A1E",
  },
  {
    chainName: "Kava",
    chainId: "kava",
    token:
      "ibc/C559977F5797BDC1D74C0836A10C379C991D664166CB60D776A83029852431B4",
  },
  {
    chainName: "Noble",
    chainId: "noble",
    token:
      "ibc/B3792E4A62DF4A934EF2DF5968556DB56F5776ED25BDE11188A4F58A7DD406F0",
  },
];
export function IbcChainItem({
  title,
  name,
  icon,
  address,
  handleSelect,
  showSelect = false,
}: Props) {
  return (
    <Box bg="primary.prussianBlue50" px="2" py="3" borderRadius="xl" flex={1}>
      <Text mb="3" textAlign="center">
        {title}
      </Text>
      <Center
        bg="primary.prussianBlue"
        mb="3"
        p="3"
        borderRadius="xl"
        flexDir="column"
      >
        <Image
          src={icon}
          boxSize={["40px", "40px", "40px", "60px", "60px", "60px"]}
          mb="2"
        />
        {showSelect ? (
          <Menu>
            {({ isOpen }) => (
              <>
                <MenuButton
                  isActive={isOpen}
                  as={Button}
                  variant={"deposit"}
                  rightIcon={<ChevronDownIcon />}
                  fontSize={["sm", "lg", "lg", "xl", "xl", "xl"]}
                  fontWeight="600"
                  h="28px"
                >
                  {name}
                </MenuButton>
                <MenuList
                  outline={"none"}
                  border="1px solid #386794"
                  bg="primary.prussianBlue"
                >
                  {chains.map((chain) => (
                    <MenuItem
                      bg="primary.prussianBlue"
                      key={chain.chainId}
                      onClick={() => handleSelect(chain.token)}
                    >
                      {chain.chainName}
                    </MenuItem>
                  ))}
                </MenuList>
              </>
            )}
          </Menu>
        ) : (
          <Text
            fontSize={["sm", "lg", "lg", "xl", "xl", "xl"]}
            fontWeight="600"
          >
            {name}
          </Text>
        )}
      </Center>
      <Box bg="primary.prussianBlue" p="2" borderRadius="xl" textAlign="center">
        <Text fontSize={["xs", "md", "lg", "xl", "xl", "xl"]}>{address}</Text>
      </Box>
    </Box>
  );
}
