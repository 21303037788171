import { useState } from "react";
import {
  ModalProps,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  Box,
  Text,
  chakra,
  CloseButton,
  Stack,
  Link,
  RadioGroup,
  Radio,
  Image,
  HStack,
  Divider,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { formatAmount, num, NumberFormatSpecifier } from "@wizard-ui/core";

import {
  Wrapper,
  useDexter,
  WarningIcon,
  TxSuccess,
  TxPending,
  TxError,
  RightArrowIcon,
} from "modules/common";
import { PoolBase } from "modules/pools";
import { useUnbondMultistakingMutation } from "modules/contracts";
import { TokenDisplay, TokenInput } from "modules/swap";

import { toast } from "react-toastify";
import { UnbondingPeriod } from "config";
import { useInstantUnlockFeeTiers } from "modules/common/hooks/useInstantUnlockFeeTiers";
import { formatDistanceStrict } from "date-fns";
import { useChain, useManager } from "@cosmos-kit/react";
import { useInstantUnbondConfig } from "modules/common/hooks/useInstantUnbondConfig";

interface FormValues {
  amount: string;
}

interface Props extends Omit<ModalProps, "children"> {
  pool: PoolBase;
  instantUnbondConfig: any;
}

export function UnbondModal({
  pool,
  onClose,
  instantUnbondConfig,
  ...rest
}: Props) {
  const { bondedLpTokens, pools } = useDexter();
  const persistenceChain =
    process.env.NEXT_PUBLIC_ENV === "testnet"
      ? "persistencetestnet2"
      : process.env.NEXT_PUBLIC_ENV === "devnet"
      ? "Dexter Devnet"
      : "persistence";
  const chainContext = useChain(persistenceChain);

  const { getWalletRepo, mainWallets } = useManager();
  const walletRepo = getWalletRepo(persistenceChain);
  const { connect, address, isWalletConnected } = chainContext;
  const [isConfirmStep, setIsConfirmStep] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [unbondOption, setUnbondOption] = useState("not-instant");

  const {
    data: instantUnlockFeeTiersData,
    isInitialLoading: feeTiersIsInitialLoading,
  } = useInstantUnlockFeeTiers(pool.lpToken);

  const { mutate, data, isLoading, reset, error } =
    useUnbondMultistakingMutation();
  const {
    handleSubmit,
    control,
    reset: resetForm,
    watch,
  } = useForm<FormValues>({
    mode: "onChange",
    defaultValues: {
      amount: "",
    },
  });
  const maxAmount = num(bondedLpTokens?.[pool.lpToken])
    .div(10 ** 18)
    .toString();
  // .toNumber();
  const { amount } = watch();
  const unbondingPeriod =
    UnbondingPeriod[
      process.env.NEXT_PUBLIC_ENV as keyof typeof UnbondingPeriod
    ];
  const onSubmit = (values: FormValues) => {
    mutate({
      msg: {
        instantUnbond:
          instantUnbondConfig?.instant_unbond_config == "disabled"
            ? false
            : unbondOption === "instant"
            ? true
            : false,
        lpTokenAddr: pool.lpToken,
        amount: num(values.amount)
          .times(10 ** 18)
          .toFixed(0),
      },
    });
  };

  const handleClose = () => {
    onClose();
    setIsConfirmStep(false);
    reset();
    resetForm();
  };

  const mobileWebModeName = "mobile-web";

  const connectHandler = () => {
    sessionStorage.setItem("terms", "show");
    if (
      (window?.leap && window?.leap?.mode === mobileWebModeName) ||
      window?.navigator?.userAgent?.includes("LeapCosmos")
    ) {
      const wallet = walletRepo.wallets.find(
        (wallet) => wallet._walletInfo.name === "leap-extension",
      );
      wallet._walletInfo.mobileDisabled = false;
      walletRepo.connect("leap-extension");
      return;
    } else if (window?.keplr && window?.keplr?.mode === mobileWebModeName) {
      const wallet = walletRepo.wallets.find(
        (wallet) => wallet._walletInfo.name === "keplr-extension",
      );
      wallet._walletInfo.mobileDisabled = false;
      walletRepo.connect("keplr-extension");
      return;
    } else {
      connect();
      return;
    }
  };

  const renderForm = () => {
    const duration = (s) => formatDistanceStrict(0, s * 1000);
    if (isConfirmStep || isLoading || data != null || error != null) {
      return null;
    }

    return (
      <Box>
        <Box mb="6">
          <TokenInput
            amountInputName="amount"
            tokenInputName="token"
            token={pool.lpToken}
            control={control}
            max={maxAmount}
            hideSelect
            isLpToken
            poolType={pool.poolType}
          />
        </Box>
        <Box mb="6">
          {instantUnbondConfig?.instant_unbond_config == "disabled" ? (
            <>
              <Text textStyle="dexterP2" mt="8" mb="1">
                You Will Receive
              </Text>
              <Wrapper variant="singleAssetLiquidity" mt="0">
                <TokenDisplay token={pool.lpToken} isLpToken />
                <Stack textAlign={"right"} spacing={0}>
                  <Text fontSize="xl">
                    {formatAmount(Number(amount) || "0", {
                      formatSpecifier: NumberFormatSpecifier.FLOAT,
                    })}
                  </Text>
                  <Text fontSize="xs" color="whiteAlpha.500">
                    {formatAmount(
                      pools
                        ?.filter(
                          (insidePool) => insidePool.lpToken == pool.lpToken,
                        )[0]
                        .getLpAmountInUsd(
                          num(Number(amount).toString().replaceAll(",", ""))
                            .times(10 ** 18)
                            .toString(),
                        ) || "0",
                      {
                        formatSpecifier: NumberFormatSpecifier.CURRENCY,
                      },
                    )}
                  </Text>
                </Stack>
              </Wrapper>
            </>
          ) : (
            <Wrapper variant="primary" p="3">
              <RadioGroup
                name="unbond-option"
                defaultValue="instant"
                value={unbondOption}
                onChange={setUnbondOption}
              >
                <Stack>
                  <Wrapper variant="unbond">
                    <Radio colorScheme={"white"} value="instant">
                      <Text fontSize={20}>Instant Unbond ⚡</Text>
                      <HStack>
                        <Text
                          fontSize={"12px"}
                          color={"#D2D2D2"}
                          fontWeight={300}
                        >
                          Instantly
                        </Text>
                        <Divider
                          orientation="vertical"
                          // width={"2px"}
                          height={"10px"}
                          borderColor="#A6A6A6"
                          borderWidth={"0.5px"}
                        />
                        <Text
                          fontSize={"12px"}
                          color={"#D2D2D2"}
                          fontWeight={300}
                        >
                          {instantUnbondConfig?.instant_unbond_config?.enabled
                            ?.max_fee / 100 || 5}
                          % fees
                        </Text>
                      </HStack>
                    </Radio>
                    <Stack textAlign={"right"} spacing={0}>
                      <Text fontSize="lg">
                        {formatAmount(
                          Number(amount) -
                            (instantUnbondConfig?.instant_unbond_config?.enabled
                              ?.max_fee / 10000 || 0.05) *
                              Number(amount) || "0",
                          {
                            formatSpecifier: NumberFormatSpecifier.FLOAT,
                          },
                        )}
                      </Text>
                      <Text fontSize="xs" color="whiteAlpha.500">
                        {formatAmount(
                          pools
                            ?.filter(
                              (insidePool) =>
                                insidePool.lpToken == pool.lpToken,
                            )[0]
                            .getLpAmountInUsd(
                              num(
                                (Number(amount) - 0.05 * Number(amount))
                                  .toString()
                                  .replaceAll(",", ""),
                              )
                                .times(10 ** 18)
                                .toString(),
                            ) || "0",
                          {
                            formatSpecifier: NumberFormatSpecifier.CURRENCY,
                          },
                        )}
                      </Text>
                    </Stack>
                  </Wrapper>
                  <Wrapper variant="unbond">
                    <Radio colorScheme={"white"} value="not-instant">
                      <Text fontSize={20}>Unbond</Text>
                      <HStack>
                        <Text
                          fontSize={"12px"}
                          color={"#D2D2D2"}
                          fontWeight={300}
                        >
                          7 days
                        </Text>
                        <Divider
                          orientation="vertical"
                          // width={"2px"}
                          height={"10px"}
                          borderColor="#A6A6A6"
                          borderWidth={"0.5px"}
                        />
                        <Text
                          fontSize={"12px"}
                          color={"#D2D2D2"}
                          fontWeight={300}
                        >
                          No fees
                        </Text>
                      </HStack>
                    </Radio>
                    <Stack textAlign={"right"} spacing={0}>
                      <Text fontSize="lg">
                        {formatAmount(amount || "0", {
                          formatSpecifier: NumberFormatSpecifier.FLOAT,
                        })}
                      </Text>
                      <Text fontSize="xs" color="whiteAlpha.500">
                        {formatAmount(
                          pools
                            ?.filter(
                              (insidePool) =>
                                insidePool.lpToken == pool.lpToken,
                            )[0]
                            .getLpAmountInUsd(
                              num(amount.replaceAll(",", ""))
                                .times(10 ** 18)
                                .toString(),
                            ) || "0",
                          {
                            formatSpecifier: NumberFormatSpecifier.CURRENCY,
                          },
                        )}
                      </Text>
                    </Stack>
                  </Wrapper>
                </Stack>
              </RadioGroup>
            </Wrapper>
          )}
        </Box>
        {/* <Wrapper variant="swapError" justifyContent="flex-start" pl={"12px"}>
          <WarningIcon boxSize="30px" />
          <Text variant="dexterP4" color="primary.white">
            {unbondOption === "instant"
              ? `Instant Unbond has a 5% fee that will be deducted in LP Shares.`
              : `LP Tokens will have an unbonding period of ${unbondingPeriod}.`}
            {unbondOption === "instant" ? (
              <Link
                href={"https://docs.dexter.zone"}
                isExternal
                target="_blank"
                rel="noopener noreferrer"
              >
                <HStack
                  gap={0}
                  fontSize="md"
                  textDecoration={"underline"}
                  textUnderlineOffset={2}
                  fontWeight={400}
                >
                  <Text>Learn more</Text>
                  <Image src="./icons/arrow-up-right.svg" />
                </HStack>
              </Link>
            ) : null}
          </Text>
        </Wrapper> */}
        <Button
          mt="0"
          variant="primary"
          type="submit"
          w="full"
          size="xl"
          isDisabled={
            isWalletConnected &&
            (num(bondedLpTokens?.[pool.lpToken])
              .div(10 ** 18)
              .lt(num(amount)) ||
              Number(amount) === 0)
          }
          onClick={() => !isWalletConnected && connectHandler()}
        >
          {isWalletConnected ? "Unbond Assets" : "Connect Wallet"}
        </Button>
        <HStack justify={"center"} mt={4}>
          <Text variant="dexterP4" color="#A6A6A6">
            Want to learn more about Unbonding on Dexter?
          </Text>
          <Link
            href={"https://docs.dexter.zone/deep-dive/instant-lp-unbonding"}
            isExternal
            target="_blank"
            rel="noopener noreferrer"
          >
            <HStack
              gap={0}
              fontSize="sm"
              // textDecoration={"underline"}
              textUnderlineOffset={2}
              fontWeight={400}
            >
              <Text bg={"primary.dexBlue"} backgroundClip={"text"}>
                Refer our Docs
              </Text>
              <Image src="./icons/arrow-up-right.svg" />
            </HStack>
          </Link>
        </HStack>
      </Box>
    );
  };

  const CustomCloseButton = ({ closeToast }) => (
    <CloseButton
      pos={"absolute"}
      left="0"
      top={"0"}
      color="#FCFCFC"
      w={34}
      h={34}
      borderRadius={"50%"}
      border={"2px solid #386794"}
      bg={"#0a1d32"}
      onClick={() => {
        handleClose();
        closeToast();
      }}
    />
  );

  const renderFinal = () => {
    // handleClose();
    // onClose();
    if (isLoading) {
      return toast(<TxPending />, {
        style: { zIndex: 1200 },
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: false,
        toastId: "   ",
        closeButton: CustomCloseButton,
      });
    } else {
      if (data == null && error != null) {
        return toast.update("   ", {
          render: <TxError />,
          style: { zIndex: 1200 },
          closeButton: CustomCloseButton,
          autoClose: 7000,
        });
      }

      if (data == null) {
        return null;
      }

      return toast.update("   ", {
        render: () => (
          <TxSuccess
            subtitle="LP Tokens Unbonded Successfully"
            data={data}
            title="Transaction Successful"
          />
        ),
        style: { zIndex: 1200 },
        closeButton: CustomCloseButton,
        autoClose: 7000,
      });
    }
  };

  const hideTitle = isLoading || data != null || error != null;

  return !hideTitle ? (
    <Modal onClose={handleClose} size="lg" isCentered {...rest}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {!hideTitle && "Unbond"}
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <chakra.form onSubmit={handleSubmit(onSubmit)}>
            {renderForm()}
            {/* {renderConfirm()} */}
            {/* {renderPending()}
            {renderFinal()} */}
          </chakra.form>
        </ModalBody>
      </ModalContent>
    </Modal>
  ) : (
    renderFinal()
  );
}
