import { useChain } from "@cosmos-kit/react";
import { useQuery } from "@tanstack/react-query";

export type BalanceResponse = {
  balance: string;
};

function isBalanceResponse(value: BalanceResponse): value is BalanceResponse {
  return value.hasOwnProperty("balance");
}

interface UseBalanceArgs {
  token?: string | null;
  address: string;
}

export const useBalance = ({ token, address }: UseBalanceArgs) => {
  const persistenceChain =
    process.env.NEXT_PUBLIC_ENV === "testnet"
      ? "persistencetestnet2"
      : process.env.NEXT_PUBLIC_ENV === "devnet"
      ? "Dexter Devnet"
      : "persistence";
  const chainContext = useChain(persistenceChain);
  const { getCosmWasmClient } = chainContext;

  const { data, ...rest } = useQuery(
    ["balance", token, address],
    async () => {
      const client = await getCosmWasmClient();
      if (address == null || client == null || token == null) {
        throw new Error("Error in useBalance");
      }

      // TODO: isNativeToken function
      if (token.startsWith("u")) {
        return client.getBalance(address, token);
      }

      return client.queryContractSmart(token, { balance: { address } });
    },
    {
      enabled: address != null && client != null && token != null,
    },
  );

  if (data == null) {
    return { data, ...rest };
  }

  if (isBalanceResponse(data)) {
    return { data: data.balance, ...rest };
  }

  return { data: data.amount, ...rest };
};
