import { useCallback } from "react";
import { truncate } from "@wizard-ui/core";

import { useDexter } from "modules/common";

export const useTokenInfo = () => {
  const { tokens } = useDexter();
  const capitalize = (s) => (s && s[0].toUpperCase() + s.slice(1)) || "";
  const getSymbol = useCallback(
    (token?: string | null) => {
      if (token == null) {
        return null;
      }

      if (tokens == null) {
        return token;
      }

      return tokens[token]?.ticker ?? truncate(token);
    },
    [tokens],
  );

  const getChain = useCallback(
    (token: string) => {
      if (tokens == null) {
        return token;
      }
      return capitalize(tokens[token]?.chain) ?? "Persistence";
    },
    [tokens],
  );

  const getDecimals = useCallback(
    (token: string) => {
      if (tokens == null) {
        return token;
      }

      return tokens[token]?.decimals ?? 6;
    },
    [tokens],
  );

  const getCoingecko = useCallback(
    (token: string) => {
      if (tokens == null) {
        return token;
      }

      return tokens[token]?.coingecko_asset_id ?? 6;
    },
    [tokens],
  );

  const getIcon = useCallback(
    (token: string) => {
      if (tokens == null) {
        return "";
      }

      const info = tokens[token];

      // if (info?.image_url) {
      //   return info?.image_url;
      // }

      return `./icons/${info?.ticker?.toLowerCase()}.svg`;
    },
    [tokens],
  );

  return {
    getCoingecko,
    getDecimals,
    getIcon,
    getChain,
    getSymbol,
  };
};

export default useTokenInfo;
