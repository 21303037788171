import React, { useState } from "react";
import type { Control, ControllerProps } from "react-hook-form";
import { useController } from "react-hook-form";
import { Flex, Text, Box, Button } from "@chakra-ui/react";

import {
  TokenPairType,
  ChevronDownIcon,
  TokenIcon,
  useTokenInfo,
} from "modules/common";

import { SelectTokenModal } from "modules/swap";

interface Props {
  isDisabled?: boolean;
  /**
   *  provide `name`, `control` & optional `rules`
   */
  name?: ControllerProps["name"];
  control: Control<any>;
  rules?: ControllerProps["rules"];
  /**
   * for readonly field, provide `value`
   */
  value?: string;
  onChange?: (value: any) => void;
}

export function TokenSelect({
  name = "",
  control,
  rules,
  onChange,
  type = "swap",
  ...props
}: Props) {
  const { getSymbol } = useTokenInfo();
  const [open, setOpen] = useState(false);

  // we decided decimal input needs rifm mask for UX
  // mask needs controller to expose `onChange` & `value` for transforms
  const { field } = useController({
    name,
    control,
    rules,
    defaultValue: props.value,
  });

  const handleSelect = (pair: TokenPairType) => {
    field.onChange?.(pair.from);
    // onChange?.(pair);
    setOpen(false);
  };

  return (
    <Box>
      <Button
        alignItems="center"
        display="flex"
        gap="3"
        h="12"
        variant="unstyled"
        onClick={() => setOpen(true)}
      >
        {field.value?.length > 0 ? (
          <Flex alignItems="center" gap="12px">
            <TokenIcon size="10" token={field.value} />
            <Text variant="dexterP1" fontWeight={700}>
              {getSymbol(field.value)}
            </Text>
            <ChevronDownIcon boxSize={6} />
          </Flex>
        ) : (
          <Flex ml="2" alignItems="center" gap="6px">
            <Text variant="dexterP1" fontWeight={400}>
              Select Token
            </Text>
            <ChevronDownIcon boxSize={6} />
          </Flex>
        )}
      </Button>
      <SelectTokenModal
        isOpen={open}
        onClose={() => setOpen(false)}
        onSelect={handleSelect}
        name={field.name}
        type={type}
      />
    </Box>
  );
}
