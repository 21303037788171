import { Icon, IconProps } from "@chakra-ui/react";

export function ChevronRightIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 9 14" fill="none" {...props}>
      <path
        d="M1.42639 12L7.57355 7L1.42639 2"
        stroke="#FCFCFC"
        strokeWidth="2.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
