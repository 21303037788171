import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { ExecuteResult } from "@cosmjs/cosmwasm-stargate";
import { toBase64 } from "@wizard-ui/core";

import { useContracts } from "modules/common";
import { useChain } from "@cosmos-kit/react";

export interface BondMultiStakingMutation {
  msg: {
    lpTokenAddr: string;
    amount: string;
  };
}

export function useBondMultiStakingMutation(
  options?: Omit<
    UseMutationOptions<ExecuteResult, Error, BondMultiStakingMutation>,
    "mutationFn"
  >,
) {
  const { multistaking } = useContracts();
  const persistenceChain =
    process.env.NEXT_PUBLIC_ENV === "testnet"
      ? "persistencetestnet2"
      : process.env.NEXT_PUBLIC_ENV === "devnet"
      ? "Dexter Devnet"
      : "persistence";
  const chainContext = useChain(persistenceChain);
  const { address, getSigningCosmWasmClient } = chainContext;
  const queryClient = useQueryClient();

  return useMutation<ExecuteResult, Error, BondMultiStakingMutation>(
    async ({ msg: { lpTokenAddr, amount } }) => {
      const signingClient = await getSigningCosmWasmClient();
      if (signingClient == null || address == null) {
        throw new Error("Missing signingClient in useBondMultiStakingMutation");
      }

      return signingClient.executeMultiple(
        address,
        [
          {
            contractAddress: lpTokenAddr,
            msg: {
              send: {
                contract: multistaking,
                amount,
                msg: toBase64({
                  bond: {},
                }),
              },
            },
            funds: [],
          },
        ],
        "auto",
        "",
      );
    },
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries(["balances"]);
        queryClient.invalidateQueries(["bondedLpTokens"]);
      },
    },
  );
}
