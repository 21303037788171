export const whitelist = {
  tokens: {
    uxprt: {
      symbol: "XPRT",
      token: "uxprt",
      icon: "./icons/xprt.svg",
      decimals: 6,
      coingecko: "persistence",
      chain: "Persistence",
    },
    "ibc/27394FB092D2ECCD56123C74F36E4C1F926001CEADA9CA97EA622B25F41E5EB2": {
      symbol: "stkATOM",
      token:
        "ibc/27394FB092D2ECCD56123C74F36E4C1F926001CEADA9CA97EA622B25F41E5EB2",
      icon: "./icons/stkatom.svg",
      decimals: 6,
      coingecko: "cosmos",
      chain: "Persistence",
    },
    // "ibc/574CE061906B04FE025C5FB58E791C452C5C10CD83D0501AFB10873843EF8737": {
    //   symbol: "AXL",
    //   token:
    //     "ibc/574CE061906B04FE025C5FB58E791C452C5C10CD83D0501AFB10873843EF8737",
    //   icon: "./icons/axelar.svg",
    //   decimals: 6,
    //   coingecko: "axelar",
    //   chain: "Axelar",
    // },
    persistence139jjs6klx3h2h52d2fc7jmm4ahvhu8cv5a9jraprk973wzk6vpcqqhsl2q: {
      symbol: "USDC",
      token:
        "persistence139jjs6klx3h2h52d2fc7jmm4ahvhu8cv5a9jraprk973wzk6vpcqqhsl2q",
      icon: "https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389",
      decimals: 6,
      coingecko: "usd-coin",
      chain: "Persistence",
    },
    persistence19kvhe5wn6lr5h9yr70pv7dk27r36lvh0sxsn4tjujc7s6t0dsxgs0925kt: {
      symbol: "ATOM",
      token:
        "persistence19kvhe5wn6lr5h9yr70pv7dk27r36lvh0sxsn4tjujc7s6t0dsxgs0925kt",
      icon: "./icons/atom.svg",
      decimals: 6,
      coingecko: "cosmos",
      chain: "Persistence",
    },
    // persistence1yu43c3fd89gh4rsdvfux3q6ekwfjtk06nnr9rze6wumys3y2plssjx5s63: {
    //   symbol: "XPRT-AXL LP",
    //   token:
    //     "persistence1yu43c3fd89gh4rsdvfux3q6ekwfjtk06nnr9rze6wumys3y2plssjx5s63",
    //   icon: "https://assets.coingecko.com/coins/images/27277/small/V-65_xQ1_400x400.jpeg?1663121730",
    //   decimals: 6,
    //   coingecko: "axelar",
    //   chain: "Axelar",
    // },
  },
};

export default whitelist;
