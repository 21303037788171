import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Image,
  Text,
} from "@chakra-ui/react";
import { AssetItem, useDexter } from "modules/common";
import { POOL_TYPES_ICONS } from "modules/pools";
import React from "react";
import PoolUnclaimedRewardsItem from "./PoolUnclaimedRewardsItem";

const PoolUnclaimedRewards = () => {
  const { unclaimedRewards, lpTokens, pools } = useDexter();
  const unclaimedRewardsArr = unclaimedRewards
    ? Object.entries(unclaimedRewards)
        .map(([key, value]) => ({
          id: key,
          pool: pools?.find((p) => p.pool.lpTokenContractAddress === key),
          rewards: value,
        }))
        .filter((item) => item.rewards.length > 0)
    : [];

  return (
    <Accordion allowToggle mb={4}>
      {unclaimedRewardsArr?.map((item: any) => {
        return (
          <AccordionItem key={item.id} borderColor={"stroke.dexBlue"}>
            <Text color={"white"}>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  <Flex flexWrap="wrap" gap="2">
                    <Image
                      src={
                        item.pool.id == 1 ||
                        item.pool.id == 7 ||
                        item.pool.id == 9 ||
                        item.pool.id == 11 ||
                        item.pool.id == 12
                          ? POOL_TYPES_ICONS["metastable"]
                          : POOL_TYPES_ICONS[item.pool.poolType]
                      }
                      boxSize={"10"}
                      alt="pool icon"
                    />
                    {item.pool.assets
                      .map((asset) => asset.identifier)
                      .join(",")
                      .split(",")
                      .map((token: string) => {
                        return (
                          <AssetItem size="sm" key={token} token={token} />
                        );
                      })}
                  </Flex>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </Text>
            <AccordionPanel pb={4}>
              <PoolUnclaimedRewardsItem item={item} />
            </AccordionPanel>
          </AccordionItem>
        );
      })}
    </Accordion>
  );
};

export default PoolUnclaimedRewards;
