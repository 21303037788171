import { CosmWasmClient } from "@cosmjs/cosmwasm-stargate";
import { num } from "@wizard-ui/core";

import { getTokenDenom, toAssetInfo } from "modules/common";
import { PoolBase } from "modules/pools";
import _ from "lodash";
import {
  parseToPoolsDict,
  filterPoolsOfInterest,
  producePaths,
  SwapHop,
  getTotalFeesInUsd,
  createPathCustom,
} from "modules/swap";

interface Opts {
  token1: string;
  token2: string;
  amount1: string;
  amount2: string;
  slippage: string;
  context: {
    pools: PoolBase[];
    client: CosmWasmClient | null;
    router: string;
    prices: Record<string, number>;
  };
}

export class SwapBase {
  public token1: string;
  public token2: string;
  public amount1: string;
  public amount2: string;
  public slippage: string;
  private _pools: PoolBase[];
  private _prices: Record<string, number>;
  private _client: CosmWasmClient | null;
  private _router: string;
  graph: Map<string, any[]> = new Map();

  constructor({
    token1,
    token2,
    amount1,
    amount2,
    slippage,
    context: { pools, client, router, prices },
  }: Opts) {
    this.token1 = token1;
    this.token2 = token2;
    this.amount1 = amount1;
    this.amount2 = amount2;
    this.slippage = slippage;
    this._pools = pools;
    this._client = client;
    this._router = router;
    this._prices = prices;
    this.poolGraph();
  }

  poolGraph() {
    if (this._pools == null) {
      return;
    }
    for (const pool of this._pools) {
      for (const asset of pool.assets) {
        const assetIdentifier = asset.identifier;
        if (!this.graph.has(assetIdentifier)) {
          this.graph.set(assetIdentifier, []);
        }
        this.graph.get(assetIdentifier)!.push(pool);
      }
    }
  }

  get paths() {
    const poolsAllDict = parseToPoolsDict(this._pools);
    const [directPools, hopsIn, hopsOut, hopsMiddle] = filterPoolsOfInterest(
      poolsAllDict,
      this.token1,
      this.token2,
    );
    if (_.isEmpty(hopsMiddle)) {
      return producePaths(
        this.token1,
        this.token2,
        directPools,
        hopsIn,
        hopsOut,
        poolsAllDict,
      );
    } else {
      return createPathCustom(
        this.token1,
        this.token2,
        hopsIn,
        hopsMiddle,
        hopsOut,
        poolsAllDict,
      );
    }
  }

  get bestPath() {
    if (
      (this.token1 === "stk/uatom" &&
        this.token2 ===
          "ibc/A6E3AF63B3C906416A9AF7A556C59EA4BD50E617EFFE6299B99700CCB780E444") ||
      (this.token1 ===
        "ibc/A6E3AF63B3C906416A9AF7A556C59EA4BD50E617EFFE6299B99700CCB780E444" &&
        this.token2 === "stk/uatom")
    ) {
      return this.paths;
    }
    return this.paths[0];
  }

  get isReady() {
    // const hasPath = this._pools != null && this.paths.length > 0;
    const hasPath = this._pools != null && this.bestPath1?.length > 0;
    // const hasAmount = num(this.amount1).gt(0);
    return hasPath;
  }

  paths1(
    source: string,
    target: string,
    visited: Set<string>,
    path: LiquidityPool[],
  ): LiquidityPool[][] {
    if (
      path.length &&
      path[path.length - 1].pool.assets.some(
        (asset) => asset.identifier === target,
      )
    ) {
      return [path.slice()];
    }
    visited.add(source);

    let results: LiquidityPool[][] = [];
    for (const pool of this.graph.get(source) || []) {
      const neighbors = pool.assets
        .map((asset) => asset.identifier)
        .filter((id) => id !== source);
      for (const neighbor of neighbors) {
        if (!visited.has(neighbor)) {
          path.push({ pool, tokenIn: source, tokenOut: neighbor });
          results = results.concat(
            this.paths1(neighbor, target, visited, path),
          );
          path.pop();
        }
      }
    }
    visited.delete(source);
    return results;
  }

  getSwapPaths(
    sourceIdentifier: string,
    targetIdentifier: string,
  ): LiquidityPool[][] {
    return this.paths1(sourceIdentifier, targetIdentifier, new Set(), []);
  }

  /**
   * The function returns the shortest path among all possible swap paths between two tokens.
   * @returns The best path, which is determined by sorting the swap paths based on their length and
   * returning the path with the shortest length.
   */
  get bestPath1() {
    return this.getSwapPaths(this.token1, this.token2).sort(
      (a, b) => a.length - b.length,
    )[0];
  }

  private async _simulateSingleSwap() {
    if (this._client == null) {
      throw new Error("caca");
    }

    const client = await this._client;

    const data = await client.queryContractSmart(
      this.bestPath1[0].pool.address,
      {
        on_swap: {
          offer_asset: toAssetInfo(this.token1),
          ask_asset: toAssetInfo(this.token2),
          amount: num(Number(this.amount1) === 0 ? "1" : this.amount1)
            .times(
              this.token1 ==
                "ibc/A6E3AF63B3C906416A9AF7A556C59EA4BD50E617EFFE6299B99700CCB780E444" ||
                this.token1 ==
                  "ibc/23DC3FF0E4CBB53A1915E4C62507CB7796956E84C68CA49707787CB8BDE90A1E" ||
                this.token1 == "stk/adydx"
                ? 10 ** 18
                : this.token1 ==
                    "ibc/5D3B6445EA1D7064C4B1CCB588638589529556E1BCBADF13475021B42EA8C73B" ||
                  this.token1 ==
                    "ibc/CCA9F9B22D39884C09975D45E1869B73A12B87080EE53CB44905CE2C422CA228"
                ? 10 ** 8
                : 10 ** 6,
            )
            .toFixed(0),
          // max_spread: "0.02",
          swap_type: {
            give_in: {},
          },
        },
      },
    );
    if (data.response.success) {
      const feesInUsd = getTotalFeesInUsd([data.fee], this._prices);
      const result = {
        fees: [data.fee],
        feesInUsd,
        tokenIn: this.token1,
        tokenOut: this.token2,
        amountIn: data.trade_params.amount_in,
        amountOut:
          Number(this.amount1) === 0 ? "" : data.trade_params.amount_out,
        beliefPrice: num(data.trade_params.amount_out)
          .div(
            this.token2 ===
              "ibc/A6E3AF63B3C906416A9AF7A556C59EA4BD50E617EFFE6299B99700CCB780E444" ||
              this.token2 ===
                "ibc/23DC3FF0E4CBB53A1915E4C62507CB7796956E84C68CA49707787CB8BDE90A1E" ||
              this.token2 === "stk/adydx"
              ? 10 ** 12
              : this.token2 ==
                  "ibc/5D3B6445EA1D7064C4B1CCB588638589529556E1BCBADF13475021B42EA8C73B" ||
                this.token2 ==
                  "ibc/CCA9F9B22D39884C09975D45E1869B73A12B87080EE53CB44905CE2C422CA228"
              ? 10 ** 2
              : 1,
          )
          .div(
            num(data.trade_params.amount_in).div(
              this.token1 ===
                "ibc/A6E3AF63B3C906416A9AF7A556C59EA4BD50E617EFFE6299B99700CCB780E444" ||
                this.token1 ===
                  "ibc/23DC3FF0E4CBB53A1915E4C62507CB7796956E84C68CA49707787CB8BDE90A1E" ||
                this.token1 === "stk/adydx"
                ? 10 ** 12
                : this.token1 ==
                    "ibc/5D3B6445EA1D7064C4B1CCB588638589529556E1BCBADF13475021B42EA8C73B" ||
                  this.token1 ==
                    "ibc/CCA9F9B22D39884C09975D45E1869B73A12B87080EE53CB44905CE2C422CA228"
                ? 10 ** 2
                : 1,
            ),
          )
          .toFixed(18),
        swaps: [
          {
            tokenIn: this.token1,
            tokenOut: this.token2,
            amountIn: data.trade_params.amount_in,
            amountOut: data.trade_params.amount_out,
            spread: data.trade_params.spread,
            pool: this.bestPath1[0].pool,
          },
        ],
      };

      return result;
    } else if (data.response.failure) {
      throw new Error(data.response.failure);
    }

    throw new Error("Issue in the single swap estimation");
  }

  private async _simulateMultiSwap() {
    if (this._client == null) {
      throw new Error("Client is not defined");
    }

    const multiswapRequest = this.bestPath1.map(
      ({ pool, tokenIn, tokenOut }: SwapHop) => {
        return {
          asset_in: toAssetInfo(tokenIn),
          asset_out: toAssetInfo(tokenOut),
          pool_id: String(pool.id),
          // max_spread: "0.02",
        };
      },
    );

    const client = await this._client;

    const data = await client.queryContractSmart(this._router, {
      simulate_multihop_swap: {
        multiswap_request: multiswapRequest,
        swap_type: {
          give_in: {},
        },
        amount: num(Number(this.amount1) === 0 ? "1" : this.amount1)
          .times(
            this.token1 ==
              "ibc/A6E3AF63B3C906416A9AF7A556C59EA4BD50E617EFFE6299B99700CCB780E444" ||
              this.token1 ==
                "ibc/23DC3FF0E4CBB53A1915E4C62507CB7796956E84C68CA49707787CB8BDE90A1E" ||
              this.token1 == "stk/adydx"
              ? 10 ** 18
              : this.token1 ==
                  "ibc/5D3B6445EA1D7064C4B1CCB588638589529556E1BCBADF13475021B42EA8C73B" ||
                this.token1 ==
                  "ibc/CCA9F9B22D39884C09975D45E1869B73A12B87080EE53CB44905CE2C422CA228"
              ? 10 ** 8
              : 10 ** 6,
          )
          .toFixed(0),
      },
    });
    if (data.swap_operations) {
      const amountIn = data.swap_operations[0].offered_amount;
      const amountOut =
        data.swap_operations[data.swap_operations.length - 1].received_amount;

      if (num(amountOut).gte(0)) {
        const feesInUsd = getTotalFeesInUsd(data.fee, this._prices);
        const result = {
          fees: data.fee,
          feesInUsd,
          amountIn,
          amountOut: Number(this.amount1) === 0 ? "" : amountOut,
          tokenIn: this.token1,
          tokenOut: this.token2,
          beliefPrice: num(amountOut)
            .div(
              this.token2 ===
                "ibc/A6E3AF63B3C906416A9AF7A556C59EA4BD50E617EFFE6299B99700CCB780E444" ||
                this.token2 ===
                  "ibc/23DC3FF0E4CBB53A1915E4C62507CB7796956E84C68CA49707787CB8BDE90A1E" ||
                this.token2 === "stk/adydx"
                ? 10 ** 12
                : this.token2 ==
                    "ibc/5D3B6445EA1D7064C4B1CCB588638589529556E1BCBADF13475021B42EA8C73B" ||
                  this.token2 ==
                    "ibc/CCA9F9B22D39884C09975D45E1869B73A12B87080EE53CB44905CE2C422CA228"
                ? 10 ** 2
                : 1,
            )
            // .times(10 ** 6)
            .div(
              num(amountIn).div(
                this.token1 ===
                  "ibc/A6E3AF63B3C906416A9AF7A556C59EA4BD50E617EFFE6299B99700CCB780E444" ||
                  this.token1 ===
                    "ibc/23DC3FF0E4CBB53A1915E4C62507CB7796956E84C68CA49707787CB8BDE90A1E" ||
                  this.token1 === "stk/adydx"
                  ? 10 ** 12
                  : this.token1 ==
                      "ibc/5D3B6445EA1D7064C4B1CCB588638589529556E1BCBADF13475021B42EA8C73B" ||
                    this.token1 ==
                      "ibc/CCA9F9B22D39884C09975D45E1869B73A12B87080EE53CB44905CE2C422CA228"
                  ? 10 ** 2
                  : 1,
              ),
            )
            .toFixed(18),
          swaps: data.swap_operations.map((swap: any) => {
            return {
              tokenIn: getTokenDenom(swap.asset_in),
              tokenOut: getTokenDenom(swap.asset_out),
              amountIn: swap.offered_amount,
              amountOut: swap.received_amount,
              poolId: swap.pool_id,
              pool: this.bestPath1.find((path) => path.pool.id == swap.pool_id)
                ?.pool,
            };
          }),
        };

        return result;
      }
    }

    throw new Error("Issue in the multi swap estimation");
  }

  public simulateSwap() {
    if (this.bestPath1.length == 1) {
      return this._simulateSingleSwap();
    }

    return this._simulateMultiSwap();
  }

  /**
   * @summary Calculate amount of lp token in USD
   */
  // public getLpAmountInUsd(amount: string): number {
  //   const assets = lpToTokens({
  //     assets: this.assets,
  //     amount,
  //     totalSupply: this.lpTokenSupply,
  //   });

  //   const assetsWithPrice = assets.map((asset) => {
  //     return {
  //       ...asset,
  //       price: this._prices[asset.token],
  //     };
  //   });

  //   return assetsWithPrice.reduce((acc, value) => {
  //     const totalPrice = num(value.amount)
  //       .div(10 ** 6)
  //       .times(value.price);
  //     return num(acc).plus(totalPrice).toNumber();
  //   }, 0);
  // }
}
