import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { ExecuteResult } from "@cosmjs/cosmwasm-stargate";

import { useContracts } from "modules/common";
import { useChain } from "@cosmos-kit/react";

interface UnlockMultistakingMutation {
  msg: {
    lpToken: string;
    instantUnlock: boolean;
    token_locks: any[];
  };
}

export function useUnlockMultistakingMutation(
  options?: Omit<
    UseMutationOptions<ExecuteResult, Error, UnlockMultistakingMutation>,
    "mutationFn"
  >,
) {
  const { multistaking } = useContracts();
  const persistenceChain =
    process.env.NEXT_PUBLIC_ENV === "testnet"
      ? "persistencetestnet2"
      : process.env.NEXT_PUBLIC_ENV === "devnet"
      ? "Dexter Devnet"
      : "persistence";
  const chainContext = useChain(persistenceChain);
  const { address, getSigningCosmWasmClient } = chainContext;
  const queryClient = useQueryClient();

  return useMutation<ExecuteResult, Error, UnlockMultistakingMutation>(
    async ({ msg: { lpToken, instantUnlock, token_locks } }) => {
      const signingClient = await getSigningCosmWasmClient();
      if (signingClient == null || address == null) {
        throw new Error(
          "Missing signingClient in useUnlockMultistakingMutation",
        );
      }
      if (instantUnlock) {
        return signingClient.executeMultiple(
          address,
          [
            {
              contractAddress: multistaking,
              msg: {
                instant_unlock: {
                  lp_token: lpToken,
                  token_locks,
                },
              },
              funds: [],
            },
          ],
          "auto",
          "",
        );
      } else {
        return signingClient.executeMultiple(
          address,
          [
            {
              contractAddress: multistaking,
              msg: {
                unlock: {
                  lp_token: lpToken,
                },
              },
              funds: [],
            },
          ],
          "auto",
          "",
        );
      }
    },
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries(["balances"]);
        queryClient.invalidateQueries(["bondedLpTokens"]);
        queryClient.invalidateQueries(["tokenLocks"]);
      },
    },
  );
}
