import { Icon, IconProps } from "@chakra-ui/react";

export function ChevronLeftIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 29 30" fill="none" {...props}>
      <path
        d="M17.3237 7.12598L9.44952 15.0002L17.3237 22.8744"
        stroke="white"
        strokeWidth="3.14969"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
