import { Icon, IconProps } from "@chakra-ui/react";

export function DisconnectIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        d="M19.5141 4.48613C17.5313 2.50332 14.3227 2.50332 12.3422 4.48613L10.0711 6.75723L11.2664 7.95254L13.5375 5.68145C14.7984 4.42051 16.9266 4.28691 18.3188 5.68145C19.7133 7.07598 19.5797 9.20176 18.3188 10.4627L16.0477 12.7338L17.2453 13.9314L19.5164 11.6604C21.4945 9.67754 21.4945 6.46895 19.5141 4.48613ZM10.4649 18.3189C9.20392 19.5799 7.07579 19.7135 5.68361 18.3189C4.28907 16.9244 4.42267 14.7986 5.68361 13.5377L7.9547 11.2666L6.75704 10.0689L4.48595 12.34C2.50314 14.3229 2.50314 17.5314 4.48595 19.5119C6.46876 21.4924 9.67736 21.4947 11.6578 19.5119L13.9289 17.2408L12.7336 16.0455L10.4649 18.3189ZM6.10079 4.90801C6.06555 4.87311 6.01796 4.85354 5.96837 4.85354C5.91878 4.85354 5.87119 4.87311 5.83595 4.90801L4.90782 5.83613C4.87293 5.87137 4.85336 5.91896 4.85336 5.96855C4.85336 6.01815 4.87293 6.06574 4.90782 6.10098L17.9016 19.0947C17.9742 19.1674 18.0938 19.1674 18.1664 19.0947L19.0945 18.1666C19.1672 18.0939 19.1672 17.9744 19.0945 17.9018L6.10079 4.90801Z"
        fill="url(#paint0_linear_2451_27591)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2451_27591"
          x1="16.5222"
          y1="82.3285"
          x2="-30.1225"
          y2="70.3304"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00AFFA" />
          <stop offset="0.47403" stopColor="#32A2BA" />
          <stop offset="1" stopColor="#24FFCA" />
        </linearGradient>
      </defs>
    </Icon>
  );
}
