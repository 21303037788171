import {
  Flex,
  Text,
  Heading,
  SimpleGrid,
  HStack,
  Center,
  useMediaQuery,
} from "@chakra-ui/react";
import { formatAmount, NumberFormatSpecifier } from "@wizard-ui/core";

import { TokenIcon, Wrapper, useTokenInfo, PoolAsset } from "modules/common";
import { PoolBase } from "modules/pools";

interface DetailRowProps {
  label: string;
  children?: React.ReactNode;
}

function DetailRow({ label, children }: DetailRowProps) {
  return (
    <Flex justify="space-between">
      <Text fontSize="sm">{label}</Text>
      <Text fontSize="sm" fontWeight="700">
        {children}
      </Text>
    </Flex>
  );
}

interface Props {
  pool: PoolBase;
  onClick?: (value: any) => void;
}

export function PoolsGridItem({ pool, onClick }: Props) {
  const { getSymbol } = useTokenInfo();
  const [isMobile] = useMediaQuery("(max-width: 800px)");
  const size = pool.assets?.length > 2 ? "sm" : "md";

  const stakingAPR = pool.stakingAPR;

  return (
    <Wrapper
      variant="secondary"
      p="4"
      onClick={() => onClick?.(pool)}
      transition="0.2s"
      _hover={{ cursor: "pointer", borderColor: "#01BDBD" }}
    >
      <Flex flexDir="column" gap="4">
        <Wrapper variant="primary" px="0" borderColor="primary.queenBlue">
          <Center mb={[0, 0, 5, 5, 5]}>
            <HStack spacing="2">
              {pool.assets.map(({ identifier }: PoolAsset) => {
                return (
                  <TokenIcon
                    key={identifier}
                    size={["40px", "40px", "80px", "80px", "80px"]}
                    token={identifier}
                  />
                );
              })}
              {isMobile ? (
                <Heading size={size} fontWeight="500" textAlign="center">
                  {pool.assets
                    .map(({ identifier }: PoolAsset) => getSymbol(identifier))
                    .join("/")}
                </Heading>
              ) : null}
            </HStack>
          </Center>
          {!isMobile ? (
            <Center>
              <Heading size={size} fontWeight="500" textAlign="center">
                {pool.assets
                  .map(({ identifier }: PoolAsset) => getSymbol(identifier))
                  .join("/")}
              </Heading>
            </Center>
          ) : null}
        </Wrapper>
        <SimpleGrid gap="2">
          <DetailRow label="APR">
            {formatAmount(
              stakingAPR
                ? (pool?.apr7d + stakingAPR).toFixed(2)
                : pool?.apr7d?.toFixed(2),
              {
                formatSpecifier: NumberFormatSpecifier.PERCENTAGE,
              },
            )}
          </DetailRow>
          <DetailRow label="Pool Liquidity">
            {formatAmount(pool.getTotalLiquidityInUsd(), {
              formatSpecifier: NumberFormatSpecifier.CURRENCY,
            })}
          </DetailRow>
          <DetailRow label="My Liquidity">
            {formatAmount(pool.getMyLiquidityInUsd(), {
              formatSpecifier: NumberFormatSpecifier.CURRENCY,
            })}
          </DetailRow>
        </SimpleGrid>
      </Flex>
    </Wrapper>
  );
}
