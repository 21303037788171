import { Box, BoxProps, useStyleConfig } from "@chakra-ui/react";

interface Props extends BoxProps {
  variant: string;
}

export function Wrapper({ variant, children, ...rest }: Props) {
  const styles = useStyleConfig("Wrapper", { variant });

  return (
    <Box __css={styles} {...rest}>
      {children}
    </Box>
  );
}
