import { useChain } from "@cosmos-kit/react";
import { useQuery } from "@tanstack/react-query";

import { useContracts } from "modules/common";
import { getTokenLocks } from "modules/contracts";

export const useTokenLocks = (lpTokens: string[], options = {}) => {
  const persistenceChain =
    process.env.NEXT_PUBLIC_ENV === "testnet"
      ? "persistencetestnet2"
      : process.env.NEXT_PUBLIC_ENV === "devnet"
      ? "Dexter Devnet"
      : "persistence";
  const chainContext = useChain(persistenceChain);
  const { address, getSigningStargateClient, getCosmWasmClient } = chainContext;
  const { multistaking } = useContracts();
  return useQuery(
    ["tokenLocks", getCosmWasmClient, multistaking, address, lpTokens],
    async () => {
      const client = await getCosmWasmClient();
      if (
        client == null ||
        multistaking == null ||
        address == null ||
        lpTokens == null
      ) {
        throw new Error("Error in useBondedLpTokenLocks");
      }

      const tokenLocks = await getTokenLocks({
        client,
        lpTokens,
        multistaking,
        address,
      });

      return tokenLocks;
    },
    {
      ...options,
      // Revisit the refetch interval
      // refetchInterval: 5000,
      enabled:
        getCosmWasmClient != null &&
        multistaking != null &&
        address != null &&
        lpTokens != null,
    },
  );
};
