export default {
  variants: {
    dexterP0: {
      textStyle: "dexterP0",
      fontSize: null,
      fontFamily: null,
      fontWeight: null,
      lineHeight: null,
    },
    dexterP1: {
      textStyle: "dexterP1",
      fontSize: null,
      fontFamily: null,
      fontWeight: null,
      lineHeight: null,
    },
    dexterP2: {
      textStyle: "dexterP2",
      fontSize: null,
      fontFamily: null,
      fontWeight: null,
      lineHeight: null,
    },
    dexterP3: {
      textStyle: "dexterP3",
      fontSize: null,
      fontFamily: null,
      fontWeight: null,
      lineHeight: null,
    },
    dexterP4: {
      textStyle: "dexterP4",
      fontSize: null,
      fontFamily: null,
      fontWeight: null,
      lineHeight: null,
    },
    dexterP5: {
      textStyle: "dexterP5",
      fontSize: null,
      fontFamily: null,
      fontWeight: null,
      lineHeight: null,
    },
    dexterBtnLarge: {
      textStyle: "dexterBtnLarge",
      fontSize: null,
      fontFamily: null,
      fontWeight: null,
      lineHeight: null,
    },
    dexterBtnMedium: {
      textStyle: "dexterBtnMedium",
      fontSize: null,
      fontFamily: null,
      fontWeight: null,
      lineHeight: null,
    },
    dexterBtnSmall: {
      textStyle: "dexterBtnSmall",
      fontSize: null,
      fontFamily: null,
      fontWeight: null,
      lineHeight: null,
    },
    dexterCaption: {
      textStyle: "dexterCaption",
      fontSize: null,
      fontFamily: null,
      fontWeight: null,
      lineHeight: null,
    },
  },
};
