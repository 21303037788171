import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { ExecuteResult } from "@cosmjs/cosmwasm-stargate";

import { useContracts, useDexter } from "modules/common";
import { useChain } from "@cosmos-kit/react";

export function useClaimRewardsMutation(
  options?: Omit<UseMutationOptions<ExecuteResult, Error>, "mutationFn">,
) {
  const { multistaking } = useContracts();
  const persistenceChain =
    process.env.NEXT_PUBLIC_ENV === "testnet"
      ? "persistencetestnet2"
      : process.env.NEXT_PUBLIC_ENV === "devnet"
      ? "Dexter Devnet"
      : "persistence";
  const chainContext = useChain(persistenceChain);
  const { address, getSigningCosmWasmClient } = chainContext;
  const { lpTokens } = useDexter();
  const queryClient = useQueryClient();

  return useMutation<ExecuteResult, Error>(
    async () => {
      const signingClient = await getSigningCosmWasmClient();
      if (signingClient == null || address == null || lpTokens == null) {
        throw new Error("Missing signingClient in useClaimRewardsMutation");
      }

      const msgs = lpTokens
        // .filter(
        //   (lpToken) =>
        //     lpToken !==
        //     "persistence1l26l2qrvvf0mc4mrt3gpzunql6t2cp8jx52x0c4hu6hrlccw6l5sc2plfh",
        // )
        .map((lpToken) => {
          return {
            contractAddress: multistaking,
            msg: {
              withdraw: {
                lp_token: lpToken,
              },
            },
            funds: [],
          };
        });

      return signingClient.executeMultiple(address, msgs, "auto", "");
    },
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries(["balances"]);
        queryClient.invalidateQueries(["unclaimedRewards"]);
      },
    },
  );
}
