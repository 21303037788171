export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigDecimal: any;
  BigInt: any;
  Bytes: any;
};

export type AddPoolEvent = {
  __typename?: "AddPoolEvent";
  blockHeight: Scalars["BigInt"];
  blockTime: Scalars["BigInt"];
  developerFeePercent: Scalars["Int"];
  id: Scalars["ID"];
  lpTokenName: Scalars["String"];
  lpTokenSymbol: Scalars["String"];
  poolId: Scalars["Int"];
  poolType: PoolType;
  protocolFeePercent: Scalars["Int"];
  totalFeeBps: Scalars["Int"];
  vaultContractAddress: Scalars["String"];
};

export type AddPoolEventAsset = {
  __typename?: "AddPoolEventAsset";
  addPoolEvent?: Maybe<AddPoolEvent>;
  assetType: AssetType;
  id: Scalars["ID"];
  identifier: Scalars["String"];
};

export type AddPoolEventAsset_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  addPoolEvent?: InputMaybe<Scalars["String"]>;
  addPoolEvent_?: InputMaybe<AddPoolEvent_Filter>;
  addPoolEvent_contains?: InputMaybe<Scalars["String"]>;
  addPoolEvent_contains_nocase?: InputMaybe<Scalars["String"]>;
  addPoolEvent_ends_with?: InputMaybe<Scalars["String"]>;
  addPoolEvent_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  addPoolEvent_gt?: InputMaybe<Scalars["String"]>;
  addPoolEvent_gte?: InputMaybe<Scalars["String"]>;
  addPoolEvent_in?: InputMaybe<Array<Scalars["String"]>>;
  addPoolEvent_lt?: InputMaybe<Scalars["String"]>;
  addPoolEvent_lte?: InputMaybe<Scalars["String"]>;
  addPoolEvent_not?: InputMaybe<Scalars["String"]>;
  addPoolEvent_not_contains?: InputMaybe<Scalars["String"]>;
  addPoolEvent_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  addPoolEvent_not_ends_with?: InputMaybe<Scalars["String"]>;
  addPoolEvent_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  addPoolEvent_not_in?: InputMaybe<Array<Scalars["String"]>>;
  addPoolEvent_not_starts_with?: InputMaybe<Scalars["String"]>;
  addPoolEvent_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  addPoolEvent_starts_with?: InputMaybe<Scalars["String"]>;
  addPoolEvent_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  assetType?: InputMaybe<AssetType>;
  assetType_in?: InputMaybe<Array<AssetType>>;
  assetType_not?: InputMaybe<AssetType>;
  assetType_not_in?: InputMaybe<Array<AssetType>>;
  id?: InputMaybe<Scalars["ID"]>;
  id_gt?: InputMaybe<Scalars["ID"]>;
  id_gte?: InputMaybe<Scalars["ID"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]>>;
  id_lt?: InputMaybe<Scalars["ID"]>;
  id_lte?: InputMaybe<Scalars["ID"]>;
  id_not?: InputMaybe<Scalars["ID"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]>>;
  identifier?: InputMaybe<Scalars["String"]>;
  identifier_contains?: InputMaybe<Scalars["String"]>;
  identifier_contains_nocase?: InputMaybe<Scalars["String"]>;
  identifier_ends_with?: InputMaybe<Scalars["String"]>;
  identifier_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  identifier_gt?: InputMaybe<Scalars["String"]>;
  identifier_gte?: InputMaybe<Scalars["String"]>;
  identifier_in?: InputMaybe<Array<Scalars["String"]>>;
  identifier_lt?: InputMaybe<Scalars["String"]>;
  identifier_lte?: InputMaybe<Scalars["String"]>;
  identifier_not?: InputMaybe<Scalars["String"]>;
  identifier_not_contains?: InputMaybe<Scalars["String"]>;
  identifier_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  identifier_not_ends_with?: InputMaybe<Scalars["String"]>;
  identifier_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  identifier_not_in?: InputMaybe<Array<Scalars["String"]>>;
  identifier_not_starts_with?: InputMaybe<Scalars["String"]>;
  identifier_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  identifier_starts_with?: InputMaybe<Scalars["String"]>;
  identifier_starts_with_nocase?: InputMaybe<Scalars["String"]>;
};

export enum AddPoolEventAsset_OrderBy {
  AddPoolEvent = "addPoolEvent",
  AssetType = "assetType",
  Id = "id",
  Identifier = "identifier",
}

export type AddPoolEvent_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  blockHeight?: InputMaybe<Scalars["BigInt"]>;
  blockHeight_gt?: InputMaybe<Scalars["BigInt"]>;
  blockHeight_gte?: InputMaybe<Scalars["BigInt"]>;
  blockHeight_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  blockHeight_lt?: InputMaybe<Scalars["BigInt"]>;
  blockHeight_lte?: InputMaybe<Scalars["BigInt"]>;
  blockHeight_not?: InputMaybe<Scalars["BigInt"]>;
  blockHeight_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  blockTime?: InputMaybe<Scalars["BigInt"]>;
  blockTime_gt?: InputMaybe<Scalars["BigInt"]>;
  blockTime_gte?: InputMaybe<Scalars["BigInt"]>;
  blockTime_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  blockTime_lt?: InputMaybe<Scalars["BigInt"]>;
  blockTime_lte?: InputMaybe<Scalars["BigInt"]>;
  blockTime_not?: InputMaybe<Scalars["BigInt"]>;
  blockTime_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  developerFeePercent?: InputMaybe<Scalars["Int"]>;
  developerFeePercent_gt?: InputMaybe<Scalars["Int"]>;
  developerFeePercent_gte?: InputMaybe<Scalars["Int"]>;
  developerFeePercent_in?: InputMaybe<Array<Scalars["Int"]>>;
  developerFeePercent_lt?: InputMaybe<Scalars["Int"]>;
  developerFeePercent_lte?: InputMaybe<Scalars["Int"]>;
  developerFeePercent_not?: InputMaybe<Scalars["Int"]>;
  developerFeePercent_not_in?: InputMaybe<Array<Scalars["Int"]>>;
  id?: InputMaybe<Scalars["ID"]>;
  id_gt?: InputMaybe<Scalars["ID"]>;
  id_gte?: InputMaybe<Scalars["ID"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]>>;
  id_lt?: InputMaybe<Scalars["ID"]>;
  id_lte?: InputMaybe<Scalars["ID"]>;
  id_not?: InputMaybe<Scalars["ID"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]>>;
  lpTokenName?: InputMaybe<Scalars["String"]>;
  lpTokenName_contains?: InputMaybe<Scalars["String"]>;
  lpTokenName_contains_nocase?: InputMaybe<Scalars["String"]>;
  lpTokenName_ends_with?: InputMaybe<Scalars["String"]>;
  lpTokenName_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  lpTokenName_gt?: InputMaybe<Scalars["String"]>;
  lpTokenName_gte?: InputMaybe<Scalars["String"]>;
  lpTokenName_in?: InputMaybe<Array<Scalars["String"]>>;
  lpTokenName_lt?: InputMaybe<Scalars["String"]>;
  lpTokenName_lte?: InputMaybe<Scalars["String"]>;
  lpTokenName_not?: InputMaybe<Scalars["String"]>;
  lpTokenName_not_contains?: InputMaybe<Scalars["String"]>;
  lpTokenName_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  lpTokenName_not_ends_with?: InputMaybe<Scalars["String"]>;
  lpTokenName_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  lpTokenName_not_in?: InputMaybe<Array<Scalars["String"]>>;
  lpTokenName_not_starts_with?: InputMaybe<Scalars["String"]>;
  lpTokenName_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  lpTokenName_starts_with?: InputMaybe<Scalars["String"]>;
  lpTokenName_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  lpTokenSymbol?: InputMaybe<Scalars["String"]>;
  lpTokenSymbol_contains?: InputMaybe<Scalars["String"]>;
  lpTokenSymbol_contains_nocase?: InputMaybe<Scalars["String"]>;
  lpTokenSymbol_ends_with?: InputMaybe<Scalars["String"]>;
  lpTokenSymbol_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  lpTokenSymbol_gt?: InputMaybe<Scalars["String"]>;
  lpTokenSymbol_gte?: InputMaybe<Scalars["String"]>;
  lpTokenSymbol_in?: InputMaybe<Array<Scalars["String"]>>;
  lpTokenSymbol_lt?: InputMaybe<Scalars["String"]>;
  lpTokenSymbol_lte?: InputMaybe<Scalars["String"]>;
  lpTokenSymbol_not?: InputMaybe<Scalars["String"]>;
  lpTokenSymbol_not_contains?: InputMaybe<Scalars["String"]>;
  lpTokenSymbol_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  lpTokenSymbol_not_ends_with?: InputMaybe<Scalars["String"]>;
  lpTokenSymbol_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  lpTokenSymbol_not_in?: InputMaybe<Array<Scalars["String"]>>;
  lpTokenSymbol_not_starts_with?: InputMaybe<Scalars["String"]>;
  lpTokenSymbol_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  lpTokenSymbol_starts_with?: InputMaybe<Scalars["String"]>;
  lpTokenSymbol_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  poolId?: InputMaybe<Scalars["Int"]>;
  poolId_gt?: InputMaybe<Scalars["Int"]>;
  poolId_gte?: InputMaybe<Scalars["Int"]>;
  poolId_in?: InputMaybe<Array<Scalars["Int"]>>;
  poolId_lt?: InputMaybe<Scalars["Int"]>;
  poolId_lte?: InputMaybe<Scalars["Int"]>;
  poolId_not?: InputMaybe<Scalars["Int"]>;
  poolId_not_in?: InputMaybe<Array<Scalars["Int"]>>;
  poolType?: InputMaybe<PoolType>;
  poolType_in?: InputMaybe<Array<PoolType>>;
  poolType_not?: InputMaybe<PoolType>;
  poolType_not_in?: InputMaybe<Array<PoolType>>;
  protocolFeePercent?: InputMaybe<Scalars["Int"]>;
  protocolFeePercent_gt?: InputMaybe<Scalars["Int"]>;
  protocolFeePercent_gte?: InputMaybe<Scalars["Int"]>;
  protocolFeePercent_in?: InputMaybe<Array<Scalars["Int"]>>;
  protocolFeePercent_lt?: InputMaybe<Scalars["Int"]>;
  protocolFeePercent_lte?: InputMaybe<Scalars["Int"]>;
  protocolFeePercent_not?: InputMaybe<Scalars["Int"]>;
  protocolFeePercent_not_in?: InputMaybe<Array<Scalars["Int"]>>;
  totalFeeBps?: InputMaybe<Scalars["Int"]>;
  totalFeeBps_gt?: InputMaybe<Scalars["Int"]>;
  totalFeeBps_gte?: InputMaybe<Scalars["Int"]>;
  totalFeeBps_in?: InputMaybe<Array<Scalars["Int"]>>;
  totalFeeBps_lt?: InputMaybe<Scalars["Int"]>;
  totalFeeBps_lte?: InputMaybe<Scalars["Int"]>;
  totalFeeBps_not?: InputMaybe<Scalars["Int"]>;
  totalFeeBps_not_in?: InputMaybe<Array<Scalars["Int"]>>;
  vaultContractAddress?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_contains?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_contains_nocase?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_ends_with?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_gt?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_gte?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_in?: InputMaybe<Array<Scalars["String"]>>;
  vaultContractAddress_lt?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_lte?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_not?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_not_contains?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_not_ends_with?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_not_in?: InputMaybe<Array<Scalars["String"]>>;
  vaultContractAddress_not_starts_with?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_starts_with?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_starts_with_nocase?: InputMaybe<Scalars["String"]>;
};

export enum AddPoolEvent_OrderBy {
  BlockHeight = "blockHeight",
  BlockTime = "blockTime",
  DeveloperFeePercent = "developerFeePercent",
  Id = "id",
  LpTokenName = "lpTokenName",
  LpTokenSymbol = "lpTokenSymbol",
  PoolId = "poolId",
  PoolType = "poolType",
  ProtocolFeePercent = "protocolFeePercent",
  TotalFeeBps = "totalFeeBps",
  VaultContractAddress = "vaultContractAddress",
}

export enum AssetType {
  Cw20 = "CW20",
  Native = "Native",
}

export type Block = {
  __typename?: "Block";
  id: Scalars["ID"];
  number?: Maybe<Scalars["BigInt"]>;
  timestamp?: Maybe<Scalars["BigInt"]>;
};

export type BlockChangedFilter = {
  number_gte: Scalars["Int"];
};

export type Block_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  id?: InputMaybe<Scalars["ID"]>;
  id_gt?: InputMaybe<Scalars["ID"]>;
  id_gte?: InputMaybe<Scalars["ID"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]>>;
  id_lt?: InputMaybe<Scalars["ID"]>;
  id_lte?: InputMaybe<Scalars["ID"]>;
  id_not?: InputMaybe<Scalars["ID"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]>>;
  number?: InputMaybe<Scalars["BigInt"]>;
  number_gt?: InputMaybe<Scalars["BigInt"]>;
  number_gte?: InputMaybe<Scalars["BigInt"]>;
  number_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  number_lt?: InputMaybe<Scalars["BigInt"]>;
  number_lte?: InputMaybe<Scalars["BigInt"]>;
  number_not?: InputMaybe<Scalars["BigInt"]>;
  number_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  timestamp?: InputMaybe<Scalars["BigInt"]>;
  timestamp_gt?: InputMaybe<Scalars["BigInt"]>;
  timestamp_gte?: InputMaybe<Scalars["BigInt"]>;
  timestamp_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  timestamp_lt?: InputMaybe<Scalars["BigInt"]>;
  timestamp_lte?: InputMaybe<Scalars["BigInt"]>;
  timestamp_not?: InputMaybe<Scalars["BigInt"]>;
  timestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
};

export type Block_Height = {
  hash?: InputMaybe<Scalars["Bytes"]>;
  number?: InputMaybe<Scalars["Int"]>;
  number_gte?: InputMaybe<Scalars["Int"]>;
};

export enum Block_OrderBy {
  Id = "id",
  Number = "number",
  Timestamp = "timestamp",
}

export type ExitPoolEvent = {
  __typename?: "ExitPoolEvent";
  blockHeight: Scalars["BigInt"];
  blockTime: Scalars["BigInt"];
  id: Scalars["ID"];
  lpTokenContractAddress: Scalars["String"];
  lpTokensBurnt: Scalars["BigInt"];
  poolAddress: Scalars["String"];
  poolId: Scalars["Int"];
  recipientAddress: Scalars["String"];
  senderAddress: Scalars["String"];
  vaultContractAddress: Scalars["String"];
};

export type ExitPoolEventRemovedAssets = {
  __typename?: "ExitPoolEventRemovedAssets";
  amountRemoved: Scalars["BigInt"];
  asset: PoolAsset;
  exitPoolEvent: ExitPoolEvent;
  id: Scalars["ID"];
};

export type ExitPoolEventRemovedAssets_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  amountRemoved?: InputMaybe<Scalars["BigInt"]>;
  amountRemoved_gt?: InputMaybe<Scalars["BigInt"]>;
  amountRemoved_gte?: InputMaybe<Scalars["BigInt"]>;
  amountRemoved_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  amountRemoved_lt?: InputMaybe<Scalars["BigInt"]>;
  amountRemoved_lte?: InputMaybe<Scalars["BigInt"]>;
  amountRemoved_not?: InputMaybe<Scalars["BigInt"]>;
  amountRemoved_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  asset?: InputMaybe<Scalars["String"]>;
  asset_?: InputMaybe<PoolAsset_Filter>;
  asset_contains?: InputMaybe<Scalars["String"]>;
  asset_contains_nocase?: InputMaybe<Scalars["String"]>;
  asset_ends_with?: InputMaybe<Scalars["String"]>;
  asset_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  asset_gt?: InputMaybe<Scalars["String"]>;
  asset_gte?: InputMaybe<Scalars["String"]>;
  asset_in?: InputMaybe<Array<Scalars["String"]>>;
  asset_lt?: InputMaybe<Scalars["String"]>;
  asset_lte?: InputMaybe<Scalars["String"]>;
  asset_not?: InputMaybe<Scalars["String"]>;
  asset_not_contains?: InputMaybe<Scalars["String"]>;
  asset_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  asset_not_ends_with?: InputMaybe<Scalars["String"]>;
  asset_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  asset_not_in?: InputMaybe<Array<Scalars["String"]>>;
  asset_not_starts_with?: InputMaybe<Scalars["String"]>;
  asset_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  asset_starts_with?: InputMaybe<Scalars["String"]>;
  asset_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  exitPoolEvent?: InputMaybe<Scalars["String"]>;
  exitPoolEvent_?: InputMaybe<ExitPoolEvent_Filter>;
  exitPoolEvent_contains?: InputMaybe<Scalars["String"]>;
  exitPoolEvent_contains_nocase?: InputMaybe<Scalars["String"]>;
  exitPoolEvent_ends_with?: InputMaybe<Scalars["String"]>;
  exitPoolEvent_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  exitPoolEvent_gt?: InputMaybe<Scalars["String"]>;
  exitPoolEvent_gte?: InputMaybe<Scalars["String"]>;
  exitPoolEvent_in?: InputMaybe<Array<Scalars["String"]>>;
  exitPoolEvent_lt?: InputMaybe<Scalars["String"]>;
  exitPoolEvent_lte?: InputMaybe<Scalars["String"]>;
  exitPoolEvent_not?: InputMaybe<Scalars["String"]>;
  exitPoolEvent_not_contains?: InputMaybe<Scalars["String"]>;
  exitPoolEvent_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  exitPoolEvent_not_ends_with?: InputMaybe<Scalars["String"]>;
  exitPoolEvent_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  exitPoolEvent_not_in?: InputMaybe<Array<Scalars["String"]>>;
  exitPoolEvent_not_starts_with?: InputMaybe<Scalars["String"]>;
  exitPoolEvent_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  exitPoolEvent_starts_with?: InputMaybe<Scalars["String"]>;
  exitPoolEvent_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  id_gt?: InputMaybe<Scalars["ID"]>;
  id_gte?: InputMaybe<Scalars["ID"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]>>;
  id_lt?: InputMaybe<Scalars["ID"]>;
  id_lte?: InputMaybe<Scalars["ID"]>;
  id_not?: InputMaybe<Scalars["ID"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]>>;
};

export enum ExitPoolEventRemovedAssets_OrderBy {
  AmountRemoved = "amountRemoved",
  Asset = "asset",
  ExitPoolEvent = "exitPoolEvent",
  Id = "id",
}

export type ExitPoolEvent_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  blockHeight?: InputMaybe<Scalars["BigInt"]>;
  blockHeight_gt?: InputMaybe<Scalars["BigInt"]>;
  blockHeight_gte?: InputMaybe<Scalars["BigInt"]>;
  blockHeight_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  blockHeight_lt?: InputMaybe<Scalars["BigInt"]>;
  blockHeight_lte?: InputMaybe<Scalars["BigInt"]>;
  blockHeight_not?: InputMaybe<Scalars["BigInt"]>;
  blockHeight_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  blockTime?: InputMaybe<Scalars["BigInt"]>;
  blockTime_gt?: InputMaybe<Scalars["BigInt"]>;
  blockTime_gte?: InputMaybe<Scalars["BigInt"]>;
  blockTime_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  blockTime_lt?: InputMaybe<Scalars["BigInt"]>;
  blockTime_lte?: InputMaybe<Scalars["BigInt"]>;
  blockTime_not?: InputMaybe<Scalars["BigInt"]>;
  blockTime_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  id?: InputMaybe<Scalars["ID"]>;
  id_gt?: InputMaybe<Scalars["ID"]>;
  id_gte?: InputMaybe<Scalars["ID"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]>>;
  id_lt?: InputMaybe<Scalars["ID"]>;
  id_lte?: InputMaybe<Scalars["ID"]>;
  id_not?: InputMaybe<Scalars["ID"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]>>;
  lpTokenContractAddress?: InputMaybe<Scalars["String"]>;
  lpTokenContractAddress_contains?: InputMaybe<Scalars["String"]>;
  lpTokenContractAddress_contains_nocase?: InputMaybe<Scalars["String"]>;
  lpTokenContractAddress_ends_with?: InputMaybe<Scalars["String"]>;
  lpTokenContractAddress_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  lpTokenContractAddress_gt?: InputMaybe<Scalars["String"]>;
  lpTokenContractAddress_gte?: InputMaybe<Scalars["String"]>;
  lpTokenContractAddress_in?: InputMaybe<Array<Scalars["String"]>>;
  lpTokenContractAddress_lt?: InputMaybe<Scalars["String"]>;
  lpTokenContractAddress_lte?: InputMaybe<Scalars["String"]>;
  lpTokenContractAddress_not?: InputMaybe<Scalars["String"]>;
  lpTokenContractAddress_not_contains?: InputMaybe<Scalars["String"]>;
  lpTokenContractAddress_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  lpTokenContractAddress_not_ends_with?: InputMaybe<Scalars["String"]>;
  lpTokenContractAddress_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  lpTokenContractAddress_not_in?: InputMaybe<Array<Scalars["String"]>>;
  lpTokenContractAddress_not_starts_with?: InputMaybe<Scalars["String"]>;
  lpTokenContractAddress_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  lpTokenContractAddress_starts_with?: InputMaybe<Scalars["String"]>;
  lpTokenContractAddress_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  lpTokensBurnt?: InputMaybe<Scalars["BigInt"]>;
  lpTokensBurnt_gt?: InputMaybe<Scalars["BigInt"]>;
  lpTokensBurnt_gte?: InputMaybe<Scalars["BigInt"]>;
  lpTokensBurnt_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  lpTokensBurnt_lt?: InputMaybe<Scalars["BigInt"]>;
  lpTokensBurnt_lte?: InputMaybe<Scalars["BigInt"]>;
  lpTokensBurnt_not?: InputMaybe<Scalars["BigInt"]>;
  lpTokensBurnt_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  poolAddress?: InputMaybe<Scalars["String"]>;
  poolAddress_contains?: InputMaybe<Scalars["String"]>;
  poolAddress_contains_nocase?: InputMaybe<Scalars["String"]>;
  poolAddress_ends_with?: InputMaybe<Scalars["String"]>;
  poolAddress_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  poolAddress_gt?: InputMaybe<Scalars["String"]>;
  poolAddress_gte?: InputMaybe<Scalars["String"]>;
  poolAddress_in?: InputMaybe<Array<Scalars["String"]>>;
  poolAddress_lt?: InputMaybe<Scalars["String"]>;
  poolAddress_lte?: InputMaybe<Scalars["String"]>;
  poolAddress_not?: InputMaybe<Scalars["String"]>;
  poolAddress_not_contains?: InputMaybe<Scalars["String"]>;
  poolAddress_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  poolAddress_not_ends_with?: InputMaybe<Scalars["String"]>;
  poolAddress_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  poolAddress_not_in?: InputMaybe<Array<Scalars["String"]>>;
  poolAddress_not_starts_with?: InputMaybe<Scalars["String"]>;
  poolAddress_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  poolAddress_starts_with?: InputMaybe<Scalars["String"]>;
  poolAddress_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  poolId?: InputMaybe<Scalars["Int"]>;
  poolId_gt?: InputMaybe<Scalars["Int"]>;
  poolId_gte?: InputMaybe<Scalars["Int"]>;
  poolId_in?: InputMaybe<Array<Scalars["Int"]>>;
  poolId_lt?: InputMaybe<Scalars["Int"]>;
  poolId_lte?: InputMaybe<Scalars["Int"]>;
  poolId_not?: InputMaybe<Scalars["Int"]>;
  poolId_not_in?: InputMaybe<Array<Scalars["Int"]>>;
  recipientAddress?: InputMaybe<Scalars["String"]>;
  recipientAddress_contains?: InputMaybe<Scalars["String"]>;
  recipientAddress_contains_nocase?: InputMaybe<Scalars["String"]>;
  recipientAddress_ends_with?: InputMaybe<Scalars["String"]>;
  recipientAddress_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  recipientAddress_gt?: InputMaybe<Scalars["String"]>;
  recipientAddress_gte?: InputMaybe<Scalars["String"]>;
  recipientAddress_in?: InputMaybe<Array<Scalars["String"]>>;
  recipientAddress_lt?: InputMaybe<Scalars["String"]>;
  recipientAddress_lte?: InputMaybe<Scalars["String"]>;
  recipientAddress_not?: InputMaybe<Scalars["String"]>;
  recipientAddress_not_contains?: InputMaybe<Scalars["String"]>;
  recipientAddress_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  recipientAddress_not_ends_with?: InputMaybe<Scalars["String"]>;
  recipientAddress_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  recipientAddress_not_in?: InputMaybe<Array<Scalars["String"]>>;
  recipientAddress_not_starts_with?: InputMaybe<Scalars["String"]>;
  recipientAddress_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  recipientAddress_starts_with?: InputMaybe<Scalars["String"]>;
  recipientAddress_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  senderAddress?: InputMaybe<Scalars["String"]>;
  senderAddress_contains?: InputMaybe<Scalars["String"]>;
  senderAddress_contains_nocase?: InputMaybe<Scalars["String"]>;
  senderAddress_ends_with?: InputMaybe<Scalars["String"]>;
  senderAddress_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  senderAddress_gt?: InputMaybe<Scalars["String"]>;
  senderAddress_gte?: InputMaybe<Scalars["String"]>;
  senderAddress_in?: InputMaybe<Array<Scalars["String"]>>;
  senderAddress_lt?: InputMaybe<Scalars["String"]>;
  senderAddress_lte?: InputMaybe<Scalars["String"]>;
  senderAddress_not?: InputMaybe<Scalars["String"]>;
  senderAddress_not_contains?: InputMaybe<Scalars["String"]>;
  senderAddress_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  senderAddress_not_ends_with?: InputMaybe<Scalars["String"]>;
  senderAddress_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  senderAddress_not_in?: InputMaybe<Array<Scalars["String"]>>;
  senderAddress_not_starts_with?: InputMaybe<Scalars["String"]>;
  senderAddress_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  senderAddress_starts_with?: InputMaybe<Scalars["String"]>;
  senderAddress_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  vaultContractAddress?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_contains?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_contains_nocase?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_ends_with?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_gt?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_gte?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_in?: InputMaybe<Array<Scalars["String"]>>;
  vaultContractAddress_lt?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_lte?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_not?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_not_contains?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_not_ends_with?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_not_in?: InputMaybe<Array<Scalars["String"]>>;
  vaultContractAddress_not_starts_with?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_starts_with?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_starts_with_nocase?: InputMaybe<Scalars["String"]>;
};

export enum ExitPoolEvent_OrderBy {
  BlockHeight = "blockHeight",
  BlockTime = "blockTime",
  Id = "id",
  LpTokenContractAddress = "lpTokenContractAddress",
  LpTokensBurnt = "lpTokensBurnt",
  PoolAddress = "poolAddress",
  PoolId = "poolId",
  RecipientAddress = "recipientAddress",
  SenderAddress = "senderAddress",
  VaultContractAddress = "vaultContractAddress",
}

export type JoinPoolEvent = {
  __typename?: "JoinPoolEvent";
  blockHeight: Scalars["BigInt"];
  blockTime: Scalars["BigInt"];
  id: Scalars["ID"];
  lpTokensMinted: Scalars["BigInt"];
  poolAddress: Scalars["String"];
  poolId: Scalars["Int"];
  recipient: Scalars["String"];
  vaultContractAddress: Scalars["String"];
};

export type JoinPoolEventAsset = {
  __typename?: "JoinPoolEventAsset";
  amount: Scalars["BigInt"];
  assetType: AssetType;
  event: JoinPoolEvent;
  id: Scalars["ID"];
  identifier: Scalars["String"];
};

export type JoinPoolEventAsset_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  amount?: InputMaybe<Scalars["BigInt"]>;
  amount_gt?: InputMaybe<Scalars["BigInt"]>;
  amount_gte?: InputMaybe<Scalars["BigInt"]>;
  amount_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  amount_lt?: InputMaybe<Scalars["BigInt"]>;
  amount_lte?: InputMaybe<Scalars["BigInt"]>;
  amount_not?: InputMaybe<Scalars["BigInt"]>;
  amount_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  assetType?: InputMaybe<AssetType>;
  assetType_in?: InputMaybe<Array<AssetType>>;
  assetType_not?: InputMaybe<AssetType>;
  assetType_not_in?: InputMaybe<Array<AssetType>>;
  event?: InputMaybe<Scalars["String"]>;
  event_?: InputMaybe<JoinPoolEvent_Filter>;
  event_contains?: InputMaybe<Scalars["String"]>;
  event_contains_nocase?: InputMaybe<Scalars["String"]>;
  event_ends_with?: InputMaybe<Scalars["String"]>;
  event_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  event_gt?: InputMaybe<Scalars["String"]>;
  event_gte?: InputMaybe<Scalars["String"]>;
  event_in?: InputMaybe<Array<Scalars["String"]>>;
  event_lt?: InputMaybe<Scalars["String"]>;
  event_lte?: InputMaybe<Scalars["String"]>;
  event_not?: InputMaybe<Scalars["String"]>;
  event_not_contains?: InputMaybe<Scalars["String"]>;
  event_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  event_not_ends_with?: InputMaybe<Scalars["String"]>;
  event_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  event_not_in?: InputMaybe<Array<Scalars["String"]>>;
  event_not_starts_with?: InputMaybe<Scalars["String"]>;
  event_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  event_starts_with?: InputMaybe<Scalars["String"]>;
  event_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  id_gt?: InputMaybe<Scalars["ID"]>;
  id_gte?: InputMaybe<Scalars["ID"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]>>;
  id_lt?: InputMaybe<Scalars["ID"]>;
  id_lte?: InputMaybe<Scalars["ID"]>;
  id_not?: InputMaybe<Scalars["ID"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]>>;
  identifier?: InputMaybe<Scalars["String"]>;
  identifier_contains?: InputMaybe<Scalars["String"]>;
  identifier_contains_nocase?: InputMaybe<Scalars["String"]>;
  identifier_ends_with?: InputMaybe<Scalars["String"]>;
  identifier_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  identifier_gt?: InputMaybe<Scalars["String"]>;
  identifier_gte?: InputMaybe<Scalars["String"]>;
  identifier_in?: InputMaybe<Array<Scalars["String"]>>;
  identifier_lt?: InputMaybe<Scalars["String"]>;
  identifier_lte?: InputMaybe<Scalars["String"]>;
  identifier_not?: InputMaybe<Scalars["String"]>;
  identifier_not_contains?: InputMaybe<Scalars["String"]>;
  identifier_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  identifier_not_ends_with?: InputMaybe<Scalars["String"]>;
  identifier_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  identifier_not_in?: InputMaybe<Array<Scalars["String"]>>;
  identifier_not_starts_with?: InputMaybe<Scalars["String"]>;
  identifier_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  identifier_starts_with?: InputMaybe<Scalars["String"]>;
  identifier_starts_with_nocase?: InputMaybe<Scalars["String"]>;
};

export enum JoinPoolEventAsset_OrderBy {
  Amount = "amount",
  AssetType = "assetType",
  Event = "event",
  Id = "id",
  Identifier = "identifier",
}

export type JoinPoolEvent_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  blockHeight?: InputMaybe<Scalars["BigInt"]>;
  blockHeight_gt?: InputMaybe<Scalars["BigInt"]>;
  blockHeight_gte?: InputMaybe<Scalars["BigInt"]>;
  blockHeight_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  blockHeight_lt?: InputMaybe<Scalars["BigInt"]>;
  blockHeight_lte?: InputMaybe<Scalars["BigInt"]>;
  blockHeight_not?: InputMaybe<Scalars["BigInt"]>;
  blockHeight_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  blockTime?: InputMaybe<Scalars["BigInt"]>;
  blockTime_gt?: InputMaybe<Scalars["BigInt"]>;
  blockTime_gte?: InputMaybe<Scalars["BigInt"]>;
  blockTime_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  blockTime_lt?: InputMaybe<Scalars["BigInt"]>;
  blockTime_lte?: InputMaybe<Scalars["BigInt"]>;
  blockTime_not?: InputMaybe<Scalars["BigInt"]>;
  blockTime_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  id?: InputMaybe<Scalars["ID"]>;
  id_gt?: InputMaybe<Scalars["ID"]>;
  id_gte?: InputMaybe<Scalars["ID"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]>>;
  id_lt?: InputMaybe<Scalars["ID"]>;
  id_lte?: InputMaybe<Scalars["ID"]>;
  id_not?: InputMaybe<Scalars["ID"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]>>;
  lpTokensMinted?: InputMaybe<Scalars["BigInt"]>;
  lpTokensMinted_gt?: InputMaybe<Scalars["BigInt"]>;
  lpTokensMinted_gte?: InputMaybe<Scalars["BigInt"]>;
  lpTokensMinted_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  lpTokensMinted_lt?: InputMaybe<Scalars["BigInt"]>;
  lpTokensMinted_lte?: InputMaybe<Scalars["BigInt"]>;
  lpTokensMinted_not?: InputMaybe<Scalars["BigInt"]>;
  lpTokensMinted_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  poolAddress?: InputMaybe<Scalars["String"]>;
  poolAddress_contains?: InputMaybe<Scalars["String"]>;
  poolAddress_contains_nocase?: InputMaybe<Scalars["String"]>;
  poolAddress_ends_with?: InputMaybe<Scalars["String"]>;
  poolAddress_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  poolAddress_gt?: InputMaybe<Scalars["String"]>;
  poolAddress_gte?: InputMaybe<Scalars["String"]>;
  poolAddress_in?: InputMaybe<Array<Scalars["String"]>>;
  poolAddress_lt?: InputMaybe<Scalars["String"]>;
  poolAddress_lte?: InputMaybe<Scalars["String"]>;
  poolAddress_not?: InputMaybe<Scalars["String"]>;
  poolAddress_not_contains?: InputMaybe<Scalars["String"]>;
  poolAddress_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  poolAddress_not_ends_with?: InputMaybe<Scalars["String"]>;
  poolAddress_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  poolAddress_not_in?: InputMaybe<Array<Scalars["String"]>>;
  poolAddress_not_starts_with?: InputMaybe<Scalars["String"]>;
  poolAddress_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  poolAddress_starts_with?: InputMaybe<Scalars["String"]>;
  poolAddress_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  poolId?: InputMaybe<Scalars["Int"]>;
  poolId_gt?: InputMaybe<Scalars["Int"]>;
  poolId_gte?: InputMaybe<Scalars["Int"]>;
  poolId_in?: InputMaybe<Array<Scalars["Int"]>>;
  poolId_lt?: InputMaybe<Scalars["Int"]>;
  poolId_lte?: InputMaybe<Scalars["Int"]>;
  poolId_not?: InputMaybe<Scalars["Int"]>;
  poolId_not_in?: InputMaybe<Array<Scalars["Int"]>>;
  recipient?: InputMaybe<Scalars["String"]>;
  recipient_contains?: InputMaybe<Scalars["String"]>;
  recipient_contains_nocase?: InputMaybe<Scalars["String"]>;
  recipient_ends_with?: InputMaybe<Scalars["String"]>;
  recipient_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  recipient_gt?: InputMaybe<Scalars["String"]>;
  recipient_gte?: InputMaybe<Scalars["String"]>;
  recipient_in?: InputMaybe<Array<Scalars["String"]>>;
  recipient_lt?: InputMaybe<Scalars["String"]>;
  recipient_lte?: InputMaybe<Scalars["String"]>;
  recipient_not?: InputMaybe<Scalars["String"]>;
  recipient_not_contains?: InputMaybe<Scalars["String"]>;
  recipient_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  recipient_not_ends_with?: InputMaybe<Scalars["String"]>;
  recipient_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  recipient_not_in?: InputMaybe<Array<Scalars["String"]>>;
  recipient_not_starts_with?: InputMaybe<Scalars["String"]>;
  recipient_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  recipient_starts_with?: InputMaybe<Scalars["String"]>;
  recipient_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  vaultContractAddress?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_contains?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_contains_nocase?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_ends_with?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_gt?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_gte?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_in?: InputMaybe<Array<Scalars["String"]>>;
  vaultContractAddress_lt?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_lte?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_not?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_not_contains?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_not_ends_with?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_not_in?: InputMaybe<Array<Scalars["String"]>>;
  vaultContractAddress_not_starts_with?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_starts_with?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_starts_with_nocase?: InputMaybe<Scalars["String"]>;
};

export enum JoinPoolEvent_OrderBy {
  BlockHeight = "blockHeight",
  BlockTime = "blockTime",
  Id = "id",
  LpTokensMinted = "lpTokensMinted",
  PoolAddress = "poolAddress",
  PoolId = "poolId",
  Recipient = "recipient",
  VaultContractAddress = "vaultContractAddress",
}

export enum Network {
  Devnet = "DEVNET",
  Mainnet = "MAINNET",
  Tesnet = "TESNET",
}

/** Defines the order direction, either ascending or descending */
export enum OrderDirection {
  Asc = "asc",
  Desc = "desc",
}

export type Pool = {
  creationBlockHeight: Scalars["BigInt"];
  developerFeePercent: Scalars["Int"];
  id: Scalars["ID"];
  lastLiquidityUpdateHeight: Scalars["BigInt"];
  lpTokenContractAddress: Scalars["String"];
  lpTokenName: Scalars["String"];
  lpTokenSymbol: Scalars["String"];
  lpTokensInSupply: Scalars["BigInt"];
  poolAssets: Array<PoolAsset>;
  poolContractAddress: Scalars["String"];
  poolId: Scalars["Int"];
  poolType: PoolType;
  volume7d: number;
  volume24h: number;
  fee7d: number;
  apr7d: number;
  tokensList: Array<string>;
  protocolFeePercent: Scalars["Int"];
  totalFeeBps: Scalars["Int"];
  vaultContractAddress: Scalars["String"];
};

export type PoolPoolAssetsArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<PoolAsset_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<PoolAsset_Filter>;
};

export type PoolAsset = {
  __typename?: "PoolAsset";
  amount: Scalars["BigInt"];
  assetType: AssetType;
  id: Scalars["ID"];
  identifier: Scalars["String"];
  pool: Pool;
};

export type PoolAsset_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  amount?: InputMaybe<Scalars["BigInt"]>;
  amount_gt?: InputMaybe<Scalars["BigInt"]>;
  amount_gte?: InputMaybe<Scalars["BigInt"]>;
  amount_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  amount_lt?: InputMaybe<Scalars["BigInt"]>;
  amount_lte?: InputMaybe<Scalars["BigInt"]>;
  amount_not?: InputMaybe<Scalars["BigInt"]>;
  amount_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  assetType?: InputMaybe<AssetType>;
  assetType_in?: InputMaybe<Array<AssetType>>;
  assetType_not?: InputMaybe<AssetType>;
  assetType_not_in?: InputMaybe<Array<AssetType>>;
  id?: InputMaybe<Scalars["ID"]>;
  id_gt?: InputMaybe<Scalars["ID"]>;
  id_gte?: InputMaybe<Scalars["ID"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]>>;
  id_lt?: InputMaybe<Scalars["ID"]>;
  id_lte?: InputMaybe<Scalars["ID"]>;
  id_not?: InputMaybe<Scalars["ID"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]>>;
  identifier?: InputMaybe<Scalars["String"]>;
  identifier_contains?: InputMaybe<Scalars["String"]>;
  identifier_contains_nocase?: InputMaybe<Scalars["String"]>;
  identifier_ends_with?: InputMaybe<Scalars["String"]>;
  identifier_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  identifier_gt?: InputMaybe<Scalars["String"]>;
  identifier_gte?: InputMaybe<Scalars["String"]>;
  identifier_in?: InputMaybe<Array<Scalars["String"]>>;
  identifier_lt?: InputMaybe<Scalars["String"]>;
  identifier_lte?: InputMaybe<Scalars["String"]>;
  identifier_not?: InputMaybe<Scalars["String"]>;
  identifier_not_contains?: InputMaybe<Scalars["String"]>;
  identifier_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  identifier_not_ends_with?: InputMaybe<Scalars["String"]>;
  identifier_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  identifier_not_in?: InputMaybe<Array<Scalars["String"]>>;
  identifier_not_starts_with?: InputMaybe<Scalars["String"]>;
  identifier_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  identifier_starts_with?: InputMaybe<Scalars["String"]>;
  identifier_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  pool?: InputMaybe<Scalars["String"]>;
  pool_?: InputMaybe<Pool_Filter>;
  pool_contains?: InputMaybe<Scalars["String"]>;
  pool_contains_nocase?: InputMaybe<Scalars["String"]>;
  pool_ends_with?: InputMaybe<Scalars["String"]>;
  pool_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  pool_gt?: InputMaybe<Scalars["String"]>;
  pool_gte?: InputMaybe<Scalars["String"]>;
  pool_in?: InputMaybe<Array<Scalars["String"]>>;
  pool_lt?: InputMaybe<Scalars["String"]>;
  pool_lte?: InputMaybe<Scalars["String"]>;
  pool_not?: InputMaybe<Scalars["String"]>;
  pool_not_contains?: InputMaybe<Scalars["String"]>;
  pool_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  pool_not_ends_with?: InputMaybe<Scalars["String"]>;
  pool_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  pool_not_in?: InputMaybe<Array<Scalars["String"]>>;
  pool_not_starts_with?: InputMaybe<Scalars["String"]>;
  pool_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  pool_starts_with?: InputMaybe<Scalars["String"]>;
  pool_starts_with_nocase?: InputMaybe<Scalars["String"]>;
};

export enum PoolAsset_OrderBy {
  Amount = "amount",
  AssetType = "assetType",
  Id = "id",
  Identifier = "identifier",
  Pool = "pool",
}

export type PoolSwapEvent = {
  __typename?: "PoolSwapEvent";
  askAmount: Scalars["BigInt"];
  askAsset: PoolAsset;
  blockHeight: Scalars["BigInt"];
  blockTime: Scalars["BigInt"];
  feeAsset: PoolAsset;
  id: Scalars["ID"];
  offerAmount: Scalars["BigInt"];
  offerAsset: PoolAsset;
  poolAddress: Scalars["String"];
  poolId: Scalars["Int"];
  receipient: Scalars["String"];
  sender: Scalars["String"];
  swapType: SwapType;
  totalFee: Scalars["BigInt"];
  vaultContractAddress: Scalars["String"];
};

export type PoolSwapEvent_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  askAmount?: InputMaybe<Scalars["BigInt"]>;
  askAmount_gt?: InputMaybe<Scalars["BigInt"]>;
  askAmount_gte?: InputMaybe<Scalars["BigInt"]>;
  askAmount_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  askAmount_lt?: InputMaybe<Scalars["BigInt"]>;
  askAmount_lte?: InputMaybe<Scalars["BigInt"]>;
  askAmount_not?: InputMaybe<Scalars["BigInt"]>;
  askAmount_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  askAsset?: InputMaybe<Scalars["String"]>;
  askAsset_?: InputMaybe<PoolAsset_Filter>;
  askAsset_contains?: InputMaybe<Scalars["String"]>;
  askAsset_contains_nocase?: InputMaybe<Scalars["String"]>;
  askAsset_ends_with?: InputMaybe<Scalars["String"]>;
  askAsset_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  askAsset_gt?: InputMaybe<Scalars["String"]>;
  askAsset_gte?: InputMaybe<Scalars["String"]>;
  askAsset_in?: InputMaybe<Array<Scalars["String"]>>;
  askAsset_lt?: InputMaybe<Scalars["String"]>;
  askAsset_lte?: InputMaybe<Scalars["String"]>;
  askAsset_not?: InputMaybe<Scalars["String"]>;
  askAsset_not_contains?: InputMaybe<Scalars["String"]>;
  askAsset_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  askAsset_not_ends_with?: InputMaybe<Scalars["String"]>;
  askAsset_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  askAsset_not_in?: InputMaybe<Array<Scalars["String"]>>;
  askAsset_not_starts_with?: InputMaybe<Scalars["String"]>;
  askAsset_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  askAsset_starts_with?: InputMaybe<Scalars["String"]>;
  askAsset_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  blockHeight?: InputMaybe<Scalars["BigInt"]>;
  blockHeight_gt?: InputMaybe<Scalars["BigInt"]>;
  blockHeight_gte?: InputMaybe<Scalars["BigInt"]>;
  blockHeight_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  blockHeight_lt?: InputMaybe<Scalars["BigInt"]>;
  blockHeight_lte?: InputMaybe<Scalars["BigInt"]>;
  blockHeight_not?: InputMaybe<Scalars["BigInt"]>;
  blockHeight_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  blockTime?: InputMaybe<Scalars["BigInt"]>;
  blockTime_gt?: InputMaybe<Scalars["BigInt"]>;
  blockTime_gte?: InputMaybe<Scalars["BigInt"]>;
  blockTime_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  blockTime_lt?: InputMaybe<Scalars["BigInt"]>;
  blockTime_lte?: InputMaybe<Scalars["BigInt"]>;
  blockTime_not?: InputMaybe<Scalars["BigInt"]>;
  blockTime_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  feeAsset?: InputMaybe<Scalars["String"]>;
  feeAsset_?: InputMaybe<PoolAsset_Filter>;
  feeAsset_contains?: InputMaybe<Scalars["String"]>;
  feeAsset_contains_nocase?: InputMaybe<Scalars["String"]>;
  feeAsset_ends_with?: InputMaybe<Scalars["String"]>;
  feeAsset_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  feeAsset_gt?: InputMaybe<Scalars["String"]>;
  feeAsset_gte?: InputMaybe<Scalars["String"]>;
  feeAsset_in?: InputMaybe<Array<Scalars["String"]>>;
  feeAsset_lt?: InputMaybe<Scalars["String"]>;
  feeAsset_lte?: InputMaybe<Scalars["String"]>;
  feeAsset_not?: InputMaybe<Scalars["String"]>;
  feeAsset_not_contains?: InputMaybe<Scalars["String"]>;
  feeAsset_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  feeAsset_not_ends_with?: InputMaybe<Scalars["String"]>;
  feeAsset_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  feeAsset_not_in?: InputMaybe<Array<Scalars["String"]>>;
  feeAsset_not_starts_with?: InputMaybe<Scalars["String"]>;
  feeAsset_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  feeAsset_starts_with?: InputMaybe<Scalars["String"]>;
  feeAsset_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  id_gt?: InputMaybe<Scalars["ID"]>;
  id_gte?: InputMaybe<Scalars["ID"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]>>;
  id_lt?: InputMaybe<Scalars["ID"]>;
  id_lte?: InputMaybe<Scalars["ID"]>;
  id_not?: InputMaybe<Scalars["ID"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]>>;
  offerAmount?: InputMaybe<Scalars["BigInt"]>;
  offerAmount_gt?: InputMaybe<Scalars["BigInt"]>;
  offerAmount_gte?: InputMaybe<Scalars["BigInt"]>;
  offerAmount_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  offerAmount_lt?: InputMaybe<Scalars["BigInt"]>;
  offerAmount_lte?: InputMaybe<Scalars["BigInt"]>;
  offerAmount_not?: InputMaybe<Scalars["BigInt"]>;
  offerAmount_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  offerAsset?: InputMaybe<Scalars["String"]>;
  offerAsset_?: InputMaybe<PoolAsset_Filter>;
  offerAsset_contains?: InputMaybe<Scalars["String"]>;
  offerAsset_contains_nocase?: InputMaybe<Scalars["String"]>;
  offerAsset_ends_with?: InputMaybe<Scalars["String"]>;
  offerAsset_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  offerAsset_gt?: InputMaybe<Scalars["String"]>;
  offerAsset_gte?: InputMaybe<Scalars["String"]>;
  offerAsset_in?: InputMaybe<Array<Scalars["String"]>>;
  offerAsset_lt?: InputMaybe<Scalars["String"]>;
  offerAsset_lte?: InputMaybe<Scalars["String"]>;
  offerAsset_not?: InputMaybe<Scalars["String"]>;
  offerAsset_not_contains?: InputMaybe<Scalars["String"]>;
  offerAsset_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  offerAsset_not_ends_with?: InputMaybe<Scalars["String"]>;
  offerAsset_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  offerAsset_not_in?: InputMaybe<Array<Scalars["String"]>>;
  offerAsset_not_starts_with?: InputMaybe<Scalars["String"]>;
  offerAsset_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  offerAsset_starts_with?: InputMaybe<Scalars["String"]>;
  offerAsset_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  poolAddress?: InputMaybe<Scalars["String"]>;
  poolAddress_contains?: InputMaybe<Scalars["String"]>;
  poolAddress_contains_nocase?: InputMaybe<Scalars["String"]>;
  poolAddress_ends_with?: InputMaybe<Scalars["String"]>;
  poolAddress_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  poolAddress_gt?: InputMaybe<Scalars["String"]>;
  poolAddress_gte?: InputMaybe<Scalars["String"]>;
  poolAddress_in?: InputMaybe<Array<Scalars["String"]>>;
  poolAddress_lt?: InputMaybe<Scalars["String"]>;
  poolAddress_lte?: InputMaybe<Scalars["String"]>;
  poolAddress_not?: InputMaybe<Scalars["String"]>;
  poolAddress_not_contains?: InputMaybe<Scalars["String"]>;
  poolAddress_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  poolAddress_not_ends_with?: InputMaybe<Scalars["String"]>;
  poolAddress_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  poolAddress_not_in?: InputMaybe<Array<Scalars["String"]>>;
  poolAddress_not_starts_with?: InputMaybe<Scalars["String"]>;
  poolAddress_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  poolAddress_starts_with?: InputMaybe<Scalars["String"]>;
  poolAddress_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  poolId?: InputMaybe<Scalars["Int"]>;
  poolId_gt?: InputMaybe<Scalars["Int"]>;
  poolId_gte?: InputMaybe<Scalars["Int"]>;
  poolId_in?: InputMaybe<Array<Scalars["Int"]>>;
  poolId_lt?: InputMaybe<Scalars["Int"]>;
  poolId_lte?: InputMaybe<Scalars["Int"]>;
  poolId_not?: InputMaybe<Scalars["Int"]>;
  poolId_not_in?: InputMaybe<Array<Scalars["Int"]>>;
  receipient?: InputMaybe<Scalars["String"]>;
  receipient_contains?: InputMaybe<Scalars["String"]>;
  receipient_contains_nocase?: InputMaybe<Scalars["String"]>;
  receipient_ends_with?: InputMaybe<Scalars["String"]>;
  receipient_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  receipient_gt?: InputMaybe<Scalars["String"]>;
  receipient_gte?: InputMaybe<Scalars["String"]>;
  receipient_in?: InputMaybe<Array<Scalars["String"]>>;
  receipient_lt?: InputMaybe<Scalars["String"]>;
  receipient_lte?: InputMaybe<Scalars["String"]>;
  receipient_not?: InputMaybe<Scalars["String"]>;
  receipient_not_contains?: InputMaybe<Scalars["String"]>;
  receipient_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  receipient_not_ends_with?: InputMaybe<Scalars["String"]>;
  receipient_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  receipient_not_in?: InputMaybe<Array<Scalars["String"]>>;
  receipient_not_starts_with?: InputMaybe<Scalars["String"]>;
  receipient_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  receipient_starts_with?: InputMaybe<Scalars["String"]>;
  receipient_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  sender?: InputMaybe<Scalars["String"]>;
  sender_contains?: InputMaybe<Scalars["String"]>;
  sender_contains_nocase?: InputMaybe<Scalars["String"]>;
  sender_ends_with?: InputMaybe<Scalars["String"]>;
  sender_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  sender_gt?: InputMaybe<Scalars["String"]>;
  sender_gte?: InputMaybe<Scalars["String"]>;
  sender_in?: InputMaybe<Array<Scalars["String"]>>;
  sender_lt?: InputMaybe<Scalars["String"]>;
  sender_lte?: InputMaybe<Scalars["String"]>;
  sender_not?: InputMaybe<Scalars["String"]>;
  sender_not_contains?: InputMaybe<Scalars["String"]>;
  sender_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  sender_not_ends_with?: InputMaybe<Scalars["String"]>;
  sender_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  sender_not_in?: InputMaybe<Array<Scalars["String"]>>;
  sender_not_starts_with?: InputMaybe<Scalars["String"]>;
  sender_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  sender_starts_with?: InputMaybe<Scalars["String"]>;
  sender_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  swapType?: InputMaybe<SwapType>;
  swapType_in?: InputMaybe<Array<SwapType>>;
  swapType_not?: InputMaybe<SwapType>;
  swapType_not_in?: InputMaybe<Array<SwapType>>;
  totalFee?: InputMaybe<Scalars["BigInt"]>;
  totalFee_gt?: InputMaybe<Scalars["BigInt"]>;
  totalFee_gte?: InputMaybe<Scalars["BigInt"]>;
  totalFee_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  totalFee_lt?: InputMaybe<Scalars["BigInt"]>;
  totalFee_lte?: InputMaybe<Scalars["BigInt"]>;
  totalFee_not?: InputMaybe<Scalars["BigInt"]>;
  totalFee_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  vaultContractAddress?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_contains?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_contains_nocase?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_ends_with?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_gt?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_gte?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_in?: InputMaybe<Array<Scalars["String"]>>;
  vaultContractAddress_lt?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_lte?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_not?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_not_contains?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_not_ends_with?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_not_in?: InputMaybe<Array<Scalars["String"]>>;
  vaultContractAddress_not_starts_with?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_starts_with?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_starts_with_nocase?: InputMaybe<Scalars["String"]>;
};

export enum PoolSwapEvent_OrderBy {
  AskAmount = "askAmount",
  AskAsset = "askAsset",
  BlockHeight = "blockHeight",
  BlockTime = "blockTime",
  FeeAsset = "feeAsset",
  Id = "id",
  OfferAmount = "offerAmount",
  OfferAsset = "offerAsset",
  PoolAddress = "poolAddress",
  PoolId = "poolId",
  Receipient = "receipient",
  Sender = "sender",
  SwapType = "swapType",
  TotalFee = "totalFee",
  VaultContractAddress = "vaultContractAddress",
}

export type PoolTempData = {
  __typename?: "PoolTempData";
  id: Scalars["ID"];
  lpTokenContractAddress?: Maybe<Scalars["String"]>;
  poolContractAddress?: Maybe<Scalars["String"]>;
  poolId: Scalars["Int"];
};

export type PoolTempData_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  id?: InputMaybe<Scalars["ID"]>;
  id_gt?: InputMaybe<Scalars["ID"]>;
  id_gte?: InputMaybe<Scalars["ID"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]>>;
  id_lt?: InputMaybe<Scalars["ID"]>;
  id_lte?: InputMaybe<Scalars["ID"]>;
  id_not?: InputMaybe<Scalars["ID"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]>>;
  lpTokenContractAddress?: InputMaybe<Scalars["String"]>;
  lpTokenContractAddress_contains?: InputMaybe<Scalars["String"]>;
  lpTokenContractAddress_contains_nocase?: InputMaybe<Scalars["String"]>;
  lpTokenContractAddress_ends_with?: InputMaybe<Scalars["String"]>;
  lpTokenContractAddress_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  lpTokenContractAddress_gt?: InputMaybe<Scalars["String"]>;
  lpTokenContractAddress_gte?: InputMaybe<Scalars["String"]>;
  lpTokenContractAddress_in?: InputMaybe<Array<Scalars["String"]>>;
  lpTokenContractAddress_lt?: InputMaybe<Scalars["String"]>;
  lpTokenContractAddress_lte?: InputMaybe<Scalars["String"]>;
  lpTokenContractAddress_not?: InputMaybe<Scalars["String"]>;
  lpTokenContractAddress_not_contains?: InputMaybe<Scalars["String"]>;
  lpTokenContractAddress_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  lpTokenContractAddress_not_ends_with?: InputMaybe<Scalars["String"]>;
  lpTokenContractAddress_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  lpTokenContractAddress_not_in?: InputMaybe<Array<Scalars["String"]>>;
  lpTokenContractAddress_not_starts_with?: InputMaybe<Scalars["String"]>;
  lpTokenContractAddress_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  lpTokenContractAddress_starts_with?: InputMaybe<Scalars["String"]>;
  lpTokenContractAddress_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  poolContractAddress?: InputMaybe<Scalars["String"]>;
  poolContractAddress_contains?: InputMaybe<Scalars["String"]>;
  poolContractAddress_contains_nocase?: InputMaybe<Scalars["String"]>;
  poolContractAddress_ends_with?: InputMaybe<Scalars["String"]>;
  poolContractAddress_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  poolContractAddress_gt?: InputMaybe<Scalars["String"]>;
  poolContractAddress_gte?: InputMaybe<Scalars["String"]>;
  poolContractAddress_in?: InputMaybe<Array<Scalars["String"]>>;
  poolContractAddress_lt?: InputMaybe<Scalars["String"]>;
  poolContractAddress_lte?: InputMaybe<Scalars["String"]>;
  poolContractAddress_not?: InputMaybe<Scalars["String"]>;
  poolContractAddress_not_contains?: InputMaybe<Scalars["String"]>;
  poolContractAddress_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  poolContractAddress_not_ends_with?: InputMaybe<Scalars["String"]>;
  poolContractAddress_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  poolContractAddress_not_in?: InputMaybe<Array<Scalars["String"]>>;
  poolContractAddress_not_starts_with?: InputMaybe<Scalars["String"]>;
  poolContractAddress_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  poolContractAddress_starts_with?: InputMaybe<Scalars["String"]>;
  poolContractAddress_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  poolId?: InputMaybe<Scalars["Int"]>;
  poolId_gt?: InputMaybe<Scalars["Int"]>;
  poolId_gte?: InputMaybe<Scalars["Int"]>;
  poolId_in?: InputMaybe<Array<Scalars["Int"]>>;
  poolId_lt?: InputMaybe<Scalars["Int"]>;
  poolId_lte?: InputMaybe<Scalars["Int"]>;
  poolId_not?: InputMaybe<Scalars["Int"]>;
  poolId_not_in?: InputMaybe<Array<Scalars["Int"]>>;
};

export enum PoolTempData_OrderBy {
  Id = "id",
  LpTokenContractAddress = "lpTokenContractAddress",
  PoolContractAddress = "poolContractAddress",
  PoolId = "poolId",
}

export enum PoolType {
  Stable2pool = "stable2pool",
  Stable3pool = "stable3pool",
  Stable5pool = "stable5pool",
  Weighted = "weighted",
  Xyk = "xyk",
}

export type Pool_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  creationBlockHeight?: InputMaybe<Scalars["BigInt"]>;
  creationBlockHeight_gt?: InputMaybe<Scalars["BigInt"]>;
  creationBlockHeight_gte?: InputMaybe<Scalars["BigInt"]>;
  creationBlockHeight_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  creationBlockHeight_lt?: InputMaybe<Scalars["BigInt"]>;
  creationBlockHeight_lte?: InputMaybe<Scalars["BigInt"]>;
  creationBlockHeight_not?: InputMaybe<Scalars["BigInt"]>;
  creationBlockHeight_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  developerFeePercent?: InputMaybe<Scalars["Int"]>;
  developerFeePercent_gt?: InputMaybe<Scalars["Int"]>;
  developerFeePercent_gte?: InputMaybe<Scalars["Int"]>;
  developerFeePercent_in?: InputMaybe<Array<Scalars["Int"]>>;
  developerFeePercent_lt?: InputMaybe<Scalars["Int"]>;
  developerFeePercent_lte?: InputMaybe<Scalars["Int"]>;
  developerFeePercent_not?: InputMaybe<Scalars["Int"]>;
  developerFeePercent_not_in?: InputMaybe<Array<Scalars["Int"]>>;
  id?: InputMaybe<Scalars["ID"]>;
  id_gt?: InputMaybe<Scalars["ID"]>;
  id_gte?: InputMaybe<Scalars["ID"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]>>;
  id_lt?: InputMaybe<Scalars["ID"]>;
  id_lte?: InputMaybe<Scalars["ID"]>;
  id_not?: InputMaybe<Scalars["ID"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]>>;
  lastLiquidityUpdateHeight?: InputMaybe<Scalars["BigInt"]>;
  lastLiquidityUpdateHeight_gt?: InputMaybe<Scalars["BigInt"]>;
  lastLiquidityUpdateHeight_gte?: InputMaybe<Scalars["BigInt"]>;
  lastLiquidityUpdateHeight_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  lastLiquidityUpdateHeight_lt?: InputMaybe<Scalars["BigInt"]>;
  lastLiquidityUpdateHeight_lte?: InputMaybe<Scalars["BigInt"]>;
  lastLiquidityUpdateHeight_not?: InputMaybe<Scalars["BigInt"]>;
  lastLiquidityUpdateHeight_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  lpTokenContractAddress?: InputMaybe<Scalars["String"]>;
  lpTokenContractAddress_contains?: InputMaybe<Scalars["String"]>;
  lpTokenContractAddress_contains_nocase?: InputMaybe<Scalars["String"]>;
  lpTokenContractAddress_ends_with?: InputMaybe<Scalars["String"]>;
  lpTokenContractAddress_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  lpTokenContractAddress_gt?: InputMaybe<Scalars["String"]>;
  lpTokenContractAddress_gte?: InputMaybe<Scalars["String"]>;
  lpTokenContractAddress_in?: InputMaybe<Array<Scalars["String"]>>;
  lpTokenContractAddress_lt?: InputMaybe<Scalars["String"]>;
  lpTokenContractAddress_lte?: InputMaybe<Scalars["String"]>;
  lpTokenContractAddress_not?: InputMaybe<Scalars["String"]>;
  lpTokenContractAddress_not_contains?: InputMaybe<Scalars["String"]>;
  lpTokenContractAddress_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  lpTokenContractAddress_not_ends_with?: InputMaybe<Scalars["String"]>;
  lpTokenContractAddress_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  lpTokenContractAddress_not_in?: InputMaybe<Array<Scalars["String"]>>;
  lpTokenContractAddress_not_starts_with?: InputMaybe<Scalars["String"]>;
  lpTokenContractAddress_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  lpTokenContractAddress_starts_with?: InputMaybe<Scalars["String"]>;
  lpTokenContractAddress_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  lpTokenName?: InputMaybe<Scalars["String"]>;
  lpTokenName_contains?: InputMaybe<Scalars["String"]>;
  lpTokenName_contains_nocase?: InputMaybe<Scalars["String"]>;
  lpTokenName_ends_with?: InputMaybe<Scalars["String"]>;
  lpTokenName_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  lpTokenName_gt?: InputMaybe<Scalars["String"]>;
  lpTokenName_gte?: InputMaybe<Scalars["String"]>;
  lpTokenName_in?: InputMaybe<Array<Scalars["String"]>>;
  lpTokenName_lt?: InputMaybe<Scalars["String"]>;
  lpTokenName_lte?: InputMaybe<Scalars["String"]>;
  lpTokenName_not?: InputMaybe<Scalars["String"]>;
  lpTokenName_not_contains?: InputMaybe<Scalars["String"]>;
  lpTokenName_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  lpTokenName_not_ends_with?: InputMaybe<Scalars["String"]>;
  lpTokenName_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  lpTokenName_not_in?: InputMaybe<Array<Scalars["String"]>>;
  lpTokenName_not_starts_with?: InputMaybe<Scalars["String"]>;
  lpTokenName_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  lpTokenName_starts_with?: InputMaybe<Scalars["String"]>;
  lpTokenName_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  lpTokenSymbol?: InputMaybe<Scalars["String"]>;
  lpTokenSymbol_contains?: InputMaybe<Scalars["String"]>;
  lpTokenSymbol_contains_nocase?: InputMaybe<Scalars["String"]>;
  lpTokenSymbol_ends_with?: InputMaybe<Scalars["String"]>;
  lpTokenSymbol_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  lpTokenSymbol_gt?: InputMaybe<Scalars["String"]>;
  lpTokenSymbol_gte?: InputMaybe<Scalars["String"]>;
  lpTokenSymbol_in?: InputMaybe<Array<Scalars["String"]>>;
  lpTokenSymbol_lt?: InputMaybe<Scalars["String"]>;
  lpTokenSymbol_lte?: InputMaybe<Scalars["String"]>;
  lpTokenSymbol_not?: InputMaybe<Scalars["String"]>;
  lpTokenSymbol_not_contains?: InputMaybe<Scalars["String"]>;
  lpTokenSymbol_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  lpTokenSymbol_not_ends_with?: InputMaybe<Scalars["String"]>;
  lpTokenSymbol_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  lpTokenSymbol_not_in?: InputMaybe<Array<Scalars["String"]>>;
  lpTokenSymbol_not_starts_with?: InputMaybe<Scalars["String"]>;
  lpTokenSymbol_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  lpTokenSymbol_starts_with?: InputMaybe<Scalars["String"]>;
  lpTokenSymbol_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  lpTokensInSupply?: InputMaybe<Scalars["BigInt"]>;
  lpTokensInSupply_gt?: InputMaybe<Scalars["BigInt"]>;
  lpTokensInSupply_gte?: InputMaybe<Scalars["BigInt"]>;
  lpTokensInSupply_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  lpTokensInSupply_lt?: InputMaybe<Scalars["BigInt"]>;
  lpTokensInSupply_lte?: InputMaybe<Scalars["BigInt"]>;
  lpTokensInSupply_not?: InputMaybe<Scalars["BigInt"]>;
  lpTokensInSupply_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  poolAssets_?: InputMaybe<PoolAsset_Filter>;
  poolContractAddress?: InputMaybe<Scalars["String"]>;
  poolContractAddress_contains?: InputMaybe<Scalars["String"]>;
  poolContractAddress_contains_nocase?: InputMaybe<Scalars["String"]>;
  poolContractAddress_ends_with?: InputMaybe<Scalars["String"]>;
  poolContractAddress_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  poolContractAddress_gt?: InputMaybe<Scalars["String"]>;
  poolContractAddress_gte?: InputMaybe<Scalars["String"]>;
  poolContractAddress_in?: InputMaybe<Array<Scalars["String"]>>;
  poolContractAddress_lt?: InputMaybe<Scalars["String"]>;
  poolContractAddress_lte?: InputMaybe<Scalars["String"]>;
  poolContractAddress_not?: InputMaybe<Scalars["String"]>;
  poolContractAddress_not_contains?: InputMaybe<Scalars["String"]>;
  poolContractAddress_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  poolContractAddress_not_ends_with?: InputMaybe<Scalars["String"]>;
  poolContractAddress_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  poolContractAddress_not_in?: InputMaybe<Array<Scalars["String"]>>;
  poolContractAddress_not_starts_with?: InputMaybe<Scalars["String"]>;
  poolContractAddress_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  poolContractAddress_starts_with?: InputMaybe<Scalars["String"]>;
  poolContractAddress_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  poolId?: InputMaybe<Scalars["Int"]>;
  poolId_gt?: InputMaybe<Scalars["Int"]>;
  poolId_gte?: InputMaybe<Scalars["Int"]>;
  poolId_in?: InputMaybe<Array<Scalars["Int"]>>;
  poolId_lt?: InputMaybe<Scalars["Int"]>;
  poolId_lte?: InputMaybe<Scalars["Int"]>;
  poolId_not?: InputMaybe<Scalars["Int"]>;
  poolId_not_in?: InputMaybe<Array<Scalars["Int"]>>;
  poolType?: InputMaybe<PoolType>;
  poolType_in?: InputMaybe<Array<PoolType>>;
  poolType_not?: InputMaybe<PoolType>;
  poolType_not_in?: InputMaybe<Array<PoolType>>;
  protocolFeePercent?: InputMaybe<Scalars["Int"]>;
  protocolFeePercent_gt?: InputMaybe<Scalars["Int"]>;
  protocolFeePercent_gte?: InputMaybe<Scalars["Int"]>;
  protocolFeePercent_in?: InputMaybe<Array<Scalars["Int"]>>;
  protocolFeePercent_lt?: InputMaybe<Scalars["Int"]>;
  protocolFeePercent_lte?: InputMaybe<Scalars["Int"]>;
  protocolFeePercent_not?: InputMaybe<Scalars["Int"]>;
  protocolFeePercent_not_in?: InputMaybe<Array<Scalars["Int"]>>;
  totalFeeBps?: InputMaybe<Scalars["Int"]>;
  totalFeeBps_gt?: InputMaybe<Scalars["Int"]>;
  totalFeeBps_gte?: InputMaybe<Scalars["Int"]>;
  totalFeeBps_in?: InputMaybe<Array<Scalars["Int"]>>;
  totalFeeBps_lt?: InputMaybe<Scalars["Int"]>;
  totalFeeBps_lte?: InputMaybe<Scalars["Int"]>;
  totalFeeBps_not?: InputMaybe<Scalars["Int"]>;
  totalFeeBps_not_in?: InputMaybe<Array<Scalars["Int"]>>;
  vaultContractAddress?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_contains?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_contains_nocase?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_ends_with?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_gt?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_gte?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_in?: InputMaybe<Array<Scalars["String"]>>;
  vaultContractAddress_lt?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_lte?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_not?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_not_contains?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_not_ends_with?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_not_in?: InputMaybe<Array<Scalars["String"]>>;
  vaultContractAddress_not_starts_with?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_starts_with?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_starts_with_nocase?: InputMaybe<Scalars["String"]>;
};

export enum Pool_OrderBy {
  CreationBlockHeight = "creationBlockHeight",
  DeveloperFeePercent = "developerFeePercent",
  Id = "id",
  LastLiquidityUpdateHeight = "lastLiquidityUpdateHeight",
  LpTokenContractAddress = "lpTokenContractAddress",
  LpTokenName = "lpTokenName",
  LpTokenSymbol = "lpTokenSymbol",
  LpTokensInSupply = "lpTokensInSupply",
  PoolAssets = "poolAssets",
  PoolContractAddress = "poolContractAddress",
  PoolId = "poolId",
  PoolType = "poolType",
  ProtocolFeePercent = "protocolFeePercent",
  TotalFeeBps = "totalFeeBps",
  VaultContractAddress = "vaultContractAddress",
}

export type Query = {
  __typename?: "Query";
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
  addPoolEvent?: Maybe<AddPoolEvent>;
  addPoolEventAsset?: Maybe<AddPoolEventAsset>;
  addPoolEventAssets: Array<AddPoolEventAsset>;
  addPoolEvents: Array<AddPoolEvent>;
  block?: Maybe<Block>;
  blocks: Array<Block>;
  exitPoolEvent?: Maybe<ExitPoolEvent>;
  exitPoolEventRemovedAssets: Array<ExitPoolEventRemovedAssets>;
  exitPoolEvents: Array<ExitPoolEvent>;
  joinPoolEvent?: Maybe<JoinPoolEvent>;
  joinPoolEventAsset?: Maybe<JoinPoolEventAsset>;
  joinPoolEventAssets: Array<JoinPoolEventAsset>;
  joinPoolEvents: Array<JoinPoolEvent>;
  pool?: Maybe<Pool>;
  poolAsset?: Maybe<PoolAsset>;
  poolAssets: Array<PoolAsset>;
  poolSwapEvent?: Maybe<PoolSwapEvent>;
  poolSwapEvents: Array<PoolSwapEvent>;
  poolTempData?: Maybe<PoolTempData>;
  poolTempDatas: Array<PoolTempData>;
  pools: Array<Pool>;
  updateLiquidityEvent?: Maybe<UpdateLiquidityEvent>;
  updateLiquidityEventAsset?: Maybe<UpdateLiquidityEventAsset>;
  updateLiquidityEventAssets: Array<UpdateLiquidityEventAsset>;
  updateLiquidityEvents: Array<UpdateLiquidityEvent>;
};

export type Query_MetaArgs = {
  block?: InputMaybe<Block_Height>;
};

export type QueryAddPoolEventArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryAddPoolEventAssetArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryAddPoolEventAssetsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<AddPoolEventAsset_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<AddPoolEventAsset_Filter>;
};

export type QueryAddPoolEventsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<AddPoolEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<AddPoolEvent_Filter>;
};

export type QueryBlockArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryBlocksArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Block_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Block_Filter>;
};

export type QueryExitPoolEventArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryExitPoolEventRemovedAssetsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<ExitPoolEventRemovedAssets_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<ExitPoolEventRemovedAssets_Filter>;
};

export type QueryExitPoolEventsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<ExitPoolEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<ExitPoolEvent_Filter>;
};

export type QueryJoinPoolEventArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryJoinPoolEventAssetArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryJoinPoolEventAssetsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<JoinPoolEventAsset_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<JoinPoolEventAsset_Filter>;
};

export type QueryJoinPoolEventsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<JoinPoolEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<JoinPoolEvent_Filter>;
};

export type QueryPoolArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryPoolAssetArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryPoolAssetsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<PoolAsset_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PoolAsset_Filter>;
};

export type QueryPoolSwapEventArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryPoolSwapEventsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<PoolSwapEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PoolSwapEvent_Filter>;
};

export type QueryPoolTempDataArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryPoolTempDatasArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<PoolTempData_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PoolTempData_Filter>;
};

export type QueryPoolsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Pool_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Pool_Filter>;
};

export type QueryUpdateLiquidityEventArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryUpdateLiquidityEventAssetArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryUpdateLiquidityEventAssetsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<UpdateLiquidityEventAsset_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<UpdateLiquidityEventAsset_Filter>;
};

export type QueryUpdateLiquidityEventsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<UpdateLiquidityEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<UpdateLiquidityEvent_Filter>;
};

export type Subscription = {
  __typename?: "Subscription";
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
  addPoolEvent?: Maybe<AddPoolEvent>;
  addPoolEventAsset?: Maybe<AddPoolEventAsset>;
  addPoolEventAssets: Array<AddPoolEventAsset>;
  addPoolEvents: Array<AddPoolEvent>;
  block?: Maybe<Block>;
  blocks: Array<Block>;
  exitPoolEvent?: Maybe<ExitPoolEvent>;
  exitPoolEventRemovedAssets: Array<ExitPoolEventRemovedAssets>;
  exitPoolEvents: Array<ExitPoolEvent>;
  joinPoolEvent?: Maybe<JoinPoolEvent>;
  joinPoolEventAsset?: Maybe<JoinPoolEventAsset>;
  joinPoolEventAssets: Array<JoinPoolEventAsset>;
  joinPoolEvents: Array<JoinPoolEvent>;
  pool?: Maybe<Pool>;
  poolAsset?: Maybe<PoolAsset>;
  poolAssets: Array<PoolAsset>;
  poolSwapEvent?: Maybe<PoolSwapEvent>;
  poolSwapEvents: Array<PoolSwapEvent>;
  poolTempData?: Maybe<PoolTempData>;
  poolTempDatas: Array<PoolTempData>;
  pools: Array<Pool>;
  updateLiquidityEvent?: Maybe<UpdateLiquidityEvent>;
  updateLiquidityEventAsset?: Maybe<UpdateLiquidityEventAsset>;
  updateLiquidityEventAssets: Array<UpdateLiquidityEventAsset>;
  updateLiquidityEvents: Array<UpdateLiquidityEvent>;
};

export type Subscription_MetaArgs = {
  block?: InputMaybe<Block_Height>;
};

export type SubscriptionAddPoolEventArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionAddPoolEventAssetArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionAddPoolEventAssetsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<AddPoolEventAsset_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<AddPoolEventAsset_Filter>;
};

export type SubscriptionAddPoolEventsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<AddPoolEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<AddPoolEvent_Filter>;
};

export type SubscriptionBlockArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionBlocksArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Block_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Block_Filter>;
};

export type SubscriptionExitPoolEventArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionExitPoolEventRemovedAssetsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<ExitPoolEventRemovedAssets_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<ExitPoolEventRemovedAssets_Filter>;
};

export type SubscriptionExitPoolEventsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<ExitPoolEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<ExitPoolEvent_Filter>;
};

export type SubscriptionJoinPoolEventArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionJoinPoolEventAssetArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionJoinPoolEventAssetsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<JoinPoolEventAsset_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<JoinPoolEventAsset_Filter>;
};

export type SubscriptionJoinPoolEventsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<JoinPoolEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<JoinPoolEvent_Filter>;
};

export type SubscriptionPoolArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionPoolAssetArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionPoolAssetsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<PoolAsset_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PoolAsset_Filter>;
};

export type SubscriptionPoolSwapEventArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionPoolSwapEventsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<PoolSwapEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PoolSwapEvent_Filter>;
};

export type SubscriptionPoolTempDataArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionPoolTempDatasArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<PoolTempData_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PoolTempData_Filter>;
};

export type SubscriptionPoolsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Pool_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Pool_Filter>;
};

export type SubscriptionUpdateLiquidityEventArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionUpdateLiquidityEventAssetArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionUpdateLiquidityEventAssetsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<UpdateLiquidityEventAsset_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<UpdateLiquidityEventAsset_Filter>;
};

export type SubscriptionUpdateLiquidityEventsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<UpdateLiquidityEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<UpdateLiquidityEvent_Filter>;
};

export enum SwapType {
  GiveIn = "GiveIn",
  GiveOut = "GiveOut",
}

export type UpdateLiquidityEvent = {
  __typename?: "UpdateLiquidityEvent";
  blockHeight: Scalars["BigInt"];
  blockTime: Scalars["BigInt"];
  blockTimeLastLiquidityUpdate: Scalars["BigInt"];
  id: Scalars["ID"];
  poolAddress: Scalars["String"];
  poolId: Scalars["Int"];
  vaultContractAddress: Scalars["String"];
};

export type UpdateLiquidityEventAsset = {
  __typename?: "UpdateLiquidityEventAsset";
  amount: Scalars["BigInt"];
  assetType: AssetType;
  event: UpdateLiquidityEvent;
  id: Scalars["ID"];
  identifier: Scalars["String"];
};

export type UpdateLiquidityEventAsset_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  amount?: InputMaybe<Scalars["BigInt"]>;
  amount_gt?: InputMaybe<Scalars["BigInt"]>;
  amount_gte?: InputMaybe<Scalars["BigInt"]>;
  amount_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  amount_lt?: InputMaybe<Scalars["BigInt"]>;
  amount_lte?: InputMaybe<Scalars["BigInt"]>;
  amount_not?: InputMaybe<Scalars["BigInt"]>;
  amount_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  assetType?: InputMaybe<AssetType>;
  assetType_in?: InputMaybe<Array<AssetType>>;
  assetType_not?: InputMaybe<AssetType>;
  assetType_not_in?: InputMaybe<Array<AssetType>>;
  event?: InputMaybe<Scalars["String"]>;
  event_?: InputMaybe<UpdateLiquidityEvent_Filter>;
  event_contains?: InputMaybe<Scalars["String"]>;
  event_contains_nocase?: InputMaybe<Scalars["String"]>;
  event_ends_with?: InputMaybe<Scalars["String"]>;
  event_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  event_gt?: InputMaybe<Scalars["String"]>;
  event_gte?: InputMaybe<Scalars["String"]>;
  event_in?: InputMaybe<Array<Scalars["String"]>>;
  event_lt?: InputMaybe<Scalars["String"]>;
  event_lte?: InputMaybe<Scalars["String"]>;
  event_not?: InputMaybe<Scalars["String"]>;
  event_not_contains?: InputMaybe<Scalars["String"]>;
  event_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  event_not_ends_with?: InputMaybe<Scalars["String"]>;
  event_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  event_not_in?: InputMaybe<Array<Scalars["String"]>>;
  event_not_starts_with?: InputMaybe<Scalars["String"]>;
  event_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  event_starts_with?: InputMaybe<Scalars["String"]>;
  event_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  id_gt?: InputMaybe<Scalars["ID"]>;
  id_gte?: InputMaybe<Scalars["ID"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]>>;
  id_lt?: InputMaybe<Scalars["ID"]>;
  id_lte?: InputMaybe<Scalars["ID"]>;
  id_not?: InputMaybe<Scalars["ID"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]>>;
  identifier?: InputMaybe<Scalars["String"]>;
  identifier_contains?: InputMaybe<Scalars["String"]>;
  identifier_contains_nocase?: InputMaybe<Scalars["String"]>;
  identifier_ends_with?: InputMaybe<Scalars["String"]>;
  identifier_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  identifier_gt?: InputMaybe<Scalars["String"]>;
  identifier_gte?: InputMaybe<Scalars["String"]>;
  identifier_in?: InputMaybe<Array<Scalars["String"]>>;
  identifier_lt?: InputMaybe<Scalars["String"]>;
  identifier_lte?: InputMaybe<Scalars["String"]>;
  identifier_not?: InputMaybe<Scalars["String"]>;
  identifier_not_contains?: InputMaybe<Scalars["String"]>;
  identifier_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  identifier_not_ends_with?: InputMaybe<Scalars["String"]>;
  identifier_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  identifier_not_in?: InputMaybe<Array<Scalars["String"]>>;
  identifier_not_starts_with?: InputMaybe<Scalars["String"]>;
  identifier_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  identifier_starts_with?: InputMaybe<Scalars["String"]>;
  identifier_starts_with_nocase?: InputMaybe<Scalars["String"]>;
};

export enum UpdateLiquidityEventAsset_OrderBy {
  Amount = "amount",
  AssetType = "assetType",
  Event = "event",
  Id = "id",
  Identifier = "identifier",
}

export type UpdateLiquidityEvent_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  blockHeight?: InputMaybe<Scalars["BigInt"]>;
  blockHeight_gt?: InputMaybe<Scalars["BigInt"]>;
  blockHeight_gte?: InputMaybe<Scalars["BigInt"]>;
  blockHeight_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  blockHeight_lt?: InputMaybe<Scalars["BigInt"]>;
  blockHeight_lte?: InputMaybe<Scalars["BigInt"]>;
  blockHeight_not?: InputMaybe<Scalars["BigInt"]>;
  blockHeight_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  blockTime?: InputMaybe<Scalars["BigInt"]>;
  blockTimeLastLiquidityUpdate?: InputMaybe<Scalars["BigInt"]>;
  blockTimeLastLiquidityUpdate_gt?: InputMaybe<Scalars["BigInt"]>;
  blockTimeLastLiquidityUpdate_gte?: InputMaybe<Scalars["BigInt"]>;
  blockTimeLastLiquidityUpdate_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  blockTimeLastLiquidityUpdate_lt?: InputMaybe<Scalars["BigInt"]>;
  blockTimeLastLiquidityUpdate_lte?: InputMaybe<Scalars["BigInt"]>;
  blockTimeLastLiquidityUpdate_not?: InputMaybe<Scalars["BigInt"]>;
  blockTimeLastLiquidityUpdate_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  blockTime_gt?: InputMaybe<Scalars["BigInt"]>;
  blockTime_gte?: InputMaybe<Scalars["BigInt"]>;
  blockTime_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  blockTime_lt?: InputMaybe<Scalars["BigInt"]>;
  blockTime_lte?: InputMaybe<Scalars["BigInt"]>;
  blockTime_not?: InputMaybe<Scalars["BigInt"]>;
  blockTime_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  id?: InputMaybe<Scalars["ID"]>;
  id_gt?: InputMaybe<Scalars["ID"]>;
  id_gte?: InputMaybe<Scalars["ID"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]>>;
  id_lt?: InputMaybe<Scalars["ID"]>;
  id_lte?: InputMaybe<Scalars["ID"]>;
  id_not?: InputMaybe<Scalars["ID"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]>>;
  poolAddress?: InputMaybe<Scalars["String"]>;
  poolAddress_contains?: InputMaybe<Scalars["String"]>;
  poolAddress_contains_nocase?: InputMaybe<Scalars["String"]>;
  poolAddress_ends_with?: InputMaybe<Scalars["String"]>;
  poolAddress_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  poolAddress_gt?: InputMaybe<Scalars["String"]>;
  poolAddress_gte?: InputMaybe<Scalars["String"]>;
  poolAddress_in?: InputMaybe<Array<Scalars["String"]>>;
  poolAddress_lt?: InputMaybe<Scalars["String"]>;
  poolAddress_lte?: InputMaybe<Scalars["String"]>;
  poolAddress_not?: InputMaybe<Scalars["String"]>;
  poolAddress_not_contains?: InputMaybe<Scalars["String"]>;
  poolAddress_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  poolAddress_not_ends_with?: InputMaybe<Scalars["String"]>;
  poolAddress_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  poolAddress_not_in?: InputMaybe<Array<Scalars["String"]>>;
  poolAddress_not_starts_with?: InputMaybe<Scalars["String"]>;
  poolAddress_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  poolAddress_starts_with?: InputMaybe<Scalars["String"]>;
  poolAddress_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  poolId?: InputMaybe<Scalars["Int"]>;
  poolId_gt?: InputMaybe<Scalars["Int"]>;
  poolId_gte?: InputMaybe<Scalars["Int"]>;
  poolId_in?: InputMaybe<Array<Scalars["Int"]>>;
  poolId_lt?: InputMaybe<Scalars["Int"]>;
  poolId_lte?: InputMaybe<Scalars["Int"]>;
  poolId_not?: InputMaybe<Scalars["Int"]>;
  poolId_not_in?: InputMaybe<Array<Scalars["Int"]>>;
  vaultContractAddress?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_contains?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_contains_nocase?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_ends_with?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_gt?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_gte?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_in?: InputMaybe<Array<Scalars["String"]>>;
  vaultContractAddress_lt?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_lte?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_not?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_not_contains?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_not_ends_with?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_not_in?: InputMaybe<Array<Scalars["String"]>>;
  vaultContractAddress_not_starts_with?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_starts_with?: InputMaybe<Scalars["String"]>;
  vaultContractAddress_starts_with_nocase?: InputMaybe<Scalars["String"]>;
};

export enum UpdateLiquidityEvent_OrderBy {
  BlockHeight = "blockHeight",
  BlockTime = "blockTime",
  BlockTimeLastLiquidityUpdate = "blockTimeLastLiquidityUpdate",
  Id = "id",
  PoolAddress = "poolAddress",
  PoolId = "poolId",
  VaultContractAddress = "vaultContractAddress",
}

export type _Block_ = {
  __typename?: "_Block_";
  /** The hash of the block */
  hash?: Maybe<Scalars["Bytes"]>;
  /** The block number */
  number: Scalars["Int"];
  /** Integer representation of the timestamp stored in blocks for the chain */
  timestamp?: Maybe<Scalars["Int"]>;
};

/** The type for the top-level _meta field */
export type _Meta_ = {
  __typename?: "_Meta_";
  /**
   * Information about a specific subgraph block. The hash of the block
   * will be null if the _meta field has a block constraint that asks for
   * a block number. It will be filled if the _meta field has no block constraint
   * and therefore asks for the latest  block
   *
   */
  block: _Block_;
  /** The deployment ID */
  deployment: Scalars["String"];
  /** If `true`, the subgraph encountered indexing errors at some past block */
  hasIndexingErrors: Scalars["Boolean"];
};

export enum _SubgraphErrorPolicy_ {
  /** Data will be returned even if the subgraph has indexing errors */
  Allow = "allow",
  /** If the subgraph has indexing errors, data will be omitted. The default. */
  Deny = "deny",
}
