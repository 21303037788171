import { Icon, IconProps } from "@chakra-ui/react";

export function CheckIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 30 30" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 15C2.5 11.6848 3.81696 8.50537 6.16117 6.16117C8.50537 3.81696 11.6848 2.5 15 2.5C18.3152 2.5 21.4946 3.81696 23.8388 6.16117C26.183 8.50537 27.5 11.6848 27.5 15C27.5 18.3152 26.183 21.4946 23.8388 23.8388C21.4946 26.183 18.3152 27.5 15 27.5C11.6848 27.5 8.50537 26.183 6.16117 23.8388C3.81696 21.4946 2.5 18.3152 2.5 15Z"
        fill="#FCFCFC"
      />
      <path
        d="M12.5 20.5125L13.125 21.25L15 19.375L14.2625 18.75L9.2625 13.75L7.5 15.5125L12.5 20.5125Z"
        fill="#0CA05E"
      />
      <path
        d="M17.5 16.7625L14.375 20L12.5 18.2375L20.7375 10L22.5 11.875L19.2625 15L17.5 16.7625Z"
        fill="#0CA05E"
      />
    </Icon>
  );
}
