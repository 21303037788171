import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { ExecuteResult } from "@cosmjs/cosmwasm-stargate";
import { toBase64 } from "@wizard-ui/core";

import { useContracts } from "modules/common";
import { useChain } from "@cosmos-kit/react";

export interface ExitPoolMutation {
  msg: {
    lpTokenAddr: string;
    amount: string;
    poolId: string;
  };
}

export function useExitPoolMutation(
  options?: Omit<
    UseMutationOptions<ExecuteResult, Error, ExitPoolMutation>,
    "mutationFn"
  >,
) {
  const { vault } = useContracts();
  const persistenceChain =
    process.env.NEXT_PUBLIC_ENV === "testnet"
      ? "persistencetestnet2"
      : process.env.NEXT_PUBLIC_ENV === "devnet"
      ? "Dexter Devnet"
      : "persistence";
  const chainContext = useChain(persistenceChain);
  const { address, getSigningCosmWasmClient } = chainContext;
  const queryClient = useQueryClient();

  return useMutation<ExecuteResult, Error, ExitPoolMutation>(
    async ({ msg: { lpTokenAddr, amount, poolId } }) => {
      const signingClient = await getSigningCosmWasmClient();
      if (signingClient == null || address == null) {
        throw new Error("Missing signingClient in useExitPoolMutation");
      }

      return signingClient.executeMultiple(
        address,
        [
          {
            contractAddress: lpTokenAddr,
            msg: {
              send: {
                contract: vault,
                amount,
                msg: toBase64({
                  exit_pool: {
                    pool_id: poolId,
                    exit_type: {
                      exact_lp_burn: {
                        lp_to_burn: amount,
                      },
                    },
                  },
                }),
              },
            },
            funds: [],
          },
        ],
        "auto",
        "",
      );
    },
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries(["balances"]);
      },
    },
  );
}
