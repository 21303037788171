import { Text, HStack } from "@chakra-ui/react";

import { TokenIcon, useTokenInfo, TokenPairType } from "modules/common";

interface Props {
  pair: TokenPairType;
  onClick: () => void;
}

export function SelectTokenModalItemPair({ pair, onClick }: Props) {
  const { getSymbol } = useTokenInfo();

  return (
    <HStack
      as="button"
      type="button"
      p="2"
      borderRadius="xl"
      bg="primary.dexBlue20"
      borderWidth="1px"
      borderColor="primary.queenBlue"
      cursor="pointer"
      onClick={onClick}
      flexShrink="0"
      _hover={{
        bg: "primary.dexBlue",
      }}
    >
      <HStack spacing="2">
        <TokenIcon token={pair.from} size="5" />
        {pair.to != null && <TokenIcon token={pair.to} size="5" />}
      </HStack>
      <Text fontSize="sm">
        {getSymbol(pair.from)}-{getSymbol(pair.to)}
      </Text>
    </HStack>
  );
}
