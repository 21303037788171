import React from "react";
import {
  Flex,
  HStack,
  Text,
  Box,
  Link as ChakraLink,
  Image,
  Button,
  Menu,
  MenuList,
  MenuButton,
  VStack,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
} from "@chakra-ui/react";
import { truncate } from "@wizard-ui/core";
import Link from "next/link";

import {
  Logo,
  NavbarItem,
  ExternalLinkIcon,
  DisconnectIcon,
  DiscordIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  stringTruncate,
} from "modules/common";
import Copy from "./Copy";
import { useChain, useManager } from "@cosmos-kit/react";
import { ArrowLeftRight, ArrowUpDown, Menu as MenuIcon } from "lucide-react";

export function NavbarMobile() {
  const persistenceChain =
    process.env.NEXT_PUBLIC_ENV === "testnet"
      ? "persistencetestnet2"
      : process.env.NEXT_PUBLIC_ENV === "devnet"
      ? "Dexter Devnet"
      : "persistence";
  const chainContext = useChain(persistenceChain);
  const { getWalletRepo, mainWallets } = useManager();
  const walletRepo = getWalletRepo(persistenceChain);

  const { isWalletConnected, username, address, connect, disconnect, wallet } =
    chainContext;

  const { isOpen, onOpen, onClose } = useDisclosure();
  const mobileWebModeName = "mobile-web";

  const connectHandler = () => {
    sessionStorage.setItem("terms", "show");
    if (
      (window?.leap && window?.leap?.mode === mobileWebModeName) ||
      window?.navigator?.userAgent?.includes("LeapCosmos")
    ) {
      const wallet = walletRepo.wallets.find(
        (wallet) => wallet._walletInfo.name === "leap-extension",
      );
      wallet._walletInfo.mobileDisabled = false;
      walletRepo.connect("leap-extension");
      return;
    } else if (window?.keplr && window?.keplr?.mode === mobileWebModeName) {
      const wallet = walletRepo.wallets.find(
        (wallet) => wallet._walletInfo.name === "keplr-extension",
      );
      wallet._walletInfo.mobileDisabled = false;
      walletRepo.connect("keplr-extension");
      return;
    } else {
      connect();
      return;
    }
  };

  const disconnectHandler = async () => {
    localStorage.removeItem("wallet");
    localStorage.removeItem("walletName");
    sessionStorage.removeItem("terms");
    if (window?.leap && window?.leap?.mode === mobileWebModeName) {
      walletRepo.disconnect("leap-extension");
      return;
    } else if (window?.keplr && window?.keplr?.mode === mobileWebModeName) {
      walletRepo.disconnect("keplr-extension");
      return;
    } else {
      await walletRepo.disconnect();
      // await disconnect();
      return;
    }
    // window.location.reload();
  };
  return (
    <>
      <Flex
        py="3"
        px={["2", "2", "2", "10", "10"]}
        justify="space-between"
        pos="relative"
        zIndex="3"
      >
        <Box
          bgColor={"primary.oxfordBlue"}
          p={3}
          borderRadius={"lg"}
          borderWidth={1}
          borderColor="primary.queenBlue"
          _hover={{ bg: "primary.dexBlue" }}
          onClick={onOpen}
        >
          <MenuIcon size={24} />
        </Box>

        <Drawer
          placement={"left"}
          size={"100px"}
          onClose={onClose}
          isOpen={isOpen}
          variant="primary"
          autoFocus={false}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader justifyContent={"center"}>
              <Link href="/">
                <Image
                  src="./images/dexter_logo_text.svg"
                  width="auto"
                  // height="24px"
                />
                {/* <Logo width={106} height={36} /> */}
              </Link>
            </DrawerHeader>
            <DrawerBody>
              <Flex justify={"center"}>
                <VStack align={"center"}>
                  <HStack spacing={-5}>
                    <Image
                      src="./icons/swap_icon.svg"
                      width="24px"
                      height="24px"
                    />
                    <NavbarItem link="/" exact>
                      Swap
                    </NavbarItem>
                  </HStack>
                  <HStack spacing={-4}>
                    <Image
                      src="./icons/pools_icon.svg"
                      // width="20px"
                      // height="20px"
                    />
                    <NavbarItem link="/pools">Pools</NavbarItem>
                  </HStack>
                  <HStack spacing={-4}>
                    <Image
                      src="./icons/assets_icon.svg"
                      // width="20px"
                      // height="20px"
                    />
                    <NavbarItem link="/assets">Assets</NavbarItem>
                  </HStack>
                </VStack>
              </Flex>
            </DrawerBody>
          </DrawerContent>
        </Drawer>

        {!isWalletConnected ? (
          <Button
            variant={"secondary"}
            minWidth={["50px", "50px", "50px", "150px", "150px"]}
            mr={["2", "2", "2", "8", "8"]}
            onClick={() => connectHandler()}
          >
            Connect Wallet
          </Button>
        ) : (
          <Menu>
            {({ isOpen }) => (
              <>
                <MenuButton
                  mr={["2", "2", "2", "8", "8"]}
                  as={Button}
                  rightIcon={
                    isWalletConnected &&
                    (isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />)
                  }
                  // onClick={() => handleConnect("keplr")}
                  variant="secondary"
                  minWidth={["50px", "50px", "50px", "150px", "150px"]}
                >
                  {isWalletConnected
                    ? stringTruncate(
                        username || wallet?.prettyName || "",
                        5,
                        12,
                      )
                    : "Connect Wallet"}
                </MenuButton>
                {isWalletConnected && (
                  <MenuList
                    outline={"none"}
                    border="none"
                    minWidth={0}
                    bg="none"
                  >
                    <Box bg="primary.oxfordBlue" p="2" borderRadius="xl">
                      <Box
                        bg="primary.prussianBlue"
                        px="2"
                        borderRadius="md"
                        mb="3"
                      >
                        <HStack py="2">
                          <Image
                            boxSize="12"
                            src={
                              wallet?.name.includes("keplr")
                                ? "./icons/keplr.png"
                                : wallet?.name.includes("leap")
                                ? "./icons/leap.svg"
                                : "./icons/cosmostation.svg"
                            }
                            alt="wallet icon"
                          />
                          <Box fontWeight="600">
                            {/* <Text fontSize="sm">{wallet?.adapter.name}</Text> */}
                            <Text fontSize="sm">{username}</Text>
                            <Box
                              bg="primary.oxfordBlue"
                              borderRadius="md"
                              w="full"
                              py="0.5"
                              px="2"
                            >
                              <Text>{address && truncate(address)}</Text>
                            </Box>
                          </Box>
                        </HStack>
                      </Box>
                      <Box
                        bg="primary.prussianBlue"
                        px="2"
                        borderRadius="md"
                        fontSize="sm"
                        fontWeight="600"
                      >
                        <HStack cursor="pointer" py="3" spacing="3">
                          {address && <Copy id={address} />}
                        </HStack>
                        <Box h="1px" bg="primary.cta" opacity="0.7" />
                        <ChakraLink
                          display="block"
                          py="3"
                          href={
                            process.env.NEXT_PUBLIC_ENV == "mainnet"
                              ? `https://mintscan.io/persistence/account/${address}`
                              : `https://testnet.mintscan.io/persistence-testnet/account/${address}`
                          }
                          isExternal
                        >
                          <HStack spacing="3">
                            <ExternalLinkIcon boxSize="6" />
                            <Text>Mintscan.io</Text>
                          </HStack>
                        </ChakraLink>
                        <Box h="1px" bg="primary.cta" opacity="0.7" />
                        <ChakraLink
                          display="block"
                          py="3"
                          href={`https://discord.persistence.one/`}
                          isExternal
                        >
                          <HStack spacing="3">
                            <DiscordIcon boxSize="6" />
                            <Text>Support</Text>
                          </HStack>
                        </ChakraLink>
                        <Box h="1px" bg="primary.cta" opacity="0.7" />
                        <HStack
                          cursor="pointer"
                          py="3"
                          spacing="3"
                          onClick={() => disconnectHandler()}
                        >
                          <DisconnectIcon boxSize="6" />
                          <Text>Disconnect</Text>
                        </HStack>
                      </Box>
                    </Box>
                  </MenuList>
                )}
              </>
            )}
          </Menu>
        )}
      </Flex>
    </>
  );
}
