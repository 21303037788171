import React from "react";
import {
  Flex,
  HStack,
  Text,
  Box,
  Link as ChakraLink,
  Image,
  Button,
  Menu,
  MenuList,
  MenuButton,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { truncate } from "@wizard-ui/core";
import Link from "next/link";

import {
  Logo,
  NavbarItem,
  ExternalLinkIcon,
  DisconnectIcon,
  DiscordIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  stringTruncate,
} from "modules/common";
import Copy from "./Copy";
import { ArrowUpDown, FileText } from "lucide-react";
import {
  DiscordIcon as FooterDiscordIcon,
  TelegramIcon,
  TwitterIcon,
} from "./Footer/icons";
import SecurityIcon from "./Footer/icons/security";
import BlogIcon from "./Footer/icons/blog";
import BugReportIcon from "./Footer/icons/bug-report";
import { useChain, useManager } from "@cosmos-kit/react";
import RebateProgramIcon from "./Footer/icons/rebate";

const MegaMenuItem = ({ icon, item, href, isExternal = false }) => {
  return (
    <ChakraLink as={Link} display="block" href={href} isExternal={isExternal}>
      <HStack>
        {icon}
        <Text>{item}</Text>
      </HStack>
    </ChakraLink>
  );
};

export function Navbar() {
  const persistenceChain =
    process.env.NEXT_PUBLIC_ENV === "testnet"
      ? "persistencetestnet2"
      : process.env.NEXT_PUBLIC_ENV === "devnet"
      ? "Dexter Devnet"
      : "persistence";
  const chainContext = useChain(persistenceChain);
  const { getWalletRepo, mainWallets } = useManager();
  const walletRepo = getWalletRepo(persistenceChain);
  const { isWalletConnected, username, address, connect, disconnect, wallet } =
    chainContext;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const disconnectHandler = async () => {
    localStorage.removeItem("wallet");
    localStorage.removeItem("walletName");
    sessionStorage.removeItem("terms");
    walletRepo.disconnect(wallet.name);
    // window.location.reload();
  };
  const handleConnect = async () => {
    sessionStorage.setItem("terms", "show");
    await connect();
  };
  return (
    <>
      <Flex
        py="6"
        px={["2", "2", "2", "10", "10"]}
        justify="space-between"
        pos="relative"
        zIndex="3"
      >
        <Link href="/">
          <Logo width={140} />
        </Link>
        <HStack spacing={["0", "0", "0", "2", "6"]}>
          <NavbarItem link="/" exact>
            Swap
          </NavbarItem>
          <NavbarItem link="/pools">Pools</NavbarItem>
          <NavbarItem link="/assets">Assets</NavbarItem>
          <Menu placement="top-end" isOpen={isOpen}>
            {/* <NavbarItem link="https://docs.dexter.zone/" isExternal> */}
            {/* {({ isOpen }) => ( */}
            <>
              <MenuButton
                mr={["2", "2", "2", "8", "8"]}
                as={Button}
                _focusVisible={{ outline: "none" }}
                onMouseEnter={onOpen}
                onMouseLeave={onClose}
                rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                // onClick={() => handleConnect("keplr")}
                variant="nav"
                color={"whiteAlpha.700"}
                fontSize={["0.9rem", "0.9rem", "0.9rem", "1.1rem", "1.4rem"]}
                minWidth={["50px", "50px", "50px", "150px", "150px"]}
              >
                More
              </MenuButton>
              <MenuList
                mt={-4}
                borderColor="primary.dexBlue20"
                outline={"none"}
                border="none"
                minWidth={0}
                bg="none"
                onMouseEnter={onOpen}
                onMouseLeave={onClose}
              >
                <HStack
                  bg="primary.prussianBlue"
                  px="8"
                  py="6"
                  gap={8}
                  borderRadius="xl"
                  borderWidth={1}
                  borderColor="primary.queenBlue"
                  align={"flex-start"}
                >
                  <VStack align={"flex-start"}>
                    <Text
                      textTransform={"uppercase"}
                      fontSize={18}
                      color="#FCFCFC"
                      fontWeight={700}
                      lineHeight={1.5}
                      letterSpacing={1}
                    >
                      Overview
                    </Text>
                    <MegaMenuItem
                      icon={<FileText />}
                      item="Docs"
                      href="https://docs.dexter.zone/onboarding/introduction"
                      isExternal
                    />
                    <MegaMenuItem
                      icon={<BlogIcon />}
                      item="Blogs"
                      href="https://medium.com/dexter-zone"
                      isExternal
                    />
                    {/* <MegaMenuItem icon={<FileText />} item="FAQs" /> */}
                    <MegaMenuItem
                      icon={<SecurityIcon />}
                      item="Security Audits"
                      href="https://github.com/dexter-zone/dexter_core/tree/main/audit"
                      isExternal
                    />
                  </VStack>
                  <VStack align={"flex-start"}>
                    <Text
                      textTransform={"uppercase"}
                      fontSize={18}
                      color="#FCFCFC"
                      fontWeight={700}
                      lineHeight={1.5}
                      letterSpacing={1}
                    >
                      Community
                    </Text>
                    <MegaMenuItem
                      icon={<TwitterIcon />}
                      item="Twitter"
                      href="https://twitter.com/dexter_zone"
                      isExternal
                    />
                    <MegaMenuItem
                      icon={<FooterDiscordIcon />}
                      item="Discord"
                      href="https://discord.persistence.one/"
                      isExternal
                    />
                    <MegaMenuItem
                      icon={<TelegramIcon />}
                      item="Telegram"
                      href="https://t.me/DexterZoneOfficial"
                      isExternal
                    />
                  </VStack>
                  <VStack align={"flex-start"}>
                    <Text
                      textTransform={"uppercase"}
                      fontSize={18}
                      color="#FCFCFC"
                      fontWeight={700}
                      lineHeight={1.5}
                      letterSpacing={1}
                    >
                      Others
                    </Text>
                    <MegaMenuItem
                      icon={<RebateProgramIcon />}
                      item="Rebate Program"
                      href="/rebate-program"
                    />
                    <MegaMenuItem
                      icon={<ArrowUpDown />}
                      item="Transaction History"
                      href="/transactions"
                    />
                    <MegaMenuItem
                      icon={<BugReportIcon />}
                      item="Bug Report"
                      href="https://discord.com/channels/796174129077813248/1231980029911633920"
                      isExternal
                    />
                  </VStack>
                </HStack>
              </MenuList>
            </>
            {/* )} */}
            {/* </NavbarItem> */}
          </Menu>
        </HStack>
        {!isWalletConnected ? (
          <Button
            variant={"secondary"}
            minWidth={["50px", "50px", "50px", "150px", "150px"]}
            mr={["2", "2", "2", "8", "8"]}
            onClick={() => handleConnect()}
          >
            Connect Wallet
          </Button>
        ) : (
          <Menu>
            {({ isOpen }) => (
              <>
                <MenuButton
                  mr={["2", "2", "2", "8", "8"]}
                  as={Button}
                  rightIcon={
                    isWalletConnected &&
                    (isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />)
                  }
                  // onClick={() => handleConnect("keplr")}
                  variant="secondary"
                  minWidth={["50px", "50px", "50px", "150px", "150px"]}
                >
                  {isWalletConnected
                    ? stringTruncate(
                        username || wallet?.prettyName || "",
                        5,
                        12,
                      )
                    : "Connect Wallet"}
                </MenuButton>
                {isWalletConnected && (
                  <MenuList
                    outline={"none"}
                    border="none"
                    minWidth={0}
                    bg="none"
                  >
                    <Box bg="primary.oxfordBlue" p="2" borderRadius="xl">
                      <Box
                        bg="primary.prussianBlue"
                        px="2"
                        borderRadius="md"
                        mb="3"
                      >
                        <HStack py="2">
                          <Image
                            boxSize="12"
                            src={
                              wallet?.name.includes("keplr")
                                ? "./icons/keplr.png"
                                : wallet?.name.includes("leap")
                                ? "./icons/leap.svg"
                                : "./icons/cosmostation.svg"
                            }
                            alt="wallet icon"
                          />
                          <Box fontWeight="600">
                            {/* <Text fontSize="sm">{wallet?.adapter.name}</Text> */}
                            <Text fontSize="sm">{username}</Text>
                            <Box
                              bg="primary.oxfordBlue"
                              borderRadius="md"
                              w="full"
                              py="0.5"
                              px="2"
                            >
                              <Text>{address && truncate(address)}</Text>
                            </Box>
                          </Box>
                        </HStack>
                      </Box>
                      <Box
                        bg="primary.prussianBlue"
                        px="2"
                        borderRadius="md"
                        fontSize="sm"
                        fontWeight="600"
                      >
                        <HStack cursor="pointer" py="3" spacing="3">
                          {address && <Copy id={address} />}
                        </HStack>
                        <Box h="1px" bg="primary.cta" opacity="0.7" />
                        <ChakraLink
                          display="block"
                          py="3"
                          href={
                            process.env.NEXT_PUBLIC_ENV == "mainnet"
                              ? `https://mintscan.io/persistence/account/${address}`
                              : `https://testnet.mintscan.io/persistence-testnet/account/${address}`
                          }
                          isExternal
                        >
                          <HStack spacing="3">
                            <ExternalLinkIcon boxSize="6" />
                            <Text>Mintscan.io</Text>
                          </HStack>
                        </ChakraLink>
                        <Box h="1px" bg="primary.cta" opacity="0.7" />
                        <ChakraLink
                          display="block"
                          py="3"
                          href={`https://discord.persistence.one/`}
                          isExternal
                        >
                          <HStack spacing="3">
                            <DiscordIcon boxSize="6" />
                            <Text>Support</Text>
                          </HStack>
                        </ChakraLink>
                        <Box h="1px" bg="primary.cta" opacity="0.7" />
                        <HStack
                          cursor="pointer"
                          py="3"
                          spacing="3"
                          onClick={() => disconnectHandler()}
                        >
                          <DisconnectIcon boxSize="6" />
                          <Text>Disconnect</Text>
                        </HStack>
                      </Box>
                    </Box>
                  </MenuList>
                )}
              </>
            )}
          </Menu>
        )}
      </Flex>
    </>
  );
}
