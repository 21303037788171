import { useMemo } from "react";
import { Box, Text, HStack, Flex } from "@chakra-ui/react";
import { formatAmount, num, NumberFormatSpecifier } from "@wizard-ui/core";

import { TokenIcon, useTokenInfo, useDexter } from "modules/common";

interface Props {
  data: any;
}

export function ClaimRewardsModalItem({ data }: Props) {
  const { prices, tokens } = useDexter();
  const { getSymbol } = useTokenInfo();

  const value = useMemo(() => {
    if (prices == null) {
      return 0;
    }

    const price = prices[data.token].price;

    if (price == null) {
      return 0;
    }

    return num(data.amount)
      .div(10 ** tokens[data.token].decimals)
      .times(price)
      .toNumber();
  }, [data, prices]);

  return (
    <Flex key={data.token} justify="space-between">
      <HStack spacing="3">
        <TokenIcon token={data.token} size="8" />
        <Text fontFamily="heading" fontSize="xl" fontWeight="600">
          {getSymbol(data.token)}
        </Text>
      </HStack>
      <HStack spacing="4">
        <Box textAlign="right">
          <Text fontSize="xl" fontWeight="600">
            {formatAmount(
              num(data.amount).div(10 ** tokens[data.token].decimals),
              {
                // shouldDivide: true,
                formatSpecifier: NumberFormatSpecifier.FLOAT,
              },
            )}
          </Text>
          <Text fontSize="xm">
            ~
            {formatAmount(value, {
              formatSpecifier: NumberFormatSpecifier.CURRENCY,
            })}
          </Text>
        </Box>
      </HStack>
    </Flex>
  );
}
