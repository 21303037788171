import { Icon, IconProps } from "@chakra-ui/react";

export function GithubIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 39 38" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5 0.333496C9.18671 0.333496 0.833374 8.89812 0.833374 19.4723C0.833374 27.9412 6.17671 35.0944 13.5967 37.6303C14.53 37.7977 14.88 37.2236 14.88 36.7212C14.88 36.2666 14.8567 34.7594 14.8567 33.1566C10.1667 34.0417 8.95337 31.9843 8.58004 30.9078C8.37004 30.3575 7.46004 28.6589 6.66671 28.2044C6.01337 27.8455 5.08004 26.9604 6.64337 26.9365C8.11337 26.9125 9.16337 28.324 9.51337 28.8982C11.1934 31.7929 13.8767 30.9795 14.95 30.4771C15.1134 29.2331 15.6034 28.3958 16.14 27.9173C11.9867 27.4388 7.64671 25.7881 7.64671 18.4675C7.64671 16.3862 8.37004 14.6637 9.56004 13.324C9.37337 12.8455 8.72004 10.8838 9.74671 8.25218C9.74671 8.25218 11.31 7.74979 14.88 10.2139C16.3734 9.78329 17.96 9.56798 19.5467 9.56798C21.1334 9.56798 22.72 9.78329 24.2134 10.2139C27.7834 7.72586 29.3467 8.25218 29.3467 8.25218C30.3734 10.8838 29.72 12.8455 29.5334 13.324C30.7234 14.6637 31.4467 16.3623 31.4467 18.4675C31.4467 25.812 27.0834 27.4388 22.93 27.9173C23.6067 28.5154 24.19 29.6637 24.19 31.458C24.19 34.0178 24.1667 36.0752 24.1667 36.7212C24.1667 37.2236 24.5167 37.8217 25.45 37.6303C29.1556 36.3475 32.3757 33.9057 34.6568 30.6484C36.938 27.3911 38.1656 23.4824 38.1667 19.4723C38.1667 8.89812 29.8134 0.333496 19.5 0.333496Z"
        fill="url(#paint0_linear_1059_14180)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1059_14180"
          x1="28.8805"
          y1="164.877"
          x2="-67.8606"
          y2="139.995"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00AFFA" />
          <stop offset="0.47403" stopColor="#32A2BA" />
          <stop offset="1" stopColor="#24FFCA" />
        </linearGradient>
      </defs>
    </Icon>
  );
}
