import { useChain } from "@cosmos-kit/react";
import { ChainInfo } from "@keplr-wallet/types";
import { useQuery } from "@tanstack/react-query";
import { CHAIN_ID, ExternalChains } from "config";

import { getBalances } from "modules/contracts";
import { isNativeToken, toAssetInfo } from "../helpers";
import useContracts from "./useContracts";
import { QueryClientImpl } from "cosmjs-types/cosmos/staking/v1beta1/query";
import { QueryClientImpl as LiquidStakeQueryClient } from "persistenceonejs/pstake/liquidstake/v1beta1/query";
import { QueryClient, createProtobufRpcClient } from "@cosmjs/stargate";
import { Tendermint34Client } from "@cosmjs/tendermint-rpc";

async function RpcClient() {
  const tendermintClient = await Tendermint34Client.connect(
    "https://rpc.core.persistence.one",
  );
  const queryClient = new QueryClient(tendermintClient);
  return createProtobufRpcClient(queryClient);
}

export const useBalances = (
  lpTokens: string[],
  tokens: string[],
  options = {},
) => {
  const persistenceChain =
    process.env.NEXT_PUBLIC_ENV === "testnet"
      ? "persistencetestnet2"
      : process.env.NEXT_PUBLIC_ENV === "devnet"
      ? "Dexter Devnet"
      : "persistence";
  const chainContext = useChain(persistenceChain);
  const { address, getCosmWasmClient, getSigningStargateClient } = chainContext;

  const persistenceChainInfo = ExternalChains[
    process.env.NEXT_PUBLIC_ENV as keyof typeof ExternalChains
  ].find(
    (chain: ChainInfo) =>
      chain.chain_id ===
      CHAIN_ID[process.env.NEXT_PUBLIC_ENV as keyof typeof CHAIN_ID]
        .persistenceChainID,
  );

  const restEndpoint = persistenceChainInfo?.rest as string;

  const { multistaking, governance_admin, superfluid_lp } = useContracts();

  return useQuery(
    [
      "balances",
      address,
      lpTokens,
      tokens,
      getCosmWasmClient,
      getSigningStargateClient,
    ],
    async () => {
      const client = await getCosmWasmClient();
      const signingStargateClient = await getSigningStargateClient();
      if (
        // signingStargateClient == null ||
        address == null ||
        tokens == undefined
      ) {
        throw new Error("Error in useBalances");
      }

      const nativeBalances = await signingStargateClient.getAllBalances(
        address,
      );

      const cw20Tokens = Object.values(tokens)
        // .map((token) => token.identifier)
        // .filter((token) => !isNativeToken(token));
        .filter((token) => token.type !== "Native")
        .map((token) => token.identifier);

      const cw20Balances = await getBalances({
        client,
        // TODO - make it dynamic
        tokens: [...cw20Tokens, ...lpTokens],
        address,
      });

      const rpcClient = await RpcClient();
      const liqStakeQueryService = new LiquidStakeQueryClient(rpcClient);
      const params = await liqStakeQueryService.Params({});
      const stakingQueryService = new QueryClientImpl(rpcClient);
      const delegations = await stakingQueryService.DelegatorDelegations({
        delegatorAddr: address,
      });
      const delegatedValidators = await stakingQueryService.DelegatorValidators(
        {
          delegatorAddr: address,
        },
      );

      // setValidatorList(delVal.validators);

      const delegatedBalances = delegations.delegationResponses.reduce(
        (acc, { balance, delegation }) => {
          const validator = delegatedValidators.validators.find(
            (validator) =>
              validator.operatorAddress === delegation.validatorAddress,
          );
          if (validator) {
            return {
              ...acc,
              [validator.operatorAddress]: {
                balance,
                moniker: validator?.description?.moniker,
                jailed: validator?.jailed,
                eligible: params.params.whitelistedValidators.find(
                  (val) => val.validatorAddress === validator.operatorAddress,
                )
                  ? true
                  : false,
              },
            };
          }
          return acc;
        },
        {},
      );

      // const multistakingCont = await client.queryContractSmart(multistaking, {
      //   reward_schedules: {
      //     lp_token:
      //       "persistence1sthrn5ep8ls5vzz8f9gp89khhmedahhdqd244dh9uqzk3hx2pzrsvpslsg",
      //     asset: toAssetInfo("uxprt"),
      //   },
      // });
      // const superfluid_lp_contract = await client.queryContractSmart(
      //   superfluid_lp,
      //   {
      //     token_locks: {
      //       user: address,
      //       asset_info: {
      //         native_token: {
      //           denom: "stk/uxprt",
      //         },
      //       },
      //     },
      //   },
      // );

      // const gov = await client.queryContractSmart(governance_admin, {
      //   pool_creation_request: { pool_creation_request_id: 4 },
      // });
      // const gov1 = await client.queryContractSmart(governance_admin, {
      //   refundable_funds: {
      //     request_type: { pool_creation_request: { request_id: 4 } },
      //   },
      // });
      // console.log({ gov, gov1 });
      // console.log({ multistakingCont, superfluid_lp_contract });
      return nativeBalances.reduce(
        (acc, { denom, amount }) => {
          return {
            ...acc,
            [denom]: amount,
          };
        },
        { ...cw20Balances, ...delegatedBalances },
      );
    },
    {
      ...options,
      enabled: getCosmWasmClient != null && address != null,
      // refetchInterval: 10000,
    },
  );
};
