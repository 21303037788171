import { Icon, IconProps } from "@chakra-ui/react";

export function TelegramIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 32 26" {...props}>
      <path
        d="M31.0096 0C30.9045 0 30.7994 1.99061e-07 30.6417 0.0526318L0.790916 11.4211C-0.0499513 11.7368 -0.0499482 12.9474 0.843473 13.2105L7.78063 15.5263L11.9324 23.8947C11.985 24.0526 12.1426 24.1579 12.3528 24.1579C12.4579 24.1579 12.5631 24.1053 12.6156 24.0526L16.5046 20.7368L12.1426 17.4211L12.5105 21.7895L9.35725 15.2632L26.0169 5.21053L12.1426 17.3684L22.9688 25.5789C23.3367 25.8421 23.7571 26 24.1775 26C25.0184 26 25.8593 25.4211 26.0695 24.5263L31.903 1.10526C32.1132 0.578948 31.6402 0 31.0096 0Z"
        fill="url(#paint0_linear_1059_14155)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1059_14155"
          x1="24.047"
          y1="114.593"
          x2="-55.8689"
          y2="89.4635"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00AFFA" />
          <stop offset="0.47403" stopColor="#32A2BA" />
          <stop offset="1" stopColor="#24FFCA" />
        </linearGradient>
      </defs>
    </Icon>
  );
}
