import { ChainInfo } from "@keplr-wallet/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { num } from "@wizard-ui/core";
import {
  CHAIN_ID,
  ExternalChains,
  stkAtomAPREndpoint,
  stkDydxAPREndpoint,
  stkHuahuaAPREndpoint,
  stkStarsAPREndpoint,
  stkXprtAPREndpoint,
} from "config";
import { useChain } from "@cosmos-kit/react";
import { GraphURL } from "config";
import request, { gql } from "graphql-request";
import { useContracts } from "modules/common";
import { getPoolsInfo } from "./helpers";

export interface PoolConfigQueryResponse {
  pool_id: string;
  lp_token_addr: string;
  vault_addr: string | null;
  assets: any;
  pool_type: any;
  fee_info: any;
  block_time_last: any;
  additional_params: string;
}

export interface PoolConfigQueryArgs {
  options?: Omit<
    UseQueryOptions<PoolConfigQueryResponse, Error>,
    "'queryKey' | 'queryFn' | 'initialData'"
  >;
}

export function usePoolConfigQuery(backendEndpoint, options = {}) {
  const persistenceChain =
    process.env.NEXT_PUBLIC_ENV === "testnet"
      ? "persistencetestnet2"
      : process.env.NEXT_PUBLIC_ENV === "devnet"
      ? "Dexter Devnet"
      : "persistence";
  const chainContext = useChain(persistenceChain);
  const { getCosmWasmClient } = chainContext;
  const { vault } = useContracts();

  const persistenceChainInfo = ExternalChains[
    process.env.NEXT_PUBLIC_ENV as keyof typeof ExternalChains
  ].find(
    (chain: ChainInfo) =>
      chain.chain_id ===
      CHAIN_ID[process.env.NEXT_PUBLIC_ENV as keyof typeof CHAIN_ID]
        .persistenceChainID,
  );

  const rpcEndpoint = persistenceChainInfo?.rpc;

  return useQuery<PoolConfigQueryResponse, Error>(
    ["poolConfig", getCosmWasmClient, backendEndpoint],
    async () => {
      const client = await getCosmWasmClient();
      if (client == null) {
        throw new Error("Missing client in usePoolConfigQuery");
      }
      if (backendEndpoint !== rpcEndpoint) {
        try {
          const { pools } = await request(
            backendEndpoint,
            gql`
              query {
                pools(where: { poolId_not_in: [14] }, orderBy: poolId) {
                  poolId
                  poolContractAddress
                  vaultContractAddress
                  poolType {
                    id
                    poolType
                  }
                  lpTokenName
                  lpTokensInSupply
                  lpTokenContractAddress
                  poolAssets {
                    identifier
                    assetType
                    amount
                  }
                }
              }
            `,
          );

          const { pool_weekly_aggregate_with_apr } = await request(
            backendEndpoint,
            gql`
              query {
                pool_weekly_aggregate_with_apr {
                  pool_id
                  total_swap_fee
                  current_liquidity_usd
                  total_volume
                  apr
                }
              }
            `,
          );
          const { pool_daily_aggregate } = await request(
            backendEndpoint,
            gql`
              query {
                pool_daily_aggregate {
                  pool_id
                  total_volume
                  total_swap_fee
                }
              }
            `,
          );

          const { pool_current_incentive_apr } = await request(
            backendEndpoint,
            gql`
              query {
                pool_current_incentive_apr {
                  incentive_apr
                  pool_id
                  recording_timestamp
                  reward_asset_id
                  total_bond_amount
                }
              }
            `,
          );

          const stkAtomAPRData = await fetch(stkAtomAPREndpoint);
          const stkAtomAPR = await stkAtomAPRData.json();
          const stkDydxAPRData = await fetch(stkDydxAPREndpoint);
          const stkDydxAPR = await stkDydxAPRData.json();
          const stkStarsAPRData = await fetch(stkStarsAPREndpoint);
          const stkStarsAPR = await stkStarsAPRData.json();
          const stkHuahuaAPRData = await fetch(stkHuahuaAPREndpoint);
          const stkHuahuaAPR = await stkHuahuaAPRData.json();
          const stkXprtAPRData = await fetch(stkXprtAPREndpoint);
          const stkXprtAPR = await stkXprtAPRData.json();
          const stkAssetAPRMap = {
            "stk/uatom": stkAtomAPR,
            "stk/adydx": stkDydxAPR,
            "stk/ustars": stkStarsAPR,
            "stk/uhuahua": stkHuahuaAPR,
            "stk/uxprt": stkXprtAPR,
          };
          return pools.map((item: any) => {
            const extra = pool_weekly_aggregate_with_apr.find(
              ({ pool_id }: any) => item.poolId == pool_id,
            );

            const extra1 = pool_current_incentive_apr.filter(
              ({ pool_id }: any) => item.poolId == pool_id,
            );

            const dailyVolumeInfo = pool_daily_aggregate.find(
              ({ pool_id }: any) => item.poolId == pool_id,
            );
            const stkAsset = item.poolAssets.find(({ identifier }: any) =>
              identifier.includes("stk/"),
            );
            return {
              ...item,
              currentLiquidityUSD: extra?.current_liquidity_usd ?? null,
              volume7d: extra?.total_volume ?? null,
              volume24h: dailyVolumeInfo?.total_volume ?? null,
              // volume24h:
              //   volumeInfo?.base_volume != null
              //     ? {
              //         amount: num(volumeInfo?.base_volume),
              //         token: volumeInfo?.base_currency,
              //       }
              //     : null,
              apr7d:
                (extra?.apr || 0) +
                  (extra1.reduce(
                    (n, { incentive_apr }) => n + incentive_apr,
                    0,
                  ) || 0) ?? null,
              fee7d: extra?.total_swap_fee ?? null,
              extApr: extra1 ?? null,
              feeApr: extra?.apr ?? null,
              stakingApr: stkAsset
                ? stkAssetAPRMap[
                    stkAsset.identifier as keyof typeof stkAssetAPRMap
                  ]
                : null,
            };
          });
        } catch (e) {
          console.log({ e });
          throw new Error("Something is wrong about pools", e);
        }
      } else {
        if (client == null) {
          throw new Error("Missing client in usePoolConfigQuery");
        }

        const vaultConfig = await client.queryContractSmart(vault, {
          config: {},
        });
        const poolsCount = vaultConfig.next_pool_id;
        const poolsInfo = await getPoolsInfo(client, vault, poolsCount);
        return poolsInfo.map((item: any) => {
          return {
            ...item,
            currentLiquidityUSD: null,
            volume7d: null,
            volume24h: null,
            apr7d: null,
            fee7d: null,
            extApr: null,
            feeApr: null,
          };
        });
      }
    },
    {
      ...options,
      enabled: getCosmWasmClient != null && backendEndpoint != null,
    },
  );
}
