import {
  ModalProps,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  IconButton,
  Box,
  chakra,
  Flex,
  VStack,
  Image,
  CloseButton,
} from "@chakra-ui/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { num, truncate } from "@wizard-ui/core";

import {
  IbcChainItem,
  ChevronRightIcon,
  useTokenInfo,
  TxPending,
  TxSuccess,
  stringTruncate,
  TxError,
} from "modules/common";
import { useIBCTransferMutation, useIBCData } from "modules/assets";
import { TokenInput, TokenDisplay } from "modules/swap";
import { toast } from "react-toastify";
import { depositSuccessDataVar } from "state/globalState";
import { useReactiveVar } from "@deevoid/reactive-var";
import { useChain } from "@cosmos-kit/react";
import useStore from "state/store";

interface FormValues {
  amount: string;
}

interface Props extends Omit<ModalProps, "children"> {
  token: string;
  showSelect?: boolean;
}

export function DepositAssetModal({
  token,
  isOpen,
  onClose,
  showSelect = false,
  ...rest
}: Props) {
  const persistenceChain =
    process.env.NEXT_PUBLIC_ENV === "testnet"
      ? "persistencetestnet2"
      : process.env.NEXT_PUBLIC_ENV === "devnet"
      ? "Dexter Devnet"
      : "persistence";
  const { address } = useChain(persistenceChain);
  const [isConfirmStep, setIsConfirmStep] = useState(false);
  const [selectedToken, setSelectedToken] = useState(token);
  const {
    otherAddress,
    otherSigningStargateClient,
    balance,
    denom,
    depositChannel,
    remoteChainExplorer,
  } = useIBCData({
    token: selectedToken,
    isOpen,
  });
  const { mutate, isLoading, data, reset, error, isError } =
    useIBCTransferMutation();
  // const depositSuccessData = useReactiveVar(depositSuccessDataVar);
  const depositSuccessData = useStore(
    (state: any) => state.setDepositSuccessDataVar,
  );
  const setDepositSuccessDataVar = useStore(
    (state: any) => state.setDepositSuccessDataVar,
  );
  const { getIcon, getChain } = useTokenInfo();
  const {
    handleSubmit,
    control,
    watch,
    reset: resetForm,
  } = useForm<FormValues>({
    mode: "onChange",
    defaultValues: {
      amount: "",
    },
  });
  const { amount } = watch();

  const onSubmit = (values: FormValues) => {
    if (otherAddress == null || otherSigningStargateClient == null) {
      throw new Error("Something went wrong");
    }

    mutate({
      msg: {
        otherAddress,
        otherSigningStargateClient,
        denom,
        channel: depositChannel,
        amount: num(values.amount)
          .times(
            selectedToken ==
              "ibc/23DC3FF0E4CBB53A1915E4C62507CB7796956E84C68CA49707787CB8BDE90A1E" ||
              selectedToken ==
                "ibc/18200EAA7E5BB3D235FF517F04045F4DCB0691CE6FC1B32E4297BEA8EF7710E3"
              ? 10 ** 18
              : selectedToken ==
                "ibc/CCA9F9B22D39884C09975D45E1869B73A12B87080EE53CB44905CE2C422CA228"
              ? 10 ** 8
              : 10 ** 6,
          )
          .toFixed(0),
      },
    });
  };

  const handleClose = () => {
    setIsConfirmStep(false);
    setDepositSuccessDataVar(null);
    reset();
    resetForm();
    onClose();
  };

  const handleSelect = (token: string) => {
    setSelectedToken(token);
    // setIsConfirmStep(true);
  };
  const renderForm = () => {
    if (
      isConfirmStep ||
      isLoading ||
      depositSuccessData != null ||
      error != null
    ) {
      return null;
    }

    return (
      <Box>
        <Flex
          gap={[2, 2, 2, 4, 4, 4]}
          align="center"
          justify={[
            "space-between",
            "space-between",
            "space-between",
            "center",
            "center",
            "center",
          ]}
          mb="6"
        >
          <IbcChainItem
            title="Source"
            icon={
              getChain(selectedToken) == "Cosmos"
                ? getIcon(selectedToken)
                : `/icons/${getChain(selectedToken).toLowerCase()}.svg`
            }
            name={getChain(selectedToken)}
            address={otherAddress && stringTruncate(otherAddress, 6)}
            handleSelect={handleSelect}
            showSelect={showSelect}
          />
          {/* <Box> */}
          <Image
            boxSize={[30, 30, 30, 42, 42, 42]}
            src="./icons/right_arrow.svg"
            alt="right arrow"
          />
          {/* </Box> */}
          <IbcChainItem
            title="Destination"
            icon="./icons/xprt.svg"
            name="Persistence"
            address={address && stringTruncate(address, 6)}
          />
        </Flex>
        <Box mb="6">
          <TokenInput
            amountInputName="amount"
            tokenInputName="token"
            token={selectedToken}
            max={balance}
            control={control}
            hideSelect
            isDeposit
          />
        </Box>
        <Button
          variant="primary"
          type="submit"
          w="full"
          size="xl"
          isDisabled={
            amount === "" ||
            Number(amount) === 0 ||
            num(balance).lt(num(amount))
          }
          // onClick={() => setIsConfirmStep(true)}
        >
          Deposit
        </Button>
      </Box>
    );
  };

  const CustomCloseButton = ({ closeToast }) => (
    <CloseButton
      pos={"absolute"}
      left="0"
      top={"0"}
      color="#FCFCFC"
      w={34}
      h={34}
      borderRadius={"50%"}
      border={"2px solid #386794"}
      bg={"#0a1d32"}
      onClick={() => {
        handleClose();
        closeToast();
      }}
    />
  );

  const renderFinal = () => {
    if (isLoading && depositSuccessData == null) {
      return toast(<TxPending />, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: false,
        toastId: " ",
        closeButton: CustomCloseButton,
      });
    } else {
      if (isError) {
        return toast.update(" ", {
          render: <TxError />,
          closeButton: CustomCloseButton,
          autoClose: 7000,
        });
      }

      // if (data == null) {
      //   return null;
      // }
      return toast.update(" ", {
        render: () => (
          <TxSuccess
            subtitle="Assets Successfully Deposited"
            data={depositSuccessData}
            title="Transaction Successful"
            href={remoteChainExplorer}
          />
        ),
        closeButton: CustomCloseButton,
        autoClose: 7000,
      });
    }
  };
  const hideTitle = isLoading || depositSuccessData != null || error != null;
  return !hideTitle ? (
    <Modal size="lg" isOpen={isOpen} isCentered onClose={handleClose} {...rest}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader mt="-4">
          Deposit Asset
          <ModalCloseButton mt="0" />
        </ModalHeader>
        <ModalBody>
          <chakra.form onSubmit={handleSubmit(onSubmit)}>
            {renderForm()}
            {/* {renderConfirm()} */}
            {/* {renderPending()}
            {renderFinal()} */}
          </chakra.form>
        </ModalBody>
      </ModalContent>
    </Modal>
  ) : (
    renderFinal()
  );
}
