import { Tr, Td, Button, Text, SimpleGrid, VStack } from "@chakra-ui/react";
import { NumberFormatSpecifier, formatAmount, num } from "@wizard-ui/core";
import {
  format,
  formatDistanceToNow,
  formatISO9075,
  fromUnixTime,
  isBefore,
} from "date-fns";
import { Wrapper } from "modules/common";
import { PoolBase } from "modules/pools";
import { useState } from "react";
import LazyHydrate from "react-lazy-hydration";
import { InstantUnbondModal } from "./InstantUnbondModal";
interface Props {
  pool: PoolBase;
  data: any;
  isMobile?: boolean;
  instantUnbondConfig: any;
}
export function LPTokensItem({
  pool,
  data,
  isMobile = false,
  instantUnbondConfig,
}: Props) {
  const [showInstantUnbond, setShowInstantUnbond] = useState(false);
  const amountInUsd = pool.getLpAmountInUsd(data.amount);
  const unlockDate = fromUnixTime(data.unlock_time);
  const unbondingTime = formatDistanceToNow(new Date(unlockDate));
  // const hasUnbonded = isBefore(unlockDate, new Date());
  const hasUnbonded = pool.hasUnbonded;
  return (
    <>
      {isMobile ? (
        <Wrapper variant="secondary" px={4}>
          <SimpleGrid columns={2} spacing={2} gap={0} rowGap={6}>
            <VStack align={"flex-start"} spacing={1}>
              <Text>Liquidity Amount</Text>
              <Text color={"#FCFCFC"} fontWeight={700} fontSize={18}>
                {formatAmount(num(data.amount).div(10 ** 12), {
                  formatSpecifier: NumberFormatSpecifier.FLOAT,
                  shouldDivide: true,
                })}{" "}
                LP Shares
              </Text>
            </VStack>
            <VStack align={"flex-end"} spacing={1}>
              <Text>Amount</Text>
              <Text color={"#FCFCFC"} fontWeight={700} fontSize={18}>
                {formatAmount(amountInUsd, {
                  formatSpecifier: NumberFormatSpecifier.CURRENCY,
                })}
              </Text>
            </VStack>
            <VStack align={"flex-start"} spacing={0}>
              <Text>Unbonding Time</Text>
              <Text color={"#FCFCFC"} fontWeight={700} fontSize={18}>
                {/* {" "}
                {unbondingTime}
                <br /> */}
                {format(unlockDate, "dd-MMM-yyyy, HH:mm")}
              </Text>
            </VStack>
            <VStack align={"flex-end"} spacing={0}>
              <Text>Status</Text>
              <Text color={"#FCFCFC"} fontWeight={700} fontSize={18}>
                {hasUnbonded ? "Unbonded" : "Unbonding"}
              </Text>
            </VStack>
          </SimpleGrid>
          {instantUnbondConfig?.instant_unbond_config == "disabled" ? (
            <Button mt={4} variant="secondary" size="sm" w="full">
              Claim
            </Button>
          ) : (
            <Button mt={4} variant="secondary" size="sm" w="full">
              Instant Unlock
            </Button>
          )}
        </Wrapper>
      ) : (
        <Tr>
          <Td textAlign="center" px={0} w={"20%"}>
            {formatAmount(num(data.amount).div(10 ** 12), {
              formatSpecifier: NumberFormatSpecifier.FLOAT,
              shouldDivide: true,
            })}{" "}
            LP Shares
          </Td>
          <Td textAlign="center" px={0} w={"20%"}>
            {formatAmount(amountInUsd, {
              formatSpecifier: NumberFormatSpecifier.CURRENCY,
            })}
          </Td>
          <Td textAlign="center" px={0} w={"20%"}>
            {/* {unbondingTime}
            <br /> */}
            {format(unlockDate, "dd-MMM-yyyy, HH:mm")}
          </Td>
          <Td textAlign="center" px={0} w={"20%"}>
            {hasUnbonded ? "Unbonded" : "Unbonding"}
          </Td>
          <Td textAlign="center" px={0} w={"20%"}>
            {instantUnbondConfig?.instant_unbond_config == "disabled" ? (
              <Button
                variant="secondary"
                size="sm"
                isDisabled
                w={150}
                //  onClick={() => setShowInstantUnbond(true)}
              >
                Claim
              </Button>
            ) : (
              <Button
                variant="secondary"
                size="sm"
                // isDisabled
                w={150}
                onClick={() => setShowInstantUnbond(true)}
              >
                Instant Unlock
              </Button>
            )}
          </Td>
        </Tr>
      )}
      {showInstantUnbond ? (
        <LazyHydrate whenVisible>
          <InstantUnbondModal
            lpAmount={data.amount}
            unlockTime={data.unlock_time}
            pool={pool}
            isOpen={showInstantUnbond}
            onClose={() => setShowInstantUnbond(false)}
          />
        </LazyHydrate>
      ) : null}
    </>
  );
}
