import React, { useEffect, useState } from "react";
import {
  Flex,
  Box,
  useMediaQuery,
  useDisclosure,
  Alert,
  AlertTitle,
  CloseButton,
  Link as ChakraLink,
} from "@chakra-ui/react";

import { Navbar, Footer, useDexter } from "modules/common";
import { Player } from "@lottiefiles/react-lottie-player";
import loading from "./loading.json";
import { NavbarMobile } from "./NavbarMobile";
import { useSessionStorageState } from "ahooks";
import Link from "next/link";
import CountryBanner from "./CountryBanner";
import { useRouter } from "next/router";
interface Props {
  children: React.ReactNode;
}

export function Layout({ children }: Props) {
  const router = useRouter();
  const path = router.asPath;
  const ipfsMatch = /.*\/Qm\w{44}\//.exec(path);

  const base = ipfsMatch ? ipfsMatch[0] : "/";
  const { backendEndpoint } = useDexter();
  const [isMobile] = useMediaQuery("(max-width: 800px)");

  const { onClose } = useDisclosure({ defaultIsOpen: true });

  const [bannerOpen, setBannerOpen] = useSessionStorageState("banner", {
    defaultValue: true,
  });

  const handleBannerClose = () => {
    setBannerOpen(false);
    onClose();
  };

  const [isNavVisible, setIsNavVisible] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const isVisible =
        prevScrollPos > currentScrollPos || currentScrollPos < 10;
      setIsNavVisible(isVisible);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsScrolled(scrollTop > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (backendEndpoint === null) {
    return (
      <>
        <Flex
          position={"fixed"}
          top={0}
          left={0}
          right={0}
          bottom={0}
          w={"100%"}
          h={"100%"}
          zIndex={9999}
          justify="center"
          alignItems={"center"}
          flexDirection="column"
          // bg="linear-gradient(0deg, #00A5BC 0%, #008BA2 5%, #006279 14.38%, #004259 23.57%, #002B42 32.38%, #001D34 40.66%, #001930 47.86%, #001529 61.5%, #000B16 80.89%, #000000 98.33%);"
          // h={"100vh"}
          backdropFilter="blur(15px)"
        >
          <Player
            src={loading}
            autoplay
            loop
            style={{ width: "250px", margin: 0 }}
          />
        </Flex>
        <Flex
          zIndex={999}
          pos="relative"
          minH="100vh"
          bg="linear-gradient(0deg, #00A5BC 0%, #008BA2 5%, #006279 14.38%, #004259 23.57%, #002B42 32.38%, #001D34 40.66%, #001930 47.86%, #001529 61.5%, #000B16 80.89%, #000000 98.33%);"
          flexDir="column"
          justify="space-between"
        >
          <Box
            pos="absolute"
            w="full"
            h="full"
            overflow="hidden"
            bgImage={"./images/bg_stars.png"}
          />
          {!isMobile ? <Navbar /> : <NavbarMobile />}
          <Footer />
        </Flex>
      </>
    );
  }

  return (
    <Flex
      pos="relative"
      minH="100vh"
      bg={[
        "linear-gradient(0deg, #010203 -15.17%, #031424 9.74%, #000000 86.6%);",
        "linear-gradient(0deg, #010203 -15.17%, #031424 9.74%, #000000 86.6%);",
        "linear-gradient(0deg, #00A5BC 0%, #008BA2 5%, #006279 14.38%, #004259 23.57%, #002B42 32.38%, #001D34 40.66%, #001930 47.86%, #001529 61.5%, #000B16 80.89%, #000000 98.33%);",
        "linear-gradient(0deg, #00A5BC 0%, #008BA2 5%, #006279 14.38%, #004259 23.57%, #002B42 32.38%, #001D34 40.66%, #001930 47.86%, #001529 61.5%, #000B16 80.89%, #000000 98.33%);",
        "linear-gradient(0deg, #00A5BC 0%, #008BA2 5%, #006279 14.38%, #004259 23.57%, #002B42 32.38%, #001D34 40.66%, #001930 47.86%, #001529 61.5%, #000B16 80.89%, #000000 98.33%);",
      ]}
      flexDir="column"
      justify="space-between"
    >
      <Box
        pos="absolute"
        w="full"
        h="full"
        overflow="hidden"
        bgImage={"./images/bg_stars.png"}
      />
      <CountryBanner />
      {bannerOpen ? (
        <Alert
          justifyContent="center"
          pos={"fixed"}
          zIndex={4}
          // display={"flex"}
          // flexDir="row"
          // height={34}
          height={["56px", "56px", "56px", "34px", "34px", "34px"]}
          bg={isScrolled ? "black" : "#072129"}
        >
          <AlertTitle fontSize={["14", "14", "16", "16", "16", "16"]}>
            stkXPRT/XPRT pool with Superfluid LP is now{" "}
            <ChakraLink
              as={Link}
              href={`${base}/pools/persistence1v2efcqkp2qtev06t0ksjnx6trxdd0f7fxg2zdrtzr8cr9wdpjkyq8r0cyc`}
              fontWeight={700}
              color="#2db8e2"
              textDecoration={"underline"}
              textUnderlineOffset={2}
            >
              LIVE!
            </ChakraLink>
          </AlertTitle>
          <CloseButton
            position={"absolute"}
            right={[1, 1, 2, 4, 4, 4]}
            onClick={handleBannerClose}
          />
        </Alert>
      ) : null}
      <Box
        pos={"fixed"}
        top={bannerOpen ? ["56px", "56px", "56px", "34px", "34px", "34px"] : 0}
        // top={0}
        width={"100%"}
        // h={24}
        bg={"#00050B"}
        zIndex={"4"}
        transition="transform 0.3s ease-in-out"
        transform={isNavVisible ? "translateY(0)" : "translateY(-200%)"}
      >
        {isMobile ? <NavbarMobile /> : <Navbar />}
      </Box>
      <Box flex="1" pos="relative" zIndex="2" mt={[40, 36, 40, 24, 24, 24]}>
        {/* <Box flex="1" pos="relative" zIndex="2"> */}
        {children}
      </Box>
      <Footer />
    </Flex>
  );
}
