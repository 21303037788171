import { MsgStakeToLP } from "persistenceonejs/pstake/liquidstake/v1beta1/tx";
import { AminoMsg, Coin } from "@cosmjs/amino";
import { AminoConverters } from "@cosmjs/stargate";

import { PERSISTENCE_STAKE_TO_LP_URL } from "config";
import {MsgExecuteContract} from "cosmjs-types/cosmwasm/wasm/v1/tx";

export interface AminoMsgStakeToLP extends AminoMsg {
  readonly type: "liquidstake/MsgStakeToLP";
  readonly value: {
    readonly delegator_address: string;
    readonly validator_address: string;
    readonly staked_amount: Coin;
    readonly liquid_amount: Coin;
  };
}

export interface AminoMsgCosmWasm extends AminoMsg {
  readonly type: "wasm/MsgExecuteContract";
  readonly value: {
    readonly sender: string;
    readonly contract: string;
    readonly msg: Uint8Array;
    readonly funds: Coin[];
  };
}

export function createStakeToLpAminoConverters(): AminoConverters {
  return {
    [PERSISTENCE_STAKE_TO_LP_URL]: {
      aminoType: "liquidstake/MsgStakeToLP",
      toAmino: ({
        delegatorAddress,
        validatorAddress,
        stakedAmount,
        liquidAmount,
      }: MsgStakeToLP): AminoMsgStakeToLP["value"] => ({
        delegator_address: delegatorAddress,
        validator_address: validatorAddress,
        staked_amount: stakedAmount,
        liquid_amount: liquidAmount,
      }),
      fromAmino: ({
        delegator_address,
        validator_address,
        staked_amount,
        liquid_amount,
      }: AminoMsgStakeToLP["value"]): MsgStakeToLP => ({
        delegatorAddress: delegator_address,
        validatorAddress: validator_address,
        stakedAmount: staked_amount,
        liquidAmount: liquid_amount,
      }),
    },
  };
}
//
// ["/cosmwasm.wasm.v1.MsgExecuteContract"]: {
//   aminoType: "wasm/MsgExecuteContract",
//   toAmino: ({
//     sender,
//     contract,
//     msg,
//     funds,
//   }: MsgExecuteContract): AminoMsgCosmWasm["value"] => ({
//     sender: sender,
//     contract: contract,
//     msg: msg,
//     funds: funds,
//   }),
//   fromAmino: ({
//     sender,
//     contract,
//     msg,
//     funds,
//   }: AminoMsgCosmWasm["value"]): MsgExecuteContract => ({
//     sender: sender,
//     contract: contract,
//     msg: msg,
//     funds: funds,
//   }),
// },
