import {
  AssetInfo,
  CW20AssetInfo,
  NativeAssetInfo,
  Asset,
  Pool,
} from "modules/common";

export function isNativeAssetInfo(
  value: NativeAssetInfo | CW20AssetInfo,
): value is NativeAssetInfo {
  return value.hasOwnProperty("native_token");
}

export const isNativeToken = (token = ""): boolean => {
  return (
    token.startsWith("u") ||
    token.startsWith("ibc") ||
    token.startsWith("stk") ||
    token.startsWith("staked")
  );
};

export const isNativeAsset = (info: AssetInfo): boolean => {
  return "native_token" in info;
};

export const toAssetInfo = (token: string): AssetInfo => {
  if (isNativeToken(token)) {
    return { native_token: { denom: token } };
  }

  return { token: { contract_addr: token } };
};

type ToAssetOpts = {
  amount: string;
  token: string;
};

export const toAsset = ({ amount, token }: ToAssetOpts) => {
  return {
    amount,
    info: toAssetInfo(token),
  };
};

export const getTokenDenom = (info: AssetInfo): string => {
  if (isNativeAssetInfo(info)) {
    return info.native_token.denom;
  }

  return info.token.contract_addr;
};

export const stringTruncate = (str: string, length = 7, limit = 10) => {
  if (str.length > limit) {
    return (
      str.substring(0, length) +
      "..." +
      str.substring(str.length - length, str.length)
    );
  }
  return str;
};

export function byObjectValues(keys) {
  return function (a, b) {
    const firstKey = keys[0];
    const isSimple = typeof firstKey === "string";
    const key = isSimple ? firstKey : firstKey.key;
    const rev = isSimple ? false : !!firstKey.reverse;
    const map = isSimple ? null : firstKey.map || null;

    const valA = map ? map(a[key]) : a[key];
    const valB = map ? map(b[key]) : b[key];
    if (valA === valB) {
      if (keys.length === 1) {
        return 0;
      }
      return byObjectValues(keys.slice(1))(a, b);
    }
    if (rev) {
      return valA > valB ? -1 : 1;
    }
    return valA > valB ? 1 : -1;
  };
}

export const MIN_FEE = 0.02;

function gcd(a, b) {
  if (b === 0) return a;
  return gcd(b, a % b);
}

function gcdOfArray(arr) {
  let result = arr[0];
  for (let i = 1; i < arr.length; i++) {
    result = gcd(result, arr[i]);
  }
  return result;
}

export function normalizeWeights(tokenWeights) {
  // Extract the weights from the array of objects
  let weights = tokenWeights.map((obj) => Math.round(obj.weight * 100));

  // Find the GCD of the whole weights
  let commonDivisor = gcdOfArray(weights);

  // Normalize the weights by dividing each weight by the GCD
  let normalizedWeights = weights.map((w) => w / commonDivisor);

  // Map the normalized weights back to the tokens
  return tokenWeights.map((obj, index) => ({
    token: obj.token,
    weight: normalizedWeights[index].toString(),
  }));
}
