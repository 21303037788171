export default {
  label: {
    color: "whiteAlpha.900",
  },
  value: {
    fontWeight: "700",
  },
  dexterP0: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontWeight: 600,
    fontSize: 26,
    lineHeight: 1.5,
  },
  dexterP1: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontWeight: 600,
    fontSize: 24,
    lineHeight: 1.5,
  },
  dexterP2: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontWeight: 600,
    fontSize: 20,
    lineHeight: 1.5,
  },
  dexterP3: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontWeight: 600,
    fontSize: 16,
    lineHeight: 1.5,
  },
  dexterP4: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontWeight: 600,
    fontSize: 14,
    lineHeight: 1.5,
  },
  dexterP5: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontWeight: 400,
    fontSize: 12,
    lineHeight: 1.5,
  },
  dexterP6: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontWeight: 300,
    fontSize: 14,
    lineHeight: 1.5,
    color: "#FCFCFC",
  },
  dexterBtnLarge: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontWeight: 700,
    fontSize: 20,
    lineHeight: 1.5,
  },
  dexterBtnMedium: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontWeight: 700,
    fontSize: 16,
    lineHeight: 1.5,
  },
  dexterBtnSmall: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontWeight: 700,
    fontSize: 12,
    lineHeight: 1.5,
  },
  dexterCaption: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontWeight: 400,
    fontSize: 12,
    lineHeight: 1.5,
  },
  dexterH0: {
    fontFamily: "'Jost', sans-serif",
    fontWeight: 500,
    fontSize: 26,
    lineHeight: 1.5,
    color: "#FCFCFC",
  },
  dexterH1: {
    fontFamily: "'Jost', sans-serif",
    fontWeight: 500,
    fontSize: 24,
    lineHeight: 1.5,
    color: "#FCFCFC",
  },
  dexterH2: {
    fontFamily: "'Jost', sans-serif",
    fontWeight: 500,
    fontSize: 22,
    lineHeight: 1.5,
    color: "#FCFCFC",
  },
  dexterH3: {
    fontFamily: "'Jost', sans-serif",
    fontWeight: 400,
    fontSize: 20,
    lineHeight: 1.5,
    color: "#FCFCFC",
  },
  dexterH4: {
    fontFamily: "'Jost', sans-serif",
    fontWeight: 500,
    fontSize: 16,
    lineHeight: 1.5,
    color: "#FCFCFC",
  },
  dexterLandingHeroTitle: {
    fontFamily: "'Jost', sans-serif",
    fontWeight: 600,
    fontSize: 40,
    lineHeight: "51px",
  },
  dexterLandingSectionTitle: {
    fontFamily: "'Jost', sans-serif",
    fontWeight: 600,
    fontSize: 48,
    lineHeight: 1.5,
  },
};
