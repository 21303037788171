import { useMemo } from "react";
import { num } from "@wizard-ui/core";

import { useDexter } from "modules/common";
import { lpToTokens, PoolBase } from "modules/pools";

export function useTotalBondedInUsd() {
  const { pools, prices, bondedLpTokens, tokens } = useDexter();
  return useMemo(() => {
    if (prices == null || pools == null || bondedLpTokens == null) {
      return 0;
    }

    const poolsWithBondedLp = pools.filter((pool) => {
      const token = pool.lpToken;
      const bondedInfo = bondedLpTokens[token];
      if (bondedInfo == null) {
        return null;
      }

      return num(bondedInfo);
    });

    const allTokens = poolsWithBondedLp.reduce((acc: any, pool: PoolBase) => {
      const token = pool.lpToken;
      const bondedInfo = bondedLpTokens[token];
      if (bondedInfo == null) {
        return acc;
      }

      const tokens = lpToTokens({
        amount: bondedInfo,
        assets: pool.assets,
        totalSupply: pool.lpTokenSupply,
      });

      return [...acc, ...tokens];
    }, []);
    const valueInUsd = allTokens.reduce((acc: number, value: any) => {
      return num(value.amount)
        .div(10 ** tokens[value.token].decimals)
        .times(prices[value.token]?.price)
        .plus(acc);
    }, 0);

    return valueInUsd.toFixed(6);
  }, [prices, pools, bondedLpTokens]);
}
