import { stkXprtCValueEndpoint } from "config";
import { useEffect, useState } from "react";

export function useStkXprtCValue() {
  const [cValue, setCValue] = useState(0.99);

  useEffect(() => {
    const getStkXprtCValue = async () => {
      const cValueData = await fetch(stkXprtCValueEndpoint);
      const cValue = await cValueData.json();
      setCValue(cValue);
    };

    getStkXprtCValue();
  });
  return cValue;
}
