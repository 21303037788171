import { useQuery } from "@tanstack/react-query";
import { num } from "@wizard-ui/core";
import { request, gql } from "graphql-request";
import { DenomHelper } from "@keplr-wallet/common";

import { useDexter } from "modules/common";

export function useMyAssets() {
  const { tokens, balances } = useDexter();
  if (tokens == null) {
    return [];
  }
  return Object.values(tokens).map((token) => {
    return { token: token.identifier, hasIBC: token.ibc_asset };
  });
  // return useQuery(["posts"], async () => {
  //   return request(
  //     "https://graph.devnet.core.dexter.zone/subgraphs/name/dexter",
  //     gql`
  //       query {
  //         pools {
  //           id
  //           poolId
  //           poolType
  //           vaultContractAddress
  //           lpTokenName
  //           lpTokenSymbol
  //           lpTokensInSupply
  //           assets
  //         }
  //         poolAssets {
  //           id
  //           identifier
  //           pool {
  //             id
  //           }
  //           assetType
  //           amount
  //         }
  //       }
  //     `,
  //   );
  // });
}
