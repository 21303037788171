import React, { useMemo } from "react";
import { Box, Flex, Text, HStack } from "@chakra-ui/react";
import { NumberFormatSpecifier, formatAmount, num } from "@wizard-ui/core";

import { useTokenInfo, TokenIcon, useDexter } from "modules/common";

type Props = {
  token: string;
  amount: string | null;
};

export function LpAsset({ token, amount }: Props) {
  const { getSymbol } = useTokenInfo();
  const { prices } = useDexter();
  const totalPrice = useMemo(() => {
    if (amount == null || prices == null) {
      return null;
    }

    return num(prices[token].price).times(amount).toString();
  }, [amount, prices, token]);

  return (
    <Flex justify="space-between" gap="10" w="full">
      <HStack spacing="2">
        <TokenIcon token={token} size="6" />
        <Text fontWeight="400" fontSize="lg">
          {getSymbol(token)}
        </Text>
      </HStack>
      <Box textAlign="right">
        <HStack spacing="1">
          <Text fontWeight="600" fontSize="md">
            {amount}
          </Text>
          <Text color="whiteAlpha.800" fontSize="xs" fontWeight="400">
            (~
            {formatAmount(totalPrice, {
              formatSpecifier: NumberFormatSpecifier.CURRENCY,
            })}
            )
          </Text>
        </HStack>
      </Box>
    </Flex>
  );
}
