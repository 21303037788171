export default function TwitterIcon() {
  return (
    <svg
      width="15"
      height="12"
      viewBox="0 0 15 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4252 2.83202C13.4338 2.95544 13.4338 3.07886 13.4338 3.20342C13.4338 6.99878 10.5487 11.376 5.27325 11.376V11.3737C3.71487 11.376 2.18886 10.9289 0.876953 10.086C1.10355 10.1133 1.33129 10.127 1.5596 10.1275C2.85106 10.1287 4.1056 9.69471 5.12162 8.8956C3.89433 8.87228 2.81812 8.07089 2.44215 6.90095C2.87207 6.98399 3.31505 6.96692 3.73702 6.85147C2.39899 6.58073 1.43636 5.4034 1.43636 4.03609C1.43636 4.02358 1.43636 4.01163 1.43636 3.99969C1.83504 4.22208 2.28143 4.3455 2.73804 4.35915C1.47782 3.51567 1.08936 1.83668 1.85038 0.52398C3.30653 2.31843 5.45499 3.40931 7.76133 3.52477C7.53019 2.52716 7.84595 1.48178 8.59107 0.780492C9.74623 -0.306983 11.563 -0.251244 12.6489 0.905051C13.2912 0.778217 13.9068 0.54218 14.4702 0.207748C14.2561 0.872631 13.808 1.43741 13.2094 1.7963C13.7779 1.72919 14.3333 1.57676 14.8564 1.34414C14.4714 1.922 13.9863 2.42535 13.4252 2.83202Z"
        fill="currentColor"
      />
    </svg>
  );
}
