import { CosmWasmClient } from "@cosmjs/cosmwasm-stargate";
import { num } from "@wizard-ui/core";
import { toAssetInfo } from "modules/common";

interface GetBalancesArgs {
  client?: CosmWasmClient | null;
  tokens?: string[] | null;
  address: string;
}

export async function getBalances({
  client,
  tokens,
  address,
}: GetBalancesArgs) {
  if (address == null || client == null || tokens == null) {
    throw new Error("Error in getBalances");
  }

  const balances = await Promise.all(
    tokens?.map(async (token) => {
      if (token.startsWith("u")) {
        const data = await client.getBalance(address, token);

        return { [token]: data.amount };
      }
      const data = await client.queryContractSmart(token, {
        balance: { address },
      });

      return { [token]: data.balance };
    }),
  );
  return balances.reduce((acc, value) => {
    return { ...acc, ...value };
  }, {});
}

interface GetBondedLpTokensArgs {
  client?: CosmWasmClient | null;
  lpTokens?: string[] | null;
  address: string;
  multistaking: string;
}

export async function getBondedLpTokens({
  client,
  multistaking,
  lpTokens,
  address,
}: GetBondedLpTokensArgs) {
  if (address == null || client == null || lpTokens == null) {
    throw new Error("Error in getBondedLpTokens");
  }

  const bondedLpTokens = await Promise.all(
    lpTokens?.map(async (lpToken: string) => {
      const data = await client.queryContractSmart(multistaking, {
        bonded_lp_tokens: {
          lp_token: lpToken,
          user: address,
        },
      });

      return { [lpToken]: data };
    }),
  );

  return bondedLpTokens.reduce((acc, value) => {
    return { ...acc, ...value };
  }, {});
}

interface GetTokenLocksArgs {
  client?: CosmWasmClient | null;
  lpTokens?: string[] | null;
  address: string;
  multistaking: string;
}

export async function getTokenLocks({
  client,
  multistaking,
  lpTokens,
  address,
}: GetTokenLocksArgs) {
  if (address == null || client == null || lpTokens == null) {
    throw new Error("Error in getBondedLpTokens");
  }

  const tokenLocks = await Promise.all(
    lpTokens?.map(async (lpToken: string) => {
      const data = await client.queryContractSmart(multistaking, {
        token_locks: {
          lp_token: lpToken,
          user: address,
        },
      });

      return { [lpToken]: data };
    }),
  );

  return tokenLocks.reduce((acc, value) => {
    return { ...acc, ...value };
  }, {});
}

interface GetRawTokenLocksArgs {
  client?: CosmWasmClient | null;
  lpToken?: string | null;
  address: string;
  multistaking: string;
}

export async function getRawTokenLocks({
  client,
  multistaking,
  lpToken,
  address,
}: GetRawTokenLocksArgs) {
  if (address == null || client == null || lpToken == null) {
    throw new Error("Error in getRawTokenLocks");
  }

  const rawTokenLocks = await client.queryContractSmart(multistaking, {
    raw_token_locks: {
      lp_token: lpToken,
      user: address,
    },
  });

  return rawTokenLocks;
}

interface GetInstantUnlockFeeTiersArgs {
  client?: CosmWasmClient | null;
  multistaking: string;
  lpToken?: string | null;
}

export async function getInstantUnlockFeeTiers({
  client,
  multistaking,
  lpToken,
}: GetInstantUnlockFeeTiersArgs) {
  if (client == null) {
    throw new Error("Error in getInstantUnlockFeeTiers");
  }

  const rawTokenLocks = await client.queryContractSmart(multistaking, {
    instant_unlock_fee_tiers: {
      lp_token: lpToken,
    },
  });

  return rawTokenLocks;
}

interface GetInstantUnlockFeeArgs {
  client?: CosmWasmClient | null;
  lpToken?: string | null;
  address: string;
  multistaking: string;
  token_lock: {
    amount: string;
    unlock_time: number;
  };
}

export async function getInstantUnlockFee({
  client,
  multistaking,
  lpToken,
  address,
  token_lock,
}: GetInstantUnlockFeeArgs) {
  if (address == null || client == null || lpToken == null) {
    throw new Error("Error in getInstantUnlockFee");
  }

  const instant_unlock_fee = await client.queryContractSmart(multistaking, {
    instant_unlock_fee: {
      user: address,
      lp_token: lpToken,
      token_lock,
    },
  });

  return instant_unlock_fee;
}

interface GetPendingRewardsArgs {
  client?: CosmWasmClient | null;
  lpTokens?: string[] | null;
  address: string;
  multistaking: string;
}

export async function getPendingRewards({
  client,
  multistaking,
  lpTokens,
  address,
}: GetPendingRewardsArgs) {
  if (address == null || client == null || lpTokens == null) {
    throw new Error("Error in getPendingRewards");
  }

  const rewards = await Promise.all(
    lpTokens.map(async (lpToken: string) => {
      try {
        const unclaimedRewards = await client.queryContractSmart(multistaking, {
          unclaimed_rewards: {
            lp_token: lpToken,
            user: address,
          },
        });

        return { [lpToken]: unclaimedRewards };
      } catch {
        return { [lpToken]: [] };
      }
    }),
  );

  return rewards.reduce((acc, value) => {
    return { ...acc, ...value };
  }, {});
}

export async function getWhitelistedLpTokens({
  client,
  multistaking,
  address,
}: GetPendingRewardsArgs) {
  if (address == null || client == null) {
    throw new Error("Error in getWhitelistedLpTokens");
  }

  const whitelistedTokens = await client.queryContractSmart(multistaking, {
    allowed_l_p_tokens_for_reward: {},
  });
  return whitelistedTokens;
}

export async function getInstantUnbondConfig({
  client,
  multistaking,
  lpToken,
  address,
}: GetRawTokenLocksArgs) {
  if (address == null || client == null || lpToken == null) {
    throw new Error("Error in getInstantUnbondConfig");
  }

  const unbondConfig = await client.queryContractSmart(multistaking, {
    unbond_config: {
      lp_token: lpToken,
    },
  });

  return unbondConfig;
}

export async function getPoolsInfo(client, vault, count) {
  if (client == null) {
    throw new Error("Error in getPoolsInfo");
  }

  function awaitAll(count, asyncFn) {
    const promises = [];

    for (let i = 1; i < count; ++i) {
      promises.push(asyncFn(i.toString()));
    }

    return Promise.all(promises);
  }

  const getPoolInfo = async (id: string) => {
    try {
      const data = await client.queryContractSmart(vault, {
        get_pool_by_id: { pool_id: id },
      });
      return Promise.resolve(data);
    } catch {
      return null;
    }
  };

  const poolsData = await awaitAll(count, getPoolInfo);
  const lpTokensData = await Promise.all(
    poolsData?.map(async (pool) => {
      try {
        const data = await client.queryContractSmart(pool.lp_token_addr, {
          token_info: {},
        });

        return data;
      } catch {
        return null;
      }
    }),
  );
  const rewardsData = await Promise.all(
    poolsData?.map(async (pool) => {
      try {
        const data = await client.queryContractSmart(
          "persistence1ufs3tlq4umljk0qfe8k5ya0x6hpavn897u2cnf9k0en9jr7qarqqv0rkes",
          {
            reward_schedules: {
              lp_token: pool.lp_token_addr,
              asset: toAssetInfo("uxprt"),
            },
          },
        );

        return data;
      } catch {
        return null;
      }
    }),
  );
  return poolsData.map((pool, index) => {
    return {
      poolId: pool.pool_id,
      poolContractAddress: pool.pool_addr,
      poolType: { poolType: Object.keys(pool.pool_type)[0] },
      lpTokenContractAddress: pool.lp_token_addr,
      lpTokenName: lpTokensData[index].name,
      lpTokensInSupply: lpTokensData[index].total_supply,
      vaultContractAddress: vault,
      rewardAssets:
        rewardsData[index] !== null && rewardsData[index].length !== 0
          ? rewardsData[index][0].reward_schedule.asset.native_token.denom
          : null,
      poolAssets: pool.assets.map((asset) => {
        return {
          amount: asset.amount,
          identifier: asset.info.token
            ? asset.info.token.contract_addr
            : asset.info.native_token.denom,
        };
      }),
    };
  });
}

export async function getLpTokensReceived(userAssets, pool, client) {
  if (pool == null || userAssets == null || client == null) {
    throw new Error("Error in getLpTokensReceived");
  }
  try {
    const lpTokenReceived = await client.queryContractSmart(pool.address, {
      on_join_pool: {
        assets_in: userAssets,
      },
    });
    const prices = pool._prices;
    const initialPoolTvl = pool.assets
      .map((asset) => {
        const assetPrice = prices[asset.identifier].price;
        return num(asset.amount)
          .times(num(assetPrice))
          .div(
            asset.identifier ==
              "ibc/A6E3AF63B3C906416A9AF7A556C59EA4BD50E617EFFE6299B99700CCB780E444" ||
              asset.identifier ==
                "ibc/23DC3FF0E4CBB53A1915E4C62507CB7796956E84C68CA49707787CB8BDE90A1E" ||
              asset.identifier == "stk/adydx"
              ? 10 ** 18
              : asset.identifier ==
                  "ibc/CCA9F9B22D39884C09975D45E1869B73A12B87080EE53CB44905CE2C422CA228" ||
                asset.identifier ==
                  "ibc/5D3B6445EA1D7064C4B1CCB588638589529556E1BCBADF13475021B42EA8C73B"
              ? 10 ** 8
              : 10 ** 6,
          )
          .toString();
      })
      .reduce((acc, value) => {
        return num(acc).plus(value).toString();
      }, 0);
    // for every asset in the pool, get the price and multiply it by the amount
    // of the asset in the pool
    const userDepositTvl = userAssets
      .map((asset) => {
        const assetPrice = prices[asset.info.native_token.denom].price;
        return num(asset.amount)
          .times(num(assetPrice))
          .div(
            asset.info.native_token.denom ==
              "ibc/A6E3AF63B3C906416A9AF7A556C59EA4BD50E617EFFE6299B99700CCB780E444" ||
              asset.info.native_token.denom ==
                "ibc/23DC3FF0E4CBB53A1915E4C62507CB7796956E84C68CA49707787CB8BDE90A1E" ||
              asset.info.native_token.denom == "stk/adydx"
              ? 10 ** 18
              : asset.info.native_token.denom ==
                  "ibc/CCA9F9B22D39884C09975D45E1869B73A12B87080EE53CB44905CE2C422CA228" ||
                asset.info.native_token.denom ==
                  "ibc/5D3B6445EA1D7064C4B1CCB588638589529556E1BCBADF13475021B42EA8C73B"
              ? 10 ** 8
              : 10 ** 6,
          )
          .toString();
      })
      .reduce((acc, value) => {
        return num(acc).plus(value).toString();
      }, 0);

    const fee = lpTokenReceived.fee
      ? lpTokenReceived.fee.reduce((acc, value) => {
          return num(acc).plus(
            num(value.amount)
              .div(
                value.info.native_token.denom ==
                  "ibc/A6E3AF63B3C906416A9AF7A556C59EA4BD50E617EFFE6299B99700CCB780E444" ||
                  value.info.native_token.denom ==
                    "ibc/23DC3FF0E4CBB53A1915E4C62507CB7796956E84C68CA49707787CB8BDE90A1E" ||
                  value.info.native_token.denom == "stk/adydx"
                  ? 10 ** 18
                  : value.info.native_token.denom ==
                      "ibc/CCA9F9B22D39884C09975D45E1869B73A12B87080EE53CB44905CE2C422CA228" ||
                    value.info.native_token.denom ==
                      "ibc/5D3B6445EA1D7064C4B1CCB588638589529556E1BCBADF13475021B42EA8C73B"
                  ? 10 ** 8
                  : 10 ** 6,
              )
              .times(num(prices[value.info.native_token.denom]?.price)),
          );
        }, 0)
      : 0;

    const finalPoolTvl = num(initialPoolTvl)
      .plus(userDepositTvl)
      .minus(num(fee))
      .toString();

    const finalLpTokens1 = num(pool.lpTokenSupply).plus(
      num(lpTokenReceived.new_shares),
    );

    const finalLpTokens = finalLpTokens1.div(10 ** 18).toString();

    const finalPerLpTokenUsdValue = num(finalPoolTvl)
      .div(finalLpTokens)
      .toString();

    const userFinalUsdValue = num(lpTokenReceived.new_shares)
      .div(10 ** 18)
      .times(finalPerLpTokenUsdValue)
      .toString();

    const initialUsdPerLp = num(initialPoolTvl)
      .div(num(pool.lpTokenSupply).div(10 ** 18))
      .toString();

    const priceImpactRaw =
      userDepositTvl == 0
        ? "0"
        : num(finalPerLpTokenUsdValue)
            .times(num(lpTokenReceived.new_shares).div(10 ** 18))
            .minus(num(userDepositTvl))
            .div(userDepositTvl)
            .times(100)
            .toFixed(2);

    const priceImpact =
      Math.abs(Number(priceImpactRaw)) == 0
        ? Math.abs(Number(priceImpactRaw)).toFixed(2)
        : priceImpactRaw;
    return {
      lpTokenReceived: num(lpTokenReceived.new_shares)
        .div(10 ** 18)
        .toFixed(4),
      priceImpact,
    };
  } catch (error) {
    return {
      lpTokenReceived: null,
      priceImpact: null,
    };
  }
}
