import { useEffect, useMemo, useState } from "react";
import {
  ModalProps,
  Modal,
  Button,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  chakra,
  VStack,
  Box,
  CloseButton,
  Text,
  HStack,
  Checkbox,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { num } from "@wizard-ui/core";

import {
  toAsset,
  TxPending,
  TxSuccess,
  TxError,
  useDexter,
  MIN_FEE,
} from "modules/common";
import { PoolBase, tokenWeights } from "modules/pools";
import { getLpTokensReceived, useJoinPoolMutation } from "modules/contracts";
import { TokenInput } from "./TokenInput";
import TokenInfo from "./TokenInfo";

import { toast } from "react-toastify";
import { useChain, useManager } from "@cosmos-kit/react";
import { useCValue } from "../hooks/useCValue";
import { useDydxCValue } from "../hooks/useDydxCValue";
import { useLpTokensReceiveQuery } from "../hooks/useLpTokensReceiveQuery";
import { debounce } from "lodash";
import { useStkXprtCValue } from "../hooks/useStkXprtCValue";
import { useStarsCValue } from "../hooks/useStarsCValue";
import { useHuahuaCValue } from "../hooks/useHuahuaCValue";

type FormValues = {
  token1: string;
  amount1: string;
  token2: string;
  amount2: string;
};

interface AddLiquidityModalProps extends Omit<ModalProps, "children"> {
  pool: PoolBase;
}

export function NewAddLiquidityModal({
  pool,
  onClose,
  ...rest
}: AddLiquidityModalProps) {
  const { balances, tokens } = useDexter();
  const persistenceChain =
    process.env.NEXT_PUBLIC_ENV === "testnet"
      ? "persistencetestnet2"
      : process.env.NEXT_PUBLIC_ENV === "devnet"
      ? "Dexter Devnet"
      : "persistence";
  const chainContext = useChain(persistenceChain);
  const { getWalletRepo } = useManager();
  const walletRepo = getWalletRepo(persistenceChain);

  const stkAtomCValue = useCValue();
  const stkDydxCValue = useDydxCValue();
  const stkStarsCValue = useStarsCValue();
  const stkHuahuaCValue = useHuahuaCValue();
  const stkXprtCValue = useStkXprtCValue();

  // const { data: poolConfig, isInitialLoading: poolsIsInitialLoading } =
  //   useLpTokensReceiveQuery(pool.address);

  // console.log({ poolConfig });

  const { connect, address, isWalletConnected, getCosmWasmClient } =
    chainContext;
  const { mutate, isLoading, data, reset, error } = useJoinPoolMutation();
  const [isConfirmStep, setIsConfirmStep] = useState(false);
  const [tokenSelect, setTokenSelect] = useState({ token: "", index: 0 });
  const [singleAsset, setSingleAsset] = useState(false);
  const [lpReceived, setLpReceived] = useState("0");
  const [priceImpact, setPriceImpact] = useState("0");
  const [autoBond, setAutoBond] = useState(false);
  const poolTokens = pool.assets.map(({ identifier }) => identifier);
  const defaultValues = poolTokens.reduce((acc, value, index) => {
    return { ...acc, [`token${index + 1}`]: value, [`amount${index + 1}`]: "" };
  }, {});
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    getValues,
    reset: resetForm,
  } = useForm<FormValues>({
    mode: "onChange",
    defaultValues,
  });

  const watchValues = watch() as Record<string, any>;

  useEffect(() => {
    async function lpTokensReceived() {
      const client = await getCosmWasmClient();
      const assets = poolTokens.reduce((acc, token, i) => {
        if (!num(watchValues[`amount${i + 1}`]).isGreaterThan(0)) {
          return acc;
        }
        const amount = num(watchValues[`amount${i + 1}`])
          .times(
            token ==
              "ibc/A6E3AF63B3C906416A9AF7A556C59EA4BD50E617EFFE6299B99700CCB780E444" ||
              token ==
                "ibc/23DC3FF0E4CBB53A1915E4C62507CB7796956E84C68CA49707787CB8BDE90A1E" ||
              token == "stk/adv4tnt" ||
              token == "stk/adydx" ||
              token ==
                "ibc/18200EAA7E5BB3D235FF517F04045F4DCB0691CE6FC1B32E4297BEA8EF7710E3"
              ? 10 ** 18
              : token ==
                  "ibc/CCA9F9B22D39884C09975D45E1869B73A12B87080EE53CB44905CE2C422CA228" ||
                token ==
                  "ibc/5D3B6445EA1D7064C4B1CCB588638589529556E1BCBADF13475021B42EA8C73B"
              ? 10 ** 8
              : 10 ** 6,
          )
          .toFixed(0);

        return [...acc, toAsset({ token, amount })];
      }, []);
      const { lpTokenReceived, priceImpact } = await getLpTokensReceived(
        assets,
        pool,
        client,
      );
      setLpReceived(lpTokenReceived);
      setPriceImpact(priceImpact);
    }
    const debouncedLpTokensReceived = debounce(lpTokensReceived, 300);
    debouncedLpTokensReceived();
  }, [watchValues]);

  const weightsStable = useMemo(() => {
    if (pool == null) {
      return [];
    }

    return tokenWeights(
      pool,
      pool.id == 1
        ? stkAtomCValue
        : pool.id == 7
        ? stkDydxCValue
        : pool.id == 9
        ? stkStarsCValue
        : pool.id == 9
        ? stkHuahuaCValue
        : stkXprtCValue,
      2,
    );
  }, [pool]);

  const invalidLiquidityRatio = () => {
    const token1 = watchValues.token1;
    const token2 = watchValues.token2;
    const amount1 = watchValues.amount1;
    const amount2 = watchValues.amount2;

    const totalSum = num(amount1).plus(num(amount2));

    const ratio1 = num(amount1).div(totalSum).times(100);
    const ratio2 = num(amount2).div(totalSum).times(100);

    const weightToken1 = weightsStable.find((w) => w.token === token1);
    const weightToken2 = weightsStable.find((w) => w.token === token2);
    if (weightToken1.weight < weightToken2.weight) {
      if (
        ratio1.toFixed(2) < weightToken1?.weight &&
        ratio2.toFixed(2) > weightToken2?.weight
      ) {
        return true;
      } else {
        return false;
      }
    } else if (weightToken1.weight > weightToken2.weight) {
      if (
        ratio1.toFixed(2) > weightToken1?.weight &&
        ratio2.toFixed(2) < weightToken2?.weight
      ) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  };

  const handleAmountChangeAvailableBalance = (
    token: string,
    amount: string,
    input: string,
  ) => {
    const amounts = pool.getProvideAmounts(token, amount);
    const otherToken = amounts.filter((amount) => amount.input !== input)[0];

    if (singleAsset || pool._totalTokensInPool == 0) {
      amounts
        .filter((token) => token == tokenSelect.token)
        .map((a) => {
          setValue(a.input, a.amount);
        });
    } else {
      if (pool.poolType === "weighted") {
        if (
          num(otherToken.amount).gt(
            num(balances?.[otherToken.token]).div(
              10 ** tokens[otherToken.token].decimals,
            ),
          )
        ) {
          const newAmounts = pool.getProvideAmounts(
            otherToken.token,
            num(balances?.[otherToken.token])
              .div(10 ** tokens[otherToken.token].decimals)
              .minus(otherToken.token === "uxprt" ? num(MIN_FEE) : 0)
              .toString(),
          );
          newAmounts.forEach((a: any) => {
            if (a.input == otherToken.input) {
              setValue(
                a.input,
                num(balances?.[otherToken.token])
                  .div(10 ** tokens[otherToken.token].decimals)
                  .minus(otherToken.token === "uxprt" ? num(MIN_FEE) : 0)
                  .toString(),
              );
            } else {
              setValue(a.input, a.amount);
            }
          });
        } else {
          amounts.forEach((a: any) => {
            if (a.input == input) {
              return;
            }
            setValue(a.input, a.amount);
          });
        }
      } else if (pool.id === 1) {
        if (token === "stk/uatom") {
          let atomAmount = num(amount).div(stkAtomCValue).toString();
          let stkAtomAmount = amount;
          if (
            num(atomAmount).gt(
              num(
                balances?.[
                  "ibc/C8A74ABBE2AF892E15680D916A7C22130585CE5704F9B17A10F184A90D53BECA"
                ],
              ).div(10 ** 6),
            )
          ) {
            atomAmount = num(
              balances?.[
                "ibc/C8A74ABBE2AF892E15680D916A7C22130585CE5704F9B17A10F184A90D53BECA"
              ],
            )
              .div(10 ** 6)
              .toString();
            stkAtomAmount = num(atomAmount).times(stkAtomCValue).toString();
          }
          setValue("amount1", atomAmount);
          setValue("amount2", stkAtomAmount);
          return;
        } else {
          let stkAtomAmount = num(amount).times(stkAtomCValue).toString();
          let atomAmount = amount;
          if (
            num(stkAtomAmount).gt(num(balances?.["stk/uatom"]).div(10 ** 6))
          ) {
            stkAtomAmount = num(balances?.["stk/uatom"])
              .div(10 ** 6)
              .toString();
            atomAmount = num(stkAtomAmount).div(stkAtomCValue).toString();
          }
          setValue("amount2", stkAtomAmount);
          setValue("amount1", atomAmount);
          return;
        }
      } else if (pool.id === 7) {
        if (token === "stk/adydx") {
          let dydxAmount = num(amount).div(stkDydxCValue).toString();
          let stkDydxAmount = amount;
          if (
            num(dydxAmount).gt(
              num(
                balances?.[
                  "ibc/23DC3FF0E4CBB53A1915E4C62507CB7796956E84C68CA49707787CB8BDE90A1E"
                ],
              ).div(10 ** 18),
            )
          ) {
            dydxAmount = num(
              balances?.[
                "ibc/23DC3FF0E4CBB53A1915E4C62507CB7796956E84C68CA49707787CB8BDE90A1E"
              ],
            )
              .div(10 ** 18)
              .toString();
            stkDydxAmount = num(dydxAmount).times(stkDydxCValue).toString();
          }
          setValue("amount1", dydxAmount);
          setValue("amount2", stkDydxAmount);
          return;
        } else {
          let stkDydxAmount = num(amount).times(stkDydxCValue).toString();
          let dydxAmount = amount;
          if (
            num(stkDydxAmount).gt(num(balances?.["stk/adydx"]).div(10 ** 18))
          ) {
            stkDydxAmount = num(balances?.["stk/adydx"])
              .div(10 ** 18)
              .toString();
            dydxAmount = num(stkDydxAmount).div(stkDydxCValue).toString();
          }
          setValue("amount2", stkDydxAmount);
          setValue("amount1", dydxAmount);
          return;
        }
      } else if (pool.id === 9) {
        if (token === "stk/ustars") {
          let starsAmount = num(amount).div(stkStarsCValue).toString();
          let stkStarsAmount = amount;
          if (
            num(starsAmount).gt(
              num(
                balances?.[
                  "ibc/AD8E1D4AC4EA8FC79CC46E33319A3791477D4DEBFC30D5D874074B993422B41B"
                ],
              ).div(10 ** 6),
            )
          ) {
            starsAmount = num(
              balances?.[
                "ibc/AD8E1D4AC4EA8FC79CC46E33319A3791477D4DEBFC30D5D874074B993422B41B"
              ],
            )
              .div(10 ** 6)
              .toString();
            stkStarsAmount = num(starsAmount).times(stkStarsCValue).toString();
          }
          setValue("amount1", starsAmount);
          setValue("amount2", stkStarsAmount);
          return;
        } else {
          let stkStarsAmount = num(amount).times(stkStarsCValue).toString();
          let starsAmount = amount;
          if (
            num(stkStarsAmount).gt(num(balances?.["stk/ustars"]).div(10 ** 6))
          ) {
            stkStarsAmount = num(balances?.["stk/ustars"])
              .div(10 ** 6)
              .toString();
            starsAmount = num(stkStarsAmount).div(stkStarsCValue).toString();
          }
          setValue("amount2", stkStarsAmount);
          setValue("amount1", starsAmount);
          return;
        }
      } else if (pool.id === 11) {
        if (token === "stk/uhuahua") {
          let huahuaAmount = num(amount).div(stkHuahuaCValue).toString();
          let stkHuahuaAmount = amount;
          if (
            num(huahuaAmount).gt(
              num(
                balances?.[
                  "ibc/B597D779FCDD9021263C98A48F1AFA9D2BCCCE980F397CDE5681CCEDE7DEE1A4"
                ],
              ).div(10 ** 6),
            )
          ) {
            huahuaAmount = num(
              balances?.[
                "ibc/B597D779FCDD9021263C98A48F1AFA9D2BCCCE980F397CDE5681CCEDE7DEE1A4"
              ],
            )
              .div(10 ** 6)
              .toString();
            stkHuahuaAmount = num(huahuaAmount)
              .times(stkHuahuaCValue)
              .toString();
          }
          setValue("amount1", huahuaAmount);
          setValue("amount2", stkHuahuaAmount);
          return;
        } else {
          let stkHuahuaAmount = num(amount).times(stkHuahuaCValue).toString();
          let huahuaAmount = amount;
          if (
            num(stkHuahuaAmount).gt(num(balances?.["stk/uhuahua"]).div(10 ** 6))
          ) {
            stkHuahuaAmount = num(balances?.["stk/uhuahua"])
              .div(10 ** 6)
              .toString();
            huahuaAmount = num(stkHuahuaAmount).div(stkHuahuaCValue).toString();
          }
          setValue("amount2", stkHuahuaAmount);
          setValue("amount1", huahuaAmount);
          return;
        }
      } else if (pool.id === 12) {
        if (token === "stk/uxprt") {
          let xprtAmount = num(amount).div(stkXprtCValue).toString();
          let stkXprtAmount = amount;
          if (num(xprtAmount).gt(num(balances?.["uxprt"]).div(10 ** 6))) {
            xprtAmount = num(balances?.["uxprt"])
              .div(10 ** 6)
              .toString();
            stkXprtAmount = num(xprtAmount).times(stkXprtCValue).toString();
          }
          setValue("amount1", xprtAmount);
          setValue("amount2", stkXprtAmount);
          return;
        } else {
          let stkXprtAmount = num(amount).times(stkXprtCValue).toString();
          let xprtAmount = amount;
          if (
            num(stkXprtAmount).gt(num(balances?.["stk/uxprt"]).div(10 ** 6))
          ) {
            stkXprtAmount = num(balances?.["stk/uxprt"])
              .div(10 ** 6)
              .toString();
            xprtAmount = num(stkXprtAmount).div(stkXprtCValue).toString();
          }
          setValue("amount2", stkXprtAmount);
          setValue("amount1", xprtAmount);
          return;
        }
      } else {
        setValue(token, amount);
      }
    }
  };
  const handleAmountChange = (token: string, amount: string, input: string) => {
    const amounts = pool.getProvideAmounts(token, amount);
    if (pool.id === 1) {
      if (token === "stk/uatom") {
        const atomAmount = num(amount).div(stkAtomCValue).toString();
        setValue("amount1", atomAmount);
        setValue("amount2", amount);
        return;
      } else {
        const stkAtomAmount = num(amount).times(stkAtomCValue).toString();
        setValue("amount2", stkAtomAmount);
        setValue("amount1", amount);
        return;
      }
    } else if (pool.id === 7) {
      if (token === "stk/adydx") {
        const dydxAmount = num(amount).div(stkDydxCValue).toString();
        setValue("amount1", dydxAmount);
        setValue("amount2", amount);
        return;
      } else {
        const stkDydxAmount = num(amount).times(stkDydxCValue).toString();
        setValue("amount2", stkDydxAmount);
        setValue("amount1", amount);
        return;
      }
    } else if (pool.id === 9) {
      if (token === "stk/ustars") {
        const starsAmount = num(amount).div(stkStarsCValue).toString();
        setValue("amount1", starsAmount);
        setValue("amount2", amount);
        return;
      } else {
        const stkStarsAmount = num(amount).times(stkStarsCValue).toString();
        setValue("amount2", stkStarsAmount);
        setValue("amount1", amount);
        return;
      }
    } else if (pool.id === 11) {
      if (token === "stk/uhuahua") {
        const huahuaAmount = num(amount).div(stkHuahuaCValue).toString();
        setValue("amount1", huahuaAmount);
        setValue("amount2", amount);
        return;
      } else {
        const stkHuahuaAmount = num(amount).times(stkHuahuaCValue).toString();
        setValue("amount2", stkHuahuaAmount);
        setValue("amount1", amount);
        return;
      }
    } else if (pool.id === 12) {
      if (token === "stk/uxprt") {
        const xprtAmount = num(amount).div(stkXprtCValue).toString();
        setValue("amount1", xprtAmount);
        setValue("amount2", amount);
        return;
      } else {
        const stkXprtAmount = num(amount).times(stkXprtCValue).toString();
        setValue("amount2", stkXprtAmount);
        setValue("amount1", amount);
        return;
      }
    }
    if (singleAsset || pool._totalTokensInPool == 0) {
      amounts
        .filter((token) => token == tokenSelect.token)
        .map((a) => {
          setValue(a.input, a.amount);
        });
    } else {
      if (pool.poolType === "weighted") {
        amounts.forEach((a: any) => {
          if (a.input == input) {
            return;
          }
          setValue(a.input, a.amount);
        });
      } else {
        setValue(token, amount);
      }
    }
  };

  const onSubmit = (values: FormValues) => {
    // @ts-expect-error - TODO
    const assets = poolTokens.reduce((acc, token, i) => {
      // @ts-expect-error - TODO
      if (!num(values[`amount${i + 1}`]).isGreaterThan(0)) {
        return acc;
      }
      // @ts-expect-error - TODO
      const amount = num(values[`amount${i + 1}`])
        .times(
          token ==
            "ibc/A6E3AF63B3C906416A9AF7A556C59EA4BD50E617EFFE6299B99700CCB780E444" ||
            token ==
              "ibc/23DC3FF0E4CBB53A1915E4C62507CB7796956E84C68CA49707787CB8BDE90A1E" ||
            token == "stk/adydx" ||
            token == "stk/adv4tnt" ||
            token ==
              "ibc/18200EAA7E5BB3D235FF517F04045F4DCB0691CE6FC1B32E4297BEA8EF7710E3"
            ? 10 ** 18
            : token ==
                "ibc/CCA9F9B22D39884C09975D45E1869B73A12B87080EE53CB44905CE2C422CA228" ||
              token ==
                "ibc/5D3B6445EA1D7064C4B1CCB588638589529556E1BCBADF13475021B42EA8C73B"
            ? 10 ** 8
            : 10 ** 6,
        )
        .toFixed(0);

      return [...acc, toAsset({ token, amount })];
    }, []);
    mutate({
      msg: {
        poolId: String(pool.id),
        assets,
        autoStake: autoBond,
      },
    });
  };

  const handleClose = () => {
    onClose();
    setIsConfirmStep(false);
    reset();
    resetForm();
  };

  const handleSingleAssetSwitch = (token: string, index: number) => {
    setTokenSelect({ token: token, index: index + 1 });
    index === 0 ? setValue("amount2", "") : setValue("amount1", "");
  };
  const handleAssetLiquiditySwitch = () => {
    setSingleAsset((singleAsset) => !singleAsset);
    setValue("amount1", "");
    setValue("amount2", "");
  };

  const mobileWebModeName = "mobile-web";

  const connectHandler = () => {
    sessionStorage.setItem("terms", "show");
    if (
      (window?.leap && window?.leap?.mode === mobileWebModeName) ||
      window?.navigator?.userAgent?.includes("LeapCosmos")
    ) {
      const wallet = walletRepo.wallets.find(
        (wallet) => wallet._walletInfo.name === "leap-extension",
      );
      wallet._walletInfo.mobileDisabled = false;
      walletRepo.connect("leap-extension");
      return;
    } else if (window?.keplr && window?.keplr?.mode === mobileWebModeName) {
      const wallet = walletRepo.wallets.find(
        (wallet) => wallet._walletInfo.name === "keplr-extension",
      );
      wallet._walletInfo.mobileDisabled = false;
      walletRepo.connect("keplr-extension");
      return;
    } else {
      connect();
      return;
    }
  };

  const renderForm = () => {
    if (isConfirmStep || isLoading || data != null || error != null) {
      return null;
    }

    return (
      <Box>
        {/* {pool.poolType === "weighted" && (
          <HStack mb="2">
            <Switch
              size={"sm"}
              color={"#386794"}
              isChecked={singleAsset}
              onChange={() => handleAssetLiquiditySwitch()}
            />
            <Text>Provide Single Asset Liquidity</Text>
            <Tooltip
              hasArrow
              bg="primary.ctaDisabled"
              color="white"
              borderRadius="lg"
              p="3"
              label="Allows a user to provide liquidity using only one asset. The asset provided gets swapped for the other asset as per the pool ratio and liquidity is added to the pool."
            >
              <span>
                <InfoIcon _hover={{ cursor: "pointer" }} />
              </span>
            </Tooltip>
          </HStack>
        )} */}
        {singleAsset ? (
          <VStack gap="1" mb="4" align="auto">
            {poolTokens.map((token, index) => {
              return tokenSelect.token !== token ? (
                <Box
                  _hover={{
                    bgColor: "primary.prussianBlue",
                    borderWidth: "1px",
                    borderRadius: "lg",
                    borderColor: "primary.queenBlue",
                    cursor: "pointer",
                  }}
                  onClick={() => handleSingleAssetSwitch(token, index)}
                >
                  <TokenInfo
                    handleAmountChange={handleAmountChange}
                    token={token}
                    index={index}
                    control={control}
                  />
                </Box>
              ) : (
                <TokenInput
                  key={token}
                  amountInputName={`amount${index + 1}`}
                  tokenInputName={`token${index + 1}`}
                  token={token}
                  control={control}
                  hideSelect
                  onAmountChange={(e) => {
                    handleAmountChange(
                      token,
                      e.target.value,
                      `amount${index + 1}`,
                    );
                  }}
                  onAmountChangeAvailable={(e) => {
                    handleAmountChangeAvailableBalance(
                      token,
                      e.target.value,
                      `amount${index + 1}`,
                    );
                  }}
                />
              );
            })}
          </VStack>
        ) : (
          <VStack gap="4" mb="4" align="auto">
            {poolTokens.map((token, index) => {
              return (
                <TokenInput
                  key={token}
                  amountInputName={`amount${index + 1}`}
                  tokenInputName={`token${index + 1}`}
                  token={token}
                  control={control}
                  hideSelect
                  onAmountChange={(e) => {
                    handleAmountChange(
                      token,
                      e.target.value,
                      `amount${index + 1}`,
                    );
                  }}
                  onAmountChangeAvailable={(e) => {
                    handleAmountChangeAvailableBalance(
                      token,
                      e.target.value,
                      `amount${index + 1}`,
                    );
                  }}
                />
              );
            })}
          </VStack>
        )}
        <Checkbox
          mb={"14px"}
          ml={"4px"}
          className="my-small-checkbox"
          isChecked={autoBond}
          onChange={(e) => setAutoBond(e.target.checked)}
          alignItems={"center"}
        >
          <Text ml="2px" fontSize={16} color={"#FCFCFC"} fontWeight={400}>
            Bond LP tokens for 7 days to earn higher yield
          </Text>
        </Checkbox>
        <VStack align={"stretch"} mb={4} px={2}>
          <HStack justify={"space-between"} fontWeight={600}>
            <Text color={"#D2D2D2"}>LP Tokens Received:</Text>
            <Text fontWeight={700}>{lpReceived ? lpReceived : "--"}</Text>
          </HStack>
          <HStack justify={"space-between"} fontWeight={600}>
            <Text color={"#D2D2D2"}>Price Impact: </Text>
            <Text
              color={Number(priceImpact) < 0 ? "#B95663" : "#10EA88"}
              fontWeight={700}
            >
              {priceImpact ? `${priceImpact}%` : "--"}
            </Text>
          </HStack>
        </VStack>
        <Button
          variant="primary"
          type="submit"
          w="full"
          size="xl"
          isDisabled={
            isWalletConnected && singleAsset
              ? num(balances?.[watchValues[`token${tokenSelect.index}`]])
                  .div(
                    10 **
                      tokens[watchValues[`token${tokenSelect.index}`]].decimals,
                  )
                  .lt(num(watchValues[`amount${tokenSelect.index}`])) ||
                watchValues[`amount${tokenSelect.index}`] === "" ||
                Number(watchValues[`amount${tokenSelect.index}`]) <= 0
              : num(balances?.[watchValues["token1"]])
                  .div(10 ** tokens[watchValues["token1"]].decimals)
                  .lt(num(watchValues["amount1"])) ||
                num(balances?.[watchValues["token2"]])
                  .div(10 ** tokens[watchValues["token2"]].decimals)
                  .lt(num(watchValues["amount2"])) ||
                watchValues["amount1"] === "" ||
                watchValues["amount2"] === "" ||
                Number(watchValues["amount1"]) <= 0 ||
                Number(watchValues["amount2"]) <= 0 ||
                (pool.poolType !== "weighted" &&
                  invalidLiquidityRatio() == true)
          }
          onClick={() => !isWalletConnected && connectHandler()}
        >
          {isWalletConnected
            ? singleAsset
              ? num(balances?.[watchValues[`token${tokenSelect.index}`]])
                  .div(
                    10 **
                      tokens[watchValues[`token${tokenSelect.index}`]].decimals,
                  )
                  .lt(num(watchValues[`amount${tokenSelect.index}`]))
                ? "Insufficient Balance"
                : "Add Liquidity"
              : num(balances?.[watchValues["token1"]])
                  .div(10 ** tokens[watchValues["token1"]].decimals)
                  .lt(num(watchValues["amount1"])) ||
                num(balances?.[watchValues["token2"]])
                  .div(10 ** tokens[watchValues["token2"]].decimals)
                  .lt(num(watchValues["amount2"]))
              ? "Insufficient Balance"
              : pool.poolType !== "weighted" && invalidLiquidityRatio() == true
              ? "Invalid Liquidity Ratio"
              : !priceImpact || !lpReceived
              ? "Invalid Input"
              : "Add Liquidity"
            : "Connect Wallet"}
        </Button>
      </Box>
    );
  };

  const CustomCloseButton = ({ closeToast }) => (
    <CloseButton
      pos={"absolute"}
      left="0"
      top={"0"}
      color="#FCFCFC"
      w={34}
      h={34}
      borderRadius={"50%"}
      border={"2px solid #386794"}
      bg={"#0a1d32"}
      onClick={() => {
        handleClose();
        closeToast();
      }}
    />
  );

  const renderFinal = () => {
    if (isLoading) {
      return toast(<TxPending />, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: false,
        toastId: "",
        closeButton: CustomCloseButton,
      });
    } else {
      if (data == null && error != null) {
        console.log(error, "Add Liq Error");
        handleClose();
        return toast.update("", {
          render: <TxError error={error} />,
          closeButton: CustomCloseButton,
          autoClose: 7000,
        });
      }

      if (data == null) {
        return null;
      }
      handleClose();
      return toast.update("", {
        render: () => (
          <TxSuccess
            subtitle="Liquidity Added Successfully"
            data={data}
            title="Transaction Successful"
          />
        ),
        closeButton: CustomCloseButton,
        autoClose: 7000,
      });
    }
  };

  const hideTitle = isLoading || data != null || error != null;

  return !hideTitle ? (
    <Modal size="lg" onClose={handleClose} isCentered {...rest}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {!hideTitle && "Add Liquidity"}
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <chakra.form onSubmit={handleSubmit(onSubmit)}>
            {renderForm()}
            {/* {renderConfirm()} */}
          </chakra.form>
        </ModalBody>
      </ModalContent>
    </Modal>
  ) : (
    //  renderPending()
    renderFinal()
  );
}
