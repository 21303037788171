import { useMemo } from "react";
import { num } from "@wizard-ui/core";
import { chain } from "lodash";

import { getTokenDenom, useDexter } from "modules/common";

export function useUnclaimedRewardsPerDenom() {
  const { unclaimedRewards } = useDexter();

  return useMemo(() => {
    if (unclaimedRewards == null) {
      return null;
    }

    const unclaimedRewardsFlat = chain(unclaimedRewards)
      .keys()
      .map((key) => {
        return unclaimedRewards[key].map(({ asset, amount }: any) => {
          return { token: getTokenDenom(asset), amount };
        });
      })
      .flattenDeep()
      .value();

    return unclaimedRewardsFlat.reduce((acc, value: Record<string, any>) => {
      const isAlready = acc[value.token];

      if (!isAlready) {
        return { ...acc, [value.token]: value.amount };
      }

      const newValue = { ...acc } as Record<string, any>;

      newValue[value.token] = num(newValue[value.token])
        .plus(value.amount)
        .toString();

      return newValue;
    }, {});
  }, [unclaimedRewards]);
}
