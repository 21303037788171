import React from "react";
import { Control, ControllerProps } from "react-hook-form";

import { ChevronDownIcon, Wrapper } from "modules/common";
import { AmountInput, TokenSelect, TokenDisplay } from "modules/swap";
import { BalanceInput } from "./BalanceInput";
import { Box, Flex, HStack, Text } from "@chakra-ui/react";

interface Props {
  /**
   *  provide `name`, `control` & optional `rules`
   */
  amountInputName?: ControllerProps["name"];
  tokenInputName?: ControllerProps["name"];
  control: Control<any>;
  rules?: ControllerProps["rules"];
  /**
   * for readonly field, provide `value`
   */
  value?: string;
  token: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  hideSelect?: boolean;
  isLpToken?: boolean;
  max?: number | string;
  onTokenChange?: (value: any) => void;
  onAmountChange?: (value: any) => void;
  onAmountChangeAvailable?: (value: any) => void;
  stakedLpInput?: boolean;
}

export function TokenInput({
  amountInputName,
  tokenInputName,
  token,
  control,
  isDisabled,
  isLoading,
  hideSelect = false,
  isLpToken = false,
  max,
  onTokenChange,
  onAmountChange,
  onAmountChangeAvailable,
  stakedLpInput = false,
  handleChooseValidatorClick,
}: Props) {
  return (
    <>
      <Wrapper variant="primary" p="3">
        <BalanceInput
          token={token}
          name={amountInputName}
          control={control}
          isDisabled={isDisabled}
          max={max}
          onChange={onAmountChangeAvailable}
        />
        <Wrapper variant="swapInner" mt="3">
          {hideSelect ? (
            <TokenDisplay token={token} isLpToken={isLpToken} />
          ) : (
            <TokenSelect
              name={tokenInputName}
              control={control}
              isDisabled={isDisabled}
              onChange={onTokenChange}
            />
          )}
          <AmountInput
            token={token}
            name={amountInputName}
            control={control}
            isDisabled={isDisabled}
            isLoading={isLoading}
            onChange={onAmountChange}
          />
        </Wrapper>
        {stakedLpInput ? (
          <HStack mt={2}>
            <Text color={"#A6A6A6"} fontSize={"16px"}>
              Validator:
            </Text>
            <Flex
              align={"center"}
              bg={"primary.prussianBlue"}
              gap={2}
              padding={"8px"}
              px={"12px"}
              borderRadius={"48px"}
              borderWidth={"1px"}
              borderColor={"stroke.dexBlue"}
              onClick={() => handleChooseValidatorClick()}
              _hover={{
                cursor: "pointer",
              }}
            >
              <Text fontSize={"16px"}>Choose Validator</Text>
              <ChevronDownIcon />
            </Flex>
          </HStack>
        ) : null}
      </Wrapper>
    </>
  );
}
