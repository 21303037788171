import React, { useEffect, useState } from "react";
import Head from "next/head";
import { AppProps } from "next/app";
import {
  ChakraProvider,
  CSSReset,
  extendTheme,
  useMediaQuery,
} from "@chakra-ui/react";
import {
  AminoTypes,
  createGovAminoConverters,
  defaultRegistryTypes,
  GasPrice,
} from "@cosmjs/stargate";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { DexterProvider } from "modules/common";

import theme from "../theme";
import { TermsModal } from "modules/common/components/TermsModal";
import {
  CHAIN_ID,
  dydxAssetsList,
  Endpoints,
  ExternalChains,
  localAssetsList,
} from "config";
import { ChainInfo } from "@keplr-wallet/types";
import "../theme/custom.css";
import "../modules/common/components/date-picker.css";
import BigNumber from "bignumber.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TagManager, { TagManagerArgs } from "react-gtm-module";
import MaintenancePage from "modules/common/components/MaintenancePage";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { addSemanticTokens, ChainProvider } from "@cosmos-kit/react";
import { chains, assets } from "chain-registry";
import { wallets as cosmosKitWallets } from "@cosmos-kit/keplr";
import { wallets as leapwallets } from "@cosmos-kit/leap-extension";
import { wallets as cosmostationWallets } from "@cosmos-kit/cosmostation";
import { SignerOptions } from "@cosmos-kit/core";
import { Registry } from "@cosmjs/proto-signing";

const queryClient = new QueryClient();

const MyApp = ({ Component, pageProps }: AppProps) => {
  const [isMobile] = useMediaQuery("(max-width: 800px)");

  const [backendEndpoint, setBackendEndpoint] = useState<{
    data: any;
    error: any;
  }>({
    data: null,
    error: null,
  });
  const [error, setError] = useState(null);
  const endpoints =
    Endpoints[process.env.NEXT_PUBLIC_ENV as keyof typeof Endpoints];
  let activeEndpoint = endpoints[0];

  const fetchBackendEndpoint = async () => {
    try {
      const response = await fetch("/api/fallback-api");
      const data = await response.json();
      setBackendEndpoint(data);
    } catch (error) {
      setError(error.message);
    }
  };
  // const fetchData = async () => {
  //   const result = await fetchWithFallback(activeEndpoint, endpoints);
  //   return result;
  // };
  // const fetchBackendEndpoint = async () => {
  //   try {
  //     const data = await fetchData();
  //     // const data = await response.json();
  //     setBackendEndpoint({ data: data, error: null });
  //   } catch (error) {
  //     setError(error.message);
  //   }
  // };

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_IPFS_DEPLOYMENT) {
      setBackendEndpoint({
        data: "https://api2.core-1.dexter.zone/v1/graphql",
        error: null,
      });
    } else {
      fetchBackendEndpoint();
    }
  }, []);
  const gtmId = process.env.NEXT_PUBLIC_GTM_ID || "";
  const tagManagerArgs: TagManagerArgs = {
    gtmId,
  };

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);

  const persistenceChainInfo = ExternalChains[
    process.env.NEXT_PUBLIC_ENV as keyof typeof ExternalChains
  ].find(
    (chain: ChainInfo) =>
      chain.chain_id ===
      CHAIN_ID[process.env.NEXT_PUBLIC_ENV as keyof typeof CHAIN_ID]
        .persistenceChainID,
  );
  const cosmosChainInfo = ExternalChains[
    process.env.NEXT_PUBLIC_ENV as keyof typeof ExternalChains
  ].find(
    (chain: ChainInfo) =>
      chain.chain_id ===
      CHAIN_ID[process.env.NEXT_PUBLIC_ENV as keyof typeof CHAIN_ID]
        .cosmosChainID,
  );

  const dydxChainInfo = ExternalChains[
    process.env.NEXT_PUBLIC_ENV as keyof typeof ExternalChains
  ].find(
    (chain: ChainInfo) =>
      chain.chain_id ===
      CHAIN_ID[process.env.NEXT_PUBLIC_ENV as keyof typeof CHAIN_ID]
        .dydxChainID,
  );
  const endpoint = persistenceChainInfo?.rpc;
  const chainId = persistenceChainInfo?.chainId;
  // const endpoint = "https://rpc.devnet.core.dexter.zone/";
  // const chainId = "persistencecore";
  BigNumber.config({ DECIMAL_PLACES: 18 });

  const devPersistence = persistenceChainInfo;
  function createAminoTypes(): AminoConverters {
    return {
      ...createGovAminoConverters(),
    };
  }
  const signerOptions: SignerOptions = {
    // signingStargate: (_chain: Chain) => {
    //   return getSigningCosmosClientOptions();
    // },
    signingStargate: (chain: Chain) => {
      switch (chain.chain_name) {
        case "Dexter Devnet":
          return {
            gasPrice: GasPrice.fromString("0.020uxprt"),
          };
        // return {
        //   gasPrice: GasPrice.fromString("0.020uxprt"),
        // };
        case "persistence":
          return {
            gasPrice: GasPrice.fromString("0.020uxprt"),
          };
        case "cosmoshub":
          return {
            gasPrice: GasPrice.fromString("0.025uatom"),
          };
        case "persistencetestnet2":
          return {
            gasPrice: GasPrice.fromString("0.000uxprt"),
          };
        case "cosmoshubtestnet":
          return {
            gasPrice: GasPrice.fromString("0.025uatom"),
          };
        case "osmosis":
          return {
            gasPrice: GasPrice.fromString("0.025uosmo"),
          };
        case "osmosistestnet":
          return {
            gasPrice: GasPrice.fromString("0.025uosmo"),
          };
        case "noble":
          return {
            gasPrice: GasPrice.fromString("0.25uusdc"),
          };
        case "nobletestnet":
          return {
            gasPrice: GasPrice.fromString("0.25uusdc"),
          };
        case "kava":
          return {
            gasPrice: GasPrice.fromString("0.025uatom"),
          };
        case "dydx":
          return {
            gasPrice: GasPrice.fromString("20000000000adydx"),
          };
        case "Dydx-Test":
          return {
            gasPrice: GasPrice.fromString("200000000000adv4tnt"),
          };
        case "stargazetestnet":
          return {
            gasPrice: GasPrice.fromString("0.025ustars"),
          };
        case "secretnetwork":
          return {
            gasPrice: GasPrice.fromString("0.025uatom"),
          };
        case "stargaze":
          return {
            gasPrice: GasPrice.fromString("0.025ustars"),
          };
        case "chihuahua":
          return {
            gasPrice: GasPrice.fromString("0.025uhuahua"),
          };
      }
    },
    signingCosmwasm: (chain: Chain) => {
      switch (chain.chain_name) {
        case "Dexter Devnet":
          return {
            gasPrice: GasPrice.fromString("0.020uxprt"),
          };
        case "persistence":
          return {
            gasPrice: GasPrice.fromString("0.020uxprt"),
          };
        case "cosmoshub":
          return {
            gasPrice: GasPrice.fromString("0.025uatom"),
          };
        case "persistencetestnet2":
          return {
            gasPrice: GasPrice.fromString("0.000uxprt"),
          };
        case "cosmoshubtestnet":
          return {
            gasPrice: GasPrice.fromString("0.025uatom"),
          };
        case "osmosis":
          return {
            gasPrice: GasPrice.fromString("0.025uosmo"),
          };
        case "osmosistestnet":
          return {
            gasPrice: GasPrice.fromString("0.025uosmo"),
          };
        case "noble":
          return {
            gasPrice: GasPrice.fromString("0.25uusdc"),
          };
        case "nobletestnet":
          return {
            gasPrice: GasPrice.fromString("0.25uusdc"),
          };
        case "kava":
          return {
            gasPrice: GasPrice.fromString("0.025uatom"),
          };
        case "dydx":
          return {
            gasPrice: GasPrice.fromString("20000000000adydx"),
          };
        case "Dydx-Test":
          return {
            gasPrice: GasPrice.fromString("200000000000adv4tnt"),
          };
        case "stargazetestnet":
          return {
            gasPrice: GasPrice.fromString("0.025ustars"),
          };
        case "secretnetwork":
          return {
            gasPrice: GasPrice.fromString("0.025uatom"),
          };
        case "stargaze":
          return {
            gasPrice: GasPrice.fromString("0.025ustars"),
          };
        case "chihuahua":
          return {
            gasPrice: GasPrice.fromString("0.025uhuahua"),
          };
      }
    },
  };
  const cosmoskitModalTheme = addSemanticTokens({
    semanticTokens: {
      colors: {
        "simple-modal-background-color-light": "#003547",
        "simple-modal-head-text-color-light": "#FCFCFC",
        "simple-modal-head-button-icon-color-light": "#FCFCFC",
        "simple-display-wallet-list-button-background-color-light":
          "linear-gradient(284.42deg, rgba(0, 175, 250, 0.2) -49.92%, rgba(50, 162, 186, 0.2) 54.24%, rgba(36, 255, 202, 0.2) 169.83%);",
        "simple-display-wallet-list-button-text-color-light": "#FCFCFC",
        "simple-display-wallet-list-button-hover-background-color-light":
          "linear-gradient(284.42deg, #00AFFA -49.92%, #32A2BA 54.24%, #24FFCA 169.83%);",
        "simple-display-wallet-list-shadow-background-color-light": "none",
        "simple-display-modal-content-loading-header-text-color-light":
          "#FCFCFC",
        "simple-modal-text-color-light": "#FCFCFC",
      },
    },
  });

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, viewport-fit=cover"
        />
        <meta
          name="keywords"
          content="Dexter, Persistence, Dex, Liquid Staked Assets, Yield Generating, Cosmos"
        />
        <title>Dexter</title>
      </Head>
      {backendEndpoint.error ? (
        <MaintenancePage />
      ) : (
        <>
          <ToastContainer
            autoClose={7000}
            hideProgressBar={true}
            closeOnClick={false}
            // toastStyle={{ background: "#0A1D32" }}
            className="toast-container-custom"
          />
          <ChainProvider
            chains={[...chains, dydxChainInfo]} // supported chains
            assetLists={[...assets, localAssetsList, dydxAssetsList]} // supported asset lists
            wallets={[
              ...cosmosKitWallets,
              ...leapwallets,
              ...cosmostationWallets,
              // ...leapMobilewallets,
            ]} // supported wallets
            signerOptions={signerOptions}
            wrappedWithChakra={false}
            modalTheme={extendTheme(cosmoskitModalTheme)}
            includeAllWalletsOnMobile={true}
            endpointOptions={{
              endpoints: {
                "Dexter Devnet": {
                  rpc: ["https://rpc.devnet.core.dexter.zone/"],
                  rest: ["https://rest.devnet.core.dexter.zone/"],
                  isLazy: true,
                },
                persistence: {
                  rpc: ["https://rpc.core.persistence.one"],
                  isLazy: true,
                },
                persistencetestnet2: {
                  rpc: ["https://rpc.testnet2.persistence.one"],
                  isLazy: true,
                },
                // cosmoshub: {
                //   rpc: [
                //     "https://rpc.cosmoshub-4.audit.one/",
                //     // "https://rpc-cosmoshub-ia.cosmosia.notional.ventures/",
                //   ],
                //   isLazy: true,
                // },
                kava: {
                  rpc: [
                    "https://rpc.data.kava.io",
                    "https://kava-rpc.polkachu.com",
                  ],
                  isLazy: true,
                },
                cosmoshubtestnet: {
                  rpc: ["https://rpc.sentry-02.theta-testnet.polypore.xyz/"],
                  isLazy: true,
                },
                secretnetwork: {
                  rpc: ["https://scrt.public-rpc.com"],
                  isLazy: true,
                },
                stargaze: {
                  rpc: ["https://stargaze-rpc.polkachu.com"],
                  isLazy: true,
                },
                chihuahua: {
                  rpc: ["https://chihuahua-rpc.polkachu.com"],
                  isLazy: true,
                },
              },
            }}
            walletConnectOptions={{
              signClient: {
                projectId: "bccc8efe6709aa7387ac47c061ee6b65",
                relayUrl: "wss://relay.walletconnect.org",
                metadata: {
                  name: "dexter",
                  description: "Interchain DEX for Yield Generating Assets",
                  url: "https://app.dexter.zone",
                  icons: [],
                },
              },
            }}
          >
            <ChakraProvider theme={theme}>
              <QueryClientProvider client={queryClient}>
                <ReactQueryDevtools initialIsOpen={false} />
                <DexterProvider backendEndpoint={backendEndpoint.data}>
                  <TermsModal />
                  <CSSReset />
                  <Component {...pageProps} />
                </DexterProvider>
              </QueryClientProvider>
            </ChakraProvider>
          </ChainProvider>
        </>
      )}
    </>
  );
};

export default MyApp;
