import { useMemo } from "react";
import { num } from "@wizard-ui/core";

import { getTokenDenom, useDexter } from "modules/common";

export const usePoolUnclaimedRewardsInUsd = (lpToken: string) => {
  const { prices, unclaimedRewards, tokens } = useDexter();

  return useMemo(() => {
    if (prices == null || unclaimedRewards == null) {
      return null;
    }

    const rewardsPerLp = unclaimedRewards[lpToken];

    if (rewardsPerLp.length == 0) {
      return null;
    }

    const totalRewardsPerLpInUsd = rewardsPerLp.reduce(
      (acc: any, data: any) => {
        const token = getTokenDenom(data.asset);
        const amountInUsd = num(data.amount)
          .div(10 ** tokens[token].decimals)
          .times(prices[token].price);

        return num(amountInUsd).plus(acc).toNumber();
      },
      0,
    );

    return totalRewardsPerLpInUsd;
  }, [lpToken, prices, unclaimedRewards]);
};
