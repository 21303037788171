export const IBCChainInfos = {
  devnet: [],
  testnet: [],
  mainnet: [],
};

export const POOL_CREATION_FEE =
  process.env.NEXT_PUBLIC_ENV === "testnet" ? 512000000 : 250000000;
export const PROPOSAL_DEPOSIT =
  process.env.NEXT_PUBLIC_ENV === "testnet" ? 0 : 3500000000;

export const GraphURL = {
  devnet: "https://api.devnet.core.dexter.zone/v1/graphql",
  testnet: "https://api.testnet.dexter.zone/v1/graphql",
  mainnet: "https://api-next.core-1.dexter.zone/v1/graphql",
};

export const Endpoints = {
  devnet: [
    // "https://api.devnet.core.dexter.zone/v1/graphql",
    "https://rpc.devnet.core.dexter.zone/",
  ],
  testnet: [
    "https://api.testnet.dexter.zone/v1/graphql",
    "https://rpc.testnet2.persistence.one/",
  ],
  mainnet: [
    "https://api-next.core-1.dexter.zone/v1/graphql",
    // "https://graph.mainnet.persistence.one/subgraphs/name/dexter",
    "https://rpc.core.persistence.one/",
  ],
};
export const PRICES_ENDPOINT =
  "https://api.core-1.dexter.zone/api/rest/cg-prices";
export const stkAtomAPREndpoint =
  "https://api.persistence.one/pstake/stkatom/apr";
export const stkAtomCValueEndpoint =
  "https://api.persistence.one/pstake/stkatom/c_value";
export const stkDydxAPREndpoint =
  "https://api.persistence.one/pstake/stkdydx/apr";
export const stkDydxCValueEndpoint =
  "https://api.persistence.one/pstake/stkdydx/c_value";
export const stkStarsAPREndpoint =
  "https://api.persistence.one/pstake/stkstars/apr";
export const stkStarsCValueEndpoint =
  "https://api.persistence.one/pstake/stkstars/c_value";
export const stkHuahuaAPREndpoint =
  "https://api.persistence.one/pstake/stkhuahua/apr";
export const stkHuahuaCValueEndpoint =
  "https://api.persistence.one/pstake/stkhuahua/c_value";
export const stkXprtAPREndpoint =
  "https://api.persistence.one/pstake/stkxprt/apr";

export const SCALING_FACTOR_MANAGER_ADDRESS =
  "persistence1eld9dngatavy9nqu9j0d5ratjvp2887zsnqp6x";
export const stkXprtCValueEndpoint =
  "https://api.persistence.one/pstake/stkxprt/c_value";

export const PERSISTENCE_STAKE_TO_LP_URL =
  "/pstake.liquidstake.v1beta1.MsgStakeToLP";

export const UnbondingPeriod = {
  devnet: "1 hour",
  testnet: "1 hour",
  mainnet: "7 days",
};

export const CHAIN_ID: any = {
  devnet: {
    cosmosChainID: "theta-testnet-001",
    persistenceChainID: "persistencecore",
  },
  testnet: {
    cosmosChainID: "theta-testnet-001",
    persistenceChainID: "test-core-2",
    dydxChainID: "dydx-testnet-4",
  },
  mainnet: {
    cosmosChainID: "cosmoshub-4",
    persistenceChainID: "core-1",
    dydxChainID: "dydx-mainnet-1",
  },
};

export const ExternalChains = {
  devnet: [
    // {
    //   chain_id: "persistencecore",
    //   chain_name: "Dexter Devnet",
    //   pretty_name: "Dexter Devnet",
    //   rpc: "https://rpc.devnet.core.dexter.zone/",
    //   rest: "https://rest.devnet.core.dexter.zone/",
    //   bip44: {
    //     coinType: 118,
    //   },
    //   bech32Config: {
    //     bech32PrefixAccAddr: "persistence",
    //     bech32PrefixAccPub: "persistence" + "pub",
    //     bech32PrefixValAddr: "persistence" + "valoper",
    //     bech32PrefixValPub: "persistence" + "valoperpub",
    //     bech32PrefixConsAddr: "persistence" + "valcons",
    //     bech32PrefixConsPub: "persistence" + "valconspub",
    //   },
    //   currencies: [
    //     {
    //       coinDenom: "XPRT",
    //       coinMinimalDenom: "uxprt",
    //       coinDecimals: 6,
    //       coinGeckoId: "persistence",
    //     },
    //   ],
    //   feeCurrencies: [
    //     {
    //       coinDenom: "XPRT",
    //       coinMinimalDenom: "uxprt",
    //       coinDecimals: 6,
    //       coinGeckoId: "persistence",
    //       gasPriceStep: {
    //         low: 0.01,
    //         average: 0.025,
    //         high: 0.04,
    //       },
    //     },
    //   ],
    //   staking: {
    //     staking_tokens: [
    //       {
    //         denom: "uxprt",
    //       },
    //     ],
    //     lock_duration: {
    //       time: "1814400s",
    //     },
    //   },
    //   stakeCurrency: {
    //     coinDenom: "XPRT",
    //     coinMinimalDenom: "uxprt",
    //     coinDecimals: 6,
    //     coinGeckoId: "persistence",
    //   },
    //   features: ["ibc-transfer"],
    // },
    {
      $schema: "../../chain.schema.json",
      chain_name: "Dexter Devnet",
      chain_id: "persistencecore",
      pretty_name: "Dexter Devnet",
      status: "live",
      network_type: "devnet",
      rpc: "https://rpc.devnet.core.dexter.zone/",
      rest: "https://rest.devnet.core.dexter.zone/",
      website: "https://persistence.one/",
      bech32_prefix: "persistence",
      daemon_name: "persistenceCore",
      node_home: "$HOME/.persistenceCore",
      key_algos: ["secp256k1"],
      slip44: 118,
      fees: {
        fee_tokens: [
          {
            denom: "uxprt",
            fixed_min_gas_price: 0,
          },
        ],
      },
      staking: {
        staking_tokens: [
          {
            denom: "uxprt",
          },
        ],
        lock_duration: {
          time: "1814400s",
        },
      },
      codebase: {
        git_repo: "https://github.com/persistenceOne/persistenceCore",
        recommended_version: "v9.1.1",
        compatible_versions: ["v9.1.1"],
        binaries: {
          "linux/amd64":
            "https://github.com/persistenceOne/persistenceCore/releases/download/v9.1.1/persistenceCore-v9.1.1-linux-amd64.tar.gz",
        },
        genesis: {
          genesis_url:
            "https://raw.githubusercontent.com/persistenceOne/networks/auditOne/test-core-2/test-core-2/genesis.json",
        },
        versions: [
          {
            name: "v7.0.2",
            recommended_version: "v7.0.2",
            compatible_versions: ["v7.0.2"],
            binaries: {
              "linux/amd64":
                "https://github.com/persistenceOne/persistenceCore/releases/download/v7.0.2/persistenceCore-v7.0.2-linux-amd64.tar.gz",
            },
          },
          {
            name: "v8",
            tag: "v8.0.0",
            recommended_version: "v8.0.0",
            compatible_versions: ["v8.0.0"],
            cosmos_sdk_version: "0.47",
            consensus: {
              type: "tendermint",
              version: "0.37",
            },
            ibc_go_version: "v7.2.0",
            cosmwasm_version: "0.40",
            cosmwasm_enabled: true,
            next_version_name: "v8.1.0",
          },
          {
            name: "v8.1.0",
            tag: "v8.1.0",
            recommended_version: "v8.1.0",
            compatible_versions: ["v8.1.0"],
            cosmos_sdk_version: "0.47",
            consensus: {
              type: "tendermint",
              version: "0.37",
            },
            ibc_go_version: "v7.2.0",
            cosmwasm_version: "0.40",
            cosmwasm_enabled: true,
            next_version_name: "v9",
          },
          {
            name: "v9",
            tag: "v9.0.0",
            height: 1531570,
            proposal: 16,
            recommended_version: "v8.0.0",
            compatible_versions: ["v8.0.0"],
            cosmos_sdk_version: "0.47",
            consensus: {
              type: "tendermint",
              version: "0.37",
            },
            ibc_go_version: "v7.2.0",
            cosmwasm_version: "0.40",
            cosmwasm_enabled: true,
            next_version_name: "v9.1.0",
          },
          {
            name: "v9.1.0",
            tag: "v9.1.1",
            height: 1543200,
            proposal: 17,
            recommended_version: "v9.1.1",
            compatible_versions: ["v9.1.1", "v9.1.0"],
            cosmos_sdk_version: "v0.47.x-lsm",
            ibc_go_version: "v7.2.0",
            ics_enabled: ["ics20-1", "ics27-1"],
            consensus: {
              type: "cometbft",
              version: "v0.37.2",
            },
            cosmwasm_version: "v0.40.2",
            cosmwasm_enabled: true,
            cosmwasm_path: "$HOME/.persistenceCore/wasm",
            binaries: {
              "linux/amd64":
                "https://github.com/persistenceOne/persistenceCore/releases/download/v9.1.1/persistenceCore-v9.1.1-linux-amd64.tar.gz",
            },
          },
        ],
      },
      peers: {
        seeds: [
          {
            id: "3ce04530d850e727d092e9a81d9f580c7ff8db56",
            address: "141.94.97.77:26656",
            provider: "Persistence",
          },
        ],
        persistent_peers: [
          {
            id: "171e837618d9ec0b5faf0c91edc0cc3edd1cf204",
            address: "188.172.228.225:26656",
            provider: "YTWOFUND",
          },
          {
            id: "cee6b94965f301e8b5ad905a65fa39c03cd193ce",
            address: "51.68.152.17.30:26656",
            provider: "Persistence",
          },
          {
            id: "7f971fc5fc2ffedbaf32f3b4021645571461a712",
            address: "198.244.177.67:26656",
            provider: "Persistence",
          },
          {
            id: "21ca0b996db604681fb73721ecb01d2c6410c628",
            address: "162.19.94.46:26656",
            provider: "Persistence",
          },
        ],
      },
      apis: {
        rpc: [
          {
            address: "https://rpc.devnet.core.dexter.zone/",
            provider: "Persistence",
          },
        ],
        rest: [
          {
            address: "https://rest.devnet.core.dexter.zone/",
            provider: "Persistence",
          },
        ],
        grpc: [
          {
            address: "persistence-testnet-grpc.polkachu.com:15490",
            provider: "Polkachu",
          },
          {
            address: "persistenceCoreTest-grpc.ytwofund.pro:9090",
            provider: "YTWOFUND",
          },
        ],
      },
      explorers: [
        {
          kind: "mintscan",
          url: "https://testnet.mintscan.io/persistence-testnet",
          tx_page:
            "https://testnet.mintscan.io/persistence-testnet/txs/${txHash}",
          account_page:
            "https://testnet.mintscan.io/persistence-testnet/account/${accountAddress}",
        },
        {
          kind: "StakeFlow",
          url: "https://stakeflow.io/persistence-testnet",
          tx_page:
            "https://stakeflow.io/persistence-testnet/transactions/${txHash}",
          account_page:
            "https://stakeflow.io/persistence-testnet/accounts/${accountAddress}",
        },
        {
          kind: "baryon",
          url: "https://testnet-explorer.baryon.dev/test-core-2",
          tx_page:
            "https://testnet-explorer.baryon.dev/test-core-2/tx/{txHash}",
          account_page:
            "https://testnet-explorer.baryon.dev/test-core-2/account/${accountAddress}",
        },
      ],
    },
    {
      chainId: "theta-testnet-001",
      chainName: "Cosmos Hub Theta Testnet",
      rpc: "https://rpc.sentry-02.theta-testnet.polypore.xyz",
      rest: "https://rest.sentry-02.theta-testnet.polypore.xyz:443",
      bip44: {
        coinType: 118,
      },
      bech32Config: {
        bech32PrefixAccAddr: "cosmos",
        bech32PrefixAccPub: "cosmos" + "pub",
        bech32PrefixValAddr: "cosmos" + "valoper",
        bech32PrefixValPub: "cosmos" + "valoperpub",
        bech32PrefixConsAddr: "cosmos" + "valcons",
        bech32PrefixConsPub: "cosmos" + "valconspub",
      },
      currencies: [
        {
          coinDenom: "ATOM",
          coinMinimalDenom: "uatom",
          coinDecimals: 6,
          coinGeckoId: "cosmos",
        },
      ],
      feeCurrencies: [
        {
          coinDenom: "ATOM",
          coinMinimalDenom: "uatom",
          coinDecimals: 6,
          coinGeckoId: "cosmos",
          gasPriceStep: {
            low: 0.01,
            average: 0.025,
            high: 0.04,
          },
        },
      ],
      stakeCurrency: {
        coinDenom: "ATOM",
        coinMinimalDenom: "uatom",
        coinDecimals: 6,
        coinGeckoId: "cosmos",
      },
    },
  ],
  testnet: [
    {
      chain_id: "test-core-2",
      chain_name: "localPersistence",
      rpc: "https://rpc.testnet2.persistence.one/",
      rest: "https://rest.testnet2.persistence.one",
      bip44: {
        coinType: 118,
      },
      bech32Config: {
        bech32PrefixAccAddr: "persistence",
        bech32PrefixAccPub: "persistence" + "pub",
        bech32PrefixValAddr: "persistence" + "valoper",
        bech32PrefixValPub: "persistence" + "valoperpub",
        bech32PrefixConsAddr: "persistence" + "valcons",
        bech32PrefixConsPub: "persistence" + "valconspub",
      },
      currencies: [
        {
          coinDenom: "XPRT",
          coinMinimalDenom: "uxprt",
          coinDecimals: 6,
          coinGeckoId: "persistence",
        },
      ],
      feeCurrencies: [
        {
          coinDenom: "XPRT",
          coinMinimalDenom: "uxprt",
          coinDecimals: 6,
          coinGeckoId: "persistence",
          gasPriceStep: {
            low: 0.01,
            average: 0.025,
            high: 0.04,
          },
        },
      ],
      stakeCurrency: {
        coinDenom: "XPRT",
        coinMinimalDenom: "uxprt",
        coinDecimals: 6,
        coinGeckoId: "persistence",
      },
      features: ["ibc-transfer"],
    },
    {
      chainId: "theta-testnet-001",
      chainName: "Cosmos Hub Testnet",
      rpc: "https://rpc.sentry-02.theta-testnet.polypore.xyz",
      rest: "https://rest.sentry-02.theta-testnet.polypore.xyz:443",
      bip44: {
        coinType: 118,
      },
      bech32Config: {
        bech32PrefixAccAddr: "cosmos",
        bech32PrefixAccPub: "cosmos" + "pub",
        bech32PrefixValAddr: "cosmos" + "valoper",
        bech32PrefixValPub: "cosmos" + "valoperpub",
        bech32PrefixConsAddr: "cosmos" + "valcons",
        bech32PrefixConsPub: "cosmos" + "valconspub",
      },
      currencies: [
        {
          coinDenom: "ATOM",
          coinMinimalDenom: "uatom",
          coinDecimals: 6,
          coinGeckoId: "cosmos",
        },
      ],
      feeCurrencies: [
        {
          coinDenom: "ATOM",
          coinMinimalDenom: "uatom",
          coinDecimals: 6,
          coinGeckoId: "cosmos",
          gasPriceStep: {
            low: 0.01,
            average: 0.025,
            high: 0.04,
          },
        },
      ],
      stakeCurrency: {
        coinDenom: "ATOM",
        coinMinimalDenom: "uatom",
        coinDecimals: 6,
        coinGeckoId: "cosmos",
      },
    },
    {
      $schema: "../chain.schema.json",
      chain_name: "Dydx-Test",
      status: "live",
      website: "https://dydx.exchange/",
      network_type: "mainnet",
      pretty_name: "dYdX Protocol",
      chain_id: "dydx-testnet-4",
      bech32_prefix: "dydx",
      daemon_name: "dydxprotocold",
      node_home: "$HOME/.dydxprotocol",
      key_algos: ["secp256k1"],
      slip44: 118,
      fees: {
        fee_tokens: [
          {
            denom: "adv4tnt",
            fixed_min_gas_price: 12500000000,
            low_gas_price: 12500000000,
            average_gas_price: 12500000000,
            high_gas_price: 20000000000,
          },
          {
            denom:
              "ibc/18200EAA7E5BB3D235FF517F04045F4DCB0691CE6FC1B32E4297BEA8EF7710E3",
            fixed_min_gas_price: 0.025,
            low_gas_price: 0.025,
            average_gas_price: 0.025,
            high_gas_price: 0.03,
          },
        ],
      },
      staking: {
        staking_tokens: [
          {
            denom: "adv4tnt",
          },
        ],
      },
      currencies: [
        {
          coinDenom: "DYDX",
          coinMinimalDenom: "adv4tnt",
          coinDecimals: 18,
          coinGeckoId: "dydx-chain",
        },
      ],
      feeCurrencies: [
        {
          coinDenom: "DV4TNT",
          coinMinimalDenom: "adv4tnt",
          coinDecimals: 18,
          gasPriceStep: {
            low: 0,
            average: 0,
            high: 25000000000,
          },
        },
      ],
      codebase: {
        git_repo: "https://github.com/dydxprotocol/v4-chain/",
        recommended_version: "v1.0.1",
        compatible_versions: ["v1.0.0", "v1.0.1"],
        cosmos_sdk_version: "v0.47.4",
        cosmwasm_enabled: false,
        genesis: {
          genesis_url:
            "https://raw.githubusercontent.com/dydxopsdao/networks/main/dydx-mainnet-1/genesis.json",
        },
        versions: [
          {
            name: "v1",
            recommended_version: "v1.0.1",
            compatible_versions: ["v1.0.0", "v1.0.1"],
            cosmos_sdk_version: "v0.47.4",
          },
        ],
      },
      logo_URIs: {
        png: "https://raw.githubusercontent.com/cosmos/chain-registry/master/dydx/images/dydx.png",
        svg: "https://raw.githubusercontent.com/cosmos/chain-registry/master/dydx/images/dydx.svg",
      },
      description:
        "Our goal is to build open source code that can power a first class product and trading experience.",
      peers: {
        seeds: [
          {
            id: "20e1000e88125698264454a884812746c2eb4807",
            address: "seeds.lavenderfive.com:23856",
            provider: "Lavender.Five Nodes 🐝",
          },
          {
            id: "ebc272824924ea1a27ea3183dd0b9ba713494f83",
            address: "dydx-mainnet-seed.autostake.com:27366",
            provider: "AutoStake 🛡️ Slash Protected",
          },
          {
            id: "65b740ee326c9260c30af1f044e9cda63c73f7c1",
            address: "seeds.kingnodes.net:23856",
            provider: "Kingnodes",
          },
          {
            id: "4c30c8a95e26b07b249813b677caab28bf0c54eb",
            address: "rpc.dydx.nodestake.top:666",
            provider: "NodeStake",
          },
          {
            id: "400f3d9e30b69e78a7fb891f60d76fa3c73f0ecc",
            address: "dydx.rpc.kjnodes.com:17059",
            provider: "kjnodes",
          },
          {
            id: "8542cd7e6bf9d260fef543bc49e59be5a3fa9074",
            address: "seed.publicnode.com:26656",
            provider: "Allnodes ⚡️ Nodes & Staking",
          },
          {
            id: "e726816f42831689eab9378d5d577f1d06d25716",
            address: "seeds.kingnodes.net:23856",
            provider: "Kingnodes",
          },
          {
            id: "4f20c3e303c9515051b6276aeb89c0b88ee79f8f",
            address: "seed.dydx.cros-nest.com:26656",
            provider: "Crosnest",
          },
        ],
        persistent_peers: [
          {
            id: "ebc272824924ea1a27ea3183dd0b9ba713494f83",
            address: "dydx-mainnet-peer.autostake.com:27366",
            provider: "AutoStake 🛡️ Slash Protected",
          },
        ],
      },
      apis: {
        rpc: [
          {
            address: "https://dydx-rpc.lavenderfive.com:443",
            provider: "Lavender.Five Nodes 🐝",
          },
          {
            address: "https://dydx-mainnet-rpc.autostake.com:443",
            provider: "AutoStake 🛡️ Slash Protected",
          },
          {
            address: "https://rpc-dydx.ecostake.com:443",
            provider: "ecostake",
          },
          {
            address: "https://rpc.dydx.nodestake.top:443",
            provider: "NodeStake",
          },
          {
            address: "https://rpc-dydx.cosmos-spaces.cloud",
            provider: "Cosmos Spaces",
          },
          {
            address: "https://dydx.rpc.kjnodes.com:443",
            provider: "kjnodes",
          },
          {
            address: "https://dydx-rpc.publicnode.com:443",
            provider: "Allnodes ⚡️ Nodes & Staking",
          },
          {
            address: "https://dydx-rpc.kingnodes.com:443",
            provider: "Kingnodes",
          },
          {
            address: "https://rpc-dydx.cros-nest.com:443",
            provider: "Crosnest",
          },
          {
            address: "https://dydx-rpc.enigma-validator.com",
            provider: "Enigma",
          },
        ],
        rest: [
          {
            address: "https://dydx-api.lavenderfive.com:443",
            provider: "Lavender.Five Nodes 🐝",
          },
          {
            address: "https://dydx-mainnet-lcd.autostake.com:443",
            provider: "AutoStake 🛡️ Slash Protected",
          },
          {
            address: "https://rest-dydx.ecostake.com:443",
            provider: "ecostake",
          },
          {
            address: "https://api-dydx.cosmos-spaces.cloud",
            provider: "Cosmos Spaces",
          },
          {
            address: "https://api.dydx.nodestake.top:443",
            provider: "NodeStake",
          },
          {
            address: "https://dydx.api.kjnodes.com:443",
            provider: "kjnodes",
          },
          {
            address: "https://dydx-rest.publicnode.com",
            provider: "Allnodes ⚡️ Nodes & Staking",
          },
          {
            address: "https://dydx-rest.kingnodes.com:443",
            provider: "Kingnodes",
          },
          {
            address: "https://rest-dydx.cros-nest.com:443",
            provider: "Crosnest",
          },
          {
            address: "https://dydx-lcd.enigma-validator.com",
            provider: "Enigma",
          },
        ],
        grpc: [
          {
            address: "https://dydx-grpc.lavenderfive.com",
            provider: "Lavender.Five Nodes 🐝",
          },
          {
            address: "dydx-mainnet-grpc.autostake.com:443",
            provider: "AutoStake 🛡️ Slash Protected",
          },
          {
            address: "https://grpc.dydx.nodestake.top",
            provider: "NodeStake",
          },
          {
            address: "dydx.grpc.kjnodes.com:443",
            provider: "kjnodes",
          },
          {
            address: "grpc-dydx.cosmos-spaces.cloud:4990",
            provider: "Cosmos Spaces",
          },
          {
            address: "dydx-grpc.publicnode.com:443",
            provider: "Allnodes ⚡️ Nodes & Staking",
          },
          {
            address: "https://dydx-grpc.kingnodes.com:443",
            provider: "Kingnodes",
          },
        ],
      },
      explorers: [
        {
          kind: "mintscan",
          url: "https://www.mintscan.io/dydx",
          tx_page: "https://www.mintscan.io/dydx/txs/${txHash}",
          account_page:
            "https://www.mintscan.io/dydx/account/${accountAddress}",
        },
        {
          kind: "NodeStake",
          url: "https://explorer.nodestake.top/dydx/",
          tx_page: "https://explorer.nodestake.top/dydx/txs/${txHash}",
          account_page:
            "https://explorer.nodestake.top/dydx/account/${accountAddress}",
        },
        {
          kind: "TC Network",
          url: "https://explorer.tcnetwork.io/dydx",
          tx_page: "https://explorer.tcnetwork.io/dydx/transaction/${txHash}",
          account_page:
            "https://explorer.tcnetwork.io/dydx/account/${accountAddress}",
        },
      ],
      images: [
        {
          png: "https://raw.githubusercontent.com/cosmos/chain-registry/master/dydx/images/dydx.png",
          svg: "https://raw.githubusercontent.com/cosmos/chain-registry/master/dydx/images/dydx.svg",
        },
      ],
    },
  ],
  mainnet: [
    {
      chain_id: "core-1",
      chain_name: "Persistence",
      rpc: "https://rpc.core.persistence.one/",
      rest: "https://rest.core.persistence.one",
      bip44: {
        coinType: 118,
      },
      bech32Config: {
        bech32PrefixAccAddr: "persistence",
        bech32PrefixAccPub: "persistence" + "pub",
        bech32PrefixValAddr: "persistence" + "valoper",
        bech32PrefixValPub: "persistence" + "valoperpub",
        bech32PrefixConsAddr: "persistence" + "valcons",
        bech32PrefixConsPub: "persistence" + "valconspub",
      },
      currencies: [
        {
          coinDenom: "XPRT",
          coinMinimalDenom: "uxprt",
          coinDecimals: 6,
          coinGeckoId: "persistence",
        },
        {
          coinDenom: "STKATOM",
          coinMinimalDenom: "stk/uatom",
          coinDecimals: 6,
          coinGeckoId: "persistence",
        },
      ],
      feeCurrencies: [
        {
          coinDenom: "XPRT",
          coinMinimalDenom: "uxprt",
          coinDecimals: 6,
          coinGeckoId: "persistence",
          gasPriceStep: {
            low: 0.01,
            average: 0.025,
            high: 0.04,
          },
        },
      ],
      stakeCurrency: {
        coinDenom: "XPRT",
        coinMinimalDenom: "uxprt",
        coinDecimals: 6,
        coinGeckoId: "persistence",
      },
      features: ["ibc-transfer"],
    },
    {
      chainId: "cosmoshub-4",
      chainName: "Cosmos Hub",
      rpc: "https://rpc.cosmoshub-4.audit.one/",
      rest: "https://rest.cosmoshub-4.audit.one/",
      bip44: {
        coinType: 118,
      },
      bech32Config: {
        bech32PrefixAccAddr: "cosmos",
        bech32PrefixAccPub: "cosmos" + "pub",
        bech32PrefixValAddr: "cosmos" + "valoper",
        bech32PrefixValPub: "cosmos" + "valoperpub",
        bech32PrefixConsAddr: "cosmos" + "valcons",
        bech32PrefixConsPub: "cosmos" + "valconspub",
      },
      currencies: [
        {
          coinDenom: "ATOM",
          coinMinimalDenom: "uatom",
          coinDecimals: 6,
          coinGeckoId: "cosmos",
        },
      ],
      feeCurrencies: [
        {
          coinDenom: "ATOM",
          coinMinimalDenom: "uatom",
          coinDecimals: 6,
          coinGeckoId: "cosmos",
          gasPriceStep: {
            low: 0.01,
            average: 0.025,
            high: 0.04,
          },
        },
      ],
      stakeCurrency: {
        coinDenom: "ATOM",
        coinMinimalDenom: "uatom",
        coinDecimals: 6,
        coinGeckoId: "cosmos",
      },
    },
    {
      $schema: "../chain.schema.json",
      chain_name: "dydx",
      status: "live",
      website: "https://dydx.exchange/",
      network_type: "mainnet",
      pretty_name: "dYdX Protocol",
      chain_id: "dydx-mainnet-1",
      bech32_prefix: "dydx",
      daemon_name: "dydxprotocold",
      node_home: "$HOME/.dydxprotocol",
      key_algos: ["secp256k1"],
      slip44: 118,
      fees: {
        fee_tokens: [
          {
            denom: "adydx",
            fixed_min_gas_price: 12500000000,
            low_gas_price: 12500000000,
            average_gas_price: 12500000000,
            high_gas_price: 20000000000,
          },
          {
            denom:
              "ibc/8E27BA2D5493AF5636760E354E46004562C46AB7EC0CC4C1CA14E9E20E2545B5",
            fixed_min_gas_price: 0.025,
            low_gas_price: 0.025,
            average_gas_price: 0.025,
            high_gas_price: 0.03,
          },
        ],
      },
      staking: {
        staking_tokens: [
          {
            denom: "adydx",
          },
        ],
      },
      codebase: {
        git_repo: "https://github.com/dydxprotocol/v4-chain/",
        recommended_version: "v1.0.1",
        compatible_versions: ["v1.0.0", "v1.0.1"],
        cosmos_sdk_version: "v0.47.4",
        cosmwasm_enabled: false,
        genesis: {
          genesis_url:
            "https://raw.githubusercontent.com/dydxopsdao/networks/main/dydx-mainnet-1/genesis.json",
        },
        versions: [
          {
            name: "v1",
            recommended_version: "v1.0.1",
            compatible_versions: ["v1.0.0", "v1.0.1"],
            cosmos_sdk_version: "v0.47.4",
          },
        ],
      },
      logo_URIs: {
        png: "https://raw.githubusercontent.com/cosmos/chain-registry/master/dydx/images/dydx.png",
        svg: "https://raw.githubusercontent.com/cosmos/chain-registry/master/dydx/images/dydx.svg",
      },
      description:
        "Our goal is to build open source code that can power a first class product and trading experience.",
      peers: {
        seeds: [
          {
            id: "20e1000e88125698264454a884812746c2eb4807",
            address: "seeds.lavenderfive.com:23856",
            provider: "Lavender.Five Nodes 🐝",
          },
          {
            id: "ebc272824924ea1a27ea3183dd0b9ba713494f83",
            address: "dydx-mainnet-seed.autostake.com:27366",
            provider: "AutoStake 🛡️ Slash Protected",
          },
          {
            id: "65b740ee326c9260c30af1f044e9cda63c73f7c1",
            address: "seeds.kingnodes.net:23856",
            provider: "Kingnodes",
          },
          {
            id: "4c30c8a95e26b07b249813b677caab28bf0c54eb",
            address: "rpc.dydx.nodestake.top:666",
            provider: "NodeStake",
          },
          {
            id: "400f3d9e30b69e78a7fb891f60d76fa3c73f0ecc",
            address: "dydx.rpc.kjnodes.com:17059",
            provider: "kjnodes",
          },
          {
            id: "8542cd7e6bf9d260fef543bc49e59be5a3fa9074",
            address: "seed.publicnode.com:26656",
            provider: "Allnodes ⚡️ Nodes & Staking",
          },
          {
            id: "e726816f42831689eab9378d5d577f1d06d25716",
            address: "seeds.kingnodes.net:23856",
            provider: "Kingnodes",
          },
          {
            id: "4f20c3e303c9515051b6276aeb89c0b88ee79f8f",
            address: "seed.dydx.cros-nest.com:26656",
            provider: "Crosnest",
          },
        ],
        persistent_peers: [
          {
            id: "ebc272824924ea1a27ea3183dd0b9ba713494f83",
            address: "dydx-mainnet-peer.autostake.com:27366",
            provider: "AutoStake 🛡️ Slash Protected",
          },
        ],
      },
      apis: {
        rpc: [
          {
            address: "https://dydx-rpc.lavenderfive.com:443",
            provider: "Lavender.Five Nodes 🐝",
          },
          {
            address: "https://dydx-mainnet-rpc.autostake.com:443",
            provider: "AutoStake 🛡️ Slash Protected",
          },
          {
            address: "https://rpc-dydx.ecostake.com:443",
            provider: "ecostake",
          },
          {
            address: "https://rpc.dydx.nodestake.top:443",
            provider: "NodeStake",
          },
          {
            address: "https://rpc-dydx.cosmos-spaces.cloud",
            provider: "Cosmos Spaces",
          },
          {
            address: "https://dydx.rpc.kjnodes.com:443",
            provider: "kjnodes",
          },
          {
            address: "https://dydx-rpc.publicnode.com:443",
            provider: "Allnodes ⚡️ Nodes & Staking",
          },
          {
            address: "https://dydx-rpc.kingnodes.com:443",
            provider: "Kingnodes",
          },
          {
            address: "https://rpc-dydx.cros-nest.com:443",
            provider: "Crosnest",
          },
          {
            address: "https://dydx-rpc.enigma-validator.com",
            provider: "Enigma",
          },
        ],
        rest: [
          {
            address: "https://dydx-api.lavenderfive.com:443",
            provider: "Lavender.Five Nodes 🐝",
          },
          {
            address: "https://dydx-mainnet-lcd.autostake.com:443",
            provider: "AutoStake 🛡️ Slash Protected",
          },
          {
            address: "https://rest-dydx.ecostake.com:443",
            provider: "ecostake",
          },
          {
            address: "https://api-dydx.cosmos-spaces.cloud",
            provider: "Cosmos Spaces",
          },
          {
            address: "https://api.dydx.nodestake.top:443",
            provider: "NodeStake",
          },
          {
            address: "https://dydx.api.kjnodes.com:443",
            provider: "kjnodes",
          },
          {
            address: "https://dydx-rest.publicnode.com",
            provider: "Allnodes ⚡️ Nodes & Staking",
          },
          {
            address: "https://dydx-rest.kingnodes.com:443",
            provider: "Kingnodes",
          },
          {
            address: "https://rest-dydx.cros-nest.com:443",
            provider: "Crosnest",
          },
          {
            address: "https://dydx-lcd.enigma-validator.com",
            provider: "Enigma",
          },
        ],
        grpc: [
          {
            address: "https://dydx-grpc.lavenderfive.com",
            provider: "Lavender.Five Nodes 🐝",
          },
          {
            address: "dydx-mainnet-grpc.autostake.com:443",
            provider: "AutoStake 🛡️ Slash Protected",
          },
          {
            address: "https://grpc.dydx.nodestake.top",
            provider: "NodeStake",
          },
          {
            address: "dydx.grpc.kjnodes.com:443",
            provider: "kjnodes",
          },
          {
            address: "grpc-dydx.cosmos-spaces.cloud:4990",
            provider: "Cosmos Spaces",
          },
          {
            address: "dydx-grpc.publicnode.com:443",
            provider: "Allnodes ⚡️ Nodes & Staking",
          },
          {
            address: "https://dydx-grpc.kingnodes.com:443",
            provider: "Kingnodes",
          },
        ],
      },
      explorers: [
        {
          kind: "mintscan",
          url: "https://www.mintscan.io/dydx",
          tx_page: "https://www.mintscan.io/dydx/txs/${txHash}",
          account_page:
            "https://www.mintscan.io/dydx/account/${accountAddress}",
        },
        {
          kind: "NodeStake",
          url: "https://explorer.nodestake.top/dydx/",
          tx_page: "https://explorer.nodestake.top/dydx/txs/${txHash}",
          account_page:
            "https://explorer.nodestake.top/dydx/account/${accountAddress}",
        },
        {
          kind: "TC Network",
          url: "https://explorer.tcnetwork.io/dydx",
          tx_page: "https://explorer.tcnetwork.io/dydx/transaction/${txHash}",
          account_page:
            "https://explorer.tcnetwork.io/dydx/account/${accountAddress}",
        },
      ],
      images: [
        {
          png: "https://raw.githubusercontent.com/cosmos/chain-registry/master/dydx/images/dydx.png",
          svg: "https://raw.githubusercontent.com/cosmos/chain-registry/master/dydx/images/dydx.svg",
        },
      ],
    },
  ],
};

export const localAssetsList = {
  $schema: "../../assetlist.schema.json",
  chain_name: "Dexter Devnet",
  assets: [
    {
      description:
        "The XPRT token is primarily a governance token for the Persistence chain.",
      denom_units: [
        {
          denom: "uxprt",
          exponent: 0,
        },
        {
          denom: "xprt",
          exponent: 6,
        },
      ],
      base: "uxprt",
      name: "Persistence",
      display: "xprt",
      symbol: "XPRT",
      logo_URIs: {
        png: "https://raw.githubusercontent.com/cosmos/chain-registry/master/persistence/images/xprt.png",
        svg: "https://raw.githubusercontent.com/cosmos/chain-registry/master/persistence/images/xprt.svg",
      },
      coingecko_id: "persistence",
      images: [
        {
          png: "https://raw.githubusercontent.com/cosmos/chain-registry/master/persistence/images/xprt.png",
          svg: "https://raw.githubusercontent.com/cosmos/chain-registry/master/persistence/images/xprt.svg",
        },
      ],
    },
    {
      description: "PSTAKE Liquid-Staked ATOM",
      denom_units: [
        {
          denom: "stk/uatom",
          exponent: 0,
          aliases: [],
        },
        {
          denom: "stkatom",
          exponent: 6,
          aliases: ["stk/atom"],
        },
      ],
      base: "stk/uatom",
      name: "PSTAKE staked ATOM",
      display: "stkatom",
      symbol: "stkATOM",
      logo_URIs: {
        png: "https://raw.githubusercontent.com/cosmos/chain-registry/master/persistence/images/stkatom.png",
        svg: "https://raw.githubusercontent.com/cosmos/chain-registry/master/persistence/images/stkatom.svg",
      },
      images: [
        {
          png: "https://raw.githubusercontent.com/cosmos/chain-registry/master/persistence/images/stkatom.png",
          svg: "https://raw.githubusercontent.com/cosmos/chain-registry/master/persistence/images/stkatom.svg",
        },
      ],
    },
    {
      description: "PSTAKE Liquid-Staked OSMO",
      denom_units: [
        {
          denom: "stk/uosmo",
          exponent: 0,
          aliases: [],
        },
        {
          denom: "stkosmo",
          exponent: 6,
          aliases: ["stk/osmo"],
        },
      ],
      base: "stk/uosmo",
      name: "PSTAKE staked OSMO",
      display: "stkosmo",
      symbol: "stkOSMO",
      logo_URIs: {
        png: "https://raw.githubusercontent.com/cosmos/chain-registry/master/persistence/images/stkosmo.png",
        svg: "https://raw.githubusercontent.com/cosmos/chain-registry/master/persistence/images/stkosmo.svg",
      },
      images: [
        {
          png: "https://raw.githubusercontent.com/cosmos/chain-registry/master/persistence/images/stkosmo.png",
          svg: "https://raw.githubusercontent.com/cosmos/chain-registry/master/persistence/images/stkosmo.svg",
        },
      ],
    },
  ],
};

export const dydxAssetsList = {
  $schema: "../assetlist.schema.json",
  chain_name: "Dydx-Test",
  assets: [
    {
      description: "The native staking token of dYdX Protocol.",
      denom_units: [
        {
          denom: "adydx",
          exponent: 0,
        },
        {
          denom: "dydx",
          exponent: 18,
        },
      ],
      base: "adydx",
      name: "dYdX",
      display: "dydx",
      symbol: "DYDX",
      logo_URIs: {
        png: "https://raw.githubusercontent.com/cosmos/chain-registry/master/dydx/images/dydx.png",
        svg: "https://raw.githubusercontent.com/cosmos/chain-registry/master/dydx/images/dydx.svg",
      },
      coingecko_id: "dydx",
      images: [
        {
          png: "https://raw.githubusercontent.com/cosmos/chain-registry/master/dydx/images/dydx.png",
          svg: "https://raw.githubusercontent.com/cosmos/chain-registry/master/dydx/images/dydx.svg",
        },
        {
          svg: "https://raw.githubusercontent.com/cosmos/chain-registry/master/dydx/images/dydx-circle.svg",
          theme: {
            circle: true,
          },
        },
      ],
    },
    {
      description: "Noble USDC on dYdX Protocol.",
      denom_units: [
        {
          denom:
            "ibc/8E27BA2D5493AF5636760E354E46004562C46AB7EC0CC4C1CA14E9E20E2545B5",
          exponent: 0,
        },
        {
          denom: "usdc",
          exponent: 6,
        },
      ],
      type_asset: "ics20",
      base: "ibc/8E27BA2D5493AF5636760E354E46004562C46AB7EC0CC4C1CA14E9E20E2545B5",
      name: "Noble USDC",
      display: "usdc",
      symbol: "USDC",
      traces: [
        {
          type: "ibc",
          counterparty: {
            chain_name: "noble",
            base_denom: "uusdc",
            channel_id: "channel-33",
          },
          chain: {
            channel_id: "channel-0",
            path: "transfer/channel-0/uusdc",
          },
        },
      ],
      images: [
        {
          image_sync: {
            chain_name: "noble",
            base_denom: "uusdc",
          },
          png: "https://raw.githubusercontent.com/cosmos/chain-registry/master/noble/images/USDCoin.png",
          svg: "https://raw.githubusercontent.com/cosmos/chain-registry/master/noble/images/USDCoin.svg",
        },
      ],
      logo_URIs: {
        png: "https://raw.githubusercontent.com/cosmos/chain-registry/master/noble/images/USDCoin.png",
        svg: "https://raw.githubusercontent.com/cosmos/chain-registry/master/noble/images/USDCoin.svg",
      },
    },
  ],
};
