import { useMemo, useEffect, useState } from "react";
import {
  ModalProps,
  Modal,
  Button,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  chakra,
  VStack,
  Box,
  CloseButton,
  Heading,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { num } from "@wizard-ui/core";

import {
  toAsset,
  TxPending,
  TxSuccess,
  TxError,
  useDexter,
  MIN_FEE,
  ChevronLeftIcon,
} from "modules/common";
import { PoolBase, tokenWeights } from "modules/pools";
import { useJoinPoolMutation } from "modules/contracts";
import { TokenInput } from "./TokenInput";
import TokenInfo from "./TokenInfo";

import { toast } from "react-toastify";
import { useChain, useManager } from "@cosmos-kit/react";
import { ValidatorDelegations } from "./ValidatorDelegations";
import { StakedTokenInput } from "./StakedTokenInput";
import { useSuperfluidLpMutation } from "modules/contracts/useSuperfluidLpMutation";
import { useCValue } from "../hooks/useCValue";
import { useDydxCValue } from "../hooks/useDydxCValue";
import { useStarsCValue } from "../hooks/useStarsCValue";
import { useHuahuaCValue } from "../hooks/useHuahuaCValue";

type FormValues = {
  token1: string;
  amount1: string;
  token2: string;
  amount2: string;
  token3: string;
  amount3: string;
};

interface AddLiquidityModalProps extends Omit<ModalProps, "children"> {
  pool: PoolBase;
}

export function AddLiquidityModal({
  pool,
  onClose,
  ...rest
}: AddLiquidityModalProps) {
  const { balances, tokens } = useDexter();
  const validatorList =
    balances === undefined
      ? []
      : Object.entries(balances)
          .filter(([key]) => key.includes("valoper"))
          .map(([key, value]) => ({
            validator: key,
            balance: value.balance,
            jailed: value.jailed,
            moniker: value.moniker,
            eligible: value.eligible,
          }))

          .sort((a, b) => {
            if (a.eligible && !b.eligible) {
              return -1;
            }
            if (!a.eligible && b.eligible) {
              return 1;
            }
            return b.balance - a.balance;
          });
  const persistenceChain =
    process.env.NEXT_PUBLIC_ENV === "testnet"
      ? "persistencetestnet2"
      : process.env.NEXT_PUBLIC_ENV === "devnet"
      ? "Dexter Devnet"
      : "persistence";
  const chainContext = useChain(persistenceChain);
  const { getWalletRepo } = useManager();
  const walletRepo = getWalletRepo(persistenceChain);

  const stkAtomCValue = useCValue();
  const stkDydxCValue = useDydxCValue();
  const stkStarsCValue = useStarsCValue();
  const stkHuahuaCValue = useHuahuaCValue();

  const { connect, address, isWalletConnected } = chainContext;
  const { mutate, isLoading, data, reset, error } = useJoinPoolMutation();
  const {
    mutate: superFluidMutate,
    isLoading: superFluidIsLoading,
    data: superFluidData,
    reset: superFluidReset,
    error: superFluidError,
  } = useSuperfluidLpMutation();
  const [isConfirmStep, setIsConfirmStep] = useState(false);
  const [tokenSelect, setTokenSelect] = useState({ token: "", index: 0 });
  const [singleAsset, setSingleAsset] = useState(false);
  const poolTokens = pool.assets.map(({ identifier }) => identifier);
  const [showValidators, setShowValidators] = useState(false);
  const [selectedValidator, setSelectValidator] = useState(validatorList[0]);
  const defaultValues = poolTokens.reduce((acc, value, index) => {
    return { ...acc, [`token${index + 1}`]: value, [`amount${index + 1}`]: "" };
  }, {});

  useEffect(() => {
    setSelectValidator(validatorList[0]);
  }, [validatorList.length]);
  // console.log({ defaultValues });
  const defaultValues1 = {
    token3: "staked-uxprt",
    amount3: "",
    ...defaultValues,
  };
  // console.log({ defaultValues1 });
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    reset: resetForm,
  } = useForm<FormValues>({
    mode: "onChange",
    defaultValues: pool.poolHasSuperfluidLp ? defaultValues1 : defaultValues,
  });

  const watchValues = watch() as Record<string, any>;
  // console.log({ watchValues });

  const weightsStable = useMemo(() => {
    if (pool == null) {
      return [];
    }

    return tokenWeights(
      pool,
      pool.id == 1
        ? stkAtomCValue
        : pool.id == 7
        ? stkDydxCValue
        : pool.id == 9
        ? stkStarsCValue
        : stkHuahuaCValue,
      2,
    );
  }, [pool]);

  const invalidLiquidityRatio = () => {
    const token1 = watchValues.token1;
    const token2 = watchValues.token2;
    const amount1 = watchValues.amount1;
    const amount2 = watchValues.amount2;

    const totalSum = num(amount1).plus(num(amount2));

    const ratio1 = num(amount1).div(totalSum).times(100);
    const ratio2 = num(amount2).div(totalSum).times(100);

    const weightToken1 = weightsStable.find((w) => w.token === token1);
    const weightToken2 = weightsStable.find((w) => w.token === token2);
    if (weightToken1.weight < weightToken2.weight) {
      if (
        ratio1.toFixed(2) < weightToken1?.weight &&
        ratio2.toFixed(2) > weightToken2?.weight
      ) {
        return true;
      } else {
        return false;
      }
    } else if (weightToken1.weight > weightToken2.weight) {
      if (
        ratio1.toFixed(2) > weightToken1?.weight &&
        ratio2.toFixed(2) < weightToken2?.weight
      ) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  };

  const handleAmountChangeAvailableBalance = (
    token: string,
    amount: string,
    input: string,
  ) => {
    const amounts = pool.getProvideAmounts(token, amount);
    const otherToken = amounts.filter((amount) => amount.input !== input)[0];

    if (singleAsset || pool._totalTokensInPool == 0) {
      amounts
        .filter((token) => token == tokenSelect.token)
        .map((a) => {
          setValue(a.input, a.amount);
        });
    } else {
      if (pool.poolType === "weighted") {
        if (
          num(otherToken.amount).gt(
            num(balances?.[otherToken.token]).div(
              10 ** tokens[otherToken.token].decimals,
            ),
          )
        ) {
          const newAmounts = pool.getProvideAmounts(
            otherToken.token,
            num(balances?.[otherToken.token])
              .div(10 ** tokens[otherToken.token].decimals)
              .minus(otherToken.token === "uxprt" ? num(MIN_FEE) : 0)
              .toString(),
          );
          newAmounts.forEach((a: any) => {
            if (a.input == otherToken.input) {
              setValue(
                a.input,
                num(balances?.[otherToken.token])
                  .div(10 ** tokens[otherToken.token].decimals)
                  .minus(otherToken.token === "uxprt" ? num(MIN_FEE) : 0)
                  .toString(),
              );
            } else {
              setValue(a.input, a.amount);
            }
          });
        } else {
          amounts.forEach((a: any) => {
            if (a.input == input) {
              return;
            }
            setValue(a.input, a.amount);
          });
        }
      } else if (pool.id === 1) {
        if (token === "stk/uatom") {
          let atomAmount = num(amount).div(stkAtomCValue).toString();
          let stkAtomAmount = amount;
          if (
            num(atomAmount).gt(
              num(
                balances?.[
                  "ibc/C8A74ABBE2AF892E15680D916A7C22130585CE5704F9B17A10F184A90D53BECA"
                ],
              ).div(10 ** 6),
            )
          ) {
            atomAmount = num(
              balances?.[
                "ibc/C8A74ABBE2AF892E15680D916A7C22130585CE5704F9B17A10F184A90D53BECA"
              ],
            )
              .div(10 ** 6)
              .toString();
            stkAtomAmount = num(atomAmount).times(stkAtomCValue).toString();
          }
          setValue("amount1", atomAmount);
          setValue("amount2", stkAtomAmount);
          return;
        } else {
          let stkAtomAmount = num(amount).times(stkAtomCValue).toString();
          let atomAmount = amount;
          if (
            num(stkAtomAmount).gt(num(balances?.["stk/uatom"]).div(10 ** 6))
          ) {
            stkAtomAmount = num(balances?.["stk/uatom"])
              .div(10 ** 6)
              .toString();
            atomAmount = num(stkAtomAmount).div(stkAtomCValue).toString();
          }
          setValue("amount2", stkAtomAmount);
          setValue("amount1", atomAmount);
          return;
        }
      } else if (pool.id === 7) {
        if (token === "stk/adydx") {
          let dydxAmount = num(amount).div(stkDydxCValue).toString();
          let stkDydxAmount = amount;
          if (
            num(dydxAmount).gt(
              num(
                balances?.[
                  "ibc/23DC3FF0E4CBB53A1915E4C62507CB7796956E84C68CA49707787CB8BDE90A1E"
                ],
              ).div(10 ** 18),
            )
          ) {
            dydxAmount = num(
              balances?.[
                "ibc/23DC3FF0E4CBB53A1915E4C62507CB7796956E84C68CA49707787CB8BDE90A1E"
              ],
            )
              .div(10 ** 18)
              .toString();
            stkDydxAmount = num(dydxAmount).times(stkDydxCValue).toString();
          }
          setValue("amount1", dydxAmount);
          setValue("amount2", stkDydxAmount);
          return;
        } else {
          let stkDydxAmount = num(amount).times(stkDydxCValue).toString();
          let dydxAmount = amount;
          if (
            num(stkDydxAmount).gt(num(balances?.["stk/adydx"]).div(10 ** 18))
          ) {
            stkDydxAmount = num(balances?.["stk/adydx"])
              .div(10 ** 18)
              .toString();
            dydxAmount = num(stkDydxAmount).div(stkDydxCValue).toString();
          }
          setValue("amount2", stkDydxAmount);
          setValue("amount1", dydxAmount);
          return;
        }
      } else if (pool.id === 9) {
        if (token === "stk/ustars") {
          let starsAmount = num(amount).div(stkStarsCValue).toString();
          let stkStarsAmount = amount;
          if (
            num(starsAmount).gt(
              num(
                balances?.[
                  "ibc/AD8E1D4AC4EA8FC79CC46E33319A3791477D4DEBFC30D5D874074B993422B41B"
                ],
              ).div(10 ** 6),
            )
          ) {
            starsAmount = num(
              balances?.[
                "ibc/AD8E1D4AC4EA8FC79CC46E33319A3791477D4DEBFC30D5D874074B993422B41B"
              ],
            )
              .div(10 ** 6)
              .toString();
            stkStarsAmount = num(starsAmount).times(stkStarsCValue).toString();
          }
          setValue("amount1", starsAmount);
          setValue("amount2", stkStarsAmount);
          return;
        } else {
          let stkStarsAmount = num(amount).times(stkStarsCValue).toString();
          let starsAmount = amount;
          if (
            num(stkStarsAmount).gt(num(balances?.["stk/ustars"]).div(10 ** 6))
          ) {
            stkStarsAmount = num(balances?.["stk/ustars"])
              .div(10 ** 6)
              .toString();
            starsAmount = num(stkStarsAmount).div(stkStarsCValue).toString();
          }
          setValue("amount2", stkStarsAmount);
          setValue("amount1", starsAmount);
          return;
        }
      } else if (pool.id === 11) {
        if (token === "stk/uhuahua") {
          let huahuaAmount = num(amount).div(stkHuahuaCValue).toString();
          let stkHuahuaAmount = amount;
          if (
            num(huahuaAmount).gt(
              num(
                balances?.[
                  "ibc/B597D779FCDD9021263C98A48F1AFA9D2BCCCE980F397CDE5681CCEDE7DEE1A4"
                ],
              ).div(10 ** 6),
            )
          ) {
            huahuaAmount = num(
              balances?.[
                "ibc/B597D779FCDD9021263C98A48F1AFA9D2BCCCE980F397CDE5681CCEDE7DEE1A4"
              ],
            )
              .div(10 ** 6)
              .toString();
            stkHuahuaAmount = num(huahuaAmount)
              .times(stkHuahuaCValue)
              .toString();
          }
          setValue("amount1", huahuaAmount);
          setValue("amount2", stkHuahuaAmount);
          return;
        } else {
          let stkHuahuaAmount = num(amount).times(stkHuahuaCValue).toString();
          let huahuaAmount = amount;
          if (
            num(stkHuahuaAmount).gt(num(balances?.["stk/uhuahua"]).div(10 ** 6))
          ) {
            stkHuahuaAmount = num(balances?.["stk/uhuahua"])
              .div(10 ** 6)
              .toString();
            huahuaAmount = num(stkHuahuaAmount).div(stkHuahuaCValue).toString();
          }
          setValue("amount2", stkHuahuaAmount);
          setValue("amount1", huahuaAmount);
          return;
        }
      } else {
        setValue(token, amount);
      }
    }
  };
  const handleAmountChange = (token: string, amount: string, input: string) => {
    const amounts = pool.getProvideAmounts(token, amount);
    if (pool.id === 1) {
      if (token === "stk/uatom") {
        const atomAmount = num(amount).div(stkAtomCValue).toString();
        setValue("amount1", atomAmount);
        setValue("amount2", amount);
        return;
      } else {
        const stkAtomAmount = num(amount).times(stkAtomCValue).toString();
        setValue("amount2", stkAtomAmount);
        setValue("amount1", amount);
        return;
      }
    } else if (pool.id === 7) {
      if (token === "stk/adydx") {
        const dydxAmount = num(amount).div(stkDydxCValue).toString();
        setValue("amount1", dydxAmount);
        setValue("amount2", amount);
        return;
      } else {
        const stkDydxAmount = num(amount).times(stkDydxCValue).toString();
        setValue("amount2", stkDydxAmount);
        setValue("amount1", amount);
        return;
      }
    } else if (pool.id === 9) {
      if (token === "stk/ustars") {
        const starsAmount = num(amount).div(stkStarsCValue).toString();
        setValue("amount1", starsAmount);
        setValue("amount2", amount);
        return;
      } else {
        const stkStarsAmount = num(amount).times(stkStarsCValue).toString();
        setValue("amount2", stkStarsAmount);
        setValue("amount1", amount);
        return;
      }
    } else if (pool.id === 11) {
      if (token === "stk/uhuahua") {
        const huahuaAmount = num(amount).div(stkHuahuaCValue).toString();
        setValue("amount1", huahuaAmount);
        setValue("amount2", amount);
        return;
      } else {
        const stkHuahuaAmount = num(amount).times(stkHuahuaCValue).toString();
        setValue("amount2", stkHuahuaAmount);
        setValue("amount1", amount);
        return;
      }
    }
    if (singleAsset || pool._totalTokensInPool == 0) {
      amounts
        .filter((token) => token == tokenSelect.token)
        .map((a) => {
          setValue(a.input, a.amount);
        });
    } else {
      if (pool.poolType === "weighted") {
        amounts.forEach((a: any) => {
          if (a.input == input) {
            return;
          }
          setValue(a.input, a.amount);
        });
      } else {
        setValue(token, amount);
      }
    }
  };

  const onSubmit = (values: FormValues) => {
    // @ts-expect-error - TODO
    const assets = poolTokens.reduce((acc, token, i) => {
      // @ts-expect-error - TODO
      if (!num(values[`amount${i + 1}`]).isGreaterThan(0)) {
        return acc;
      }
      // @ts-expect-error - TODO
      const amount = num(values[`amount${i + 1}`])
        .times(10 ** tokens[token].decimals)
        .toFixed(0);

      return [...acc, toAsset({ token, amount })];
    }, []);
    if (
      pool.poolHasSuperfluidLp &&
      values.amount3 !== "" &&
      values.amount3 !== "0"
    ) {
      const superFluidAssets = [
        ...assets,
        toAsset({
          token: "staked-uxprt",
          amount: num(values.amount3)
            .times(10 ** 6)
            .toFixed(0),
        }),
      ];
      superFluidMutate({
        msg: {
          poolId: String(pool.id),
          validatorAddress: selectedValidator.validator,
          assets: superFluidAssets,
        },
      });
    } else {
      mutate({
        msg: {
          poolId: String(pool.id),
          assets,
        },
      });
    }
  };

  const handleClose = () => {
    onClose();
    setIsConfirmStep(false);
    setShowValidators(false);
    superFluidReset();
    reset();
    resetForm();
  };

  const handleSingleAssetSwitch = (token: string, index: number) => {
    setTokenSelect({ token: token, index: index + 1 });
    index === 0 ? setValue("amount2", "") : setValue("amount1", "");
  };
  const handleAssetLiquiditySwitch = () => {
    setSingleAsset((singleAsset) => !singleAsset);
    setValue("amount1", "");
    setValue("amount2", "");
  };

  const handleChooseValidatorClick = () => {
    setShowValidators(true);
  };

  const handleSelect = (validator) => {
    setSelectValidator(validator);
    // setValue("amount3", "")
    setShowValidators(false);
  };

  const mobileWebModeName = "mobile-web";

  const connectHandler = () => {
    sessionStorage.setItem("terms", "show");
    if (
      (window?.leap && window?.leap?.mode === mobileWebModeName) ||
      window?.navigator?.userAgent?.includes("LeapCosmos")
    ) {
      const wallet = walletRepo.wallets.find(
        (wallet) => wallet._walletInfo.name === "leap-extension",
      );
      wallet._walletInfo.mobileDisabled = false;
      walletRepo.connect("leap-extension");
      return;
    } else if (window?.keplr && window?.keplr?.mode === mobileWebModeName) {
      const wallet = walletRepo.wallets.find(
        (wallet) => wallet._walletInfo.name === "keplr-extension",
      );
      wallet._walletInfo.mobileDisabled = false;
      walletRepo.connect("keplr-extension");
      return;
    } else {
      connect();
      return;
    }
  };

  const renderForm = () => {
    if (isConfirmStep || isLoading || data != null || error != null) {
      return null;
    }

    return (
      <Box>
        {/* {pool.poolType === "weighted" && (
          <HStack mb="2">
            <Switch
              size={"sm"}
              color={"#386794"}
              isChecked={singleAsset}
              onChange={() => handleAssetLiquiditySwitch()}
            />
            <Text>Provide Single Asset Liquidity</Text>
            <Tooltip
              hasArrow
              bg="primary.ctaDisabled"
              color="white"
              borderRadius="lg"
              p="3"
              label="Allows a user to provide liquidity using only one asset. The asset provided gets swapped for the other asset as per the pool ratio and liquidity is added to the pool."
            >
              <span>
                <InfoIcon _hover={{ cursor: "pointer" }} />
              </span>
            </Tooltip>
          </HStack>
        )} */}
        {singleAsset ? (
          <VStack gap="1" mb="4" align="auto">
            {poolTokens.map((token, index) => {
              return tokenSelect.token !== token ? (
                <Box
                  _hover={{
                    bgColor: "primary.prussianBlue",
                    borderWidth: "1px",
                    borderRadius: "lg",
                    borderColor: "primary.queenBlue",
                    cursor: "pointer",
                  }}
                  onClick={() => handleSingleAssetSwitch(token, index)}
                >
                  <TokenInfo
                    handleAmountChange={handleAmountChange}
                    token={token}
                    index={index}
                    control={control}
                  />
                </Box>
              ) : (
                <TokenInput
                  key={token}
                  amountInputName={`amount${index + 1}`}
                  tokenInputName={`token${index + 1}`}
                  token={token}
                  control={control}
                  hideSelect
                  onAmountChange={(e) => {
                    handleAmountChange(
                      token,
                      e.target.value,
                      `amount${index + 1}`,
                    );
                  }}
                  onAmountChangeAvailable={(e) => {
                    handleAmountChangeAvailableBalance(
                      token,
                      e.target.value,
                      `amount${index + 1}`,
                    );
                  }}
                />
              );
            })}
          </VStack>
        ) : (
          <VStack gap="1" mb="4" align="auto">
            {poolTokens.map((token, index) => {
              return (
                <TokenInput
                  key={token}
                  amountInputName={`amount${index + 1}`}
                  tokenInputName={`token${index + 1}`}
                  token={token}
                  control={control}
                  hideSelect
                  onAmountChange={(e) => {
                    handleAmountChange(
                      token,
                      e.target.value,
                      `amount${index + 1}`,
                    );
                  }}
                  onAmountChangeAvailable={(e) => {
                    handleAmountChangeAvailableBalance(
                      token,
                      e.target.value,
                      `amount${index + 1}`,
                    );
                  }}
                />
              );
            })}
            {pool.poolHasSuperfluidLp ? (
              <>
                <Heading variant="dexterH1">
                  Convert Staked XPRT into LP
                </Heading>
                <StakedTokenInput
                  amountInputName={`amount3`}
                  tokenInputName={`token3`}
                  token={"staked-uxprt"}
                  control={control}
                  hideSelect
                  selectedValidator={selectedValidator}
                  handleChooseValidatorClick={handleChooseValidatorClick}
                  onAmountChange={(e) => {
                    handleAmountChange(
                      "staked-uxprt",
                      e.target.value,
                      `amount3`,
                    );
                  }}
                  // onAmountChangeAvailable={(e) => {
                  //   handleAmountChangeAvailableBalance(
                  //     token,
                  //     e.target.value,
                  //     `amount${index + 1}`,
                  //   );
                  // }}
                />
              </>
            ) : null}
          </VStack>
        )}
        <Button
          variant="primary"
          // type="submit"
          w="full"
          size="xl"
          isDisabled={
            isWalletConnected && singleAsset
              ? num(balances?.[watchValues[`token${tokenSelect.index}`]])
                  .div(
                    10 **
                      tokens[watchValues[`token${tokenSelect.index}`]].decimals,
                  )
                  .lt(num(watchValues[`amount${tokenSelect.index}`])) ||
                watchValues[`amount${tokenSelect.index}`] === "" ||
                Number(watchValues[`amount${tokenSelect.index}`]) <= 0
              : num(balances?.[watchValues["token1"]])
                  .div(10 ** tokens[watchValues["token1"]].decimals)
                  .lt(num(watchValues["amount1"])) ||
                num(balances?.[watchValues["token2"]])
                  .div(10 ** tokens[watchValues["token2"]].decimals)
                  .lt(num(watchValues["amount2"])) ||
                watchValues["amount1"] === "" ||
                watchValues["amount2"] === "" ||
                Number(watchValues["amount1"]) <= 0 ||
                Number(watchValues["amount2"]) <= 0
            // ||
            // (pool.poolType !== "weighted" &&
            //   invalidLiquidityRatio() == true)
          }
          onClick={() =>
            !isWalletConnected ? connectHandler() : onSubmit(watchValues)
          }
        >
          {isWalletConnected
            ? singleAsset
              ? num(balances?.[watchValues[`token${tokenSelect.index}`]])
                  .div(
                    10 **
                      tokens[watchValues[`token${tokenSelect.index}`]].decimals,
                  )
                  .lt(num(watchValues[`amount${tokenSelect.index}`]))
                ? "Insufficient Balance"
                : "Add Liquidity"
              : num(balances?.[watchValues["token1"]])
                  .div(10 ** tokens[watchValues["token1"]].decimals)
                  .lt(num(watchValues["amount1"])) ||
                num(balances?.[watchValues["token2"]])
                  .div(10 ** tokens[watchValues["token2"]].decimals)
                  .lt(num(watchValues["amount2"]))
              ? "Insufficient Balance"
              : // : pool.poolType !== "weighted" && invalidLiquidityRatio() == true
                // ? "Invalid Liquidity Ratio"
                "Add Liquidity"
            : "Connect Wallet"}
        </Button>
      </Box>
    );
  };

  const CustomCloseButton = ({ closeToast }) => (
    <CloseButton
      pos={"absolute"}
      left="0"
      top={"0"}
      color="#FCFCFC"
      w={34}
      h={34}
      borderRadius={"50%"}
      border={"2px solid #386794"}
      bg={"#0a1d32"}
      onClick={() => {
        handleClose();
        closeToast();
      }}
    />
  );

  const renderFinal = () => {
    if (isLoading) {
      return toast(<TxPending />, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: false,
        toastId: "",
        closeButton: CustomCloseButton,
      });
    } else {
      if (data == null && error != null) {
        console.log(error, "Add Liq Error");
        handleClose();
        return toast.update("", {
          render: <TxError error={error} />,
          closeButton: CustomCloseButton,
          autoClose: 7000,
        });
      }

      if (data == null) {
        return null;
      }
      handleClose();
      return toast.update("", {
        render: () => (
          <TxSuccess
            subtitle="Liquidity Added Successfully"
            data={data}
            title="Transaction Successful"
          />
        ),
        closeButton: CustomCloseButton,
        autoClose: 7000,
      });
    }
  };

  const renderFinalSuperFluid = () => {
    if (superFluidIsLoading) {
      return toast(<TxPending />, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: false,
        toastId: "",
        closeButton: CustomCloseButton,
      });
    } else {
      if (superFluidData == null && superFluidError != null) {
        console.log(error, "Add Liq Error");
        return toast.update("", {
          render: <TxError />,
          closeButton: CustomCloseButton,
          autoClose: 7000,
        });
      }

      if (superFluidData == null) {
        return null;
      }

      return toast.update("", {
        render: () => (
          <TxSuccess
            subtitle="Liquidity Added Successfully"
            data={superFluidData}
            title="Transaction Successful"
          />
        ),
        closeButton: CustomCloseButton,
        autoClose: 7000,
      });
    }
  };

  const hideTitleAddLiq = isLoading || data != null || error != null;

  const hideTitleSuperfluid =
    superFluidIsLoading || superFluidData != null || superFluidError != null;

  return !pool.poolHasSuperfluidLp ? (
    !hideTitleAddLiq ? (
      <Modal size="lg" onClose={handleClose} isCentered {...rest}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {showValidators ? (
              <ChevronLeftIcon
                position={"absolute"}
                _hover={{ cursor: "pointer" }}
                boxSize={"24px"}
                left={"24px"}
                top={"40px"}
                onClick={() => setShowValidators(false)}
              />
            ) : null}
            {(!hideTitleAddLiq || !hideTitleSuperfluid) && showValidators
              ? "Choose Validator"
              : "Add Liquidity"}
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody>
            <chakra.form onSubmit={handleSubmit(onSubmit)}>
              {/* {renderForm()} */}
              {showValidators ? (
                <ValidatorDelegations handleSelect={handleSelect} />
              ) : (
                renderForm()
              )}
              {/* {renderValidator()} */}
              {/* {renderConfirm()} */}
            </chakra.form>
          </ModalBody>
        </ModalContent>
      </Modal>
    ) : (
      //  renderPending()
      renderFinal()
    )
  ) : !hideTitleSuperfluid ? (
    <Modal size="lg" onClose={handleClose} isCentered {...rest}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {showValidators ? (
            <ChevronLeftIcon
              position={"absolute"}
              _hover={{ cursor: "pointer" }}
              boxSize={"24px"}
              left={"24px"}
              top={"40px"}
              onClick={() => setShowValidators(false)}
            />
          ) : null}
          {(!hideTitleAddLiq || !hideTitleSuperfluid) && showValidators
            ? "Choose Validator"
            : "Add Liquidity"}
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <chakra.form onSubmit={handleSubmit(onSubmit)}>
            {/* {renderForm()} */}
            {showValidators ? (
              <ValidatorDelegations handleSelect={handleSelect} />
            ) : (
              renderForm()
            )}
            {/* {renderValidator()} */}
            {/* {renderConfirm()} */}
          </chakra.form>
        </ModalBody>
      </ModalContent>
    </Modal>
  ) : (
    //  renderPending()
    renderFinalSuperFluid()
  );
}
