import { num } from "@wizard-ui/core";
import { fromUnixTime, isBefore } from "date-fns";

import { Pool, PoolAsset, getTokenDenom } from "modules/common";
import { lpToTokens } from "modules/pools";

export class PoolBase {
  public pool: Pool;
  private _prices: Record<string, number>;
  private _balances: Record<string, string> | undefined;
  private _bondedLpTokens: Record<string, any> | undefined;
  private _tokenLocks: Record<string, any> | undefined;
  private _unclaimedRewards: Record<string, any> | undefined;
  private _tokens: Record<string, any> | undefined;

  constructor(
    pool: Pool,
    context: {
      prices: Record<string, number>;
      balances: Record<string, string> | undefined;
      bondedLpTokens: Record<string, any> | undefined;
      tokenLocks: Record<string, any> | undefined;
      unclaimedRewards: Record<string, any> | undefined;
      tokens: Record<string, any> | undefined;
    },
  ) {
    this.pool = pool;
    this._prices = context.prices;
    this._balances = context.balances;
    this._bondedLpTokens = context.bondedLpTokens;
    this._tokenLocks = context.tokenLocks;
    this._unclaimedRewards = context.unclaimedRewards;
    this._tokens = context.tokens;
  }

  get _totalTokensInPool() {
    return this.assets.reduce((acc: number, asset: PoolAsset) => {
      return num(asset.amount)
        .div(10 ** this._tokens[asset.identifier].decimals)
        .plus(acc)
        .toNumber();
    }, 0);
  }

  get id() {
    return this.pool.poolId;
  }

  get assets() {
    return this.pool.poolAssets;
  }

  get address() {
    return this.pool.poolContractAddress;
  }

  get vaultAddress() {
    return this.pool.vaultContractAddress;
  }

  get fee() {
    return this.pool.totalFeeBps / 1000;
  }

  get fee7d() {
    return this.pool.fee7d;
  }

  get volume7d() {
    return this.pool.volume7d;
  }
  get volume24h() {
    return this.pool.volume24h;
  }

  get apr7d() {
    if (!Number.isNaN(this.pool.apr7d)) {
      return this.pool.apr7d;
    }
    return 0;
  }
  get feeApr() {
    return this.pool.feeApr;
  }
  get extApr() {
    return this.pool.extApr;
  }

  get lpToken() {
    return this.pool.lpTokenContractAddress;
  }

  get lpTokenSupply() {
    return this.pool.lpTokensInSupply;
  }

  get tokensList() {
    return this.pool.poolAssets.map((asset: PoolAsset) => asset.identifier);
  }

  get tokensListWithTicker() {
    return this.pool.poolAssets.map((asset: PoolAsset) => {
      const token = asset.identifier;
      return this._tokens?.[token]?.ticker;
    });
  }

  get poolType() {
    if (
      this.pool.poolId == 1 ||
      this.pool.poolId == 7 ||
      this.pool.poolId == 9 ||
      this.pool.poolId == 11 ||
      this.pool.poolId == 12
    ) {
      return "metastable";
    } else if (this.pool.poolType.poolType == "stable_swap") {
      return "stableswap";
    }
    return this.pool.poolType.poolType;
  }

  get availableToBondAmount() {
    if (this._balances == undefined) {
      return "0";
    }
    return this._balances[this.lpToken];
  }

  get bondedAmount() {
    if (this._bondedLpTokens == undefined) {
      return "0";
    }
    return this._bondedLpTokens[this.lpToken];
  }

  get unbondingAmount() {
    if (this._tokenLocks == undefined) {
      return "0";
    }
    return this._tokenLocks[this.lpToken].locks.reduce(
      (acc: any, value: any) => {
        return num(value.amount).plus(acc).toString();
      },
      0,
    );
  }

  get unbondedAmount() {
    if (this._tokenLocks == undefined) {
      return "0";
    }
    return this._tokenLocks[this.lpToken].locks.reduce(
      (acc: any, value: any) => {
        const unlockDate = fromUnixTime(value.unlock_timestamp);
        const hasUnbonded = isBefore(unlockDate, new Date());

        if (!hasUnbonded) {
          return;
        }

        return num(value.amount).plus(acc).toString();
      },
      0,
    );
  }

  get unlockedAmount() {
    if (this._tokenLocks == undefined) {
      return "0";
    }
    return num(this._tokenLocks[this.lpToken].unlocked_amount).toString();
  }

  get unlockedAmountInUsd() {
    return this.getLpAmountInUsd(this.unlockedAmount);
  }

  get availableToBondAmountInUsd() {
    return this.getLpAmountInUsd(this.availableToBondAmount);
  }

  get bondedAmountInUsd() {
    return this.getLpAmountInUsd(this.bondedAmount);
  }

  get unbondingAmountInUsd() {
    return this.getLpAmountInUsd(this.unbondingAmount);
  }

  get unbondedAmountInUsd() {
    return this.getLpAmountInUsd(this.unbondedAmount);
  }

  get hasBonded() {
    return num(this.bondedAmount).gt(0);
  }

  get hasUnbonding() {
    return num(this.unbondingAmount).gt(0);
  }

  get hasUnbonded() {
    return num(this.unbondedAmount).gt(0);
  }

  get hasUnlocked() {
    return num(this.unlockedAmount).gt(0);
  }

  get stakingAPR() {
    // if (this.tokensList.includes("stk/uatom")) {
    if (this.pool.stakingApr != null) {
      const stakingApr = this.pool.stakingApr;
      const lpUSDPrice = this.getLpAmountInUsd(
        num(1)
          .times(10 ** 18)
          .toString(),
      );
      const stkAsset = this.assets.find((asset) =>
        asset.identifier.includes("stk/"),
      );
      const stkTokensCount = stkAsset?.amount;
      const stkTokensInLp = num(stkTokensCount ?? 0)
        .div(10 ** (stkAsset?.identifier == "stk/adydx" ? 18 : 6))
        .div(num(this.lpTokenSupply).div(10 ** 18))
        .toNumber();

      const currentStkAssetUSDPriceInLp = num(stkTokensInLp)
        .times(this._prices?.[stkAsset?.identifier! || "stk/uatom"]?.price ?? 0)
        .toNumber();

      const stakingRewardsUSDPrice = stakingApr * currentStkAssetUSDPriceInLp;
      const stakingAPR = (stakingRewardsUSDPrice * 100) / lpUSDPrice;
      return stakingAPR;
    } else {
      return null;
    }
  }

  get unclaimedRewardsInUsd() {
    if (this._unclaimedRewards == undefined) {
      return null;
    }

    const rewardsPerLp = this._unclaimedRewards[this.lpToken];

    if (rewardsPerLp.length == 0) {
      return null;
    }

    const totalRewardsPerLpInUsd = rewardsPerLp.reduce(
      (acc: any, data: any) => {
        const token = getTokenDenom(data.asset);
        const amountInUsd = num(data.amount)
          .div(10 ** this._tokens[token].decimals)
          .times(this._prices?.[token]?.price);

        return num(amountInUsd).plus(acc).toNumber();
      },
      0,
    );

    return totalRewardsPerLpInUsd;
  }

  get poolHasSuperfluidLp() {
    if (
      this.pool.poolAssets.some(
        (item) =>
          item.identifier.includes("uxprt") &&
          item.identifier.includes("stk/uxprt"),
      )
    ) {
      return true;
    }
    return false;
  }

  /**
   * @summary Calculates total liquidity of pool in USD.
   */
  public getTotalLiquidityInUsd(): number | null {
    // if (this.pool.currentLiquidityUSD != null) {
    //   return this.pool.currentLiquidityUSD;
    // }
    const prices = this._prices;

    if (prices == null) {
      return null;
    }

    const assetsWithPrice = this.assets.map((asset) => {
      const token = asset.identifier;
      return {
        amount: asset.amount,
        price: prices?.[token]?.price,
        token,
      };
    });

    return assetsWithPrice.reduce((acc, value) => {
      const totalPrice = num(value.amount)
        .div(10 ** this._tokens[value.token].decimals)
        .times(value.price);
      return num(acc).plus(totalPrice).toNumber();
    }, 0);
  }

  /**
   * @summary Calculates total user liquidity of pool in USD.
   */
  public getMyLiquidityInUsd(): number | null {
    if (this._balances == undefined) {
      return 0;
    }
    const totalLiquidityInUsd = this.getTotalLiquidityInUsd();
    const balance = this._balances[this.lpToken];
    if (totalLiquidityInUsd == null || balance == null) {
      return null;
    } else if (totalLiquidityInUsd == 0) {
      return 0;
    }
    return num(balance)
      .div(10 ** 6)
      .times(num(totalLiquidityInUsd).div(num(this.lpTokenSupply).div(10 ** 6)))
      .plus(this.bondedAmountInUsd)
      .plus(this.unbondingAmountInUsd)
      .plus(this.unlockedAmountInUsd)
      .toNumber();
  }

  /**
   * @summary Calculate amounts of other tokens to provide liqudity
   */
  public getProvideAmounts(token: string, amount: string): Record<string, any> {
    const ratios = this.assets.reduce(
      (acc: Record<string, number>, asset: PoolAsset) => {
        return {
          ...acc,
          [asset.identifier]: num(asset.amount)
            .div(10 ** this._tokens[asset.identifier].decimals)
            .div(this._totalTokensInPool)
            .toNumber(),
        };
      },
      {},
    );
    const tokenRatio = ratios[token];

    const prices = this._prices;
    // if (prices == null) {
    //   return null;
    // }

    return this.assets.map(({ identifier }: PoolAsset, index: number) => {
      const poolTokenRatio = ratios[identifier];

      return {
        token: identifier,
        input: `amount${index + 1}`,
        amount: num(poolTokenRatio)
          .times(amount)
          // .times((Number(amount) * prices[token]) / prices[identifier])
          .div(tokenRatio)
          .toFixed(),
      };
    });
  }

  /**
   * @summary Calculate amount of lp token in USD
   */
  public getLpAmountInUsd(amount: string): number {
    const poolLiquidity = this.getTotalLiquidityInUsd();
    const lpTokenSupply = this.lpTokenSupply;
    if (poolLiquidity == 0 && Number(lpTokenSupply) == 0) {
      return 0;
    }
    // return (Number(amount) * poolLiquidity) / lpTokenSupply;
    return num(amount)
      .div(10 ** 6)
      .times(poolLiquidity)
      .div(num(lpTokenSupply).div(10 ** 6))
      .toNumber();
  }
}
