import { useState } from "react";
import {
  ModalProps,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  Box,
  Text,
  chakra,
  CloseButton,
  Stack,
  Link,
  Image,
  HStack,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { formatAmount, num, NumberFormatSpecifier } from "@wizard-ui/core";

import {
  Wrapper,
  useDexter,
  WarningIcon,
  TxSuccess,
  TxPending,
  TxError,
  RightArrowIcon,
} from "modules/common";
import { PoolBase } from "modules/pools";
import { useUnlockMultistakingMutation } from "modules/contracts";
import { TokenDisplay } from "modules/swap";
import { toast } from "react-toastify";
import { useRawTokenLocks } from "modules/common/hooks/useRawTokenLocks";
import { useInstantUnlockFee } from "modules/common/hooks/useInstantUnlockFee";
import { useChain } from "@cosmos-kit/react";
import { useInstantUnlockFeeTiers } from "modules/common/hooks/useInstantUnlockFeeTiers";
import { useInstantUnbondConfig } from "modules/common/hooks/useInstantUnbondConfig";

interface FormValues {
  amount: string;
}

interface Props extends Omit<ModalProps, "children"> {
  pool: PoolBase;
  lpAmount: string;
  unlockTime: number;
}

export function InstantUnbondModal({
  pool,
  lpAmount,
  unlockTime,
  onClose,
  ...rest
}: Props) {
  const { bondedLpTokens, pools } = useDexter();
  const persistenceChain =
    process.env.NEXT_PUBLIC_ENV === "testnet"
      ? "persistencetestnet2"
      : process.env.NEXT_PUBLIC_ENV === "devnet"
      ? "Dexter Devnet"
      : "persistence";
  const chainContext = useChain(persistenceChain);
  const { connect, address, isWalletConnected } = chainContext;

  const [isConfirmStep, setIsConfirmStep] = useState(false);
  const {
    data: rawTokenLocksData,
    isInitialLoading: tokenLocksIsInitialLoading,
  } = useRawTokenLocks(pool.lpToken);

  const {
    data: instantUnlockFeeTiersData,
    isInitialLoading: feeTiersIsInitialLoading,
  } = useInstantUnlockFeeTiers(pool.lpToken);
  const {
    data: instantUnlockFeeData,
    isInitialLoading: instantUnlockFeeIsInitialLoading,
  } = useInstantUnlockFee(
    pool.lpToken,
    rawTokenLocksData?.find(
      (lock) => lock.amount == lpAmount && lock.unlock_time == unlockTime,
    ),
  );

  const { mutate, data, isLoading, reset, error } =
    useUnlockMultistakingMutation();
  const {
    handleSubmit,
    control,
    reset: resetForm,
    watch,
  } = useForm<FormValues>({
    mode: "onChange",
    defaultValues: {
      amount: "",
    },
  });
  const onSubmit = (values: FormValues) => {
    mutate({
      msg: {
        lpToken: pool.lpToken,
        token_locks: rawTokenLocksData?.filter(
          (lock) => lock.amount == lpAmount && lock.unlock_time == unlockTime,
        ),
        instantUnlock: true,
      },
    });
  };

  const handleClose = () => {
    onClose();
    setIsConfirmStep(false);
    reset();
    resetForm();
  };

  const renderForm = () => {
    if (isConfirmStep || isLoading || data != null || error != null) {
      return null;
    }

    return (
      <Box>
        {/* <Wrapper variant="primary" p="2" mb="6"> */}
        <Wrapper variant="unbond">
          <TokenDisplay
            amount={formatAmount(num(lpAmount).div(10 ** 12), {
              formatSpecifier: NumberFormatSpecifier.FLOAT,
              shouldDivide: true,
            })}
            token={pool.lpToken}
            isLpToken
            poolType={pool.poolType}
          />
        </Wrapper>
        {/* </Wrapper> */}
        <Text textStyle="dexterP2" mt="6" mb="2" ml="4">
          You Will Receive
        </Text>
        <Box mb="6" px="4">
          {/* <Wrapper variant="primary" p="2"> */}
          {/* <Wrapper variant="unbond"> */}
          <TokenDisplay
            amount={formatAmount(
              num(lpAmount)
                .minus(num(instantUnlockFeeData?.unlock_fee || 0))
                .div(10 ** 18) || "0",
              {
                formatSpecifier: NumberFormatSpecifier.FLOAT,
              },
            )}
            token={pool.lpToken}
            isLpToken
            poolType={pool.poolType}
          />
          {/* <Text fontSize={20}>Instant Unbond ⚡</Text>
              <Stack textAlign={"right"} spacing={0}>
                <Text fontSize="lg">
                  {formatAmount(
                    num(lpAmount)
                      .minus(num(instantUnlockFeeData?.unlock_fee || 0))
                      .div(10 ** 18) || "0",
                    {
                      formatSpecifier: NumberFormatSpecifier.FLOAT,
                    },
                  )}
                </Text>
                <Text fontSize="xs" color="whiteAlpha.500">
                  {formatAmount(
                    pools
                      ?.filter(
                        (insidePool) => insidePool.lpToken == pool.lpToken,
                      )[0]
                      .getLpAmountInUsd(
                        num(
                          num(lpAmount)
                            .minus(num(instantUnlockFeeData?.unlock_fee || 0))
                            .div(10 ** 18)
                            .toString()
                            .replaceAll(",", ""),
                        )
                          .times(10 ** 18)
                          .toString(),
                      ) || "0",
                    {
                      formatSpecifier: NumberFormatSpecifier.CURRENCY,
                    },
                  )}
                </Text>
              </Stack> */}
          {/* </Wrapper> */}
          {/* </Wrapper> */}
        </Box>
        <Wrapper variant="swapError" justifyContent="flex-start" pl={"12px"}>
          <WarningIcon boxSize="30px" />
          <Text variant="dexterP4" color="primary.white">
            {instantUnlockFeeData?.unlock_fee_bp / 100}% will be deducted from
            your LP Shares. <br />
            <Link
              href={
                "https://docs.dexter.zone/deep-dive/instant-lp-unbonding#fee-structure"
              }
              isExternal
              target="_blank"
              rel="noopener noreferrer"
            >
              <HStack
                gap={0}
                fontSize="md"
                textDecoration={"underline"}
                textUnderlineOffset={2}
                fontWeight={400}
              >
                <Text>Learn more</Text>
                <Image src="./icons/arrow-up-right.svg" />
              </HStack>
            </Link>
          </Text>
        </Wrapper>
        <Button
          mt="8"
          variant="primary"
          type="submit"
          w="full"
          size="xl"
          // isDisabled
          isDisabled={
            isWalletConnected &&
            num(lpAmount)
              .div(10 ** 18)
              .lt(0)
          }
          onClick={() => !isWalletConnected && connect()}
        >
          {isWalletConnected ? "Unlock" : "Connect Wallet"}
        </Button>
      </Box>
    );
  };

  const CustomCloseButton = ({ closeToast }) => (
    <CloseButton
      pos={"absolute"}
      left="0"
      top={"0"}
      color="#FCFCFC"
      w={34}
      h={34}
      borderRadius={"50%"}
      border={"2px solid #386794"}
      bg={"#0a1d32"}
      onClick={() => {
        handleClose();
        closeToast();
      }}
    />
  );

  const renderFinal = () => {
    // handleClose();
    // onClose();
    if (isLoading) {
      return toast(<TxPending />, {
        style: { zIndex: 1200 },
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: false,
        toastId: "   ",
        closeButton: CustomCloseButton,
      });
    } else {
      if (data == null && error != null) {
        return toast.update("   ", {
          render: <TxError />,
          style: { zIndex: 1200 },
          closeButton: CustomCloseButton,
        });
      }

      if (data == null) {
        return null;
      }

      return toast.update("   ", {
        render: () => (
          <TxSuccess
            subtitle="LP Tokens Unbonded Successfully"
            data={data}
            title="Transaction Successful"
          />
        ),
        style: { zIndex: 1200 },
        closeButton: CustomCloseButton,
      });
    }
  };

  const hideTitle = isLoading || data != null || error != null;

  return !hideTitle ? (
    <Modal onClose={handleClose} size="lg" isCentered {...rest}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {!hideTitle && "Instant Unlock"}
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <chakra.form onSubmit={handleSubmit(onSubmit)}>
            {renderForm()}
            {/* {renderConfirm()} */}
            {/* {renderPending()}
            {renderFinal()} */}
          </chakra.form>
        </ModalBody>
      </ModalContent>
    </Modal>
  ) : (
    renderFinal()
  );
}
