import { useMemo } from "react";
import { num } from "@wizard-ui/core";

import { getTokenDenom, useDexter } from "modules/common";

export function useUnclaimedRewardsInUsd() {
  const { prices, unclaimedRewards, tokens } = useDexter();

  return useMemo(() => {
    if (prices == null || unclaimedRewards == null) {
      return null;
    }
    const totalRewards = Object.keys(unclaimedRewards).reduce(
      (acc: any, lpToken: string) => {
        const rewardsPerLp = unclaimedRewards[lpToken];

        if (rewardsPerLp.length == 0) {
          return acc;
        }

        const totalRewardsPerLpInUsd = rewardsPerLp.reduce(
          (acc: any, data: any) => {
            const token = getTokenDenom(data.asset);
            const amountInUsd = num(data.amount)
              .div(10 ** tokens[token].decimals)
              .times(prices[token].price);
            return num(amountInUsd).plus(acc).toNumber();
          },
          0,
        );
        return num(acc).plus(totalRewardsPerLpInUsd).toNumber();
      },
      0,
    );

    return totalRewards;
  }, [prices, unclaimedRewards]);
}
