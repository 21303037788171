import { Flex, Box, Text, Link, HStack } from "@chakra-ui/react";
import {
  TelegramIcon,
  TwitterIcon,
  DiscordIcon,
  GitHubIcon,
  MediumIcon,
} from "./icons";

export function Footer() {
  return (
    <Box width="full" zIndex="0">
      <Box
        backgroundImage="./images/bg_rocks.png"
        w="full"
        h={["100px", "100px", "128px", "238px", "238px", "238px"]}
        backgroundSize="cover"
        backgroundPosition="center center"
      />
      <Flex
        as="footer"
        alignItems="center"
        justifyContent="center"
        gap="8px"
        color="primary.bluePinch"
        py="16px"
        marginTop="153px"
        position="absolute"
        width="full"
        left="0px"
        bottom="0px"
        direction={["column", "column", "column", "row", "row", "row"]}
      >
        <HStack>
          <Link href="https://www.dexter.zone/" isExternal>
            <Text variant="dexterP4">About</Text>
          </Link>
          <Box
            width="3px"
            height="3px"
            borderRadius="full"
            background="primary.bluePinch"
          />
          <Link href="https://www.dexter.zone/terms" isExternal>
            <Text variant="dexterP4">Terms of Use</Text>
          </Link>
          <Box
            width="3px"
            height="3px"
            borderRadius="full"
            background="primary.bluePinch"
          />
          <Link href="https://www.dexter.zone/privacy" isExternal>
            <Text variant="dexterP4">Privacy Policy</Text>
          </Link>

          <Box
            width="3px"
            height="3px"
            borderRadius="full"
            background="primary.bluePinch"
          />
        </HStack>
        <HStack>
          <Link href="https://t.me/DexterZoneOfficial" isExternal>
            <TelegramIcon />
          </Link>
          <Link href="https://twitter.com/dexter_zone" isExternal>
            <TwitterIcon />
          </Link>
          <Link href="https://discord.persistence.one/" isExternal>
            <DiscordIcon />
          </Link>
          <Link href="https://github.com/dexter-zone" isExternal>
            <GitHubIcon />
          </Link>
          <Link href="https://medium.com/@Dexter.Zone" isExternal>
            <MediumIcon />
          </Link>
        </HStack>
      </Flex>
    </Box>
  );
}
