import { useEffect, useState } from "react";
import {
  ModalProps,
  Modal,
  Button,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  chakra,
  VStack,
  Box,
  CloseButton,
  Text,
  Heading,
  HStack,
  Checkbox,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { num } from "@wizard-ui/core";

import {
  toAsset,
  TxPending,
  TxSuccess,
  TxError,
  useDexter,
  ChevronLeftIcon,
} from "modules/common";
import { PoolBase } from "modules/pools";
import { getLpTokensReceived } from "modules/contracts";
import { TokenInput } from "./TokenInput";
import TokenInfo from "./TokenInfo";

import { toast } from "react-toastify";
import { useChain, useManager } from "@cosmos-kit/react";
import { debounce, floor } from "lodash";
import { useSuperfluidLpMutation } from "modules/contracts/useSuperfluidLpMutation";
import { ValidatorDelegations } from "./ValidatorDelegations";
import { StakedTokenInput } from "./StakedTokenInput";
import { useStkXprtCValue } from "../hooks/useStkXprtCValue";

type FormValues = {
  token1: string;
  amount1: string;
  token2: string;
  amount2: string;
  token3: string;
  amount3: string;
};

interface AddLiquidityModalProps extends Omit<ModalProps, "children"> {
  pool: PoolBase;
}

export function SuperfluidAddLiquidityModal({
  pool,
  onClose,
  ...rest
}: AddLiquidityModalProps) {
  const { balances } = useDexter();
  const validatorList =
    balances === undefined
      ? []
      : Object.entries(balances)
          .filter(([key]) => key.includes("valoper"))
          // .sort((a, b) => {
          //   if (a[1].balance.amount > b[1].balance.amount) {
          //     return -1;
          //   }
          //   if (a[1].balance.amount < b[1].balance.amount) {
          //     return 1;
          //   }
          //   return 0;
          // })
          .map(([key, value]) => ({
            validator: key,
            balance: value.balance,
            jailed: value.jailed,
            moniker: value.moniker,
            eligible: value.eligible,
          }))
          .sort((a, b) => {
            if (a.eligible !== b.eligible) {
              return a.eligible ? -1 : 1;
            }

            // If both validators have the same eligibility, sort by balance amount in descending order
            return b.balance.amount - a.balance.amount;
          });
  const persistenceChain =
    process.env.NEXT_PUBLIC_ENV === "testnet"
      ? "persistencetestnet2"
      : process.env.NEXT_PUBLIC_ENV === "devnet"
      ? "Dexter Devnet"
      : "persistence";
  const chainContext = useChain(persistenceChain);
  const { getWalletRepo } = useManager();
  const walletRepo = getWalletRepo(persistenceChain);

  const cValue = useStkXprtCValue();
  // const stkDydxCValue = useDydxCValue();

  // const { data: poolConfig, isInitialLoading: poolsIsInitialLoading } =
  //   useLpTokensReceiveQuery(pool.address);

  // console.log({ poolConfig });

  const { connect, address, isWalletConnected, getCosmWasmClient } =
    chainContext;
  const { mutate, isLoading, data, reset, error } = useSuperfluidLpMutation();
  const [isConfirmStep, setIsConfirmStep] = useState(false);
  const [tokenSelect, setTokenSelect] = useState({ token: "", index: 0 });
  const [singleAsset, setSingleAsset] = useState(false);
  const [lpReceived, setLpReceived] = useState("0");
  const [priceImpact, setPriceImpact] = useState("0");
  const [showValidators, setShowValidators] = useState(false);
  const [selectedValidator, setSelectValidator] = useState(validatorList[0]);
  const [autoBond, setAutoBond] = useState(false);
  const tokens = pool.assets.map(({ identifier }) => identifier);
  const defaultValues = tokens.reduce((acc, value, index) => {
    return {
      ...acc,
      [`token${index + 1}`]: value,
      [`amount${index + 1}`]: "0",
    };
  }, {});

  useEffect(() => {
    setSelectValidator(validatorList[0]);
  }, [validatorList.length]);
  // console.log({ defaultValues });
  const defaultValues1 = {
    token3: "staked-uxprt",
    amount3: "0",
    ...defaultValues,
  };
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    getValues,
    reset: resetForm,
  } = useForm<FormValues>({
    mode: "onChange",
    defaultValues: defaultValues1,
  });

  const watchValues = watch() as Record<string, any>;

  useEffect(() => {
    async function lpTokensReceived() {
      const client = await getCosmWasmClient();
      const assets = tokens.reduce((acc, token, i) => {
        if (!num(watchValues[`amount${i + 1}`]).isGreaterThan(0)) {
          return acc;
        }
        const amount = num(watchValues[`amount${i + 1}`])
          .times(
            token ==
              "ibc/A6E3AF63B3C906416A9AF7A556C59EA4BD50E617EFFE6299B99700CCB780E444" ||
              token ==
                "ibc/23DC3FF0E4CBB53A1915E4C62507CB7796956E84C68CA49707787CB8BDE90A1E" ||
              token == "stk/adv4tnt" ||
              token ==
                "ibc/18200EAA7E5BB3D235FF517F04045F4DCB0691CE6FC1B32E4297BEA8EF7710E3"
              ? 10 ** 18
              : 10 ** 6,
          )
          .toFixed(0);

        return [...acc, toAsset({ token, amount })];
      }, []);
      const superFluidAssets = [
        ...assets,
        toAsset({
          token: "staked-uxprt",
          amount: num(watchValues.amount3)
            .times(10 ** 6)
            .toFixed(0),
        }),
      ];
      const finalAssets = superFluidAssets.map(
        (asset: {
          info: { native_token: { denom: string } };
          amount: BigNumber.Value | undefined;
        }) => {
          if (asset.info.native_token.denom === "staked-uxprt") {
            const stkXprtAmount = num(asset.amount)
              .times(num(floor(cValue, 5)))
              .toFixed(0);
            return toAsset({ amount: stkXprtAmount, token: "stk/uxprt" });
          }
          return {
            info: asset.info,
            amount: asset.amount,
          };
        },
      );
      const stkXprtAmount = finalAssets.reduce((acc: any, asset: any) => {
        if (asset.info.native_token.denom === "stk/uxprt") {
          return acc + num(asset.amount).toNumber();
        }
        return acc;
      }, 0);
      const stkXprtAsset = toAsset({
        amount: stkXprtAmount.toString(),
        token: "stk/uxprt",
      });
      const xprtAmount = num(watchValues.amount2)
        .times(10 ** 6)
        .toFixed(0);
      const xprtAsset = toAsset({
        amount: xprtAmount || "0",
        token: "uxprt",
      });
      const totalAssets = [xprtAsset, stkXprtAsset];

      const { lpTokenReceived, priceImpact } = await getLpTokensReceived(
        totalAssets,
        pool,
        client,
      );
      setLpReceived(lpTokenReceived);
      setPriceImpact(priceImpact);
    }
    const debouncedLpTokensReceived = debounce(lpTokensReceived, 300);
    debouncedLpTokensReceived();
  }, [watchValues]);

  const handleAmountChangeAvailableBalance = (
    token: string,
    amount: string,
    input: string,
  ) => {
    const amounts = pool.getProvideAmounts(token, amount);
    const otherToken = amounts.filter((amount) => amount.input !== input)[0];

    if (singleAsset || pool._totalTokensInPool == 0) {
      amounts
        .filter((token) => token == tokenSelect.token)
        .map((a) => {
          setValue(a.input, a.amount);
        });
    } else {
      setValue(token, amount);
    }
  };
  const handleAmountChange = async (
    token: string,
    amount: string,
    input: string,
  ) => {
    setValue(token, amount);
  };

  const onSubmit = (values: FormValues) => {
    // @ts-expect-error - TODO
    const assets = tokens.reduce((acc, token, i) => {
      // @ts-expect-error - TODO
      if (!num(values[`amount${i + 1}`]).isGreaterThan(0)) {
        return acc;
      }
      // @ts-expect-error - TODO
      const amount = num(values[`amount${i + 1}`])
        .times(
          token ==
            "ibc/A6E3AF63B3C906416A9AF7A556C59EA4BD50E617EFFE6299B99700CCB780E444" ||
            token ==
              "ibc/23DC3FF0E4CBB53A1915E4C62507CB7796956E84C68CA49707787CB8BDE90A1E" ||
            token == "stk/adydx" ||
            token ==
              "ibc/18200EAA7E5BB3D235FF517F04045F4DCB0691CE6FC1B32E4297BEA8EF7710E3"
            ? 10 ** 18
            : 10 ** 6,
        )
        .toFixed(0);

      return [...acc, toAsset({ token, amount })];
    }, []);
    const superFluidAssets = [
      ...assets,
      toAsset({
        token: "staked-uxprt",
        amount: num(values.amount3)
          .times(10 ** 6)
          .toFixed(0),
      }),
    ];
    if (num(values.amount3).eq(0)) {
      mutate({
        msg: {
          poolId: String(pool.id),
          validatorAddress: "",
          assets: assets,
          normalJoinPool: true,
          autoStake: autoBond,
        },
      });
    } else {
      mutate({
        msg: {
          poolId: String(pool.id),
          validatorAddress: selectedValidator.validator,
          assets: superFluidAssets,
          normalJoinPool: false,
          autoStake: true,
        },
      });
    }
  };

  const handleClose = () => {
    onClose();
    setIsConfirmStep(false);
    reset();
    resetForm();
  };

  const handleSingleAssetSwitch = (token: string, index: number) => {
    setTokenSelect({ token: token, index: index + 1 });
    index === 0 ? setValue("amount2", "") : setValue("amount1", "");
  };
  const handleAssetLiquiditySwitch = () => {
    setSingleAsset((singleAsset) => !singleAsset);
    setValue("amount1", "");
    setValue("amount2", "");
  };

  const handleChooseValidatorClick = () => {
    setShowValidators(true);
  };

  const handleSelect = (validator) => {
    setSelectValidator(validator);
    // setValue("amount3", "")
    setShowValidators(false);
  };

  const mobileWebModeName = "mobile-web";

  const connectHandler = () => {
    sessionStorage.setItem("terms", "show");
    if (
      (window?.leap && window?.leap?.mode === mobileWebModeName) ||
      window?.navigator?.userAgent?.includes("LeapCosmos")
    ) {
      const wallet = walletRepo.wallets.find(
        (wallet) => wallet._walletInfo.name === "leap-extension",
      );
      wallet._walletInfo.mobileDisabled = false;
      walletRepo.connect("leap-extension");
      return;
    } else if (window?.keplr && window?.keplr?.mode === mobileWebModeName) {
      const wallet = walletRepo.wallets.find(
        (wallet) => wallet._walletInfo.name === "keplr-extension",
      );
      wallet._walletInfo.mobileDisabled = false;
      walletRepo.connect("keplr-extension");
      return;
    } else {
      connect();
      return;
    }
  };

  const renderForm = () => {
    if (isConfirmStep || isLoading || data != null || error != null) {
      return null;
    }

    return (
      <Box>
        {/* {pool.poolType === "weighted" && (
          <HStack mb="2">
            <Switch
              size={"sm"}
              color={"#386794"}
              isChecked={singleAsset}
              onChange={() => handleAssetLiquiditySwitch()}
            />
            <Text>Provide Single Asset Liquidity</Text>
            <Tooltip
              hasArrow
              bg="primary.ctaDisabled"
              color="white"
              borderRadius="lg"
              p="3"
              label="Allows a user to provide liquidity using only one asset. The asset provided gets swapped for the other asset as per the pool ratio and liquidity is added to the pool."
            >
              <span>
                <InfoIcon _hover={{ cursor: "pointer" }} />
              </span>
            </Tooltip>
          </HStack>
        )} */}
        {singleAsset ? (
          <VStack gap="1" mb="4" align="auto">
            {tokens.map((token, index) => {
              return tokenSelect.token !== token ? (
                <Box
                  _hover={{
                    bgColor: "primary.prussianBlue",
                    borderWidth: "1px",
                    borderRadius: "lg",
                    borderColor: "primary.queenBlue",
                    cursor: "pointer",
                  }}
                  onClick={() => handleSingleAssetSwitch(token, index)}
                >
                  <TokenInfo
                    handleAmountChange={handleAmountChange}
                    token={token}
                    index={index}
                    control={control}
                  />
                </Box>
              ) : (
                <TokenInput
                  key={token}
                  amountInputName={`amount${index + 1}`}
                  tokenInputName={`token${index + 1}`}
                  token={token}
                  control={control}
                  hideSelect
                  onAmountChange={(e) => {
                    handleAmountChange(
                      token,
                      e.target.value,
                      `amount${index + 1}`,
                    );
                  }}
                  onAmountChangeAvailable={(e) => {
                    handleAmountChangeAvailableBalance(
                      token,
                      e.target.value,
                      `amount${index + 1}`,
                    );
                  }}
                />
              );
            })}
          </VStack>
        ) : (
          <VStack gap="4" mb="4" align="auto">
            {tokens.map((token, index) => {
              return (
                <TokenInput
                  key={token}
                  amountInputName={`amount${index + 1}`}
                  tokenInputName={`token${index + 1}`}
                  token={token}
                  control={control}
                  hideSelect
                  onAmountChange={(e) => {
                    handleAmountChange(
                      token,
                      e.target.value,
                      `amount${index + 1}`,
                    );
                  }}
                  onAmountChangeAvailable={(e) => {
                    handleAmountChangeAvailableBalance(
                      token,
                      e.target.value,
                      `amount${index + 1}`,
                    );
                  }}
                />
              );
            })}
            {validatorList.length > 0 ? (
              <>
                <Heading variant="dexterH1">
                  Convert Staked XPRT into LP
                </Heading>
                <StakedTokenInput
                  amountInputName={`amount3`}
                  tokenInputName={`token3`}
                  token={"staked-uxprt"}
                  control={control}
                  hideSelect
                  selectedValidator={selectedValidator}
                  handleChooseValidatorClick={handleChooseValidatorClick}
                  onAmountChange={(e) => {
                    handleAmountChange(
                      "staked-uxprt",
                      e.target.value,
                      `amount3`,
                    );
                  }}
                  // onAmountChangeAvailable={(e) => {
                  //   handleAmountChangeAvailableBalance(
                  //     token,
                  //     e.target.value,
                  //     `amount${index + 1}`,
                  //   );
                  // }}
                />
              </>
            ) : null}
          </VStack>
        )}
        <Checkbox
          mb={"14px"}
          ml={"4px"}
          className="my-small-checkbox"
          isChecked={num(watchValues.amount3).gt(0) || autoBond}
          isDisabled={num(watchValues.amount3).gt(0)}
          onChange={(e) => setAutoBond(e.target.checked)}
          alignItems={"center"}
        >
          <Text ml="2px" fontSize={16} color={"#FCFCFC"} fontWeight={400}>
            Bond LP tokens for 7 days to earn higher yield
          </Text>
        </Checkbox>
        <VStack align={"stretch"} mb={4} px={2}>
          <HStack justify={"space-between"} fontWeight={600}>
            <Text color={"#D2D2D2"}>LP Tokens Received:</Text>
            <Text fontWeight={700}>{lpReceived ? lpReceived : "--"}</Text>
          </HStack>
          <HStack justify={"space-between"} fontWeight={600}>
            <Text color={"#D2D2D2"}>Price Impact: </Text>
            <Text
              color={Number(priceImpact) < 0 ? "#B95663" : "#10EA88"}
              fontWeight={700}
            >
              {priceImpact ? `${priceImpact}%` : "--"}
            </Text>
          </HStack>
        </VStack>
        <Button
          variant="primary"
          type="submit"
          w="full"
          size="xl"
          isDisabled={
            isWalletConnected && singleAsset
              ? num(balances?.[watchValues[`token${tokenSelect.index}`]])
                  .div(
                    watchValues[`token${tokenSelect.index}`] ==
                      "ibc/A6E3AF63B3C906416A9AF7A556C59EA4BD50E617EFFE6299B99700CCB780E444" ||
                      watchValues[`token${tokenSelect.index}`] ==
                        "ibc/23DC3FF0E4CBB53A1915E4C62507CB7796956E84C68CA49707787CB8BDE90A1E" ||
                      watchValues[`token${tokenSelect.index}`] ==
                        "ibc/18200EAA7E5BB3D235FF517F04045F4DCB0691CE6FC1B32E4297BEA8EF7710E3" ||
                      watchValues[`token${tokenSelect.index}`] == "stk/adv4tnt"
                      ? 10 ** 18
                      : 10 ** 6,
                  )
                  .lt(num(watchValues[`amount${tokenSelect.index}`])) ||
                watchValues[`amount${tokenSelect.index}`] === "" ||
                Number(watchValues[`amount${tokenSelect.index}`]) <= 0
              : num(balances?.[watchValues["token1"]])
                  .div(
                    watchValues["token1"] ==
                      "ibc/A6E3AF63B3C906416A9AF7A556C59EA4BD50E617EFFE6299B99700CCB780E444" ||
                      watchValues["token1"] ==
                        "ibc/23DC3FF0E4CBB53A1915E4C62507CB7796956E84C68CA49707787CB8BDE90A1E" ||
                      watchValues["token1"] ==
                        "ibc/18200EAA7E5BB3D235FF517F04045F4DCB0691CE6FC1B32E4297BEA8EF7710E3" ||
                      watchValues["token1"] == "stk/adv4tnt"
                      ? 10 ** 18
                      : 10 ** 6,
                  )
                  .lt(num(watchValues["amount1"])) ||
                num(balances?.[watchValues["token2"]])
                  .div(
                    watchValues["token2"] ==
                      "ibc/A6E3AF63B3C906416A9AF7A556C59EA4BD50E617EFFE6299B99700CCB780E444" ||
                      watchValues["token2"] ==
                        "ibc/23DC3FF0E4CBB53A1915E4C62507CB7796956E84C68CA49707787CB8BDE90A1E" ||
                      watchValues["token2"] ==
                        "ibc/18200EAA7E5BB3D235FF517F04045F4DCB0691CE6FC1B32E4297BEA8EF7710E3" ||
                      watchValues["token2"] == "stk/adv4tnt"
                      ? 10 ** 18
                      : 10 ** 6,
                  )
                  .lt(num(watchValues["amount2"])) ||
                watchValues["amount1"] === "" ||
                watchValues["amount2"] === "" ||
                watchValues["amount3"] === "" ||
                (Number(watchValues["amount1"]) <= 0 &&
                  Number(watchValues["amount2"]) <= 0 &&
                  Number(watchValues["amount3"]) <= 0) ||
                Number(watchValues["amount1"]) < 0 ||
                Number(watchValues["amount2"]) < 0
          }
          onClick={() => !isWalletConnected && connectHandler()}
        >
          {isWalletConnected
            ? singleAsset
              ? num(balances?.[watchValues[`token${tokenSelect.index}`]])
                  .div(
                    watchValues[`token${tokenSelect.index}`] ==
                      "ibc/A6E3AF63B3C906416A9AF7A556C59EA4BD50E617EFFE6299B99700CCB780E444" ||
                      watchValues[`token${tokenSelect.index}`] ==
                        "ibc/23DC3FF0E4CBB53A1915E4C62507CB7796956E84C68CA49707787CB8BDE90A1E" ||
                      watchValues[`token${tokenSelect.index}`] ==
                        "ibc/18200EAA7E5BB3D235FF517F04045F4DCB0691CE6FC1B32E4297BEA8EF7710E3" ||
                      watchValues[`token${tokenSelect.index}`] == "stk/adv4tnt"
                      ? 10 ** 18
                      : 10 ** 6,
                  )
                  .lt(num(watchValues[`amount${tokenSelect.index}`]))
                ? "Insufficient Balance"
                : "Add Liquidity"
              : num(balances?.[watchValues["token1"]])
                  .div(
                    watchValues["token1"] ==
                      "ibc/A6E3AF63B3C906416A9AF7A556C59EA4BD50E617EFFE6299B99700CCB780E444" ||
                      watchValues["token1"] ==
                        "ibc/23DC3FF0E4CBB53A1915E4C62507CB7796956E84C68CA49707787CB8BDE90A1E" ||
                      watchValues["token1"] ==
                        "ibc/18200EAA7E5BB3D235FF517F04045F4DCB0691CE6FC1B32E4297BEA8EF7710E3" ||
                      watchValues["token1"] == "stk/adv4tnt"
                      ? 10 ** 18
                      : 10 ** 6,
                  )
                  .lt(num(watchValues["amount1"])) ||
                num(balances?.[watchValues["token2"]])
                  .div(
                    watchValues["token2"] ==
                      "ibc/A6E3AF63B3C906416A9AF7A556C59EA4BD50E617EFFE6299B99700CCB780E444" ||
                      watchValues["token2"] ==
                        "ibc/23DC3FF0E4CBB53A1915E4C62507CB7796956E84C68CA49707787CB8BDE90A1E" ||
                      watchValues["token2"] ==
                        "ibc/18200EAA7E5BB3D235FF517F04045F4DCB0691CE6FC1B32E4297BEA8EF7710E3" ||
                      watchValues["token2"] == "stk/adv4tnt"
                      ? 10 ** 18
                      : 10 ** 6,
                  )
                  .lt(num(watchValues["amount2"]))
              ? "Insufficient Balance"
              : "Add Liquidity"
            : "Connect Wallet"}
        </Button>
      </Box>
    );
  };

  const CustomCloseButton = ({ closeToast }) => (
    <CloseButton
      pos={"absolute"}
      left="0"
      top={"0"}
      color="#FCFCFC"
      w={34}
      h={34}
      borderRadius={"50%"}
      border={"2px solid #386794"}
      bg={"#0a1d32"}
      onClick={() => {
        handleClose();
        closeToast();
      }}
    />
  );

  const renderFinal = () => {
    if (isLoading) {
      return toast(<TxPending />, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: false,
        toastId: "",
        closeButton: CustomCloseButton,
      });
    } else {
      if (data == null && error != null) {
        console.log(error, "Add Liq Error");
        handleClose();
        return toast.update("", {
          render: <TxError />,
          closeButton: CustomCloseButton,
          autoClose: 7000,
        });
      }

      if (data == null) {
        return null;
      }
      handleClose();
      return toast.update("", {
        render: () => (
          <TxSuccess
            subtitle="Liquidity Added Successfully"
            data={data}
            title="Transaction Successful"
          />
        ),
        closeButton: CustomCloseButton,
        autoClose: 7000,
      });
    }
  };

  const hideTitle = isLoading || data != null || error != null;

  return !hideTitle ? (
    <Modal size="lg" onClose={handleClose} isCentered {...rest}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {showValidators ? (
            <ChevronLeftIcon
              position={"absolute"}
              _hover={{ cursor: "pointer" }}
              boxSize={"24px"}
              left={"24px"}
              top={"40px"}
              onClick={() => setShowValidators(false)}
            />
          ) : null}
          {showValidators ? "Choose Validator" : "Superfluid Add Liquidity"}
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <chakra.form onSubmit={handleSubmit(onSubmit)}>
            {showValidators ? (
              <ValidatorDelegations handleSelect={handleSelect} />
            ) : (
              renderForm()
            )}
            {/* {renderConfirm()} */}
          </chakra.form>
        </ModalBody>
      </ModalContent>
    </Modal>
  ) : (
    //  renderPending()
    renderFinal()
  );
}
