export function Logo({
  width = 189,
  height = 50,
}: {
  width?: number;
  height?: number;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 189 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.93093 19.2256L7.77115 11.2478C7.879 10.2238 8.36188 9.27572 9.12692 8.58606L9.12692 8.58605C9.50981 8.24088 10.1221 8.49322 10.1503 9.00781L10.7099 19.2256H6.93093Z"
        fill="#FCFCFC"
      />
      <g opacity="0.35">
        <path
          d="M10.1257 8.55863C10.0902 7.91158 9.12692 7.93792 9.12692 8.58593V8.58595C9.07136 8.63604 9.01776 8.68787 8.96521 8.74061L9.28274 14.5379C9.34841 15.7367 8.39348 16.7448 7.1922 16.7448H7.1922L6.93094 19.2255H10.71L10.1257 8.55863Z"
          fill="#1F1E1C"
        />
      </g>
      <path
        d="M10.7099 19.7476H6.93088C6.78282 19.7476 6.64158 19.6847 6.54255 19.5747C6.4434 19.4647 6.39569 19.3178 6.41127 19.1706L7.2516 11.1928C7.37305 10.0388 7.91472 8.97521 8.77699 8.19789C9.10501 7.90227 9.56264 7.82312 9.97075 7.99136C10.3791 8.15966 10.6478 8.53802 10.672 8.97893L11.2317 19.1968C11.2395 19.34 11.188 19.4802 11.0893 19.5843C10.9907 19.6885 10.8534 19.7476 10.7099 19.7476ZM7.51128 18.703H10.158L9.62846 9.03597C9.62774 9.02183 9.62534 8.97881 9.57235 8.957C9.51948 8.93519 9.48747 8.96401 9.47692 8.97348C8.80648 9.57789 8.3853 10.4048 8.29082 11.3021L7.51128 18.703Z"
        fill="#1F1E1C"
      />
      <path
        d="M11.4517 31.9552C10.7494 32.0618 10.0573 32.1014 9.41138 32.1001C7.31725 32.0958 5.59225 30.4658 5.42263 28.3796C5.18457 25.4517 5.27093 22.969 5.42254 21.1907C5.58346 19.3031 7.03888 17.7834 8.91829 17.5374C9.46494 17.4659 9.96997 17.4199 10.3933 17.3903C11.0885 17.3418 11.691 17.8656 11.7418 18.5603L12.6149 30.4972C12.6672 31.2119 12.1605 31.8475 11.4517 31.9552Z"
        fill="#FCFCFC"
      />
      <g opacity="0.35">
        <path
          d="M12.6149 30.497L12.3568 26.9685C12.3443 27.63 11.8568 28.1979 11.1904 28.2991C10.4881 28.4058 9.79598 28.4454 9.15011 28.4441C7.29637 28.4403 5.7328 27.1622 5.28039 25.4219C5.29439 26.3433 5.33748 27.3322 5.42263 28.3794C5.59224 30.4655 7.31724 32.0955 9.41137 32.0998C10.0572 32.1011 10.7494 32.0616 11.4517 31.9549C12.1605 31.8473 12.6671 31.2116 12.6149 30.497Z"
          fill="#1F1E1C"
        />
      </g>
      <path
        d="M9.44215 32.6224H9.41026C7.07332 32.6176 5.09294 30.7726 4.90183 28.422C4.70101 25.9518 4.70101 23.504 4.90183 21.1464C5.08214 19.031 6.74277 17.2955 8.85048 17.0196C9.34072 16.9555 9.84762 16.905 10.357 16.8694C11.3368 16.8029 12.1913 17.5425 12.263 18.5222L13.136 30.4592C13.2077 31.44 12.5024 32.3239 11.5301 32.4715C10.8711 32.5716 10.1687 32.6224 9.44215 32.6224ZM10.4816 17.9096C10.4645 17.9096 10.4472 17.9102 10.4298 17.9113C9.94126 17.9454 9.45557 17.9939 8.9862 18.0553C7.3619 18.2679 6.08205 19.6052 5.94321 21.2351C5.74719 23.5352 5.74731 25.9248 5.94345 28.3374C6.09092 30.1508 7.61463 31.5742 9.41242 31.5778C9.42225 31.5779 9.43232 31.5779 9.44227 31.5779C10.1164 31.5779 10.7659 31.5312 11.3731 31.4388C11.8094 31.3726 12.1259 30.9758 12.0937 30.5354L11.2206 18.5984C11.1921 18.2086 10.8659 17.9096 10.4816 17.9096Z"
        fill="#1F1E1C"
      />
      <path
        d="M51.21 19.8467L50.3698 11.8689C50.262 10.8448 49.7791 9.89682 49.0141 9.20715L49.014 9.20715C48.6312 8.86198 48.0189 9.11431 47.9907 9.6289L47.431 19.8467H51.21Z"
        fill="#FCFCFC"
      />
      <g opacity="0.35">
        <path
          d="M48.0153 9.17972C48.0507 8.53268 49.014 8.55901 49.014 9.20703V9.20705C49.0696 9.25713 49.1232 9.30896 49.1758 9.36171L48.8582 15.159C48.7925 16.3578 49.7475 17.3659 50.9488 17.3659H50.9488L51.21 19.8466H47.431L48.0153 9.17972Z"
          fill="#1F1E1C"
        />
      </g>
      <path
        d="M47.4311 20.3691H51.2101C51.3581 20.3691 51.4994 20.3063 51.5984 20.1963C51.6976 20.0863 51.7453 19.9394 51.7297 19.7922L50.8894 11.8143C50.7679 10.6604 50.2262 9.5968 49.364 8.81947C49.0359 8.52385 48.5783 8.44471 48.1702 8.61295C47.7619 8.78124 47.4932 9.1596 47.469 9.60051L46.9093 19.8183C46.9015 19.9615 46.9529 20.1017 47.0516 20.2059C47.1503 20.3101 47.2876 20.3691 47.4311 20.3691ZM50.6297 19.3246H47.9829L48.5125 9.65755C48.5132 9.64341 48.5156 9.60039 48.5686 9.57858C48.6215 9.55678 48.6535 9.58559 48.664 9.59506C49.3345 10.1995 49.7557 11.0264 49.8501 11.9237L50.6297 19.3246Z"
        fill="#1F1E1C"
      />
      <path
        d="M46.6893 32.5762C47.3916 32.6829 48.0837 32.7225 48.7296 32.7212C50.8237 32.7169 52.5487 31.0869 52.7183 29.0007C52.9564 26.0728 52.87 23.5901 52.7184 21.8118C52.5575 19.9242 51.1021 18.4045 49.2227 18.1585C48.676 18.087 48.171 18.041 47.7476 18.0114C47.0524 17.9629 46.4499 18.4867 46.3991 19.1814L45.5261 31.1183C45.4738 31.833 45.9805 32.4686 46.6893 32.5762Z"
        fill="#FCFCFC"
      />
      <g opacity="0.35">
        <path
          d="M45.5261 31.1186L45.7842 27.5901C45.7967 28.2516 46.2841 28.8195 46.9505 28.9207C47.6528 29.0274 48.345 29.067 48.9908 29.0656C50.8446 29.0619 52.4082 27.7838 52.8606 26.0435C52.8466 26.9649 52.8035 27.9538 52.7183 29.001C52.5487 31.0871 50.8237 32.7171 48.7296 32.7214C48.0837 32.7227 47.3916 32.6831 46.6893 32.5765C45.9805 32.4688 45.4738 31.8332 45.5261 31.1186Z"
          fill="#1F1E1C"
        />
      </g>
      <path
        d="M48.6988 33.2435H48.7307C51.0676 33.2387 53.048 31.3937 53.2391 29.0431C53.4399 26.5729 53.4399 24.1251 53.2391 21.7675C53.0588 19.6521 51.3982 17.9166 49.2905 17.6407C48.8002 17.5766 48.2933 17.5261 47.7839 17.4905C46.8041 17.424 45.9497 18.1636 45.878 19.1433L45.0049 31.0803C44.9332 32.0611 45.6386 32.945 46.6109 33.0926C47.2698 33.1927 47.9723 33.2435 48.6988 33.2435ZM47.6593 18.5306C47.6765 18.5306 47.6937 18.5312 47.7111 18.5324C48.1997 18.5665 48.6854 18.6149 49.1548 18.6764C50.7791 18.889 52.0589 20.2263 52.1977 21.8562C52.3938 24.1563 52.3936 26.5459 52.1975 28.9585C52.05 30.7719 50.5263 32.1953 48.7285 32.1989C48.7187 32.199 48.7086 32.199 48.6987 32.199C48.0245 32.199 47.3751 32.1523 46.7678 32.0599C46.3315 31.9937 46.015 31.5969 46.0473 31.1565L46.9203 19.2195C46.9489 18.8297 47.2751 18.5306 47.6593 18.5306Z"
        fill="#1F1E1C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.2216 12.8219C27.1123 12.8312 26.6056 12.8695 26.0955 12.907C25.0002 12.9874 23.1417 13.1634 22.3636 13.2604C18.6772 13.7199 15.1444 14.8623 12.7005 16.3854C11.6872 17.017 11.3364 17.2738 10.9473 17.6692C10.3265 18.2999 10.0861 18.8513 9.78326 20.3392C8.92158 24.5727 8.90563 27.9374 9.73194 31.1731C9.82693 31.545 9.99727 32.0838 10.1105 32.3705C10.2237 32.6571 10.3735 33.0581 10.4433 33.2615C10.6139 33.7586 10.9307 34.3416 11.4769 35.1639C12.3899 36.5384 13.292 37.5518 14.5245 38.5875C17.0875 40.7412 20.6911 42.2186 25.2122 42.9692C28.0587 43.4417 30.1846 43.443 32.9866 42.9737C35.5118 42.5508 37.7687 41.8381 39.9629 40.7707C41.7039 39.9237 42.9425 39.1088 44.1549 38.0129C44.7896 37.4391 45.3238 36.8067 46.4756 35.2655C46.7191 34.9396 47.2994 33.8625 47.4302 33.4936C47.496 33.3082 47.6389 32.9482 47.7478 32.6937C48.4495 31.0536 48.7564 29.3494 48.8047 26.8248C48.849 24.5097 48.6225 22.2287 48.128 20.0083C47.7591 18.3517 47.5669 18.04 46.3514 17.128C44.6493 15.8507 42.5381 14.8467 40.1206 14.1647C37.2053 13.3423 33.1579 12.8433 29.1869 12.8167C28.2153 12.8102 27.3309 12.8125 27.2216 12.8219Z"
        fill="#1F1E1C"
      />
      <path
        d="M32.3152 14.5213C31.2163 14.4422 30.1406 14.3771 29.9249 14.3768C28.3345 14.3745 29.0056 14.3758 27.9984 14.3765C27.128 14.3774 23.6563 14.6745 22.1642 14.876C18.8666 15.3214 15.5888 16.3874 13.5249 17.6858C11.5817 18.9083 11.5855 18.9032 11.1952 20.8139C10.7169 23.1552 10.4977 25.6435 10.6127 27.4246C10.7203 29.0906 11.1177 31.0919 11.5216 32.0002C11.6686 32.3308 11.8305 32.7669 11.8815 32.9693C11.9324 33.1717 12.3369 33.8738 12.7802 34.5295C15.1554 38.0422 18.8034 40.2093 24.181 41.3023C27.2323 41.9225 29.2335 42.0214 31.8441 41.681C37.2125 40.981 42.2164 38.5627 44.3908 35.6172C44.8091 35.0505 45.1888 34.5551 45.2347 34.5161C45.3706 34.4007 46.1019 33.1571 46.1019 33.0205C46.1019 32.9514 46.2133 32.5406 46.3687 32.2098C47.4967 29.8095 47.6708 25.2936 46.8039 20.9218C46.645 20.1205 46.4516 19.3472 46.3742 19.2036C46.1451 18.7778 44.5336 17.6498 43.232 17.0039C40.4808 15.6389 37.0729 14.8638 32.3152 14.5213Z"
        fill="white"
      />
      <path
        d="M13.451 27.5921C13.2168 26.2394 13.3085 21.0312 13.3085 21.0312C13.3085 21.0312 13.2493 18.7671 13.6463 17.4354C13.6661 17.4228 13.7393 17.3832 13.7596 17.3703C15.8201 16.0598 19.059 15.1217 22.3511 14.6722C23.8408 14.4688 27.3067 14.1689 28.1756 14.168C28.5019 14.1678 28.6518 14.1675 28.7596 14.1673C28.9841 14.1668 29.0264 14.1667 30.0989 14.1683C30.3143 14.1686 31.3882 14.2343 32.4852 14.3142C37.235 14.6599 40.6373 15.4422 43.3839 16.8198C43.7022 16.9794 44.039 17.2748 44.3695 17.4753C44.7933 19.213 45.0603 21.1278 45.0603 24.203C45.06 26.6587 44.8531 27.5508 44.5777 28.6735C43.2602 34.0465 39.8853 37.2089 33.6211 38.9409C31.4158 39.5506 28.4189 39.6506 26.0214 39.1945C21.9289 38.4159 18.5528 36.7411 16.5935 34.5176C14.9243 32.6234 13.9501 30.4767 13.451 27.5921Z"
        fill="#1F1E1C"
      />
      <rect
        x="39.0698"
        y="30.0269"
        width="7.05578"
        height="9.0583"
        rx="3.52789"
        transform="rotate(-180 39.0698 30.0269)"
        fill="#FCFCFC"
      />
      <rect
        x="25.9891"
        y="30.0269"
        width="7.05578"
        height="9.0583"
        rx="3.52789"
        transform="rotate(-180 25.9891 30.0269)"
        fill="#FCFCFC"
      />
      <path
        d="M14.7628 18.0918C19.0244 15.33 32.7472 12.1483 43.38 18.0918C34.8918 15.6681 27.5949 15.0187 14.7628 18.0918Z"
        fill="#767676"
        fillOpacity="0.76"
      />
      <path
        d="M43.2587 31.9082C35.2556 44.5121 17.4305 38.9376 14.7628 31.9081C20.9346 35.6107 25.3854 37.1285 29.7989 36.64C33.9293 36.6512 37.8321 35.1021 43.2587 31.9082Z"
        fill="#262626"
      />
      <path
        d="M47.3518 26.8464C47.3382 27.8067 47.2582 29.2303 46.9301 30.9495C46.8929 31.1442 46.8237 31.507 46.7438 31.8329C46.0903 34.4974 44.2855 36.4823 43.6153 37.163C43.2011 37.5837 41.8922 38.8549 39.1922 40.1275C37.2895 41.0243 33.8822 42.3054 29.1496 42.3949C24.7921 42.395 21.5751 41.2731 19.7543 40.471C18.2326 39.8007 16.4977 39.0166 14.7624 37.2808C14.1802 36.6984 12.1911 34.6017 11.2514 31.2832C10.474 28.5376 10.6256 26.042 10.6347 26.0415C10.6405 26.0412 10.7216 27.0506 10.9229 28.4023C10.9686 28.7093 11.3456 31.1459 12.3302 33.1287C13.7602 36.0081 16.2733 37.5303 17.8223 38.4686C22.2574 41.155 26.7834 41.3984 29.179 41.3348C33.7423 41.2031 37.0648 40.0308 38.9862 39.1753C41.568 38.0258 42.8286 36.9105 43.321 36.4465C44.0628 35.7475 45.6337 34.2346 46.479 31.8329C47.2725 29.5781 47.3241 26.8306 47.3503 26.834C47.3516 26.8342 47.3518 26.8409 47.3518 26.8464Z"
        fill="#B1B0B0"
      />
      <path
        d="M89.2016 25.5209C89.2016 26.7617 89.0388 27.9587 88.7133 29.1117C88.4003 30.2522 87.9495 31.3238 87.361 32.3265C86.7725 33.3166 86.0713 34.2253 85.2575 35.0525C84.4436 35.8672 83.5358 36.569 82.5341 37.1581C81.5324 37.7472 80.4556 38.2046 79.3037 38.5305C78.1643 38.8438 76.9748 39.0005 75.7352 39.0005H67.6215V12.0413H75.7352C76.9748 12.0413 78.1643 12.2043 79.3037 12.5301C80.4556 12.8435 81.5324 13.2947 82.5341 13.8837C83.5358 14.4728 84.4436 15.1809 85.2575 16.0081C86.0713 16.8228 86.7725 17.7315 87.361 18.7341C87.9495 19.7243 88.4003 20.7959 88.7133 21.9489C89.0388 23.0895 89.2016 24.2801 89.2016 25.5209ZM83.8113 25.5209C83.8113 24.4055 83.5984 23.3589 83.1727 22.3813C82.7595 21.4037 82.1835 20.5515 81.4448 19.8245C80.7186 19.0851 79.8609 18.5023 78.8717 18.0761C77.8951 17.65 76.8495 17.4369 75.7352 17.4369H73.0494V33.6049H75.7352C76.8495 33.6049 77.8951 33.3981 78.8717 32.9845C79.8609 32.5584 80.7186 31.9818 81.4448 31.2549C82.1835 30.5154 82.7595 29.6569 83.1727 28.6793C83.5984 27.6892 83.8113 26.6364 83.8113 25.5209Z"
        fill="#FCFCFC"
      />
      <path
        d="M100.245 34.0749C100.446 34.1376 100.646 34.1814 100.846 34.2065C101.047 34.219 101.247 34.2253 101.447 34.2253C101.948 34.2253 102.43 34.1564 102.893 34.0185C103.357 33.8806 103.789 33.6864 104.189 33.4357C104.603 33.1725 104.966 32.8592 105.279 32.4957C105.604 32.1197 105.867 31.7061 106.068 31.2549L109.824 35.0337C109.348 35.7105 108.797 36.3184 108.171 36.8573C107.558 37.3962 106.888 37.8537 106.161 38.2297C105.448 38.6057 104.69 38.8877 103.889 39.0757C103.1 39.2762 102.286 39.3765 101.447 39.3765C100.032 39.3765 98.6989 39.1133 97.4468 38.5869C96.2072 38.0605 95.1178 37.3273 94.1788 36.3873C93.2522 35.4473 92.5197 34.3318 91.9813 33.0409C91.4429 31.7374 91.1737 30.3086 91.1737 28.7545C91.1737 27.1628 91.4429 25.7089 91.9813 24.3929C92.5197 23.0769 93.2522 21.9552 94.1788 21.0277C95.1178 20.1003 96.2072 19.3796 97.4468 18.8657C98.6989 18.3519 100.032 18.0949 101.447 18.0949C102.286 18.0949 103.106 18.1952 103.908 18.3957C104.709 18.5963 105.467 18.8845 106.18 19.2605C106.906 19.6365 107.583 20.1003 108.209 20.6517C108.835 21.1907 109.386 21.7985 109.861 22.4753L100.245 34.0749ZM102.875 23.4905C102.637 23.4028 102.399 23.3464 102.161 23.3213C101.936 23.2963 101.698 23.2837 101.447 23.2837C100.746 23.2837 100.082 23.4153 99.4564 23.6785C98.8429 23.9292 98.3045 24.2927 97.8412 24.7689C97.3904 25.2452 97.0336 25.8217 96.7706 26.4985C96.5077 27.1628 96.3762 27.9148 96.3762 28.7545C96.3762 28.9425 96.3825 29.1556 96.395 29.3937C96.42 29.6318 96.4513 29.8762 96.4889 30.1269C96.539 30.365 96.5953 30.5969 96.6579 30.8225C96.7205 31.0481 96.8019 31.2486 96.9021 31.4241L102.875 23.4905Z"
        fill="#FCFCFC"
      />
      <path
        d="M130.784 39.0005H124.511L120.511 32.8341L116.473 39.0005H110.2L117.543 28.6793L110.2 18.7529H116.473L120.511 24.5245L124.511 18.7529H130.784L123.422 28.6793L130.784 39.0005Z"
        fill="#FCFCFC"
      />
      <path
        d="M142.711 39.0005C141.471 39.0005 140.307 38.7686 139.217 38.3049C138.128 37.8286 137.17 37.1832 136.344 36.3685C135.53 35.5413 134.885 34.5825 134.409 33.4921C133.946 32.4017 133.714 31.2361 133.714 29.9953V24.0169H131.216V18.9033H133.714V10.8569H138.823V18.9033H146.598V24.0169H138.823V29.9953C138.823 30.5342 138.923 31.0418 139.123 31.5181C139.324 31.9818 139.599 32.3892 139.95 32.7401C140.3 33.091 140.713 33.373 141.189 33.5861C141.665 33.7866 142.172 33.8869 142.711 33.8869H146.598V39.0005H142.711Z"
        fill="#FCFCFC"
      />
      <path
        d="M157.492 34.0749C157.692 34.1376 157.892 34.1814 158.093 34.2065C158.293 34.219 158.493 34.2253 158.694 34.2253C159.195 34.2253 159.677 34.1564 160.14 34.0185C160.603 33.8806 161.035 33.6864 161.436 33.4357C161.849 33.1725 162.212 32.8592 162.525 32.4957C162.851 32.1197 163.114 31.7061 163.314 31.2549L167.07 35.0337C166.595 35.7105 166.044 36.3184 165.418 36.8573C164.804 37.3962 164.134 37.8537 163.408 38.2297C162.694 38.6057 161.937 38.8877 161.135 39.0757C160.347 39.2762 159.533 39.3765 158.694 39.3765C157.279 39.3765 155.945 39.1133 154.693 38.5869C153.454 38.0605 152.364 37.3273 151.425 36.3873C150.499 35.4473 149.766 34.3318 149.228 33.0409C148.689 31.7374 148.42 30.3086 148.42 28.7545C148.42 27.1628 148.689 25.7089 149.228 24.3929C149.766 23.0769 150.499 21.9552 151.425 21.0277C152.364 20.1003 153.454 19.3796 154.693 18.8657C155.945 18.3519 157.279 18.0949 158.694 18.0949C159.533 18.0949 160.353 18.1952 161.154 18.3957C161.956 18.5963 162.713 18.8845 163.427 19.2605C164.153 19.6365 164.829 20.1003 165.455 20.6517C166.081 21.1907 166.632 21.7985 167.108 22.4753L157.492 34.0749ZM160.121 23.4905C159.883 23.4028 159.645 23.3464 159.407 23.3213C159.182 23.2963 158.944 23.2837 158.694 23.2837C157.993 23.2837 157.329 23.4153 156.703 23.6785C156.089 23.9292 155.551 24.2927 155.088 24.7689C154.637 25.2452 154.28 25.8217 154.017 26.4985C153.754 27.1628 153.623 27.9148 153.623 28.7545C153.623 28.9425 153.629 29.1556 153.641 29.3937C153.667 29.6318 153.698 29.8762 153.735 30.1269C153.785 30.365 153.842 30.5969 153.904 30.8225C153.967 31.0481 154.048 31.2486 154.149 31.4241L160.121 23.4905Z"
        fill="#FCFCFC"
      />
      <path
        d="M174.827 39.0005H169.7V18.8657H170.939L172.63 21.2533C173.456 20.5013 174.395 19.9248 175.447 19.5237C176.499 19.1101 177.588 18.9033 178.715 18.9033H183.241V24.0169H178.715C178.177 24.0169 177.67 24.1172 177.194 24.3177C176.718 24.5183 176.305 24.794 175.954 25.1449C175.604 25.4959 175.328 25.9095 175.128 26.3857C174.927 26.862 174.827 27.3696 174.827 27.9085V39.0005Z"
        fill="#FCFCFC"
      />
    </svg>
  );
}
