const variants = {
  primary: {
    overlay: {
      background: "primary.prussianBlue20",
      backdropFilter: "blur(15px)",
    },
    closeButton: {
      boxShadow: "none",
      borderRadius: "100%",
      right: "24px",
      top: "50px",
      transform: "translateY(-50%)",
      _focus: {
        boxShadow: "none",
      },
      _active: {
        backgroundcolor: "transparent",
      },
      w: "24px",
      h: "24px",
    },
    header: {
      pt: "8",
      px: "6",
      pb: "0",
      position: "relative",
      color: "primary.white",
      textAlign: "center",
      textStyle: "dexterH0",
      fontSize: null,
      fontWeight: null,
    },
    dialog: {
      borderRadius: "xl",
      bgColor: "primary.oxfordBlue",
      boxShadow: "0px 0px 4px rgba(112, 112, 112, 0.25);",
      borderColor: "#425E91",
      borderWidth: "1px",
      width: "95%",
    },
    body: {
      px: ["2", "2", "2", "4", "4", "4"],
      py: "6",
      color: "primary.white",
    },
  },
  secondary: {
    overlay: {
      background: "primary.prussianBlue20",
      backdropFilter: "blur(25px)",
    },
    closeButton: {
      boxShadow: "none",
      borderRadius: "100%",
      right: "6",
      top: "6",
      _focus: {
        boxShadow: "none",
      },
      _active: {
        backgroundcolor: "transparent",
      },
      w: "8",
      h: "8",
    },
    header: {
      pt: "6",
      px: "8",
      position: "relative",
      color: "primary.white",
      textAlign: "left",
      textStyle: "dexterH0",
      fontSize: null,
      fontWeight: null,
    },
    dialog: {
      borderRadius: "lg",
      bgColor: "primary.oxfordBlue",
      boxShadow: "0px 0px 4px rgba(112, 112, 112, 0.25);",
      borderWidth: "1px",
      borderColor: "stroke.dexBlue",
    },
    body: {
      px: "4",
      py: "2",
      color: "primary.white",
      pb: "3",
      overflow: "hidden",
    },
  },
  tokenSelect: {
    overlay: {
      background: "primary.prussianBlue20",
      backdropFilter: "blur(25px)",
    },
    closeButton: {
      boxShadow: "none",
      borderRadius: "100%",
      right: "6",
      top: "6",
      _focus: {
        boxShadow: "none",
      },
      _active: {
        backgroundcolor: "transparent",
      },
      w: "8",
      h: "8",
    },
    header: {
      pt: "6",
      px: "8",
      borderRadius: "lg",
      borderBottomLeftRadius: "none",
      borderBottomRightRadius: "none",
      // boxShadow: "0px 0px 4px rgba(112, 112, 112, 0.25);",
      borderWidth: "1px",
      borderColor: "stroke.dexBlue",
      borderBottom: "none",
      position: "relative",
      color: "primary.white",
      bgColor: "primary.oxfordBlue",
      textAlign: "left",
      textStyle: "dexterH0",
      fontSize: null,
      fontWeight: null,
    },
    dialog: {
      bgColor: "transparent",
      // borderRadius: "lg",
      // boxShadow: "0px 0px 4px rgba(112, 112, 112, 0.25);",
      // borderWidth: "1px",
      // borderColor: "stroke.dexBlue",
    },
    body: {
      px: "4",
      py: "2",
      borderRadius: "lg",
      borderTopLeftRadius: "none",
      borderTopRightRadius: "none",
      borderTop: "none",
      bgColor: "primary.oxfordBlue",
      borderWidth: "1px",
      borderColor: "stroke.dexBlue",
      color: "primary.white",
      pb: "3",
      overflow: "hidden",
      mb: "4",
    },
    footer: {
      py: "18px",
      bgColor: "primary.oxfordBlue",
      borderRadius: "lg",
      borderWidth: "1px",
      borderColor: "stroke.dexBlue",
      justifyContent: "flex-start",
    },
  },
  dexterLandingModal: {
    overlay: {
      backdropFilter: "blur(15px)",
    },
    closeButton: {
      boxShadow: "none",
      borderRadius: "100%",
      right: "18px",
      top: "44px",
      transform: "translateY(-50%)",
      _focus: {
        boxShadow: "none",
      },
      _active: {
        backgroundcolor: "transparent",
      },
      w: "24px",
      h: "24px",
    },
    header: {
      pt: "24px",
      px: "24px",
      pb: "0px",
      position: "relative",
      color: "#FCFCFC",
      textAlign: "center",
      textStyle: "dexterH0",
      fontSize: null,
      fontWeight: null,
    },
    dialog: {
      borderRadius: "15px",
      bg: "#0A1D32",
      boxShadow: "0px 0px 4px rgba(112, 112, 112, 0.25);",
      borderWidth: "1px",
      borderColor: "stroke.dexBlue",
    },
  },
};

export default {
  variants,
  defaultProps: {
    variant: "primary",
  },
};
