import { Box, Text, Flex, HStack } from "@chakra-ui/react";
import { TokenIcon, useTokenInfo, useDexter } from "modules/common";
import { formatAmount, num, NumberFormatSpecifier } from "@wizard-ui/core";

interface Props {
  token: {
    from: string;
    disabled?: boolean;
    decimals: number;
    symbol: string;
    token: string;
    icon: string;
    chain: string;
    coingecko: string;
  };
  onClick: () => void;
}

export function SelectTokenModalItem({ token, onClick }: Props) {
  const { balances, prices, tokens } = useDexter();
  const { getSymbol } = useTokenInfo();
  const balance = balances?.[token.from];

  return (
    <Flex
      as="button"
      py="2"
      px="4"
      align="center"
      justify="space-between"
      cursor={token.disabled ? "auto" : "pointer"}
      w="full"
      disabled={token.disabled}
      onClick={onClick}
      type="button"
      opacity={token.disabled ? 0.5 : 1}
      _hover={token.disabled ? { bg: "none" } : { bg: "whiteAlpha.200" }}
      _last={{ mb: "0" }}
    >
      <HStack spacing="4">
        <TokenIcon token={token.from} size="9" />
        <Box textAlign="left">
          <Text fontWeight="700">{getSymbol(token.from)}</Text>
          <Text fontSize="sm" color="#D2D2D2">
            Persistence
          </Text>
        </Box>
      </HStack>
      <Box textAlign="right">
        <Text fontWeight="700">
          {formatAmount(num(balance).div(10 ** tokens[token.from].decimals), {
            formatSpecifier: NumberFormatSpecifier.FLOAT,
            // shouldDivide: true,
          })}
        </Text>
        <Text fontSize="sm" color="#D2D2D2">
          {formatAmount(prices?.[token.from].price, {
            formatSpecifier: NumberFormatSpecifier.CURRENCY,
          })}
        </Text>
      </Box>
    </Flex>
  );
}
