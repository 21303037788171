import { Text, HStack } from "@chakra-ui/react";

import { useTokenInfo, TokenIcon } from "modules/common";

interface Props {
  token: string;
  size?: string;
  weight?: string | number;
}

export function AssetItem({ token, weight, size = "md" }: Props) {
  const { getSymbol } = useTokenInfo();

  return (
    <HStack
      bg="primary.dexBlue20"
      borderRadius="md"
      spacing={[1, 1, 1.5, 1.5, 1.5, 1.5]}
      px="2"
      py="1.5"
    >
      <TokenIcon token={token} size={["6", "6", "6", "8", "7", "7"]} />
      <Text fontWeight="700" fontSize={size}>
        {getSymbol(token)}
      </Text>
      {weight && (
        <Text
          fontSize={["xs", "xs", "xs", "xs", "xs", "xs"]}
          color="whiteAlpha.900"
        >
          {weight}%
        </Text>
      )}
    </HStack>
  );
}
