import { Icon, IconProps } from "@chakra-ui/react";

export function MediumIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 40 24" {...props}>
      <path
        d="M22.4055 11.9169C22.4055 18.4982 17.3898 23.8333 11.2029 23.8333C5.01609 23.8333 0 18.4982 0 11.9169C0 5.33557 5.01571 0 11.2029 0C17.3902 0 22.4055 5.33557 22.4055 11.9169Z"
        fill="url(#paint0_linear_1059_14170)"
      />
      <path
        d="M34.6959 11.9171C34.6959 18.1119 32.188 23.1359 29.0944 23.1359C26.0008 23.1359 23.4929 18.1119 23.4929 11.9171C23.4929 5.72218 26.0004 0.698242 29.094 0.698242C32.1876 0.698242 34.6955 5.72057 34.6955 11.9171"
        fill="url(#paint1_linear_1059_14170)"
      />
      <path
        d="M39.7217 11.917C39.7217 17.4661 38.8398 21.9673 37.7517 21.9673C36.6635 21.9673 35.782 17.4673 35.782 11.917C35.782 6.36669 36.6639 1.8667 37.7517 1.8667C38.8394 1.8667 39.7217 6.36629 39.7217 11.917Z"
        fill="url(#paint2_linear_1059_14170)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1059_14170"
          x1="16.8324"
          y1="105.043"
          x2="-41.6483"
          y2="90.9031"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00AFFA" />
          <stop offset="0.47403" stopColor="#32A2BA" />
          <stop offset="1" stopColor="#24FFCA" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1059_14170"
          x1="31.9093"
          y1="99.59"
          x2="1.46105"
          y2="95.6799"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00AFFA" />
          <stop offset="0.47403" stopColor="#32A2BA" />
          <stop offset="1" stopColor="#24FFCA" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1059_14170"
          x1="38.7417"
          y1="90.4581"
          x2="27.8851"
          y2="89.9108"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00AFFA" />
          <stop offset="0.47403" stopColor="#32A2BA" />
          <stop offset="1" stopColor="#24FFCA" />
        </linearGradient>
      </defs>
    </Icon>
  );
}
