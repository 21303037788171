import { useState, ReactNode, useMemo } from "react";
import {
  Box,
  Flex,
  SimpleGrid,
  Text,
  Heading,
  HStack,
  Button,
  Grid,
  GridItem,
  Image,
  useMediaQuery,
} from "@chakra-ui/react";
import { NumberFormatSpecifier, formatAmount, num } from "@wizard-ui/core";

import LazyHydrate from "react-lazy-hydration";

import {
  InfoIcon,
  AssetItem,
  ChevronDownIcon,
  ChevronUpIcon,
  useTokenInfo,
  useDexter,
  PoolAsset,
} from "modules/common";
import {
  UnbondModal,
  BondModal,
  AddLiquidityModal,
  RemoveLiquidityModal,
  PoolBase,
  POOL_TYPES,
} from "modules/pools";
import { useUnlockMultistakingMutation } from "modules/contracts";
import { POOL_TYPES_ICONS, tokenWeights } from "../helpers";
import { PieChart } from "react-minimal-pie-chart";
import { LpAsset } from "./LpAsset";
import { TradePairModal } from "./TradePairModal";
import { TooltipWithTouch } from "modules/common/components/TooltipWithTouch";
import { useCValue } from "../hooks/useCValue";
import { useDydxCValue } from "../hooks/useDydxCValue";
import { useStarsCValue } from "../hooks/useStarsCValue";
import { useHuahuaCValue } from "../hooks/useHuahuaCValue";
import { NewAddLiquidityModal } from "./NewAddLiquidityModal";
import { SuperfluidAddLiquidityModal } from "./SuperfluidAddLiquidityModal";

function PoolStats({
  label,
  value,
  subValue,
  button,
  tooltip,
}: {
  label: string;
  tooltip?: string | ReactNode;
  value: string;
  subValue?: string;
  button?: ReactNode;
}) {
  return (
    <Box>
      <HStack>
        <Text>{label}</Text>
        {tooltip && (
          <TooltipWithTouch
            hasArrow
            label={tooltip}
            bg="#00506B"
            color="white"
            borderRadius="lg"
            p="3"
            maxW="72"
          >
            <span>
              <InfoIcon _hover={{ cursor: "pointer" }} />
            </span>
          </TooltipWithTouch>
        )}
      </HStack>
      {/* <VStack alignItems={"flex-start"}> */}
      <Text fontSize="3xl" fontWeight="700">
        {value}
      </Text>
      {subValue && (
        <Text fontSize="sm" fontWeight="500">
          {subValue}
        </Text>
      )}
      {/* </VStack> */}
      {button}
    </Box>
  );
}

interface Props {
  pool: PoolBase;
}

const renderBar = (index: number, length: number) => {
  if (index === 0) {
    return (
      <Box
        borderTopLeftRadius={"6"}
        borderBottomLeftRadius={"6"}
        bgColor={"#B95663"}
        h="10px"
      ></Box>
    );
  } else if (index === length - 1) {
    return (
      <Box
        borderTopRightRadius={"6"}
        borderBottomRightRadius={"6"}
        bgColor={"#c7c7c7"}
        h="10px"
      ></Box>
    );
  } else {
    return <Box bgColor={"#190370"} h="10px"></Box>;
  }
};

export function PoolDetails({ pool }: Props) {
  const [isMobile] = useMediaQuery("(max-width: 800px)");
  const { mutate, isLoading } = useUnlockMultistakingMutation();
  const { getSymbol, getIcon } = useTokenInfo();
  const { prices, balances, bondedLpTokens, tokens } = useDexter();
  const [showUnbond, setShowUnbond] = useState(false);
  const [showBond, setShowBond] = useState(false);
  const [showAddLiquidity, setShowAddLiquidity] = useState(false);
  const [showTradePair, setShowTradePair] = useState(false);
  const [showRemoveLiquidity, setShowRemoveLiquidity] = useState(false);
  const [showPoolComp, setShowPoolComp] = useState<boolean>(false);
  const handleUnlockClick = () => {
    mutate({ msg: { lpToken: pool.lpToken } });
  };
  const stkAtomCValue = useCValue();
  const stkDydxCValue = useDydxCValue();
  const stkStarsCValue = useStarsCValue();
  const stkHuahuaCValue = useHuahuaCValue();
  const ibcAtom =
    process.env.NEXT_PUBLIC_ENV == "mainnet"
      ? "ibc/C8A74ABBE2AF892E15680D916A7C22130585CE5704F9B17A10F184A90D53BECA"
      : "ibc/C4CFF46FD6DE35CA4CF4CE031E643C8FDC9BA4B99AE598E9B0ED98FE3A2319F9";
  const ibcPstake =
    process.env.NEXT_PUBLIC_ENV == "mainnet"
      ? "ibc/A6E3AF63B3C906416A9AF7A556C59EA4BD50E617EFFE6299B99700CCB780E444"
      : "";
  const ibcUSDT =
    process.env.NEXT_PUBLIC_ENV == "mainnet"
      ? "ibc/C559977F5797BDC1D74C0836A10C379C991D664166CB60D776A83029852431B4"
      : "";

  const ibcUSDC =
    process.env.NEXT_PUBLIC_ENV == "mainnet"
      ? "ibc/B3792E4A62DF4A934EF2DF5968556DB56F5776ED25BDE11188A4F58A7DD406F0"
      : "";

  const ibcDYDX =
    process.env.NEXT_PUBLIC_ENV == "mainnet"
      ? "ibc/23DC3FF0E4CBB53A1915E4C62507CB7796956E84C68CA49707787CB8BDE90A1E"
      : "ibc/18200EAA7E5BB3D235FF517F04045F4DCB0691CE6FC1B32E4297BEA8EF7710E3";

  const ibcSHD =
    "ibc/5D3B6445EA1D7064C4B1CCB588638589529556E1BCBADF13475021B42EA8C73B";

  const ibcStars =
    "ibc/AD8E1D4AC4EA8FC79CC46E33319A3791477D4DEBFC30D5D874074B993422B41B";

  const ibcWBTC =
    "ibc/CCA9F9B22D39884C09975D45E1869B73A12B87080EE53CB44905CE2C422CA228";
  const mainnetWeightsAtom = {
    uxprt: "50",
    [ibcAtom]: "50",
  };
  const mainnetWeightsPStake = {
    uxprt: "50",
    [ibcPstake]: "50",
  };
  const mainnetWeightsXprtUsdt = {
    uxprt: "50",
    [ibcUSDT]: "50",
  };
  const mainnetWeightsDydxUsdc = {
    [ibcUSDC]: "50",
    [ibcDYDX]: "50",
  };

  const mainnetWeightsShdXprt = {
    [ibcSHD]: "50",
    uxprt: "50",
  };
  const mainnetWeightsWBTCXprt = {
    [ibcWBTC]: "50",
    uxprt: "50",
  };
  const mainnetWeightsStarsXprt = {
    [ibcStars]: "50",
    uxprt: "50",
  };
  const testnetWeightsAtom = {
    uxprt: "90",
    [ibcAtom]: "10",
  };
  const testnetWeightsPStake = {
    uxprt: "90",
    [ibcPstake]: "10",
  };
  const weightsAtom =
    process.env.NEXT_PUBLIC_ENV == "mainnet"
      ? mainnetWeightsAtom
      : testnetWeightsAtom;
  const weightsPStake =
    process.env.NEXT_PUBLIC_ENV == "mainnet"
      ? mainnetWeightsPStake
      : testnetWeightsPStake;

  const weightsXprtUsdt = mainnetWeightsXprtUsdt;

  const weightsDydxUsdc = mainnetWeightsDydxUsdc;

  const weightsShdXprt = mainnetWeightsShdXprt;

  const weightsStarsXprt = mainnetWeightsStarsXprt;

  const weightsWbtcXprt = mainnetWeightsWBTCXprt;

  const weights =
    pool.id === 3
      ? weightsPStake
      : pool.id === 5
      ? weightsXprtUsdt
      : pool.id === 6
      ? weightsDydxUsdc
      : pool.id === 8
      ? weightsShdXprt
      : pool.id === 10
      ? weightsStarsXprt
      : pool.id === 13
      ? weightsWbtcXprt
      : weightsAtom;

  const poolTokens = pool.assets.map(({ identifier }) => identifier);

  const lpAmount = num(balances?.[pool.lpToken])
    .plus(num(bondedLpTokens?.[pool.lpToken]))
    .div(10 ** 18)
    .toFixed(6);

  const ratio = useMemo(() => {
    if (pool == null) {
      return {};
    }

    return pool.assets.reduce((prev: any, a: PoolAsset) => {
      return {
        ...prev,
        [a.identifier]: Number(a.amount) / Number(pool.lpTokenSupply),
      };
    }, {});
  }, [pool]);

  const weights2 = useMemo(() => {
    if (pool == null) {
      return [];
    }

    return tokenWeights(
      pool,
      pool.id == 1
        ? stkAtomCValue
        : pool.id == 7
        ? stkDydxCValue
        : pool.id == 9
        ? stkStarsCValue
        : stkHuahuaCValue,
      2,
    );
  }, [pool]);

  return (
    <>
      <Flex justify={"space-between"}>
        <HStack mb="4">
          <Image
            src={
              pool.id == 1 ||
              pool.id == 7 ||
              pool.id == 9 ||
              pool.id == 11 ||
              pool.id == 12
                ? POOL_TYPES_ICONS["metastable"]
                : POOL_TYPES_ICONS[pool.poolType]
            }
            boxSize={"8"}
            alt="pool icon"
          />
          <Heading fontSize="2xl" mb="0">
            {pool.id == 1 ||
            pool.id == 7 ||
            pool.id == 9 ||
            pool.id == 11 ||
            pool.id == 12
              ? POOL_TYPES["metastable"]
              : POOL_TYPES[pool.poolType]}{" "}
            Pool
          </Heading>
        </HStack>
        {!isMobile ? (
          <HStack>
            <Button
              size="lg"
              minW="56"
              onClick={() => setShowAddLiquidity(true)}
            >
              Add Liquidity
            </Button>
            <Button
              size="lg"
              minW="56"
              variant="secondary"
              isDisabled={pool.availableToBondAmountInUsd <= 0}
              onClick={() => setShowRemoveLiquidity(true)}
            >
              Remove Liquidity
            </Button>
          </HStack>
        ) : null}
      </Flex>
      <Flex
        bg={"primary.dexBlue20"}
        p="4"
        borderRadius={"12"}
        mt={4}
        flexDir={"column"}
        justify="center"
        align={["stretch", "stretch", "stretch", "stretch", "stretch"]}
      >
        <Flex
          justify="space-between"
          px={2}
          mt="2"
          flexDir={["column", "column", "row", "row", "row"]}
        >
          <Flex
            gap="4"
            mb={[4, 4, 0, 0, 0]}
            // flexWrap="wrap"
            align={"center"}
            justify={"center"}
          >
            {pool.tokensList.map((token) => {
              return (
                <AssetItem
                  key={token}
                  token={token}
                  weight={
                    pool.poolType === "weighted"
                      ? weights[token]
                      : weights2.find((w) => w.token === token)?.weight
                  }
                  size={["xs", "md", "lg", "2xl", "2xl", "2xl"]}
                />
              );
            })}
            {!isMobile ? (
              <Button
                size="lg"
                px={16}
                // minW="56"
                bg="primary.dexBlue20"
                borderWidth={"2.5px"}
                borderColor={"#01BDBD"}
                onClick={() => setShowTradePair(true)}
              >
                Trade Pair
              </Button>
            ) : null}
          </Flex>
          {isMobile ? (
            <Button
              size="lg"
              px={16}
              mb={4}
              // minW="56"
              bg="primary.dexBlue20"
              borderWidth={"2.5px"}
              borderColor={"#01BDBD"}
              onClick={() => setShowTradePair(true)}
            >
              Trade Pair
            </Button>
          ) : null}
          <PoolStats
            label="Pool Liquidity"
            tooltip="Total $value of the liquidity supplied to the pool"
            value={formatAmount(pool.getTotalLiquidityInUsd(), {
              formatSpecifier: NumberFormatSpecifier.CURRENCY,
            })}
          />
        </Flex>
        {showPoolComp ? (
          <Grid
            px={"8px"}
            mt="6"
            width={"full"}
            templateColumns={`repeat(100, 1fr)`}
          >
            {pool.assets.map((asset, index) => (
              <GridItem
                gridArea={
                  pool.poolType === "weighted"
                    ? index === 0
                      ? "1/1/2/51"
                      : "1/51/2/101"
                    : index === 0
                    ? `1/1/2/${Math.floor(weights2[0].weight)}`
                    : `1/${Math.floor(weights2[0].weight)}/2/101`
                }
                key={asset.identifier}
              >
                <Text variant={"dexterP6"}>
                  {getSymbol(asset.identifier)}
                  {/* :{" "}
                  {pool.poolType === "weighted"
                    ? weights[asset.identifier]
                    : "50"}
                  % */}
                </Text>
                <Text
                  my="2"
                  variant={"dexterP1"}
                  fontSize={[12, 14, 16, 24, 24]}
                >
                  {num(asset.amount)
                    .div(10 ** tokens[asset.identifier].decimals)
                    .toFixed(pool.id == 13 ? 4 : 2)}
                </Text>
                <Text
                  my="2"
                  mr={2}
                  variant={"dexterP3"}
                  fontSize={[10, 12, 12, 16, 16]}
                >
                  {formatAmount(
                    num(asset.amount)
                      .times(prices?.[asset.identifier].price)
                      .div(10 ** tokens[asset.identifier].decimals)
                      .toNumber(),
                    {
                      formatSpecifier: NumberFormatSpecifier.CURRENCY,
                    },
                  )}
                </Text>
                {renderBar(index, pool.assets.length)}
              </GridItem>
            ))}
          </Grid>
        ) : null}
        <Flex
          justify={"center"}
          _hover={{ cursor: "pointer" }}
          mt="4"
          onClick={() => setShowPoolComp((showPoolComp) => !showPoolComp)}
        >
          {showPoolComp ? (
            <Text fontSize={"16px"}>
              Hide Pool Composition <ChevronUpIcon />
            </Text>
          ) : (
            <Text fontSize={"16px"}>
              View Pool Composition <ChevronDownIcon />
            </Text>
          )}
        </Flex>
        {isMobile ? (
          <HStack mt={4} px={2}>
            <Button
              size="sm"
              w={["auto", 40, 40, 40, 40]}
              onClick={() => setShowAddLiquidity(true)}
            >
              Add Liquidity
            </Button>
            <Button
              size="sm"
              w={["auto", 40, 40, 40, 40]}
              variant="secondary"
              isDisabled={pool.availableToBondAmountInUsd <= 0}
              onClick={() => setShowRemoveLiquidity(true)}
            >
              Remove Liquidity
            </Button>
          </HStack>
        ) : null}
      </Flex>
      <SimpleGrid columns={[1, 1, 2, 2, 2]} gap="8" my="6">
        <Flex
          justify="space-around"
          align={["flex-start", "flex-start", "center", "center", "center"]}
          bg={"primary.dexBlue20"}
          p={[6, 6, 0, 0, 0]}
          gap={4}
          borderRadius={"12"}
          flexDir={["column", "column", "row", "row", "row"]}
        >
          <PoolStats
            label="My Liquidity"
            tooltip="Total $value of liquidity you have supplied to the pool which comprises of bonded, unbonded and unbonding assets"
            value={formatAmount(pool.getMyLiquidityInUsd(), {
              formatSpecifier: NumberFormatSpecifier.CURRENCY,
            })}
            subValue={`${lpAmount} LP Shares`}
          />
          <Flex flexDir="column" gap="2" mb="0">
            {poolTokens.map((token) => {
              return (
                <LpAsset
                  key={token}
                  amount={
                    !num(lpAmount).isNaN()
                      ? num(lpAmount)
                          .times(
                            token ===
                              "ibc/A6E3AF63B3C906416A9AF7A556C59EA4BD50E617EFFE6299B99700CCB780E444" ||
                              token ===
                                "ibc/23DC3FF0E4CBB53A1915E4C62507CB7796956E84C68CA49707787CB8BDE90A1E" ||
                              token === "stk/adydx"
                              ? 1
                              : token ===
                                  "ibc/5D3B6445EA1D7064C4B1CCB588638589529556E1BCBADF13475021B42EA8C73B" ||
                                token ===
                                  "ibc/CCA9F9B22D39884C09975D45E1869B73A12B87080EE53CB44905CE2C422CA228"
                              ? 10 ** 10
                              : 10 ** 12,
                          )
                          .times(ratio[token])
                          .toFixed(6)
                      : null
                  }
                  token={token}
                />
              );
            })}
          </Flex>
        </Flex>
        <Box bg={"primary.dexBlue20"} p="0" borderRadius={"12"}>
          <Flex
            align={"center"}
            justify="center"
            gap={-10}
            flexDir={["column", "column", "row", "row", "row"]}
          >
            <Box width={200}>
              {pool.getMyLiquidityInUsd() !== 0 ? (
                <PieChart
                  lineWidth={20}
                  radius={isMobile ? 40 : 30}
                  data={[
                    {
                      title: "Bonded",
                      value: pool.bondedAmountInUsd,
                      color: "#B95663",
                    },
                    {
                      title: "Unbonding",
                      value:
                        pool.unbondedAmountInUsd +
                        pool.unbondingAmountInUsd +
                        pool.unlockedAmountInUsd,
                      color: "rgba(185, 86, 99, 0.5)",
                    },
                    {
                      title: "Available",
                      value: pool.availableToBondAmountInUsd,
                      color: "#ffffff",
                    },
                  ]}
                />
              ) : (
                <PieChart
                  lineWidth={20}
                  radius={isMobile ? 40 : 30}
                  data={[
                    {
                      title: "Bonded",
                      value: 10,
                      color: "#909090",
                    },
                    {
                      title: "Unbonding",
                      value: 10,
                      color: "#909090",
                    },
                    {
                      title: "Available",
                      value: 30,
                      color: "#909090",
                    },
                  ]}
                />
              )}
            </Box>
            <SimpleGrid
              width={[300, 320, 360, 400, 400, 400]}
              columns={2}
              gap={[0, 0, 0, 10, 10, 10]}
              my="6"
              px={4}
            >
              <Box justifySelf={"left"}>
                <HStack>
                  <Box
                    bg={
                      pool.getMyLiquidityInUsd() !== 0 ? "#B95663" : "#909090"
                    }
                    width="16px"
                    height={"16px"}
                  />
                  <Text>Bonded</Text>
                </HStack>
                <HStack>
                  <Box
                    bg={
                      pool.getMyLiquidityInUsd() !== 0
                        ? "rgba(185, 86, 99, 0.5);"
                        : "#909090"
                    }
                    width="16px"
                    height={"16px"}
                  />
                  <Text>Unbonding</Text>
                </HStack>
                <HStack>
                  <Box
                    bg={
                      pool.getMyLiquidityInUsd() !== 0 ? "#ffffff" : "#909090"
                    }
                    width="16px"
                    height={"16px"}
                  />
                  <Text>Available</Text>
                </HStack>
              </Box>
              <Box textAlign={["right"]}>
                {" "}
                <Text>
                  {formatAmount(pool.bondedAmountInUsd, {
                    formatSpecifier: NumberFormatSpecifier.CURRENCY,
                  })}{" "}
                  (
                  {pool.getMyLiquidityInUsd() !== 0
                    ? formatAmount(
                        (pool.bondedAmountInUsd * 100) /
                          pool.getMyLiquidityInUsd(),
                        {
                          formatSpecifier: NumberFormatSpecifier.PERCENTAGE,
                        },
                      )
                    : "0%"}
                  )
                </Text>
                <Text>
                  {" "}
                  {formatAmount(
                    pool.unbondedAmountInUsd +
                      pool.unbondingAmountInUsd +
                      pool.unlockedAmountInUsd,
                    {
                      formatSpecifier: NumberFormatSpecifier.CURRENCY,
                    },
                  )}{" "}
                  (
                  {pool.getMyLiquidityInUsd() !== 0
                    ? formatAmount(
                        ((pool.unbondedAmountInUsd +
                          pool.unbondingAmountInUsd +
                          pool.unlockedAmountInUsd) *
                          100) /
                          pool.getMyLiquidityInUsd(),
                        {
                          formatSpecifier: NumberFormatSpecifier.PERCENTAGE,
                        },
                      )
                    : "0%"}
                  )
                </Text>
                <Text>
                  {" "}
                  {formatAmount(pool.availableToBondAmountInUsd, {
                    formatSpecifier: NumberFormatSpecifier.CURRENCY,
                  })}{" "}
                  (
                  {pool.getMyLiquidityInUsd() !== 0
                    ? formatAmount(
                        (pool.availableToBondAmountInUsd * 100) /
                          pool.getMyLiquidityInUsd(),
                        {
                          formatSpecifier: NumberFormatSpecifier.PERCENTAGE,
                        },
                      )
                    : "0%"}
                  )
                </Text>
              </Box>
            </SimpleGrid>
          </Flex>
        </Box>
      </SimpleGrid>
      {showBond ? (
        <LazyHydrate whenVisible>
          <BondModal
            pool={pool}
            isOpen={showBond}
            onClose={() => setShowBond(false)}
          />
        </LazyHydrate>
      ) : null}
      {/* <LazyHydrate whenVisible> */}
      {showUnbond ? (
        <UnbondModal
          pool={pool}
          isOpen={showUnbond}
          onClose={() => setShowUnbond(false)}
        />
      ) : null}
      {/* </LazyHydrate> */}
      {showTradePair ? (
        <LazyHydrate whenVisible>
          <TradePairModal
            pool={pool}
            isOpen={showTradePair}
            onClose={() => setShowTradePair(false)}
          />
        </LazyHydrate>
      ) : null}
      {showAddLiquidity && !pool.poolHasSuperfluidLp && (
        <NewAddLiquidityModal
          pool={pool}
          isOpen={showAddLiquidity}
          onClose={() => setShowAddLiquidity(false)}
        />
      )}
      {showAddLiquidity && pool.poolHasSuperfluidLp && (
        <SuperfluidAddLiquidityModal
          pool={pool}
          isOpen={showAddLiquidity}
          onClose={() => setShowAddLiquidity(false)}
        />
      )}
      {showRemoveLiquidity ? (
        <LazyHydrate whenVisible>
          <RemoveLiquidityModal
            pool={pool}
            isOpen={showRemoveLiquidity}
            onClose={() => setShowRemoveLiquidity(false)}
          />
        </LazyHydrate>
      ) : null}
      {/* <LazyHydrate whenVisible> */}
      <TradePairModal
        pool={pool}
        isOpen={showTradePair}
        onClose={() => setShowTradePair(false)}
      />
    </>
  );
}
