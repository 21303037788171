const variants = {
  primary: {
    overlay: {
      background: "primary.prussianBlue20",
      backdropFilter: "blur(1px)",
    },

    header: {
      pt: "8",
      px: "6",
      pb: "0",
      position: "relative",
      color: "primary.white",
      textAlign: "center",
      textStyle: "dexterH0",
      fontSize: null,
      fontWeight: null,
    },
    dialog: {
      borderRadius: "xl",
      bgColor: "primary.oxfordBlue",
      boxShadow: "0px 0px 4px rgba(112, 112, 112, 0.25);",
      borderColor: "#425E91",
      borderWidth: "1px",
      width: "50% !important",
    },
    body: {
      p: "6",
      color: "primary.white",
    },
  },
};

export default {
  variants,
  defaultProps: {
    variant: "primary",
  },
};
