import { Alert, AlertTitle, Link as ChakraLink } from "@chakra-ui/react";
import Link from "next/link";
import React, { useEffect, useState } from "react";

const countries = [
  "BB",
  "BF",
  "CM",
  "CD",
  "CG",
  "GI",
  "HT",
  "IR",
  "JM",
  "KP",
  "ML",
  "MZ",
  "MM",
  "SN",
  "SS",
  "SY",
  "TZ",
  "UG",
  "YE",
  "UM",
  "US",
  "GB",
  "CU",
  "CA",
];

const CountryBanner: React.FC = () => {
  const workerUrl = "https://worker-geofence.auditdev.workers.dev/";
  const [country, setCountry] = useState<string>("");

  useEffect(() => {
    fetch(workerUrl)
      .then((response) => response.json())
      .then((data) => {
        setCountry(data.country);
      })
      .catch((error) => console.error("Error fetching country:", error));
  }, [workerUrl]);
  if (countries.includes(country)) {
    return (
      <Alert
        justifyContent="center"
        pos={"fixed"}
        zIndex={8}
        // display={"flex"}
        // flexDir="row"
        // height={34}
        height={["56px", "56px", "56px", "34px", "34px", "34px"]}
        bg={"#072129"}
      >
        <AlertTitle fontSize={["14", "14", "16", "16", "16", "16"]}>
          IMPORTANT NOTICE: Please note that from 1st March 2024,
          app.dexter.zone will no longer be accessible from your location. See
          more details{" "}
          <ChakraLink
            as={Link}
            href={"https://geofence-notice.pages.dev/?ref=dexter"}
            isExternal
            fontWeight={700}
            color="#2db8e2"
            textDecoration={"underline"}
            textUnderlineOffset={2}
          >
            here.
          </ChakraLink>
        </AlertTitle>
      </Alert>
    );
  }

  return null;
};

export default CountryBanner;
