import { useChain } from "@cosmos-kit/react";
import { useQuery } from "@tanstack/react-query";

import { useContracts } from "modules/common";
import { getInstantUnlockFeeTiers } from "modules/contracts";

export const useInstantUnlockFeeTiers = (lpToken: string) => {
  const persistenceChain =
    process.env.NEXT_PUBLIC_ENV === "testnet"
      ? "persistencetestnet2"
      : process.env.NEXT_PUBLIC_ENV === "devnet"
      ? "Dexter Devnet"
      : "persistence";
  const chainContext = useChain(persistenceChain);
  const { getCosmWasmClient } = chainContext;
  const { multistaking } = useContracts();
  return useQuery(
    ["instantUnlockFeeTiers", getCosmWasmClient, multistaking, lpToken],
    async () => {
      const client = await getCosmWasmClient();
      if (client == null || multistaking == null || lpToken == null) {
        throw new Error("Error in useInstantUnlockFeeTiers");
      }

      const instantUnlockFeeTiers = await getInstantUnlockFeeTiers({
        client,
        multistaking,
        lpToken,
      });

      return instantUnlockFeeTiers;
    },
    {
      // Revisit the refetch interval
      // refetchInterval: 5000,
      enabled:
        getCosmWasmClient != null && multistaking != null && lpToken != null,
    },
  );
};
