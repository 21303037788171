export default {
  variants: {
    filterBar: {
      button: {
        color: "primary.white",
        bgColor: "primary.oxfordBlue",
        px: "4",
        height: "10",
        borderRadius: "lg",
        borderWidth: "1px",
        borderColor: "primary.queenBlue",
      },
      list: {
        py: "0",
        bgColor: "primary.oxfordBlue",
        borderColor: "primary.queenBlue",
        overflow: "hidden",
      },
    },
  },
};
