import { Icon, IconProps } from "@chakra-ui/react";

export function ListViewIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" fill="currentColor" stroke="#32A2BA" {...props}>
      <circle cx="6.5" cy="7" r="1" />
      <path d="M9.5 7C9.5 6.44772 9.94772 6 10.5 6H17.5C18.0523 6 18.5 6.44772 18.5 7C18.5 7.55228 18.0523 8 17.5 8H10.5C9.94772 8 9.5 7.55228 9.5 7Z" />
      <circle cx="6.5" cy="12" r="1" />
      <path d="M9.5 12C9.5 11.4477 9.94772 11 10.5 11H17.5C18.0523 11 18.5 11.4477 18.5 12C18.5 12.5523 18.0523 13 17.5 13H10.5C9.94772 13 9.5 12.5523 9.5 12Z" />
      <circle cx="6.5" cy="17" r="1" />
      <path d="M9.5 17C9.5 16.4477 9.94772 16 10.5 16H17.5C18.0523 16 18.5 16.4477 18.5 17C18.5 17.5523 18.0523 18 17.5 18H10.5C9.94772 18 9.5 17.5523 9.5 17Z" />
    </Icon>
  );
}
