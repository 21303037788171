export default function MediumIcon() {
  return (
    <svg
      width="20"
      height="11"
      viewBox="0 0 20 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6205 5.50009C11.6205 8.53762 9.19339 11 6.19952 11C3.20565 11 0.77832 8.53762 0.77832 5.50009C0.77832 2.46257 3.20547 0 6.19952 0C9.19358 0 11.6205 2.46257 11.6205 5.50009Z"
        fill="currentColor"
      />
      <path
        d="M17.5677 5.50018C17.5677 8.35935 16.3541 10.6781 14.8571 10.6781C13.3601 10.6781 12.1465 8.35935 12.1465 5.50018C12.1465 2.64101 13.3599 0.322266 14.8569 0.322266C16.3539 0.322266 17.5675 2.64026 17.5675 5.50018"
        fill="currentColor"
      />
      <path
        d="M20.0002 5.49992C20.0002 8.06104 19.5735 10.1385 19.0469 10.1385C18.5203 10.1385 18.0938 8.0616 18.0938 5.49992C18.0938 2.93825 18.5205 0.861328 19.0469 0.861328C19.5733 0.861328 20.0002 2.93806 20.0002 5.49992Z"
        fill="currentColor"
      />
    </svg>
  );
}
