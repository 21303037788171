export default function BlogIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.81 17C15.554 17 16.97 15.58 16.98 13.846L17 11.293L16.97 11.153L16.887 10.979L16.745 10.869C16.561 10.725 15.629 10.879 15.378 10.651C15.2 10.488 15.172 10.195 15.118 9.796C15.018 9.025 14.955 8.985 14.834 8.723C14.394 7.794 13.204 7.096 12.386 7H10.169C9.33049 7.00131 8.52657 7.33438 7.93281 7.92645C7.33905 8.51852 7.0037 9.3215 7 10.16V13.846C7 15.58 8.426 17 10.17 17H13.81ZM10.21 9.581H11.967C12.302 9.581 12.574 9.854 12.574 10.185C12.574 10.515 12.302 10.789 11.967 10.789H10.21C10.0496 10.7887 9.89582 10.7251 9.78213 10.612C9.66844 10.4989 9.60406 10.3454 9.603 10.185C9.603 9.854 9.874 9.581 10.21 9.581ZM9.603 13.8C9.603 13.47 9.874 13.199 10.21 13.199H13.78C14.113 13.199 14.384 13.469 14.384 13.8C14.384 14.127 14.114 14.401 13.78 14.401H10.21C10.05 14.401 9.89656 14.3379 9.78289 14.2253C9.66923 14.1128 9.60458 13.9599 9.603 13.8Z"
        fill="white"
      />
      <path
        d="M6 4C5.46957 4 4.96086 4.21071 4.58579 4.58579C4.21071 4.96086 4 5.46957 4 6V18C4 18.5304 4.21071 19.0391 4.58579 19.4142C4.96086 19.7893 5.46957 20 6 20H18C18.5304 20 19.0391 19.7893 19.4142 19.4142C19.7893 19.0391 20 18.5304 20 18V6C20 5.46957 19.7893 4.96086 19.4142 4.58579C19.0391 4.21071 18.5304 4 18 4H6ZM6 2H18C19.0609 2 20.0783 2.42143 20.8284 3.17157C21.5786 3.92172 22 4.93913 22 6V18C22 19.0609 21.5786 20.0783 20.8284 20.8284C20.0783 21.5786 19.0609 22 18 22H6C4.93913 22 3.92172 21.5786 3.17157 20.8284C2.42143 20.0783 2 19.0609 2 18V6C2 4.93913 2.42143 3.92172 3.17157 3.17157C3.92172 2.42143 4.93913 2 6 2Z"
        fill="white"
      />
    </svg>
  );
}
