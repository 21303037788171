import { Flex, Heading, Box, HStack, Skeleton } from "@chakra-ui/react";
import { formatAmount, num, NumberFormatSpecifier } from "@wizard-ui/core";

import { Wrapper, InfoIcon, useDexter } from "modules/common";
import {
  useAssetBalanceInUsd,
  useTotalBondedInUsd,
  useTotalMyLiquidityInUsd,
  useUnclaimedRewardsInUsd,
} from "modules/assets";
import { TooltipWithTouch } from "modules/common/components/TooltipWithTouch";

export function AssetsStats() {
  const { isLoading } = useDexter();
  const assetBalanceInUsd = useAssetBalanceInUsd();
  const totalBondedInUsd = useTotalBondedInUsd();
  const totalMyLiquidityInUsd = useTotalMyLiquidityInUsd();
  const unclaimedRewardsInUsd = useUnclaimedRewardsInUsd();
  const totalAssets = num(totalMyLiquidityInUsd)
    .plus(num(assetBalanceInUsd))
    .plus(num(totalBondedInUsd).plus(num(unclaimedRewardsInUsd)));

  const items = [
    {
      label: "Total Assets",
      value: Number.isNaN(totalAssets.toNumber()) ? 0 : totalAssets,
      showButton: false,
    },
    {
      label: "Asset Balance",
      value: assetBalanceInUsd,
      showButton: false,
      tooltip:
        "Total $value of assets present on the application which comprises of assets which are not bonded",
    },
    {
      label: "Bonded Assets",
      value: totalBondedInUsd,
      showButton: false,
      tooltip: "Total $value liquidity bonded in pools to earn rewards",
    },
  ];

  return (
    <>
      <Heading variant="dexterH1" mb="6">
        My Assets Balance
      </Heading>
      <Skeleton
        startColor="primary.prussianBlue80"
        endColor="primary.ctaDisabled"
        isLoaded={!isLoading}
      >
        <Wrapper variant="secondary" py="6" px={["8", "8", "20", "20", "20"]}>
          <Flex
            justify="space-between"
            flexDir={["column", "column", "row", "row", "row"]}
            gap={[4, 4, 4, 0, 0, 0]}
          >
            {items.map((item) => {
              return (
                <Flex key={item.label} gap="4" align="flex-end">
                  <Box>
                    <HStack mb={[1, 1, 1, 3, 3, 3]}>
                      <Heading variant="dexterH1">{item.label}</Heading>
                      {item.tooltip != null && (
                        <TooltipWithTouch
                          hasArrow
                          label={item.tooltip}
                          bg="#00506B"
                          color="white"
                          borderRadius="lg"
                          p="3"
                          maxW="56"
                        >
                          <span>
                            <InfoIcon _hover={{ cursor: "pointer" }} />
                          </span>
                        </TooltipWithTouch>
                      )}
                    </HStack>
                    <Heading variant="dexterH3">
                      {formatAmount(item.value, {
                        formatSpecifier: NumberFormatSpecifier.CURRENCY,
                      })}
                    </Heading>
                  </Box>
                </Flex>
              );
            })}
          </Flex>
        </Wrapper>
      </Skeleton>
    </>
  );
}
