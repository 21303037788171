export default {
  primary: {
    prussianBlue: "#003547",
    prussianBlue50: "#00354780",
    prussianBlue80: "#003547FF",
    prussianBlue20: "#00354733",
    popStar: "#B95663",
    popStar50: "#B9566380",
    popStar20: "#B9566333",
    dexBlue:
      "linear-gradient(284.42deg, #00AFFA -49.92%, #32A2BA 54.24%, #24FFCA 169.83%);",
    dexBlue50:
      "linear-gradient(284.42deg, rgba(0, 175, 250, 0.5) -49.92%, rgba(50, 162, 186, 0.5) 54.24%, rgba(36, 255, 202, 0.5) 169.83%);",
    dexBlue20:
      "linear-gradient(284.42deg, rgba(0, 175, 250, 0.2) -49.92%, rgba(50, 162, 186, 0.2) 54.24%, rgba(36, 255, 202, 0.2) 169.83%);",
    oxfordBlue: "#0A1D32",
    cta: "linear-gradient(284.42deg, #00AFFA -49.92%, #32A2BA 54.24%, #24FFCA 169.83%);",
    cta20:
      "linear-gradient(284.42deg, #00AFFA33 -49.92%, #32A2BA33 54.24%, #24FFCA33 169.83%);",
    ctaHover: "#01BDBD",
    ctaPressed: "#1EA7D4",
    ctaDisabled:
      "linear-gradient(0deg, rgba(81, 82, 82, 0.6), rgba(81, 82, 82, 0.6)), linear-gradient(284.42deg, #00AFFA -49.92%, #32A2BA 54.24%, #24FFCA 169.83%);",
    queenBlue: "#386794",
    disabled: "#909090",
    white: "#FCFCFC",
    black: "#1F1E1C",
    bluePinch: "#EBFAFB",
  },
  stroke: {
    dexBlue: "#386794",
  },
  link1: {
    dexBlue:
      "linear-gradient(284.42deg, #00AFFA -49.92%, #32A2BA 54.24%, #24FFCA 169.83%);",
  },
};
