import { extendTheme } from "@chakra-ui/react";

import Button from "./button";
import Heading from "./heading";
import Input from "./input";
import Menu from "./menu";
import Modal from "./modal";
import Tabs from "./tabs";
import Drawer from "./drawer";
import Wrapper from "./wrapper";
import Table from "./table";
import Text from "./text";
import Checkbox from "./checkbox";
import textStyles from "./textStyles";
import colors from "./colors";
const breakpoints = {
  sm: "330px",
  md: "768px",
  lg: "960px",
  xl: "1200px",
  "2xl": "1536px",
};
export default extendTheme({
  breakpoints,
  styles: {
    global: {
      "*": {
        scrollbarWidth: "0",
      },

      "::-webkit-scrollbar": {
        width: "10px",
      },

      /* Track */
      "::-webkit-scrollbar-track": {
        background: "#f1f1f1",
      },

      /* Handle */
      "::-webkit-scrollbar-thumb": {
        background: "#888",
      },

      /* Handle on hover */
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },

      // "*::-webkit-scrollbar": {
      //   display: "none",
      // },
      body: {
        color: "white",
      },
      ".wz-menu-items": {
        width: "12rem",
      },
      ".wz-connect-button": {
        background: "transparent",
        borderColor: "var(--chakra-colors-primary-cta)",
        fontSize: "var(--chakra-fontSizes-md)",
        borderRadius: "var(--chakra-radii-full)",
        fontWeight: 700,
        height: "var(--chakra-sizes-12)",
        padding: "0 var(--chakra-sizes-8)",
      },
      ".wz-connect-button:hover": {
        transition: "none",
        background: "var(--chakra-colors-primary-ctaHover)",
        color: "white",
      },
      ".wz-connect-button:before": {
        content: "''",
        position: "absolute",
        zIndex: -1,
        inset: 0,
        padding: "2px",
        borderRadius: "full",
        background:
          "linear-gradient(284.42deg, #00AFFA -49.92%, #32A2BA 54.24%, #24FFCA 169.83%)",
        WebkitMask:
          "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
        mask: "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
        WebkitMaskComposite: "xor",
        maskComposite: "exclude",
      },
    },
  },
  fonts: {
    heading:
      "Jost,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji",
    body: "Nunito Sans,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji",
  },
  components: {
    Button,
    Heading,
    Input,
    Menu,
    Modal,
    Tabs,
    Drawer,
    Wrapper,
    Table,
    Text,
    Checkbox,
  },
  textStyles,
  colors,
});
