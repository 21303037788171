import { Icon, IconProps } from "@chakra-ui/react";

export function WarningIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        d="M22.7514 19.0094L13.823 3.05134C13.6452 2.7333 13.3824 2.46776 13.0623 2.28271C12.7421 2.09767 12.3764 2 12.0039 2C11.6313 2 11.2656 2.09767 10.9455 2.28271C10.6253 2.46776 10.3625 2.7333 10.1847 3.05134L1.26272 19.0094C1.08629 19.3162 0.995756 19.6626 1.00015 20.0141C1.00455 20.3655 1.10372 20.7097 1.28778 21.0122C1.47184 21.3148 1.73435 21.5653 2.04914 21.7386C2.36393 21.9119 2.72 22.002 3.08184 22H20.9323C21.2905 21.9996 21.6424 21.909 21.9537 21.737C22.265 21.565 22.525 21.3174 22.7083 21.0186C22.8916 20.7197 22.992 20.3798 22.9995 20.032C23.0071 19.6842 22.9216 19.3405 22.7514 19.0344V19.0094Z"
        fill="currentColor"
      />
      <path
        d="M11.1811 7.01586C11.1811 6.78404 11.2759 6.56171 11.4446 6.39779C11.6134 6.23387 11.8423 6.14178 12.081 6.14178C12.3197 6.14178 12.5486 6.23387 12.7173 6.39779C12.8861 6.56171 12.9809 6.78404 12.9809 7.01586V14.5079C12.9809 14.7397 12.8861 14.962 12.7173 15.126C12.5486 15.2899 12.3197 15.382 12.081 15.382C11.8423 15.382 11.6134 15.2899 11.4446 15.126C11.2759 14.962 11.1811 14.7397 11.1811 14.5079V7.01586ZM12.081 19.4089C11.8521 19.4089 11.6284 19.343 11.4382 19.2195C11.2479 19.0961 11.0996 18.9205 11.012 18.7152C10.9245 18.5098 10.9015 18.2839 10.9462 18.0659C10.9908 17.8479 11.101 17.6477 11.2628 17.4905C11.4247 17.3333 11.6308 17.2263 11.8553 17.1829C12.0797 17.1396 12.3123 17.1618 12.5238 17.2469C12.7352 17.3319 12.9159 17.476 13.043 17.6608C13.1702 17.8456 13.238 18.0629 13.238 18.2851C13.238 18.4327 13.2081 18.5789 13.1499 18.7152C13.0918 18.8515 13.0066 18.9754 12.8991 19.0798C12.7917 19.1841 12.6641 19.2669 12.5238 19.3234C12.3834 19.3799 12.2329 19.4089 12.081 19.4089Z"
        fill="#FCFCFC"
      />
    </Icon>
  );
}
