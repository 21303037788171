import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Box,
  Heading,
  Flex,
  Button,
  Text,
  VStack,
  useMediaQuery,
  Skeleton,
} from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { Wrapper, useDexter } from "modules/common";
import { BondModal, LPTokensItem, PoolBase } from "modules/pools";
import { LPTokensItemBonded } from "./LPTokensItemBonded";
import LazyHydrate from "react-lazy-hydration";
import { LPTokensItemUnlocked } from "./LPTokensItemUnlocked";
import { useChain } from "@cosmos-kit/react";
import { useInstantUnbondConfig } from "modules/common/hooks/useInstantUnbondConfig";
interface Props {
  pool: PoolBase;
}
export function LPTokens({ pool }: Props) {
  const [isMobile] = useMediaQuery("(max-width: 800px)");
  const { tokenLocks, bondedLpTokens, balances } = useDexter();
  const [showBond, setShowBond] = useState(false);
  const persistenceChain =
    process.env.NEXT_PUBLIC_ENV === "testnet"
      ? "persistencetestnet2"
      : process.env.NEXT_PUBLIC_ENV === "devnet"
      ? "Dexter Devnet"
      : "persistence";
  const chainContext = useChain(persistenceChain);
  const { isWalletConnected, isWalletConnecting } = chainContext;
  const {
    data: instantUnbondConfig,
    isInitialLoading: instantUnbondConfigIsLoading,
  } = useInstantUnbondConfig(pool.lpToken);

  const periods = useMemo(() => {
    if (tokenLocks == null || pool == null) {
      return [];
    }
    return tokenLocks[pool.lpToken].locks;
  }, [tokenLocks, pool]);

  const bondedLp = useMemo(() => {
    if (bondedLpTokens == null || pool == null) {
      return [];
    }
    return bondedLpTokens[pool.lpToken];
  }, [bondedLpTokens, pool]);
  return (
    <>
      <Box>
        <Flex
          justify={"space-between"}
          flexDir={["column", "column", "row", "row", "row"]}
        >
          <VStack align={"flex-start"} spacing={0} mb={4}>
            <Heading variant="dexterH1">Bond Liquidity</Heading>
            <Text variant={"dexterP5"}>
              Lock up your LP shares for longer duration to earn higher APRs.
            </Text>
          </VStack>
          <Button
            variant={"primary"}
            type="submit"
            size="lg"
            minW="48"
            isDisabled={pool.availableToBondAmountInUsd === 0}
            onClick={() => setShowBond(true)}
            mb={[4, 4, 0, 0, 0]}
          >
            Bond Shares
          </Button>
        </Flex>
        {isWalletConnected ? (
          periods.length > 0 || bondedLp !== "0" || pool.hasUnlocked ? (
            !isMobile ? (
              <Wrapper variant="primary">
                {/* <TableContainer overflowY={"auto"} maxHeight="210px"> */}
                <Table variant="bondLp">
                  <Thead position="sticky" top={0}>
                    <Tr>
                      <Th w={"20%"} textAlign="center">
                        Liquidity Amount
                      </Th>
                      <Th w={"20%"} textAlign="center">
                        Amount
                      </Th>
                      <Th w={"20%"} textAlign="center">
                        Unbonding Time
                      </Th>
                      <Th w={"20%"} textAlign="center">
                        Status
                      </Th>
                      <Th w={"20%"} textAlign="center">
                        Action
                      </Th>
                    </Tr>
                  </Thead>
                </Table>
                {/* </TableContainer> */}
                <TableContainer overflowY={"auto"} maxHeight={"170px"}>
                  <Table variant={"bondLp"}>
                    <Tbody>
                      {pool.hasBonded && (
                        <LPTokensItemBonded
                          pool={pool}
                          data={bondedLp}
                          instantUnbondConfig={instantUnbondConfig}
                        />
                      )}
                      {pool.hasUnlocked && (
                        <LPTokensItemUnlocked
                          pool={pool}
                          data={pool.unlockedAmount}
                        />
                      )}
                      {periods.map((data: any) => {
                        return (
                          <LPTokensItem
                            key={data.unlock_time}
                            pool={pool}
                            data={data}
                            instantUnbondConfig={instantUnbondConfig}
                          />
                        );
                      })}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Wrapper>
            ) : (
              <Flex
                px={2}
                py={2}
                flexDir="column"
                gap={2}
                bg={"primary.prussianBlue50"}
                borderColor={"stroke.dexBlue"}
                borderWidth={2}
                borderRadius={"2xl"}
              >
                {pool.hasBonded && (
                  <LPTokensItemBonded
                    pool={pool}
                    data={bondedLp}
                    instantUnbondConfig={instantUnbondConfig}
                    isMobile
                  />
                )}
                {pool.hasUnlocked && (
                  <LPTokensItemUnlocked
                    pool={pool}
                    data={pool.unlockedAmount}
                    isMobile
                  />
                )}
                {periods.map((data: any) => {
                  return (
                    <LPTokensItem
                      key={data.unlock_time}
                      pool={pool}
                      data={data}
                      instantUnbondConfig={instantUnbondConfig}
                      isMobile
                    />
                  );
                })}
              </Flex>
            )
          ) : (
            <Box>
              <Wrapper variant="empty" px={6}>
                <Text
                  variant="dexterP1"
                  fontSize={["20px", "20px", "20px", "24px", "24px", "24px"]}
                >
                  Bond your LP shares for 7 days to earn external APRs.
                </Text>
              </Wrapper>
            </Box>
          )
        ) : (
          <Box>
            <Skeleton
              startColor="primary.prussianBlue80"
              endColor="primary.ctaDisabled"
              isLoaded={!isWalletConnecting}
            >
              <Wrapper variant="empty" p={4} h="150px">
                <Text variant="dexterP1">
                  Connect your wallet to view your bonded shares.
                </Text>
              </Wrapper>
            </Skeleton>
          </Box>
        )}
      </Box>
      <LazyHydrate whenVisible>
        <BondModal
          pool={pool}
          isOpen={showBond}
          onClose={() => setShowBond(false)}
        />
      </LazyHydrate>
    </>
  );
}
