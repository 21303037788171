import { useMemo } from "react";
import { useRouter } from "next/router";
import {
  Flex,
  Text,
  SimpleGrid,
  HStack,
  Divider,
  Image,
} from "@chakra-ui/react";
import { ColumnDef } from "@tanstack/react-table";
import { formatAmount, NumberFormatSpecifier } from "@wizard-ui/core";

import {
  AssetItem,
  useDexter,
  Table,
  TokenIcon,
  useTokenInfo,
} from "modules/common";
import { PoolBase, POOL_TYPES_ICONS, tokenWeights } from "modules/pools";
import { TooltipWithTouch } from "modules/common/components/TooltipWithTouch";
import { useCValue } from "../hooks/useCValue";
import { useDydxCValue } from "../hooks/useDydxCValue";
import { useStarsCValue } from "../hooks/useStarsCValue";
import { useHuahuaCValue } from "../hooks/useHuahuaCValue";

export function AllPools() {
  const router = useRouter();
  const path = router.asPath;
  const ipfsMatch = /.*\/Qm\w{44}\//.exec(path);

  const base = ipfsMatch ? ipfsMatch[0] : "/";
  const { getSymbol } = useTokenInfo();
  const { pools, prices, isPoolsLoading, isPricesLoading } = useDexter();
  const ibcAtom =
    process.env.NEXT_PUBLIC_ENV == "mainnet"
      ? "ibc/C8A74ABBE2AF892E15680D916A7C22130585CE5704F9B17A10F184A90D53BECA"
      : "ibc/C4CFF46FD6DE35CA4CF4CE031E643C8FDC9BA4B99AE598E9B0ED98FE3A2319F9";
  const mainnetWeights = {
    uxprt: "50",
    [ibcAtom]: "50",
  };
  const testnetWeights = {
    uxprt: "90",
    [ibcAtom]: "10",
  };
  const weights =
    process.env.NEXT_PUBLIC_ENV == "mainnet" ? mainnetWeights : testnetWeights;

  const stkAtomCValue = useCValue();
  const stkDydxCValue = useDydxCValue();
  const stkStarsCValue = useStarsCValue();
  const stkHuahuaCValue = useHuahuaCValue();
  const columns = useMemo<ColumnDef<PoolBase>[]>(
    () => [
      {
        header: "Pool Name",
        accessorFn: (row) => {
          return row;
        },
        size: 16000,
        cell: (info) => {
          const weights2 = tokenWeights(
            info.getValue(),
            (info.getValue() as any)?.id == 1
              ? stkAtomCValue
              : (info.getValue() as any)?.id == 7
              ? stkDydxCValue
              : (info.getValue() as any)?.id == 9
              ? stkStarsCValue
              : stkHuahuaCValue,
            0,
          );
          return (
            <Flex flexWrap="wrap" gap="2">
              <Image
                src={
                  (info.getValue() as any).id == 1 ||
                  (info.getValue() as any).id == 7 ||
                  (info.getValue() as any).id == 9 ||
                  (info.getValue() as any).id == 11 ||
                  (info.getValue() as any).id == 12
                    ? POOL_TYPES_ICONS["metastable"]
                    : POOL_TYPES_ICONS[(info.getValue() as any).poolType]
                }
                boxSize={"10"}
                alt="pool icon"
              />
              {(info.getValue() as any).assets
                .map((item) => item.identifier)
                .join(",")
                .split(",")
                .map((token: string) => {
                  return (
                    <AssetItem
                      size="sm"
                      key={token}
                      token={token}
                      weight={
                        (info.getValue() as any).poolType === "weighted"
                          ? "50"
                          : weights2.find((w) => w.token === token)?.weight
                      }
                    />
                  );
                })}
            </Flex>
          );
        },
      },
      {
        header: "Pool Liquidity",
        accessorFn: (row) => {
          return row.getTotalLiquidityInUsd();
        },
        cell: (info) => {
          return formatAmount(info.getValue() as any, {
            formatSpecifier: NumberFormatSpecifier.CURRENCY,
          });
        },
      },
      {
        header: "Volume 24H",
        accessorKey: "volume24h",
        cell: (info) => {
          const volume = info.getValue() as any;
          if (!volume) return <>--</>;
          else {
            // const amount = volume.amount;
            // const token = volume.token;
            // const price = prices?.[token].price;
            // const volumeInUsd = num(amount).times(price).toString();
            return formatAmount(volume, {
              formatSpecifier: NumberFormatSpecifier.CURRENCY,
            });
          }
        },
      },
      {
        header: "Volume 7D",
        accessorKey: "volume7d",
        cell: (info) => {
          return formatAmount(info.getValue() as any, {
            formatSpecifier: NumberFormatSpecifier.CURRENCY,
          });
        },
      },
      {
        header: "Fees 7D",
        accessorKey: "fee7d",
        cell: (info) => {
          return formatAmount(info.getValue() as any, {
            formatSpecifier: NumberFormatSpecifier.CURRENCY,
          });
        },
      },
      {
        header: "APR",
        accessorFn: (row) => {
          return row.stakingAPR ? row?.apr7d + row.stakingAPR : row?.apr7d;
        },
        cell: ({ row }) => {
          const pool = row.original;
          const stakingAPR = pool.stakingAPR;
          return (
            <TooltipWithTouch
              hasArrow
              label={
                <>
                  <SimpleGrid columns={2} spacing={2}>
                    <Text variant={"dexterP6"}>Swap Fee</Text>
                    <Text variant={"dexterP6"}>
                      {pool?.feeApr?.toFixed(2)}%
                    </Text>
                    {stakingAPR != null ? (
                      <>
                        <Text variant={"dexterP6"}>Staking APR</Text>
                        <Text variant={"dexterP6"}>
                          {stakingAPR?.toFixed(2)}%
                        </Text>
                      </>
                    ) : null}
                    {pool?.extApr?.map((item) => {
                      return item.incentive_apr > 0 ? (
                        <>
                          <HStack spacing={"2"}>
                            <TokenIcon token={item?.reward_asset_id} />
                            <Text variant={"dexterP6"}>
                              {getSymbol(item?.reward_asset_id)}
                            </Text>
                          </HStack>
                          <Text variant={"dexterP6"}>
                            {item?.incentive_apr?.toFixed(2)}%
                          </Text>
                        </>
                      ) : null;
                    })}
                  </SimpleGrid>
                  <Divider my="2" />
                  <SimpleGrid columns={2} spacing={2}>
                    <Text variant={"dexterP6"}>Total APR</Text>
                    <Text variant={"dexterP6"}>
                      {stakingAPR
                        ? (pool?.apr7d + stakingAPR).toFixed(2)
                        : pool?.apr7d?.toFixed(2)}
                      %
                    </Text>
                  </SimpleGrid>
                </>
              }
              bg="#00506B"
              color="white"
              borderRadius="lg"
              p="3"
              maxW="56"
            >
              {formatAmount(
                stakingAPR ? pool?.apr7d + stakingAPR : pool?.apr7d,
                {
                  formatSpecifier: NumberFormatSpecifier.PERCENTAGE,
                },
              )}
            </TooltipWithTouch>
          );
        },
      },
      {
        header: "Rewards",
        accessorFn: (row) => {
          return row;
        },
        cell: (info) => {
          return (
            <HStack spacing={"-1"}>
              {info.getValue()?.extApr?.length > 0 ? (
                info.getValue()?.extApr?.map((item) => {
                  return item.incentive_apr > 0 ? (
                    <>
                      <TokenIcon size="6" token={item?.reward_asset_id} />
                    </>
                  ) : info.getValue()?.extApr?.length === 1 ? (
                    <>--</>
                  ) : null;
                })
              ) : (
                <>--</>
              )}
            </HStack>
          );
        },
      },
    ],
    [],
  );

  const handleClick = ({ address }: PoolBase) => {
    router.push(`${base}/pools/${address}`);
  };

  return (
    <Table
      title="All Pools"
      data={pools ?? []}
      columns={columns}
      onItemClick={handleClick}
      loading={isPoolsLoading || isPricesLoading}
    />
  );
}
