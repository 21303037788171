import { Text, HStack, Box, Link, Circle } from "@chakra-ui/react";
import { ExecuteResult } from "@cosmjs/cosmwasm-stargate";
import { motion } from "framer-motion";

import { CheckIcon } from "modules/common";

interface Props {
  successLabel: string;
  isLoading: boolean;
  data?: ExecuteResult;
}

const MotionCircle = motion(Circle);

export function TxNotification({ successLabel, data, isLoading }: Props) {
  if (isLoading) {
    return (
      <Box bg="primary.prussianBlue" borderRadius="md" p="4">
        <HStack spacing="4">
          <MotionCircle
            bg="primary.dexBlue"
            size="4"
            animate={{ scale: 1.5 }}
            transition={{ repeat: Infinity, repeatType: "mirror", duration: 1 }}
          >
            <MotionCircle
              bg="white"
              size="2"
              animate={{ scale: 1.3 }}
              transition={{
                repeat: Infinity,
                repeatType: "mirror",
                duration: 0.75,
              }}
            />
          </MotionCircle>
          <Text fontWeight="700" fontSize="md">
            Transaction Broadcasting...
          </Text>
        </HStack>
      </Box>
    );
  }

  if (data) {
    return (
      <Box bg="primary.prussianBlue" borderRadius="md" p="4">
        <HStack spacing="4">
          <CheckIcon />
          <Box>
            <Text fontWeight="600" fontSize="md">
              {successLabel}
            </Text>
            <Link
              color="whiteAlpha.700"
              fontSize="sm"
              href={`https://www.mintscan.io/persistence/txs/${data.transactionHash}`}
              isExternal
            >
              View Transaction
            </Link>
          </Box>
        </HStack>
      </Box>
    );
  }

  return null;
}
