import { Icon, IconProps } from "@chakra-ui/react";

export function ChevronDownIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 18 18" fill="none" {...props}>
      <path
        d="M4 7L9 12L14 7"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
