import {
  InputGroup,
  InputLeftElement,
  Input,
  InputProps,
} from "@chakra-ui/react";
import { SearchIcon } from "modules/common";

export function TokenSearch({
  variant = "dexterSearchSmall",
  ...rest
}: InputProps) {
  return (
    <InputGroup mt="2" mb="4">
      <InputLeftElement pointerEvents="none">
        <SearchIcon boxSize={6} />
      </InputLeftElement>
      <Input variant={variant} placeholder="Search by token" {...rest} />
    </InputGroup>
  );
}
