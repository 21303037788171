export default function GitHubIcon() {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.30003 0C3.71405 0 0.80957 2.98232 0.80957 6.66441C0.80957 9.61341 2.66746 12.1042 5.24742 12.9873C5.57195 13.0456 5.69364 12.8456 5.69364 12.6707C5.69364 12.5124 5.68553 11.9876 5.68553 11.4295C4.0548 11.7377 3.63292 11.0213 3.50311 10.6464C3.43009 10.4548 3.11368 9.86333 2.83784 9.70505C2.61067 9.58009 2.28615 9.27186 2.82973 9.26353C3.34085 9.2552 3.70594 9.7467 3.82763 9.94663C4.41178 10.9546 5.34478 10.6714 5.71798 10.4964C5.77477 10.0633 5.94515 9.77169 6.13175 9.60508C4.68762 9.43847 3.17859 8.86366 3.17859 6.31453C3.17859 5.58977 3.43009 4.98998 3.84386 4.52347C3.77896 4.35686 3.55179 3.67376 3.90877 2.7574C3.90877 2.7574 4.45234 2.58246 5.69364 3.4405C6.21288 3.29055 6.76457 3.21558 7.31626 3.21558C7.86795 3.21558 8.41964 3.29055 8.93887 3.4405C10.1802 2.57413 10.7237 2.7574 10.7237 2.7574C11.0807 3.67376 10.8536 4.35686 10.7887 4.52347C11.2024 4.98998 11.4539 5.58144 11.4539 6.31453C11.4539 8.87199 9.93678 9.43847 8.49265 9.60508C8.72793 9.81334 8.93076 10.2132 8.93076 10.838C8.93076 11.7294 8.92265 12.4458 8.92265 12.6707C8.92265 12.8456 9.04434 13.0539 9.36887 12.9873C10.6573 12.5406 11.7769 11.6903 12.5701 10.5561C13.3633 9.42184 13.7901 8.06076 13.7905 6.66441C13.7905 2.98232 10.886 0 7.30003 0Z"
        fill="currentColor"
      />
    </svg>
  );
}
