const variants = {
  primary: {
    control: {
      width: "20px",
      height: "20px",
      border: "2px solid",
      borderColor: "primary.white",
      borderRadius: "4px",
      backgroundColor: "transparent",
      color: "transparent",
      _focus: {
        boxShadow: "none",
      },
      _focusVisible: {
        boxShadow: "none",
      },
      _checked: {
        backgroundColor: "transparent",
        borderColor: "primary.white",
        color: "primary.white",
        _hover: {
          backgroundColor: "transparent",
          borderColor: "primary.white",
        },
      },
    },
    label: {
      textStyle: "dexterP4",
      fontSize: null,
      fontWeight: null,
      lineHeight: null,
    },
  },
};

export default {
  variants,
  defaultProps: {
    variant: "primary",
  },
};
