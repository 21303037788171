import { useState } from "react";
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { DeliverTxResponse, SigningStargateClient } from "@cosmjs/stargate";
import { depositSuccessDataVar } from "state/globalState";
import { useChain } from "@cosmos-kit/react";
import useStore from "state/store";

export interface TransferIBCAssetMutation {
  msg: {
    otherAddress: string;
    amount: string;
    denom: string;
    channel: string;
    otherSigningStargateClient?: SigningStargateClient;
    isWithdraw?: boolean;
  };
}

export function useIBCTransferMutation(
  options?: Omit<
    UseMutationOptions<DeliverTxResponse, Error, TransferIBCAssetMutation>,
    "mutationFn"
  >,
) {
  const persistenceChain =
    process.env.NEXT_PUBLIC_ENV === "testnet"
      ? "persistencetestnet2"
      : process.env.NEXT_PUBLIC_ENV === "devnet"
      ? "Dexter Devnet"
      : "persistence";
  const chainContext = useChain(persistenceChain);
  const { address, getSigningStargateClient } = chainContext;
  const queryClient = useQueryClient();

  const setDepositSuccessDataVar = useStore(
    (state: any) => state.setDepositSuccessDataVar,
  );

  return useMutation<DeliverTxResponse, Error, TransferIBCAssetMutation>(
    async ({
      msg: {
        otherAddress,
        otherSigningStargateClient,
        channel,
        denom,
        amount,
        isWithdraw,
      },
    }) => {
      if (
        address == null ||
        amount == null ||
        denom == null ||
        channel == null ||
        otherAddress == null
      ) {
        throw new Error("Missing something in useIBCTransferMutation");
      }

      if (isWithdraw) {
        if (otherSigningStargateClient == null) {
          throw new Error(
            "Missing signingStargateClient in useIBCTransferMutation",
          );
        }

        return otherSigningStargateClient.sendIbcTokens(
          address,
          otherAddress,
          {
            denom,
            amount,
          },
          "transfer",
          channel,
          undefined,
          Math.floor(Date.now() / 1000) + 60,
          "auto",
          "",
        );
      }

      if (otherSigningStargateClient == null) {
        throw new Error(
          "Missing otherSigningStargateClient in useIBCTransferMutation",
        );
      }

      return otherSigningStargateClient.sendIbcTokens(
        otherAddress,
        address,
        {
          denom,
          amount,
        },
        "transfer",
        channel,
        undefined,
        Math.floor(Date.now() / 1000) + 60,
        "auto",
        "",
      );
    },
    {
      ...options,
      onSuccess: (data, variables, context) => {
        setDepositSuccessDataVar(data);
        queryClient.invalidateQueries(["balances"]);
      },
      // onSuccess: () => {
      //   queryClient.invalidateQueries(["balances"]);
      // },
    },
  );
}
