import { Icon, IconProps } from "@chakra-ui/react";

export function RightArrowIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 18 18" {...props}>
      <path
        d="M5.25 12.75L12.75 5.25"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.25 5.25H12.75V12.75"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
