import { create } from "zustand";

const useStore = create((set) => ({
  tokensVar: { token1: "", token2: "" },
  rewardsTokensVar: { token1: "", token2: "" },
  withdrawSuccessDataVar: null,
  depositSuccessDataVar: null,
  setTokensVar: (newTokens) =>
    set((state) => ({ ...state, tokensVar: newTokens })),
  setRewardsTokensVar: (newRewardsTokens) =>
    set((state) => ({ ...state, rewardsTokensVar: newRewardsTokens })),
  setWithdrawSuccessDataVar: (newWithdrawSuccessData) =>
    set((state) => ({
      ...state,
      withdrawSuccessDataVar: newWithdrawSuccessData,
    })),
  setDepositSuccessDataVar: (newDepositSuccessData) =>
    set((state) => ({
      ...state,
      depositSuccessDataVar: newDepositSuccessData,
    })),
}));

export default useStore;
