import {
  FC,
  ReactNode,
  useMemo,
  Context,
  createContext,
  useContext,
  Consumer,
} from "react";
import { num } from "@wizard-ui/core";

import {
  usePrices,
  useBalances,
  useBondedLpTokens,
  useTokenLocks,
  useUnclaimedRewards,
  Tokens,
  Pool,
} from "modules/common";
import { PoolBase, usePoolsQuery } from "modules/pools";
import { useTokensDataQuery } from "./hooks/useTokensData";
import { useGetWhitelistedLpTokens } from "./hooks/useGetWhitelistedLpTokens";
import { usePoolConfigQuery } from "modules/contracts";
import mainnetConfig from "../common/assets.mainnet.json";
import testnetConfig from "../common/assets.testnet.json";
import devnetConfig from "../common/assets.devnet.json";

type Dexter = {
  pools: PoolBase[] | null;
  tokens: Tokens | null;
  lpTokens: string[] | null;
  whitelistedLpTokens: string[] | null;
  prices: Record<string, number> | null | undefined;
  balances: Record<string, string> | null | undefined;
  bondedLpTokens: Record<string, any> | null | undefined;
  unclaimedRewards: Record<string, any> | null | undefined;
  tokenLocks: Record<string, any> | null | undefined;
  isLoading: boolean;
  isPricesLoading: boolean;
  isPoolsLoading: boolean;
  isBalancesLoading: boolean;
  isBondedLpTokensLoading: boolean;
  isUnclaimedRewardsLoading: boolean;
  isTokenLocksLoading: boolean;
  backendEndpoint: any | null;
};

export const DexterContext: Context<Dexter> = createContext<Dexter>({
  pools: null,
  tokens: null,
  lpTokens: null,
  whitelistedLpTokens: null,
  balances: null,
  bondedLpTokens: null,
  prices: null,
  tokenLocks: null,
  unclaimedRewards: null,
  isLoading: true,
  isPricesLoading: true,
  isPoolsLoading: true,
  isBalancesLoading: true,
  isBondedLpTokensLoading: true,
  isUnclaimedRewardsLoading: true,
  isTokenLocksLoading: true,
  backendEndpoint: null,
});

type Props = {
  children: ReactNode;
  backendEndpoint: string | null;
};
export const tokensConfig = {
  devnet: devnetConfig,
  testnet: testnetConfig,
  mainnet: mainnetConfig,
};
export const DexterProvider: FC<Props> = ({ children, backendEndpoint }) => {
  // const { data: tokensData, isInitialLoading: tokensIsInitialLoading } =
  //   useTokensDataQuery();
  // const tokens = useMemo(() => {
  //   if (tokensData == null) {
  //     return config.token_metadata;
  //   }
  //   return tokensData;
  // }, [tokensData]);
  const config =
    tokensConfig[process.env.NEXT_PUBLIC_ENV as keyof typeof tokensConfig];
  const tokens = useMemo(() => {
    return config.token_metadata;
  }, [config.token_metadata]);
  // TODO: Fix type
  const { data: pricesData, isInitialLoading: pricesIsInitialLoading } =
    usePrices(tokens);
  // const { data: poolsData, isInitialLoading: poolsIsInitialLoading } =
  //   usePoolsQuery();
  const { data: poolsData, isInitialLoading: poolsIsInitialLoading } =
    usePoolConfigQuery(backendEndpoint, {
      enabled:
        backendEndpoint != null &&
        (pricesData != null || pricesData != undefined),
    });
  const lpTokens = useMemo(() => {
    if (poolsData == null) {
      return [];
    }

    return poolsData.map((pool: Pool) => {
      return pool.lpTokenContractAddress;
    });
  }, [poolsData]);
  const { data: balancesData, isInitialLoading: balancesIsInitialLoading } =
    useBalances(lpTokens, tokens);
  const {
    data: bondedLpTokensData,
    isInitialLoading: bondedLpTokensIsInitialLoading,
  } = useBondedLpTokens(lpTokens);
  const { data: tokenLocksData, isInitialLoading: tokenLocksIsInitialLoading } =
    useTokenLocks(lpTokens);
  const {
    data: unclaimedRewardsData,
    isInitialLoading: unclaimedRewardsIsInitialLoading,
  } = useUnclaimedRewards(lpTokens);
  // const {
  //   data: whitelistedLpTokens,
  //   isInitialLoading: whitelistedLpTokensLoading,
  // } = useGetWhitelistedLpTokens();
  const pools = useMemo(() => {
    if (
      poolsData == null ||
      pricesData == null
      //  ||
      // tokenLocksData == null ||
      // balancesData == null ||
      // bondedLpTokensData == null ||
      // unclaimedRewardsData == null
    ) {
      return null;
    }
    return poolsData.map((pool: Pool) => {
      return new PoolBase(pool, {
        prices: pricesData,
        balances: balancesData,
        bondedLpTokens: bondedLpTokensData,
        tokenLocks: tokenLocksData,
        unclaimedRewards: unclaimedRewardsData,
        tokens,
      });
    });
    // .filter((pool: PoolBase) => {
    //   return (
    //     // TODO - dynamic
    //     num(pool.lpTokenSupply).isGreaterThan(0)
    //     // &&
    //     // pool.vaultAddress ==
    //     //   "persistence1rkdtlrftnqrtka62yfq9re44mak4qy5jw8h0wrarglm9njjumhwqp68f05"
    //   );
    // });
  }, [
    poolsData,
    pricesData,
    balancesData,
    bondedLpTokensData,
    tokenLocksData,
    unclaimedRewardsData,
  ]);
  return (
    <DexterContext.Provider
      value={{
        pools,
        tokens,
        lpTokens,
        // whitelistedLpTokens,
        balances: balancesData,
        bondedLpTokens: bondedLpTokensData,
        prices: pricesData,
        unclaimedRewards: unclaimedRewardsData,
        tokenLocks: tokenLocksData,
        isLoading:
          // tokensIsInitialLoading ||
          pricesIsInitialLoading ||
          // balancesIsInitialLoading ||
          poolsIsInitialLoading ||
          // whitelistedLpTokensLoading ||
          tokenLocksIsInitialLoading ||
          unclaimedRewardsIsInitialLoading ||
          bondedLpTokensIsInitialLoading,
        isPricesLoading: pricesIsInitialLoading,
        isPoolsLoading: poolsIsInitialLoading,
        isBalancesLoading: balancesIsInitialLoading,
        isBondedLpTokensLoading: bondedLpTokensIsInitialLoading,
        isTokenLocksLoading: tokenLocksIsInitialLoading,
        isUnclaimedRewardsLoading: unclaimedRewardsIsInitialLoading,
        backendEndpoint,
      }}
    >
      {children}
    </DexterContext.Provider>
  );
};

export function useDexter(): Dexter {
  return useContext(DexterContext);
}

export const DexterConsumer: Consumer<Dexter> = DexterContext.Consumer;
