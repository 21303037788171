import { Flex, Text, Box, HStack, VStack } from "@chakra-ui/react";
import { NumberFormatSpecifier, formatAmount } from "@wizard-ui/core";

import { Asset, TokenIcon, useDexter, useTokenInfo } from "modules/common";

interface Props {
  data: {
    swaps: any;
    tokenIn: string;
    tokenOut: string;
    beliefPrice: string;
    fees: Asset[];
    feesInUsd: number;
  };
}

export function SwapInfoTokens({ data }: Props) {
  const { prices } = useDexter();
  const { tokenIn, tokenOut } = data;
  const { getSymbol } = useTokenInfo();

  return (
    <VStack mt="6" align="normal" spacing="4">
      {[tokenIn, tokenOut].map((token) => {
        return (
          <Flex key={token} align="center" justify="space-between">
            <HStack spacing="4" w={"40"}>
              <TokenIcon token={token} size="8" />
              <Box>
                <Text fontWeight="700" mb="1">
                  {getSymbol(token)}
                </Text>
                <Text fontSize="sm">Persistence</Text>
              </Box>
            </HStack>
            <Box w={"40%"} textAlign="center">
              <Text fontSize="sm" mb="1">
                Price
              </Text>
              <Text fontWeight="700">
                {formatAmount(prices?.[token].price, {
                  formatSpecifier: NumberFormatSpecifier.CURRENCY,
                })}
              </Text>
            </Box>
            <Box w={"20%"} textAlign="end">
              <Text fontSize="sm" mb="1">
                24H%
              </Text>
              <Text
                fontWeight="700"
                color={
                  Number(prices?.[token].price_change) < 0
                    ? "#B95663"
                    : "#10EA88"
                }
              >
                {formatAmount(prices?.[token].price_change, {
                  formatSpecifier: NumberFormatSpecifier.PERCENTAGE,
                })}
              </Text>
            </Box>
          </Flex>
        );
      })}
    </VStack>
  );
}
