import { useEffect, useMemo, useState } from "react";
import {
  ModalProps,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Box,
  Text,
  Button,
  Flex,
  CloseButton,
} from "@chakra-ui/react";
import { formatAmount, NumberFormatSpecifier } from "@wizard-ui/core";

import { TxError, TxPending, TxSuccess } from "modules/common";
import { useClaimRewardsMutation } from "modules/contracts";
import {
  useUnclaimedRewardsInUsd,
  ClaimRewardsModalItem,
  useUnclaimedRewardsPerDenom,
} from "modules/assets";
import { toast } from "react-toastify";
import PoolUnclaimedRewards from "./PoolUnclaimedRewards";

export function ClaimRewardsModal({
  onClose,
  ...rest
}: Omit<ModalProps, "children">) {
  const unclaimedRewardsInUsd = useUnclaimedRewardsInUsd();
  const unclaimedRewardsPerDenom = useUnclaimedRewardsPerDenom();
  const { mutate, isLoading, data, reset, error } = useClaimRewardsMutation();

  const [isLedger, setIsLedger] = useState(false);
  useEffect(() => {
    async function checkLedger() {
      if (typeof window !== "undefined") {
        const key = await window?.keplr.getKey("core-1");
        setIsLedger(key.isNanoLedger);
      }
    }
    checkLedger();
  }, []);

  const handleClose = () => {
    onClose();
    reset();
  };

  const handleClick = () => {
    mutate();
  };

  const items = useMemo(() => {
    if (unclaimedRewardsPerDenom == null) {
      return [];
    }

    return Object.keys(unclaimedRewardsPerDenom).map((key) => {
      return {
        token: key,
        amount: unclaimedRewardsPerDenom[key],
      };
    });
  }, [unclaimedRewardsPerDenom]);

  const renderForm = () => {
    if (isLoading || data != null || error != null) {
      return null;
    }

    return (
      <Box>
        <Flex justify="space-between" mb="4">
          <Text fontFamily="heading" fontSize="2xl" fontWeight="500">
            Total Rewards
          </Text>
          <Text fontSize="2xl" fontWeight="600">
            {formatAmount(unclaimedRewardsInUsd, {
              formatSpecifier: NumberFormatSpecifier.CURRENCY,
            })}
          </Text>
        </Flex>
        <Box bg="primary.prussianBlue" borderRadius="xl" w="full" p="4" mb="6">
          {items.map((data) => {
            return <ClaimRewardsModalItem key={data.token} data={data} />;
          })}
        </Box>
        <PoolUnclaimedRewards />

        {!isLedger ? (
          <Button
            variant="primary"
            type="button"
            w="full"
            size="xl"
            isLoading={isLoading}
            onClick={handleClick}
          >
            Claim All Rewards
          </Button>
        ) : null}
      </Box>
    );
  };

  const CustomCloseButton = ({ closeToast }) => (
    <CloseButton
      pos={"absolute"}
      left="0"
      top={"0"}
      color="#FCFCFC"
      w={34}
      h={34}
      borderRadius={"50%"}
      border={"2px solid #386794"}
      bg={"#0a1d32"}
      onClick={() => {
        handleClose();
        closeToast();
      }}
    />
  );

  const renderPending = () => {
    if (!isLoading) {
      return null;
    }

    return <TxPending />;
  };

  const renderFinal = () => {
    if (isLoading) {
      return toast(<TxPending />, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: false,
        toastId: "",
        closeButton: CustomCloseButton,
      });
    } else {
      if (data == null && error != null) {
        console.log({ error });
        return toast.update("", {
          render: <TxError />,
          closeButton: CustomCloseButton,
          autoClose: 7000,
        });
      }

      if (data == null) {
        return null;
      }

      return toast.update("", {
        render: () => (
          <TxSuccess
            subtitle="Rewards Successfully Claimed"
            data={data}
            title="Transaction Successful"
          />
        ),
        closeButton: CustomCloseButton,
        autoClose: 7000,
      });
    }
  };
  const hideTitle = isLoading || data != null || error != null;
  return !hideTitle ? (
    <Modal size="lg" onClose={handleClose} isCentered {...rest}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Claim Rewards
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          {renderForm()}

          {/* {renderPending()}
          {renderFinal()} */}
        </ModalBody>
      </ModalContent>
    </Modal>
  ) : (
    renderFinal()
  );
}
