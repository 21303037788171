import { Icon, IconProps } from "@chakra-ui/react";

export function SearchIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 25" {...props}>
      <path
        d="M10 18.5C11.775 18.4996 13.4988 17.9054 14.897 16.812L19.293 21.208L20.707 19.794L16.311 15.398C17.405 13.9997 17.9996 12.2754 18 10.5C18 6.089 14.411 2.5 10 2.5C5.589 2.5 2 6.089 2 10.5C2 14.911 5.589 18.5 10 18.5ZM10 4.5C13.309 4.5 16 7.191 16 10.5C16 13.809 13.309 16.5 10 16.5C6.691 16.5 4 13.809 4 10.5C4 7.191 6.691 4.5 10 4.5Z"
        fill="currentColor"
      />
    </Icon>
  );
}
