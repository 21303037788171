const variants = {
  primary: {
    tab: {
      background: "transparent",
      _selected: {
        bg: "primary.cta",
        borderRadius: "12px",
      },
    },
    tablist: {
      // border: "1px solid",
      bg: "primary.dexBlue20",
      borderColor: "stroke.dexBlue",
      borderRadius: "12px",
    },
  },
};

export default {
  variants,
  defaultProps: {
    variant: "primary",
  },
};
