import {
  Text,
  Box,
  BoxProps,
  Heading,
  Skeleton,
  SkeletonText,
  Button,
  Flex,
  useMediaQuery,
} from "@chakra-ui/react";
import { PlusIcon } from "lucide-react";

import { Wrapper } from "modules/common";
import { useRouter } from "next/router";

interface Props extends BoxProps {
  title: string;
  description: string;
  connecting?: boolean;
  children?: React.ReactNode;
}

export function DexterEmptyCard({
  title,
  description,
  connecting,
  children,
  ...rest
}: Props) {
  const router = useRouter();
  const [isMobile] = useMediaQuery("(max-width: 800px)");
  return (
    <Box {...rest} mt={[0, 0, 0, 12, 12, 12]}>
      <Flex justify={"space-between"}>
        <Heading variant="dexterH1" mb="6">
          {title}
        </Heading>
        {title === "All Pools" && !isMobile ? (
          <Button
            variant={"secondary"}
            size={"lg"}
            leftIcon={<PlusIcon size={16} />}
            onClick={() => {
              router.push("/create-pool");
            }}
          >
            Create New Pool
          </Button>
        ) : null}
      </Flex>
      <Skeleton
        startColor="primary.prussianBlue80"
        endColor="primary.ctaDisabled"
        isLoaded={!connecting}
      >
        <Wrapper variant="empty" p={2} h="150px">
          <Text variant="dexterP1">{description}</Text>
          {children}
        </Wrapper>
      </Skeleton>
    </Box>
  );
}
