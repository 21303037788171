import { useQuery } from "@tanstack/react-query";
import { PRICES_ENDPOINT } from "config";
import { cloneDeep } from "lodash";

import { Token } from "modules/common";

export const usePrices = (tokens: Token[]) => {
  return useQuery(
    ["prices", tokens],
    async () => {
      if (tokens == null) {
        throw new Error("Error in usePrices");
      }

      const res = await fetch(PRICES_ENDPOINT);

      const data = await res.json();
      const prices = cloneDeep(tokens) as Record<string, any>;
      const dataObject = data.price.reduce((acc, obj) => {
        acc[obj.asset_id] = obj;
        return acc;
      }, {});
      Object.keys(prices).forEach((key: string) => {
        const { coingecko_asset_id, identifier } = prices[key];

        prices[key] = {
          price: dataObject[coingecko_asset_id]?.price_usd,
          price_change: dataObject[coingecko_asset_id]?.last_24h_change,
        };
      });
      return prices;
    },
    {
      enabled: tokens != null && tokens.length !== 0,
      refetchOnWindowFocus: false,
    },
  );
};
