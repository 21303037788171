import React from "react";
import type { Control, ControllerProps } from "react-hook-form";
import { useController } from "react-hook-form";
import { Flex, Input, Spinner, Text } from "@chakra-ui/react";
import { useRifm } from "rifm";
import {
  parseNumber,
  numberAccept,
  formatFloatingPointNumber,
  num,
  formatAmount,
  NumberFormatSpecifier,
} from "@wizard-ui/core";
import { useDexter } from "modules/common";

interface Props extends React.ComponentProps<"input"> {
  /**
   *  provide `name`, `control` & optional `rules`
   */
  name?: ControllerProps["name"];
  control: Control<any>;
  isLpToken?: boolean;
  /**
   * for readonly field, provide `value`
   */
  value?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
}

export function AmountInput({
  token,
  poolType,
  isDisabled,
  isLoading,
  name = "",
  control,
  isLpToken = false,
  onChange,
  ...props
}: Props) {
  // we decided decimal input needs rifm mask for UX
  // mask needs controller to expose `onChange` & `value` for transforms
  const { field } = useController({
    name,
    control,
    rules: {
      validate: (value) => {
        return num(value).gte(0); // TODO: Amount >= 0 check if affecting anywhere else
      },
    },
    defaultValue: props.value || "",
  });
  const { prices, pools } = useDexter();

  const rifm = useRifm({
    value: (props.value ?? field.value) as string,
    onChange: (value: string) => {
      const changeEvent = {
        target: { value: parseNumber(value) },
      } as React.ChangeEvent<HTMLInputElement>;
      field.onChange?.(changeEvent); // react-hook-form onChange
      onChange?.(changeEvent); // parent onChange, if any
    },
    accept: numberAccept,
    format: (v) => formatFloatingPointNumber(v, 6),
  });
  return (
    <Flex textAlign="right" flexDir="column">
      {!isLoading && (
        <>
          <Input
            variant="dexterInline"
            size="md"
            // type="tel"
            maxLength={12}
            value={rifm.value}
            onChange={rifm.onChange}
            onBlur={field.onBlur}
            isDisabled={isDisabled}
            placeholder="0"
            _disabled={{
              color: "white",
              cursor: "not-allowed",
            }}
          />
          {isLpToken ? (
            <Text fontSize="xs" color="whiteAlpha.500">
              {formatAmount(
                pools
                  ?.filter((pool) => pool.lpToken == token)[0]
                  .getLpAmountInUsd(
                    num(rifm.value.replaceAll(",", ""))
                      .times(10 ** 18)
                      .toString(),
                  ) || "0",
                {
                  formatSpecifier: NumberFormatSpecifier.CURRENCY,
                },
              )}
            </Text>
          ) : (
            <Text fontSize="xs" color="whiteAlpha.500">
              {formatAmount(
                rifm.value.includes(",")
                  ? Number(rifm.value.replaceAll(",", "")) *
                      prices?.[token]?.price
                  : Number(rifm.value) * prices?.[token]?.price,
                {
                  formatSpecifier: NumberFormatSpecifier.CURRENCY,
                },
              )}
            </Text>
          )}
        </>
      )}
      {isLoading && <Spinner color="whiteAlpha.500" />}
    </Flex>
  );
}
