// TO REMOVE

export type TokenPairType = { from: string; to?: string };

/*
 *
 * Dexter
 *
 */

export type Token = {
  coingecko_asset_id: string;
  decimals: number;
  ibc_asset: boolean;
  identifier: string;
  image_url: string;
  marketing_url: string;
  name: string;
  ticker: string;
  type: string;
  chain: string;
  whitelist_for_swap: boolean;
};
export type ibcToken = {
  ibcDenom: string;
  remoteChainExplorer: string;
  chainId: string;
  endpoint: string;
  depositChannel: string;
  denom: string;
  remote_port_id?: string;
  withdrawChannel: string;
  source_port_id?: string;
};

export type Tokens = {
  [token: string]: Token;
};

/*
 *
 * POOL
 *
 */

export type CW20AssetInfo = { token: { contract_addr: string } };
export type NativeAssetInfo = { native_token: { denom: string } };

export type AssetInfo = CW20AssetInfo | NativeAssetInfo;

export type CW20Asset = {
  amount: string;
  info: CW20AssetInfo;
};
export type NativeAsset = {
  amount: string;
  info: NativeAssetInfo;
};

export type Asset = CW20Asset | NativeAsset;

/*
 *
 * Swap contract
 *
 */

export type SwapType =
  | {
      give_in: {
        [k: string]: unknown;
      };
    }
  | {
      give_out: {
        [k: string]: unknown;
      };
    }
  | {
      custom: string;
    };

/*
 *
 * GRAPHQL contract
 *
 */

export * from "./graphql";
