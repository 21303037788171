import { useChain } from "@cosmos-kit/react";
import { useQuery } from "@tanstack/react-query";

import { useContracts } from "modules/common";
import { getInstantUnlockFee } from "modules/contracts";

export const useInstantUnlockFee = (lpToken: string, token_lock: any) => {
  const persistenceChain =
    process.env.NEXT_PUBLIC_ENV === "testnet"
      ? "persistencetestnet2"
      : process.env.NEXT_PUBLIC_ENV === "devnet"
      ? "Dexter Devnet"
      : "persistence";
  const chainContext = useChain(persistenceChain);
  const { address, getCosmWasmClient } = chainContext;
  const { multistaking } = useContracts();
  return useQuery(
    [
      "instantUnlockFee",
      getCosmWasmClient,
      multistaking,
      address,
      lpToken,
      token_lock,
    ],
    async () => {
      const client = await getCosmWasmClient();
      if (
        client == null ||
        multistaking == null ||
        address == null ||
        lpToken == null ||
        token_lock == null
      ) {
        throw new Error("Error in useInstantUnlockFee");
      }

      const instant_unlock_fee = await getInstantUnlockFee({
        client,
        lpToken,
        multistaking,
        address,
        token_lock,
      });

      return instant_unlock_fee;
    },
    {
      // Revisit the refetch interval
      // refetchInterval: 5000,
      enabled:
        getCosmWasmClient != null &&
        multistaking != null &&
        address != null &&
        lpToken != null,
    },
  );
};
