import { useMemo } from "react";
import { Box, HStack, Text, Flex } from "@chakra-ui/react";
import { formatAmount, num, NumberFormatSpecifier } from "@wizard-ui/core";

import { TokenIcon, useDexter, useTokenInfo } from "modules/common";

interface Props {
  token: string;
  amount: string;
}

export function TxTokensItem({ amount, token }: Props) {
  const { prices } = useDexter();
  const { getSymbol } = useTokenInfo();
  const totalPrice = useMemo(() => {
    if (amount == null || prices == null) {
      return null;
    }

    return num(prices[token].price).times(amount).toString();
  }, [amount, prices, token]);

  return (
    <Flex key={token} justify="space-between">
      <HStack>
        <TokenIcon token={token} size="8" />
        <Text fontFamily="heading" fontSize="xl" fontWeight="600">
          {getSymbol(token)}
        </Text>
      </HStack>
      <Box textAlign="right">
        <Text fontSize="lg" fontWeight="600">
          {formatAmount(amount, {
            formatSpecifier: NumberFormatSpecifier.FLOAT,
          })}
        </Text>
        <Text fontSize="sm">
          {formatAmount(totalPrice, {
            formatSpecifier: NumberFormatSpecifier.CURRENCY,
          })}
        </Text>
      </Box>
    </Flex>
  );
}
